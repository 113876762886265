import Grid from '@material-ui/core/Grid';

const TreeItemLabelActions = ({
  children,
}) => (
  <Grid item xs="auto">
    {children}
  </Grid>
);

export default TreeItemLabelActions;
