import {
  RENDER_INTERIORS_LIST,
  RENDER_INTERIORS_COUNTS,
  PUT_INTERIOR_SCENES,
  RESET_INTERIOR_SCENES,
  RESET_INTERIORS,
  RENDER_INTERIOR,
  SORTED_ID, DELETE_INTERIOR_ERROR,
  CREATE_INTERIOR_ERROR,
  UPDATE_INTERIOR_ERROR,
  CREATE_INTERIOR_SUCCESS,
  UPDATE_INTERIOR_SUCCESS,
  UPDATE_INTERIOR,
  CREATE_INTERIOR,
  RESET_INTERIOR_API_STATE, GET_INTERIOR_USAGE_SUCCESS,
} from './actions';
import { SortId } from '@app/sort/sortValues';

const defaultInteriors = {
  items: [],
  total: 0,
};

const initialState = {
  interiors: defaultInteriors,
  counts: [],
  scenes: null,
  sorting: {
    id: SortId.AtoZ,
    label: 'A-Z',
  },
  interior: {
    name: '',
    url: '',
    width: '',
    height: '',
  },
  sortedId: SortId.AtoZ,
  state: 'initial',
  error: null,
  usage: [],
};

export default function interiorsReducer(state = initialState, action) {
  switch (action.type) {
    case RENDER_INTERIORS_LIST:
      return {
        ...state,
        interiors: action.interiors,
      };
    case RENDER_INTERIORS_COUNTS:
      return {
        ...state,
        counts: action.payload,
      };
    case PUT_INTERIOR_SCENES:
      return {
        ...state,
        scenes: action.payload,
      };
    case RESET_INTERIOR_SCENES:
      return {
        ...state,
        scenes: null,
      };
    case RESET_INTERIORS:
      return {
        ...state,
        interiors: defaultInteriors,
      };
    case RENDER_INTERIOR:
      return {
        ...state,
        interior: action.interior,
      };
    case SORTED_ID:
      return {
        ...state,
        sortedId: action.value,
      };
    case CREATE_INTERIOR:
    case UPDATE_INTERIOR:
      return {
        ...state,
        state: 'processing',
      };
    case DELETE_INTERIOR_ERROR:
      if (action.id && action.error) {
        return {
          ...state,
          error: {
            id: action.id,
            data: action.error,
            type: 'DELETE',
          },
          state: 'error',
        };
      }
      return {
        ...state,
        error: null,
        state: 'success',
      };
    case CREATE_INTERIOR_ERROR:
      if (action.error) {
        return {
          ...state,
          error: {
            data: action.error,
            type: 'CREATE',
          },
          state: 'error',
        };
      }
      return {
        ...state,
        error: null,
        state: 'success',
      };
    case UPDATE_INTERIOR_ERROR:
      if (action.error) {
        return {
          ...state,
          error: {
            data: action.error,
            type: 'UPDATE',
          },
          state: 'error',
        };
      }
      return {
        ...state,
        error: null,
        state: 'success',
      };
    case CREATE_INTERIOR_SUCCESS:
    case UPDATE_INTERIOR_SUCCESS:
      return {
        ...state,
        state: 'success',
      };
    case RESET_INTERIOR_API_STATE:
      return {
        ...state,
        state: 'initial',
        error: null,
      };
    case GET_INTERIOR_USAGE_SUCCESS:
      return {
        ...state,
        usage: action.data,
      };
    default:
      return state;
  }
}
