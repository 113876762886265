import { useCallback, useState, useRef } from 'react';

export const Types = {
  Default: 'default',
  Delete: 'delete',
  Edit: 'edit',
};

const AlertContent = {
  [Types.Default]: {
    ariaAction: Types.Default,
    title: 'Are you sure you want to do it?',
  },
  [Types.Delete]: {
    ariaAction: Types.Delete,
    title: 'Are you sure you want to delete it permanently?',
  },
  [Types.Edit]: {
    ariaAction: Types.Edit,
    title: 'Are you sure you want to edit it?',
  },
};

const useAlertControl = () => {
  const [alertOpen, setAlertOpen] = useState(false);
  const [type, setType] = useState(Types.Default);
  const actionCallbackRef = useRef(null);

  const [title, setTitle] = useState(null);

  const handleAlertControlClick = (newType, callback) => (event) => {
    event?.stopPropagation();
    setAlertOpen(true);
    setType(newType);
    actionCallbackRef.current = callback;
  };

  const handleAgree = useCallback(() => {
    setAlertOpen(false);
    actionCallbackRef.current?.();
  }, []);

  const handleDisagree = useCallback((event) => {
    event.stopPropagation();
    setAlertOpen(false);
  }, []);

  return [alertOpen, {
    handleAlertControlClick,
    handleAgree,
    handleDisagree,
    setTitle,
    ...AlertContent[type],
    title: title ?? AlertContent[type].title,
  }];
};

export default useAlertControl;
