import { Point3D, Vector3D } from '../types';

export function p(x: number, y: number, z: number): Point3D {
  return { x, y, z };
}

export function v(x: number, y: number, z: number): Vector3D {
  return p(x, y, z);
}

export function zero(): Point3D {
  return p(0, 0, 0);
}

export function distance(a: Point3D, b: Point3D): number {
  return Math.sqrt(Math.pow(b.x - a.x, 2) + Math.pow(b.y - a.y, 2) + Math.pow(b.z - a.z, 2));
}

export function length(vector: Vector3D): number {
  return Math.sqrt(Math.pow(vector.x, 2) + Math.pow(vector.y, 2) + Math.pow(vector.z, 2));
}

export function normalized(vector: Vector3D): Vector3D {
  const l = length(vector);

  if (l == 0) {
    return v(0, 0, 0);
  }
  return v(vector.x / l, vector.y / l, vector.z / l);
}

export function cross(l: Vector3D, r: Vector3D): Vector3D {
  return v(
    l.y * r.z - l.z * r.y,
    l.z * r.x - l.x * r.z,
    l.x * r.y - l.y * r.x,
  );
}

export function dot(a: Vector3D, b: Vector3D): number {
  return a.x * b.x + a.y * b.y + a.z * b.z;
}

export function add(a: Point3D, b: Point3D): Point3D {
  return p(a.x + b.x, a.y + b.y, a.z + b.z);
}

export function substract(a: Point3D, b: Point3D | number): Point3D {
  if (typeof b === 'number') {
    return p(a.x - b, a.y - b, a.z - b);
  }
  return p(a.x - b.x, a.y - b.y, a.z - b.z);
}

export function multiply(a: Point3D, b: Point3D | number): Point3D {
  if (typeof b === 'number') {
    return p(a.x * b, a.y * b, a.z * b);
  }
  return p(a.x * b.x, a.y * b.y, a.z * b.z);
}

export function divide(a: Point3D, b: Point3D | number): Point3D {
  if (typeof b === 'number') {
    return p(a.x / b, a.y / b, a.z / b);
  }
  return p(a.x / b.x, a.y / b.y, a.z / b.z);
}

export function sum(...points: Point3D[]): Point3D {
  return points.reduce((r, v) => ({ x: r.x + v.x, y: r.y + v.y, z: r.z + v.z }), zero());
}

export function center(...points: Point3D[]): Point3D {
  return divide(sum(...points), points.length);
}
