import { createAction, createSlice } from '@reduxjs/toolkit';

import { SortId } from '@app/sort/sortValues';

const initialState = {
  users: [],
  sorting: {
    id: SortId.AtoZ,
    label: 'A-Z',
  },
};

const loadUsers = createAction('users/loadUsers');
const createUser = createAction('users/createUser');
const updateUser = createAction('users/updateUser');
const deleteUser = createAction('users/deleteUser');

const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    renderUsers: (state, action) => {
      state.users = action.payload;
    },
  },
});

export const actions = {
  ...usersSlice.actions,
  loadUsers,
  createUser,
  updateUser,
  deleteUser,
};

export default usersSlice.reducer;
