/* eslint-disable max-len */
import SvgIcon from '@material-ui/core/SvgIcon';

const DotsTreeIcon = (props) => (
  <SvgIcon
    viewBox="0 0 14 16"
    { ...props }
  >
    <path d="M12.9383 10.5157L13.8213 11.0303C13.8758 11.062 13.9209 11.1069 13.9522 11.1605C13.9835 11.2142 14 11.2748 14 11.3365C14 11.3982 13.9835 11.4588 13.9522 11.5125C13.9209 11.5661 13.8758 11.611 13.8213 11.6427L7.37835 15.3981C7.26409 15.4648 7.1333 15.5 7 15.5C6.8667 15.5 6.73591 15.4648 6.62165 15.3981L0.178652 11.6427C0.124174 11.611 0.0790797 11.5661 0.0477689 11.5125C0.0164581 11.4588 0 11.3982 0 11.3365C0 11.2748 0.0164581 11.2142 0.0477689 11.1605C0.0790797 11.1069 0.124174 11.062 0.178652 11.0303L1.06172 10.5157L7 13.9771L12.9383 10.5157ZM12.9383 7.16142L13.8213 7.67599C13.8758 7.70767 13.9209 7.75253 13.9522 7.80619C13.9835 7.85985 14 7.92048 14 7.98216C14 8.04384 13.9835 8.10447 13.9522 8.15813C13.9209 8.21179 13.8758 8.25665 13.8213 8.28833L7 12.2643L0.178652 8.28833C0.124174 8.25665 0.0790797 8.21179 0.0477689 8.15813C0.0164581 8.10447 0 8.04384 0 7.98216C0 7.92048 0.0164581 7.85985 0.0477689 7.80619C0.0790797 7.75253 0.124174 7.70767 0.178652 7.67599L1.06172 7.16142L7 10.6228L12.9383 7.16142ZM7.37762 0.601922L13.8213 4.35734C13.8758 4.38902 13.9209 4.43388 13.9522 4.48754C13.9835 4.5412 14 4.60183 14 4.66351C14 4.72519 13.9835 4.78582 13.9522 4.83948C13.9209 4.89314 13.8758 4.938 13.8213 4.96969L7 8.94563L0.178652 4.96969C0.124174 4.938 0.0790797 4.89314 0.0477689 4.83948C0.0164581 4.78582 0 4.72519 0 4.66351C0 4.60183 0.0164581 4.5412 0.0477689 4.48754C0.0790797 4.43388 0.124174 4.38902 0.178652 4.35734L6.62165 0.601922C6.73591 0.535232 6.8667 0.5 7 0.5C7.1333 0.5 7.26409 0.535232 7.37835 0.601922H7.37762ZM7 2.04571L2.509 4.66351L7 7.28132L11.491 4.66351L7 2.04571Z" fill="currentColor" />
  </SvgIcon>
);

export default DotsTreeIcon;
