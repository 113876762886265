import { ReactNode, useRef, useState, useMemo } from 'react';
import { If, Then } from 'react-if';

import Button from '@shared/components/button';

import { Point3, isNone } from '../utils';
import {
  AbstractStep,
  SolverResult,
  ImageData,
  ZKViewport,
  ZKPosition,
} from '../types';

import Scene from '../components/Scene';
import ModelObject from '../components/ModelObject';
import Stack from '../../stack';

interface ComponentProps {
    image: ImageData
    interior: {
      viewport: ZKViewport
      floorPoints: ZKPosition[]
      height: number,
    }
    onComplete: () => void
    onCancel: () => void
}

function Component({ image, interior, onComplete, onCancel }: ComponentProps) {
  const imageRef: any = useRef<HTMLImageElement>();

  const [[width, height], setSize] = useState([0, 0]);

  const isLoaded = useMemo(() => (width > 0 && height > 0), [width, height]);

  const position = useMemo(() => {
    return Point3.center(...interior.floorPoints);
  }, [interior.floorPoints]);

  const handleImageLoad = () => {
    const el: HTMLImageElement | undefined = imageRef.current;

    if (el) {
      setSize([el.clientWidth, el.clientHeight]);
    }
  };

  return (
    <Stack direction="vertical" gap={ 2 }>
      <div
        style={ {
          position: 'relative',
          overflow: 'hidden',
          lineHeight: 0,
        } }
      >
        <img
          ref={ imageRef }
          style={ {
            maxWidth: '100%',
            maxHeight: '100%',
          } }
          src={ image.url! }
          onLoad={ handleImageLoad }
        />
        <If condition={ isLoaded }>
          <Then>
            <Scene
              interior={ {
                layout: interior,
              } }
              width={ width }
              height={ height }
              style={ {
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
              } }
            >
              <ModelObject
                position={ position }
                rotation={ Math.PI }
              />
            </Scene>
          </Then>
        </If>
      </div>
      <Stack
        direction="row"
        gap={ 2 }
      >
        <Button onClick={ () => onCancel && onCancel() }>Back</Button>
        <Button onClick={ () => onComplete() } variant="contained">Complete</Button>
      </Stack>
    </Stack>
  );
}

class PreviewStep extends AbstractStep {
    private image?: ImageData

    private result?: SolverResult

    private onComplete: (result: SolverResult) => void

    private onCancel: () => void

    get label() {
      return 'Preview';
    }

    get isCompleted(): boolean {
      return !isNone(this.result);
    }

    constructor(image: ImageData, result: SolverResult, onComplete: (result: SolverResult) => void, onCancel: () => void) {
      super();
      this.image = image;
      this.result = result;
      this.onComplete = onComplete;
      this.onCancel = onCancel;
    }

    component(roomHeight: number): ReactNode {
      return (
        <Component
          image={ this.image! }
          interior={ {
            ...this.result!,
            height: roomHeight,
          } }
          onComplete={ () => this.complete() }
          onCancel={ this.onCancel }
        />
      );
    }

    private complete() {
      this.onComplete(this.result!);
    }
}

export default PreviewStep;
