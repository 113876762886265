import { Color } from './utils';

class KelvinColor {
  startingValue = 1000;

  step = 100;

  kelvin;

  map;

  allKelvins;

  // eslint-disable-next-line class-methods-use-this
  rgbToHex(r, g, b) {
    return Color.rgbToHex(r, g, b);
  }

  calcMapId(step) {
    return this.startingValue + this.step * step;
  }

  constructor(color) {
    if (color && color.startsWith('#')) {
      color = color.substring(1);
    }
    this.map = {
      [this.calcMapId(0)]: this.rgbToHex(255, 56, 0),
      [this.calcMapId(1)]: this.rgbToHex(255, 71, 0),
      [this.calcMapId(2)]: this.rgbToHex(255, 83, 0),
      [this.calcMapId(3)]: this.rgbToHex(255, 93, 0),
      [this.calcMapId(4)]: this.rgbToHex(255, 101, 0),
      [this.calcMapId(5)]: this.rgbToHex(255, 109, 0),
      [this.calcMapId(6)]: this.rgbToHex(255, 115, 0),
      [this.calcMapId(7)]: this.rgbToHex(255, 121, 0),
      [this.calcMapId(8)]: this.rgbToHex(255, 126, 0),
      [this.calcMapId(9)]: this.rgbToHex(255, 131, 0),
      [this.calcMapId(10)]: this.rgbToHex(255, 138, 18),
      [this.calcMapId(11)]: this.rgbToHex(255, 142, 33),
      [this.calcMapId(12)]: this.rgbToHex(255, 147, 44),
      [this.calcMapId(13)]: this.rgbToHex(255, 152, 54),
      [this.calcMapId(14)]: this.rgbToHex(255, 157, 63),
      [this.calcMapId(15)]: this.rgbToHex(255, 161, 72),
      [this.calcMapId(16)]: this.rgbToHex(255, 165, 79),
      [this.calcMapId(17)]: this.rgbToHex(255, 169, 87),
      [this.calcMapId(18)]: this.rgbToHex(255, 173, 94),
      [this.calcMapId(19)]: this.rgbToHex(255, 177, 101),
      [this.calcMapId(20)]: this.rgbToHex(255, 180, 107),
      [this.calcMapId(21)]: this.rgbToHex(255, 184, 114),
      [this.calcMapId(22)]: this.rgbToHex(255, 187, 120),
      [this.calcMapId(23)]: this.rgbToHex(255, 190, 126),
      [this.calcMapId(24)]: this.rgbToHex(255, 193, 132),
      [this.calcMapId(25)]: this.rgbToHex(255, 196, 137),
      [this.calcMapId(26)]: this.rgbToHex(255, 199, 143),
      [this.calcMapId(27)]: this.rgbToHex(255, 201, 148),
      [this.calcMapId(28)]: this.rgbToHex(255, 204, 153),
      [this.calcMapId(29)]: this.rgbToHex(255, 206, 159),
      [this.calcMapId(30)]: this.rgbToHex(255, 209, 163),
      [this.calcMapId(31)]: this.rgbToHex(255, 211, 168),
      [this.calcMapId(32)]: this.rgbToHex(255, 213, 173),
      [this.calcMapId(33)]: this.rgbToHex(255, 215, 177),
      [this.calcMapId(34)]: this.rgbToHex(255, 217, 182),
      [this.calcMapId(35)]: this.rgbToHex(255, 219, 186),
      [this.calcMapId(36)]: this.rgbToHex(255, 221, 190),
      [this.calcMapId(37)]: this.rgbToHex(255, 223, 194),
      [this.calcMapId(38)]: this.rgbToHex(255, 225, 198),
      [this.calcMapId(39)]: this.rgbToHex(255, 227, 202),
      [this.calcMapId(40)]: this.rgbToHex(255, 228, 206),
      [this.calcMapId(41)]: this.rgbToHex(255, 230, 210),
      [this.calcMapId(42)]: this.rgbToHex(255, 232, 213),
      [this.calcMapId(43)]: this.rgbToHex(255, 233, 217),
      [this.calcMapId(44)]: this.rgbToHex(255, 235, 220),
      [this.calcMapId(45)]: this.rgbToHex(255, 236, 224),
      [this.calcMapId(46)]: this.rgbToHex(255, 238, 227),
      [this.calcMapId(47)]: this.rgbToHex(255, 239, 230),
      [this.calcMapId(48)]: this.rgbToHex(255, 240, 233),
      [this.calcMapId(49)]: this.rgbToHex(255, 242, 236),
      [this.calcMapId(50)]: this.rgbToHex(255, 243, 239),
      [this.calcMapId(51)]: this.rgbToHex(255, 244, 242),
      [this.calcMapId(52)]: this.rgbToHex(255, 245, 245),
      [this.calcMapId(53)]: this.rgbToHex(255, 246, 247),
      [this.calcMapId(54)]: this.rgbToHex(255, 248, 251),
      [this.calcMapId(55)]: this.rgbToHex(255, 255, 255),
      [this.calcMapId(56)]: this.rgbToHex(254, 249, 255),
      [this.calcMapId(57)]: this.rgbToHex(252, 247, 255),
      [this.calcMapId(58)]: this.rgbToHex(249, 246, 255),
      [this.calcMapId(59)]: this.rgbToHex(247, 245, 255),
      [this.calcMapId(60)]: this.rgbToHex(245, 243, 255),
      [this.calcMapId(61)]: this.rgbToHex(243, 242, 255),
      [this.calcMapId(62)]: this.rgbToHex(240, 241, 255),
      [this.calcMapId(63)]: this.rgbToHex(239, 240, 255),
      [this.calcMapId(64)]: this.rgbToHex(237, 239, 255),
      [this.calcMapId(65)]: this.rgbToHex(235, 238, 255),
      [this.calcMapId(66)]: this.rgbToHex(233, 237, 255),
      [this.calcMapId(67)]: this.rgbToHex(231, 236, 255),
      [this.calcMapId(68)]: this.rgbToHex(230, 235, 255),
      [this.calcMapId(69)]: this.rgbToHex(228, 234, 255),
      [this.calcMapId(70)]: this.rgbToHex(227, 233, 255),
      [this.calcMapId(71)]: this.rgbToHex(225, 232, 255),
      [this.calcMapId(72)]: this.rgbToHex(224, 231, 255),
      [this.calcMapId(73)]: this.rgbToHex(222, 230, 255),
      [this.calcMapId(74)]: this.rgbToHex(221, 230, 255),
      [this.calcMapId(75)]: this.rgbToHex(220, 229, 255),
      [this.calcMapId(76)]: this.rgbToHex(218, 229, 255),
      [this.calcMapId(77)]: this.rgbToHex(217, 227, 255),
      [this.calcMapId(78)]: this.rgbToHex(216, 227, 255),
      [this.calcMapId(79)]: this.rgbToHex(215, 226, 255),
      [this.calcMapId(80)]: this.rgbToHex(214, 225, 255),
      [this.calcMapId(81)]: this.rgbToHex(212, 225, 255),
      [this.calcMapId(82)]: this.rgbToHex(211, 224, 255),
      [this.calcMapId(83)]: this.rgbToHex(210, 223, 255),
      [this.calcMapId(84)]: this.rgbToHex(209, 223, 255),
      [this.calcMapId(85)]: this.rgbToHex(208, 222, 255),
      [this.calcMapId(86)]: this.rgbToHex(207, 221, 255),
      [this.calcMapId(87)]: this.rgbToHex(207, 221, 255),
      [this.calcMapId(88)]: this.rgbToHex(206, 220, 255),
      [this.calcMapId(89)]: this.rgbToHex(205, 220, 255),
      [this.calcMapId(90)]: this.rgbToHex(207, 218, 255),
      [this.calcMapId(91)]: this.rgbToHex(207, 218, 255),
      [this.calcMapId(92)]: this.rgbToHex(206, 217, 255),
      [this.calcMapId(93)]: this.rgbToHex(205, 217, 255),
      [this.calcMapId(94)]: this.rgbToHex(204, 216, 255),
      [this.calcMapId(95)]: this.rgbToHex(204, 216, 255),
      [this.calcMapId(96)]: this.rgbToHex(203, 215, 255),
      [this.calcMapId(97)]: this.rgbToHex(202, 215, 255),
      [this.calcMapId(98)]: this.rgbToHex(202, 214, 255),
      [this.calcMapId(99)]: this.rgbToHex(201, 214, 255),
      [this.calcMapId(100)]: this.rgbToHex(200, 213, 255),
      [this.calcMapId(101)]: this.rgbToHex(200, 213, 255),
      [this.calcMapId(102)]: this.rgbToHex(199, 212, 255),
      [this.calcMapId(103)]: this.rgbToHex(198, 212, 255),
      [this.calcMapId(104)]: this.rgbToHex(198, 212, 255),
      [this.calcMapId(105)]: this.rgbToHex(197, 211, 255),
      [this.calcMapId(106)]: this.rgbToHex(197, 211, 255),
      [this.calcMapId(107)]: this.rgbToHex(197, 210, 255),
      [this.calcMapId(108)]: this.rgbToHex(196, 210, 255),
      [this.calcMapId(109)]: this.rgbToHex(195, 210, 255),
      [this.calcMapId(110)]: this.rgbToHex(195, 209, 255),
    };

    this.allKelvins = Object.keys(this.map).map((key) => Number(key));

    this.kelvin = this.getKelvinByColor(color || this.firstColor) || this.firstKelvin;
  }

  get color() {
    return this.getColorByKelvin(this.kelvin);
  }

  get firstKelvin() {
    return this.allKelvins[0];
  }

  get lastKelvin() {
    return this.allKelvins[this.allKelvins.length - 1];
  }

  get firstColor() {
    return this.getColorByKelvin(this.firstKelvin);
  }

  get lastColor() {
    return this.getColorByKelvin(this.lastKelvin);
  }

  getColorByKelvin(kelvin) {
    return this.map[kelvin];
  }

  getKelvinByColor(color) {
    return this.allKelvins.find((id) => this.getColorByKelvin(id) === color);
  }
}

export default KelvinColor;
