import { forwardRef, memo, useCallback } from 'react';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@shared/components/checkbox';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  root: {
    paddingLeft: '8px',
  },
}));

const MainGroupField = forwardRef(({ name, onChange, onAfterChange, value, disabled }, ref) => {
  const classes = useStyles();

  const handleChange = useCallback((_, checked) => {
    if (onChange) {
      onChange(checked);
    }

    if (onAfterChange) {
      onAfterChange(checked);
    }
  }, [onChange, onAfterChange]);

  return (
    <FormControlLabel
      name={ name }
      inputRef={ ref }
      label="Main Element"
      className={ classes.root }
      control={ (
        <Checkbox
          checked={ value }
          onChange={ handleChange }
          disabled={ disabled }
        />
      ) }
    />
  );
});

export default memo(MainGroupField);
