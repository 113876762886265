import { forwardRef, memo, useCallback, useMemo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';

import Autocomplete from '@shared/components/autocomplete/Autocomplete';
import { TextField } from '@shared/views/form-dialog';

const useStyles = makeStyles({
  textFieldRoot: {
    "& > div.MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root']": {
      paddingRight: '9px',
      '& button': {
        order: 3,
      },
      '& > div.MuiAutocomplete-endAdornment': {
        position: 'relative',
        order: 2,
      },
    },
  },
  option: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    '& > img': {
      width: '40px',
      height: '40px',
    },
  },
});

const CombinationTextField = forwardRef(({
  name,
  label,
  onChange,
  value,
  defaultValue,
  options,
  className,
}, ref) => {
  const classes = useStyles();

  const renderedOptions = useMemo(() => options.filter((o) => o.groupName), [options]);

  const handleChange = useCallback((_, data) => {
    if (data && data.groupName) {
      onChange(data);
    } else {
      const option = renderedOptions.find((op) => op.groupName === data);
      if (option) {
        onChange(option);
        return;
      }
      if (value.groupName === data) {
        return;
      }
      onChange(defaultValue);
    }
  }, [onChange, renderedOptions, name, value, defaultValue]);

  const getOptionObject = useCallback((option) => ((typeof option === 'string' || option instanceof String)
    ? renderedOptions.find((op) => op.groupName === option)
    : option
  ) || defaultValue, [renderedOptions, defaultValue]);

  const getOptionLabel = useCallback((option) => {
    if (!option) {
      return '';
    }
    return getOptionObject(option).groupName || '';
  }, [getOptionObject]);

  const getOptionSelected = useCallback((option, value) => (
    value ? option.groupName === getOptionObject(value).groupName : value
  ), [getOptionObject]);

  return (
    <Autocomplete
      className={ className }
      freeSolo
      options={ renderedOptions }
      getOptionLabel={ getOptionLabel }
      getOptionSelected={ getOptionSelected }
      onChange={ handleChange }
      onBlur={ (e) => handleChange(e, e.target.value) }
      ref={ ref }
      value={ value || defaultValue }
      renderInput={ (params) => (
        <TextField
          { ...params }
          classes={ {
            root: classes.textFieldRoot,
          } }
          label={ label }
          variant="outlined"
          InputProps={ {
            ...params.InputProps,
          } }
        />
      ) }
      renderOption={ (props, { selected }) => (
        <div className={ classNames({ selected }, classes.option) }>
          { props.groupName }
        </div>
      ) }
    />
  );
});

export default memo(CombinationTextField);
