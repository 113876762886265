/* eslint-disable max-len */
import { makeStyles } from '@material-ui/core';
import SvgIcon from '@material-ui/core/SvgIcon';
import { DefaultIconSizeValue, IconSize } from '@resources/icons/constants';

const useStyles = makeStyles(() => ({
  root: {
    fill: 'none',
  },
}));

const ElectricLightBehind = ({ size = IconSize.Medium, selected = false }) => {
  const classes = useStyles();
  return (
    <SvgIcon
      style={ { width: DefaultIconSizeValue[size], height: DefaultIconSizeValue[size] } }
      className={ classes.root }
      viewBox="0 0 32 32"
    >
      <circle cx="16" cy="16" r="14" fill={ selected ? '#ffffff' : '#EBEFF2' } />
      <path
        d="M31 31.5H1C0.723858 31.5 0.5 31.2761 0.5 31V1C0.5 0.723856 0.723858 0.5 1 0.5H31C31.2761 0.5 31.5 0.723856 31.5 1V31C31.5 31.2761 31.2761 31.5 31 31.5Z"
        stroke="#334D6E"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle
        r="5.4824"
        transform="matrix(1 0 0 -1 16.4778 15.4761)"
        fill="#F0F0F0"
        stroke="#334D6E"
      />
      <circle r="3.98827" transform="matrix(0 1 1 0 16.4781 15.4761)" fill="#334D6E" />
      <path d="M9 15.9736H10.9941" stroke="#334D6E" strokeLinecap="round" />
      <path d="M22.4609 15.9736L24 15.998" stroke="#334D6E" strokeLinecap="round" />
      <path d="M16.478 7.99805V9.99218" stroke="#334D6E" strokeLinecap="round" />
      <path d="M16.478 21.459V23.4531" stroke="#334D6E" strokeLinecap="round" />
      <path d="M11.0142 10.2607L12.4242 11.6708" stroke="#334D6E" strokeLinecap="round" />
      <path d="M20.5328 19.7793L21.9429 21.1894" stroke="#334D6E" strokeLinecap="round" />
      <path d="M21.9418 10.2607L20.5318 11.6708" stroke="#334D6E" strokeLinecap="round" />
      <path d="M12.4232 19.7793L11.0131 21.1894" stroke="#334D6E" strokeLinecap="round" />
      <rect y="1" width="2" height="30" fill="#334D6E" />
      <rect x="30" y="1" width="2" height="30" fill="#334D6E" />
      <rect x="1" y="2" width="2" height="30" transform="rotate(-90 1 2)" fill="#334D6E" />
      <rect x="1" y="32" width="2" height="30" transform="rotate(-90 1 32)" fill="#334D6E" />
    </SvgIcon>
  );
};

export default ElectricLightBehind;
