import classNames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import getPreviewInputId from './getPreviewInputId';

const useStyles = makeStyles(() => ({
  root: {
    height: 'inherit',
    width: 'inherit',
    cursor: 'pointer',
  },
}));

const ClickableUpload = ({ children, className, name }) => {
  const classes = useStyles();

  return (
    <label
      htmlFor={ getPreviewInputId(name) }
      className={ classNames(classes.root, className) }
    >
      {children}
    </label>
  );
};

export default ClickableUpload;
