/* eslint-disable max-len */
import { makeStyles } from '@material-ui/core';
import SvgIcon from '@material-ui/core/SvgIcon';
import { DefaultIconSizeValue, IconSize } from '@resources/icons/constants';

const useStyles = makeStyles(() => ({
  root: {
    fill: 'none',
  },
}));

const ElectricLightTop = ({ size = IconSize.Medium, selected = false }) => {
  const classes = useStyles();
  return (
    <SvgIcon
      style={ { width: DefaultIconSizeValue[size], height: DefaultIconSizeValue[size] } }
      className={ classes.root }
      viewBox="0 0 32 32"
    >
      <g clipPath="url(#clip0_1086_9207)">
        <path
          d="M1.15738 30.2008L9.78026 2.7008C9.91101 2.28379 10.2974 2 10.7344 2H20.7715C21.2056 2 21.5902 2.28018 21.7233 2.69347L30.5793 30.1935C30.7872 30.8391 30.3057 31.5 29.6274 31.5H2.11157C1.43652 31.5 0.955405 30.8449 1.15738 30.2008Z"
          fill={ selected ? '#ffffff' : '#EBEFF2' }
        />
        <path
          d="M0.5 1C0.5 0.723858 0.723858 0.5 1 0.5H31C31.2761 0.5 31.5 0.723858 31.5 1V31C31.5 31.2761 31.2761 31.5 31 31.5H1C0.723857 31.5 0.5 31.2761 0.5 31V1Z"
          stroke="#334D6E"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <mask id="path-4-inside-1_1086_9207" fill="white">
          <path d="M0 0H32V32H0V0Z" />
        </mask>
        <path d="M0 2H32V-2H0V2Z" fill="#334D6E" mask="url(#path-4-inside-1_1086_9207)" />
        <mask id="path-6-inside-2_1086_9207" fill="white">
          <path d="M21.9648 0.982405C21.9648 1.76803 21.8101 2.54595 21.5094 3.27177C21.2088 3.99759 20.7681 4.65709 20.2126 5.2126C19.6571 5.76812 18.9976 6.20878 18.2718 6.50943C17.546 6.81007 16.768 6.96481 15.9824 6.96481C15.1968 6.96481 14.4189 6.81007 13.693 6.50943C12.9672 6.20878 12.3077 5.76812 11.7522 5.2126C11.1967 4.65709 10.756 3.99759 10.4554 3.27177C10.1547 2.54595 10 1.76802 10 0.982404L15.9824 0.982405L21.9648 0.982405Z" />
        </mask>
        <path
          d="M21.9648 0.982405C21.9648 1.76803 21.8101 2.54595 21.5094 3.27177C21.2088 3.99759 20.7681 4.65709 20.2126 5.2126C19.6571 5.76812 18.9976 6.20878 18.2718 6.50943C17.546 6.81007 16.768 6.96481 15.9824 6.96481C15.1968 6.96481 14.4189 6.81007 13.693 6.50943C12.9672 6.20878 12.3077 5.76812 11.7522 5.2126C11.1967 4.65709 10.756 3.99759 10.4554 3.27177C10.1547 2.54595 10 1.76802 10 0.982404L15.9824 0.982405L21.9648 0.982405Z"
          fill="#F0F0F0"
          stroke="#334D6E"
          strokeWidth="2"
          mask="url(#path-6-inside-2_1086_9207)"
        />
        <path
          d="M19.9717 0.98241C19.9717 1.50616 19.8685 2.02478 19.6681 2.50866C19.4676 2.99253 19.1739 3.4322 18.8035 3.80254C18.4332 4.17289 17.9935 4.46666 17.5096 4.66709C17.0258 4.86752 16.5071 4.97068 15.9834 4.97068C15.4596 4.97068 14.941 4.86752 14.4571 4.66709C13.9733 4.46666 13.5336 4.17289 13.1633 3.80254C12.7929 3.4322 12.4991 2.99253 12.2987 2.50865C12.0983 2.02478 11.9951 1.50616 11.9951 0.98241L15.9834 0.98241L19.9717 0.98241Z"
          fill="#334D6E"
        />
        <path d="M15.9824 8.46094V6.4668" stroke="#334D6E" strokeLinecap="round" />
        <path d="M10.5195 6.19824L11.9296 4.78818" stroke="#334D6E" strokeLinecap="round" />
        <path d="M21.4463 6.19824L20.0362 4.78818" stroke="#334D6E" strokeLinecap="round" />
      </g>
      <defs>
        <clipPath id="clip0_1086_9207">
          <rect width="32" height="32" rx="1" fill="white" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
};

export default ElectricLightTop;
