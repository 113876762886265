const ENDPOINT = '/users';

class UsersApi {
  constructor(http) {
    this.http = http;
  }

  getUsers = () => this.http.get(ENDPOINT)

  getCurrent = () => this.http.get(`${ENDPOINT}/current`)

  createUser = (user) => this.http.post(ENDPOINT, user)

  updateUser = (id, user) => this.http.put(`${ENDPOINT}/${id}`, user)

  deleteUser = (id) => this.http.delete(`${ENDPOINT}/${id}`)

  register = (data) => this.http.post(`${ENDPOINT}/register`, data)
}

export default UsersApi;
