import { makeStyles } from '@material-ui/core/styles';
import TranslationsDropdown from '@shared/components/translations/TranslationsDropdown';


const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'right',
    justifyContent: 'space-between',
    minHeight: '100%',
  },
}));

const FormTitle = ({ actionType, options, onLangChange, selectedLanguage }) => {
  const classes = useStyles();

  return (
    <div className={ classes.root }>
      <>
        { actionType } Attribute Definition
      </>
      <TranslationsDropdown
        langOptions={ options }
        onLangChange={ onLangChange }
        selectedLanguage={ selectedLanguage }
      />
    </div>
  );
};

export default FormTitle;
