import Grid from '@material-ui/core/Grid';

const TreeItemLabel = ({
  children,
  onClick,
  wrap,
}) => (
  <Grid
    container
    spacing={ 1 }
    justifyContent="space-between"
    alignItems="center"
    wrap={ wrap }
    onClick={ onClick }
  >
    {children}
  </Grid>
);

export default TreeItemLabel;
