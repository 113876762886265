/* eslint-disable max-len */
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core';
import SvgIcon from '@material-ui/core/SvgIcon';
import { IconSize, DefaultIconSizeValue } from './constants';

const useStyles = makeStyles(() => ({
  root: {
    fill: 'currentColor',
  },
}));

const ClosedEye = ({ size = IconSize.Medium, className }) => {
  const classes = useStyles();
  return (
    <SvgIcon
      style={ { width: DefaultIconSizeValue[size], height: DefaultIconSizeValue[size] } }
      className={ classNames(classes.root, className) }
      viewBox="0 0 14 6"
    >
      <path d="M8.75044 6.00024C8.58862 6.00423 8.43044 5.95155 8.30281 5.85116C8.17518 5.75077 8.086 5.60889 8.05044 5.44965L7.84044 4.54612C7.2678 4.60258 6.69107 4.60258 6.11844 4.54612L5.91544 5.44965C5.87459 5.63686 5.76167 5.80005 5.60152 5.9033C5.44136 6.00656 5.24709 6.04143 5.06144 6.00024C4.87578 5.95905 4.71396 5.84519 4.61157 5.68368C4.50917 5.52218 4.47459 5.32628 4.51544 5.13906L4.71144 4.27083C4.09996 4.09128 3.51056 3.84264 2.95444 3.52965L2.25444 4.34142C2.13562 4.48557 1.96488 4.57622 1.77978 4.59343C1.59468 4.61064 1.41039 4.553 1.26744 4.43318C1.12448 4.31337 1.03459 4.14119 1.01752 3.95454C1.00046 3.76789 1.05762 3.58204 1.17644 3.43789L1.74344 2.73201C1.14916 2.26565 0.606971 1.73547 0.126436 1.15083C0.00854759 1.002 -0.0458817 0.812033 -0.0248776 0.622732C-0.00387357 0.43343 0.0908432 0.260296 0.238436 0.141417C0.386029 0.0225374 0.574408 -0.0323492 0.762132 -0.0111686C0.949856 0.0100119 1.12155 0.105525 1.23944 0.254358C1.79272 0.9349 2.44673 1.52536 3.17844 2.00495C3.94232 2.51022 4.79551 2.86251 5.69144 3.04259H5.74744C6.56593 3.20137 7.40694 3.20137 8.22544 3.04259H8.28144C9.17697 2.86117 10.0299 2.50898 10.7944 2.00495C11.5261 1.52536 12.1802 0.9349 12.7334 0.254358C12.8523 0.110205 13.023 0.0195527 13.2081 0.00234347C13.3932 -0.0148657 13.5775 0.0427776 13.7204 0.162593C13.8634 0.282409 13.9533 0.454582 13.9704 0.641236C13.9874 0.827889 13.9303 1.01373 13.8114 1.15789C13.3309 1.74253 12.7887 2.27271 12.1944 2.73906L12.7614 3.44495C12.8803 3.5891 12.9374 3.77495 12.9204 3.9616C12.9033 4.14825 12.8134 4.32043 12.6704 4.44024C12.5275 4.56006 12.3432 4.6177 12.1581 4.60049C11.973 4.58328 11.8023 4.49263 11.6834 4.34848L11.0464 3.52965C10.4751 3.84855 9.86907 4.09963 9.24044 4.27789L9.42944 5.14612C9.46925 5.32764 9.43645 5.51767 9.33815 5.67494C9.23986 5.83221 9.084 5.94403 8.90444 5.98612C8.85396 5.99773 8.80216 6.00248 8.75044 6.00024Z" />
    </SvgIcon>
  );
};

export default ClosedEye;
