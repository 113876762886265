import { useEffect } from 'react';
import { useThree } from '@react-three/fiber';
import CubeController from './CubeController';
import Lights from './Lights';

const Scene = ({
  disabled,
  rotation,
  onRotationEnd,
}) => {
  const { camera } = useThree();

  useEffect(() => {
    camera.position.set(0, 0, 2);
  }, [camera]);

  return (
    <>
      <CubeController
        disabled={ disabled }
        rotation={ rotation }
        onRotationEnd={ onRotationEnd }
      />
      <Lights />
    </>
  );
};

export default Scene;
