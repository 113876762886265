import { useMemo } from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { makeStyles } from '@material-ui/core/styles';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import DropdownItemSelectedIcon from '@resources/icons/DropdownItemSelectedIcon';
import SizeIcon from '@resources/icons/SizeIcon';

const useStyles = makeStyles(() => ({
  root: {
    position: 'absolute',
    top: 0,
    left: '150px',
    zIndex: 1,
  },
  lightsDropdown: {
    height: '41px',
    margin: '15.5px 0 0 21px',

    '& .MuiSelect-select': {
      padding: '10px',
      border: '1px solid #767676',
      borderRadius: '2px',
      backgroundColor: '#E2EAF3',
    },
  },
  dropdownValueWrapper: {
    display: 'flex',
    gap: '10px',
  },
  dropdownValueIcon: {
    color: '#334D6E',
    width: '13px',
    height: '18px',
    flexShrink: 0,
  },
  dropdownValue: {
    fontSize: '14px',
    fontWeight: '400',
    lineHeight: '21px',
    letterSpacing: '0.01em',
    color: '#102035',
  },
  dropdownPopover: {
    '& .MuiMenu-list': {
      padding: '0',
      width: '206px',
    },

    '& .MuiListItem-root': {
      textAlign: 'left',
      color: '#272D4E',
      fontSize: '15px',
      fontWeight: '500',
      lineHeight: '23px',
      letterSpacing: '0em',
      padding: '4.5px 16px',

      '&:hover': {
        backgroundColor: '#D6E0EE',
      },

      '& .MuiSvgIcon-root': {
        color: '#00C98F',
        marginLeft: '9px',
      },
    },
  },
}));

const TextureSizeController = ({
  name,
}) => {
  const classes = useStyles();
  const {
    control,
  } = useFormContext();
  const sizeOptions = useMemo(() => [{
    name: '128x128',
    value: 128,
  }, {
    name: '256x256',
    value: 256,
  }, {
    name: '512x512',
    value: 512,
  }, {
    name: '1024x1024',
    value: 1024,
  }, {
    name: '2048x2048',
    value: 2048,
  }, {
    name: '4096x4096',
    value: 4096,
  }, {
    name: 'Original',
    value: 0,
  }], []);

  return (
    <div className={ classes.root }>
      <Controller
        name={ name }
        control={ control }
        render={ ({ field }) => (
          <Select
            { ...field }
            className={ classes.lightsDropdown }
            disableUnderline
            IconComponent={ () => null }
            MenuProps={ {
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'left',
              },
              getContentAnchorEl: null,
              PopoverClasses: { paper: classes.dropdownPopover },
            } }
            renderValue={ (data) => {
              const item = sizeOptions.find((i) => i.value === data);
              return (
                <div className={ classes.dropdownValueWrapper }>
                  <SizeIcon className={ classes.dropdownValueIcon } size="small" />
                  <span className={ classes.dropdownValue }>{item.name}</span>
                </div>
              );
            } }
          >
            {sizeOptions.map((option) => (
              // eslint-disable-next-line react/no-array-index-key
              <MenuItem key={ option.value } value={ option.value }>
                {option?.name || 'Original'}
                {field.value?.name === option?.name ? (
                  <DropdownItemSelectedIcon />
                ) : null}
              </MenuItem>
            ))}
          </Select>
        ) }
      />
    </div>
  );
};

export default TextureSizeController;
