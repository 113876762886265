import { SpotLightFieldset } from './fieldset';
import SpotDiameter from './lightOptions/SpotDiameter';
import SelectSpotCenterOption from './lightOptions/SelectSpotCenterOption';
import toFixedNumber from '../utils/toFixedNumber';
import calcDiameterFromAngle from '../utils/calcDiameterFromAngle';
import calcAngleFromDiameter from '../utils/calcAngleFromDiameter';
import PositionOption from '@pages/scenes/child-details/LightDetails/lightOptions/PositionOption';

const SpotLightOptions = ({
  options,
  sceneBox,
  onChange,
}) => {
  const diameter = calcDiameterFromAngle(options[SpotLightFieldset.Distance], options[SpotLightFieldset.Angle]);

  const handleDiameterChange = ({ value }, name) => {
    const angle = calcAngleFromDiameter(value, options[SpotLightFieldset.Distance]);
    onChange({ value: angle }, name);
  };

  const handleSelectSpotCenter = (name, value) => {
    onChange({ value: !!value }, name);
  };

  return (
    <>
      <PositionOption
        name={ SpotLightFieldset.Target }
        value={ options[SpotLightFieldset.Target] }
        label="Target"
        onChange={ onChange }
      />
      <SpotDiameter
        name={ SpotLightFieldset.Angle }
        value={ toFixedNumber(diameter, 2) }
        onChange={ handleDiameterChange }
        sceneBox={ sceneBox }
      />
    </>
  );
};

export default SpotLightOptions;
