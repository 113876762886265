import { all, call, put, takeEvery } from 'redux-saga/effects';

import { companiesApi } from '@api';

import { actions } from '.';

const { loadCompanies, createCompany, updateCompany, deleteCompany, renderCompanies, generateApiToken } = actions;

function* loadCompaniesSaga() {
  try {
    const { items } = yield call(companiesApi.getCompanies);
    yield put(renderCompanies(items));
  } catch (e) {
    console.error(e);
  }
}

function* createCompanySaga(action) {
  try {
    yield call(companiesApi.createCompany, action.payload);
    yield put(loadCompanies());
  } catch (e) {
    console.error(e);
  }
}

function* updateCompanySaga(action) {
  try {
    const { id, company } = action.payload;
    yield call(companiesApi.updateCompany, id, company);
    yield put(loadCompanies());
  } catch (e) {
    console.error(e);
  }
}

function* generateCompanyApiTokenSaga(action) {
  try {
    const { id } = action.payload;
    yield call(companiesApi.generateCompanyApiToken, id);
    yield put(loadCompanies());
  } catch (e) {
    console.error(e);
  }
}

function* deleteCompanySaga(action) {
  try {
    yield call(companiesApi.updateCompany, action.payload.id, { ...action.payload, isDeleted: true });
    yield put(loadCompanies());
  } catch (e) {
    console.error(e);
  }
}

export default function* companiesSaga() {
  yield all([
    takeEvery(loadCompanies, loadCompaniesSaga),
    takeEvery(createCompany, createCompanySaga),
    takeEvery(updateCompany, updateCompanySaga),
    takeEvery(deleteCompany, deleteCompanySaga),
    takeEvery(generateApiToken, generateCompanyApiTokenSaga),
  ]);
}
