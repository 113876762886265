import MuiPaper from '@material-ui/core/Paper';

const Paper = ({
  children, className, component, noValidate, onSubmit,
}) => (
  <MuiPaper
    className={ className }
    component={ component }
    noValidate={ noValidate }
    onSubmit={ onSubmit }
  >
    {children}
  </MuiPaper>
);

export default Paper;
