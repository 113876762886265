/* eslint-disable max-len */

const ModelsIcon = () => (
  <svg
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.6093 9.24473H16.715V2.92618C16.715 1.91923 15.7365 1.10001 14.5337 1.10001H7.46628C6.26351 1.10001 5.28497 1.91923 5.28497 2.92618V9.24473H4.39064C3.78925 9.24473 3.29999 9.65434 3.29999 10.1578V12.2031C3.29999 12.7066 3.78925 13.1162 4.39064 13.1162H4.92144V18.8869C4.92144 19.3904 5.4107 19.8 6.01209 19.8H7.75713C8.35852 19.8 8.84778 19.3904 8.84778 18.8869V16.5494H13.1522V18.8869C13.1522 19.3904 13.6415 19.8 14.2428 19.8H15.9879C16.5893 19.8 17.0785 19.3904 17.0785 18.8869V13.1162H17.6093C18.2107 13.1162 18.7 12.7066 18.7 12.2031V10.1578C18.7 9.65434 18.2107 9.24473 17.6093 9.24473ZM6.59376 2.92618C6.59376 2.5234 6.98517 2.19571 7.46628 2.19571H14.5337C15.0148 2.19571 15.4062 2.5234 15.4062 2.92618V9.24473H6.59376V2.92618ZM7.539 18.7043H6.23022V13.1162H7.539V18.7043ZM8.84778 15.4537V13.1162H13.1522V15.4537H8.84778ZM15.7698 18.7043H14.461V13.1162H15.7698V18.7043ZM17.3912 12.0205C16.9639 12.0205 5.13913 12.0205 4.60877 12.0205V10.3404H17.3912V12.0205Z"
      fill="#BCC8D9"
    />
  </svg>
);

export default ModelsIcon;
