import classNames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import MuiToggleButton from '@material-ui/lab/ToggleButton';

const ToggleButton = ({
  children, className, value, ...rest
}) => (
  <MuiToggleButton
    className={ classNames('scenes__toggle', className) }
    size="small"
    value={ value }
    { ...rest }
  >
    {children}
  </MuiToggleButton>
);

const useStyles = makeStyles((theme) => ({
  grouped: {
    margin: theme.spacing(0.5),
    border: 'none !important',
    '&:not(:first-child)': {
      borderRadius: theme.shape.borderRadius,
    },
    '&:first-child': {
      borderRadius: theme.shape.borderRadius,
    },
  },
  button: {
    width: theme.spacing(14.25),
    height: theme.spacing(6),
    '& span': {
      fontSize: '1.4rem',
      fontWeight: 500,
    },
  },
}));

export const Dimensions = {
  TwoDimensional: '2d',
  ThreeDimensional: '3d',
};

const DimensionsToggle = ({ value, onChange }) => {
  const classes = useStyles();

  return (
    <ToggleButtonGroup
      value={ value }
      exclusive
      onChange={ onChange }
      aria-label="scene dimension"
      classes={ { grouped: classes.grouped } }
    >
      <ToggleButton
        value={ Dimensions.TwoDimensional }
        disabled={ Dimensions.TwoDimensional === value }
        className={ classes.button }
      >
        Preview
      </ToggleButton>
      <ToggleButton
        value={ Dimensions.ThreeDimensional }
        disabled={ Dimensions.ThreeDimensional === value }
        className={ classes.button }
      >
        Scene
      </ToggleButton>
    </ToggleButtonGroup>
  );
};

export default DimensionsToggle;
