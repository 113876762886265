import { Fragment, useCallback, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import { deleteProductError, deleteVariant } from '@store/products/actions';
import Button from '@shared/components/button';
import classNames from 'classnames';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@resources/icons/EditIcon';
import DeleteIcon from '@resources/icons/DeleteIcon';
import ImagePlaceholder from '@shared/components/placeholder/ImagePlaceholder';
import useWarningControl from '@shared/components/card-controls/useWarningControl';
import WarningDialog from '@shared/components/card-controls/WarningDialog';

const useStyles = makeStyles((theme) => ({
  noVariantsMessage: {
    fontSize: '14px',
    fontWeight: '600',
    lineHeight: '21px',
    letterSpacing: '0.01em',
  },
  button: {
    width: '140px',
  },
  variantPanel: {
    width: '100%',
    height: 'auto',
    minHeight: '54px',
    boxSizing: 'border-box',
    padding: '5px',
    marginTop: '30px',
    display: 'flex',
    justifyContent: 'space-between',
    border: '1px solid #E0E7EF',
    borderRadius: '2px',
  },
  variantPanelActive: {
    border: `2px solid ${theme.palette.common.green}`,
  },
  variantInfoWrapper: {
    display: 'flex',
    justifyContent: 'left',
    gap: '16px',
  },
  variantActionsWrapper: {
    display: 'flex',
    justifyContent: 'right',
  },
  variantPreview: {
    width: '42px',
    height: '42px',
    objectFit: 'cover',
  },
  previewPlaceholder: {
    width: '42px',
    height: '42px',

    '& svg': {
      width: '38px',
      height: '38px',
    },
  },
  variantTextInfo: {
    display: 'flex',
    flexDirection: 'column',
  },
  variantText: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    width: '175px',
    fontSize: '14px',
    fontWeight: '400',
    lineHeight: '21px',
    letterSpacing: '0.01em',
  },
  variantName: {
    color: '#102035',
  },
  variantId: {
    color: '#9CACC2',
  },
  variantActionButton: {
    padding: '8px',

    '& .MuiIconButton-label': {
      fontSize: '20px',
    },
  },
  deleteConfirmationPanel: {
    display: 'flex',
    padding: '5px',
    border: '1px solid #E0E7EF',
    borderTop: 'none',
    borderBottomRightRadius: '2px',
    borderBottomLeftRadius: '2px',
  },
  deleteConfirmationMessage: {
    width: '65%',
    fontSize: '14px',
    fontWeight: '400',
    color: '#102035',
  },
  deleteConfirmationButtonsWrapper: {
    display: 'flex',
    flexGrow: 1,
    gap: '5px',
  },
}));

const VariantsList = ({
  data,
  onAddVariantClick,
  onEditVariantClick,
  onVariantSelect,
}) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const apiError = useSelector((state) => state.products.error);

  const [showDeleteConfirmFor, setShowDeleteConfirmFor] = useState(null);
  const [selectedVariant, setSelectedVariant] = useState(null);
  const [warningTitle, setWarningTitle] = useState('');

  const [warningOpen, {
    handleWarningControlClick,
    handleWarningClose,
  }] = useWarningControl();

  useEffect(() => {
    if (onVariantSelect) {
      onVariantSelect(selectedVariant);
    }
  }, [selectedVariant, onVariantSelect]);

  useEffect(() => {
    setSelectedVariant((prevState) => {
      if (prevState) {
        return data?.childProducts?.find((item) => item.id === prevState.id);
      }

      return prevState;
    });
  }, [data?.childProducts]);

  const handleVariantEditClick = useCallback((variantId) => {
    onEditVariantClick(variantId);
    setShowDeleteConfirmFor(null);
  }, [onEditVariantClick]);

  const handleVariantDeleteClick = useCallback((variantId, event) => {
    event.stopPropagation();
    setShowDeleteConfirmFor(variantId);
  }, []);

  const handleDeleteConfirmationCancel = useCallback(() => {
    setShowDeleteConfirmFor(null);
  }, []);

  const handleDeleteConfirmationSubmit = useCallback(() => {
    dispatch(deleteVariant(showDeleteConfirmFor, data.id));
    setShowDeleteConfirmFor(null);
  }, [showDeleteConfirmFor, data, dispatch]);

  const handleApiErrorClear = () => {
    dispatch(deleteProductError());
  };

  useEffect(() => {
    if (apiError && apiError.type === 'DELETE') {
      handleWarningControlClick();
      const titles = apiError.data.message.map((message) => {
        if (message.reason === 'used' && message.location === 'Studio') {
          const interiors = message.usedIn.map((p) => p.name).join(', ');
          return `Action cannot be performed because given product is used in the following studios: [${interiors}]`;
        }
        if (message.reason === 'used' && message.location === 'Scene') {
          const interiors = message.usedIn.map((p) => p.name).join(', ');
          return `Action cannot be performed because given product is used in the following interiors: [${interiors}]`;
        }
      });
      setWarningTitle(titles.join('\n'));
    }
    if (warningOpen && !apiError) {
      handleWarningClose();
    }
  }, [
    warningOpen,
    handleWarningControlClick,
    apiError,
    showDeleteConfirmFor,
  ]);

  const handleVariantClick = useCallback((variant) => setSelectedVariant(variant), []);

  return (
    <>
      {!data?.childProducts?.length ? (
        <span className={ classes.noVariantsMessage }>
          You don&#39;t have any variations yet
        </span>
      ) : null}

      <Button
        className={ classes.button }
        onClick={ () => {
          setShowDeleteConfirmFor(null);
          onAddVariantClick();
        } }
      >
        Add Variation
      </Button>

      {data?.childProducts?.map((variant) => (
        <Fragment key={ variant.id }>
          <div
            className={ classNames(classes.variantPanel, { [classes.variantPanelActive]: selectedVariant?.id === variant.id }) }
            onClick={ handleVariantClick.bind(this, variant) }
          >
            <div className={ classes.variantInfoWrapper }>
              {!variant.thumbnail ? (<ImagePlaceholder className={ classes.previewPlaceholder } />) : (
                <img src={ variant.thumbnail } alt="Variation Preview" className={ classes.variantPreview } />
              )}

              <div className={ classes.variantTextInfo }>
                <Tooltip
                // eslint-disable-next-line no-nested-ternary
                  title={ variant.name?.length < 22 ? '' : variant.name }
                  placement="top"
                >
                  <span className={ classNames(classes.variantText, classes.variantName) }>
                    {/* eslint-disable-next-line no-nested-ternary */}
                    {variant.name?.length ? variant.name : data.name?.length ? data.name : '\u00A0'}
                  </span>
                </Tooltip>
                <span className={ classNames(classes.variantText, classes.variantId) }>ID: {variant.externalId}</span>
              </div>
            </div>

            <div className={ classes.variantActionsWrapper }>
              <IconButton
                className={ classes.variantActionButton }
                aria-label="Edit Variation"
                onClick={ handleVariantEditClick.bind(this, variant.id) }
              >
                <EditIcon />
              </IconButton>

              <IconButton
                className={ classes.variantActionButton }
                aria-label="Delete Variation"
                onClick={ handleVariantDeleteClick.bind(this, variant.id) }
              >
                <DeleteIcon />
              </IconButton>
            </div>
          </div>

          {showDeleteConfirmFor === variant.id ? (
            <div className={ classes.deleteConfirmationPanel }>
              <span className={ classes.deleteConfirmationMessage }>Are you sure you want to delete this variation?</span>

              <div className={ classes.deleteConfirmationButtonsWrapper }>
                <Button color="secondary" variant="outlined" onClick={ handleDeleteConfirmationCancel }>
                  No
                </Button>

                <Button color="secondary" variant="outlined" onClick={ handleDeleteConfirmationSubmit }>
                  Yes
                </Button>
              </div>
            </div>
          ) : null}
        </Fragment>
      ))}
      <WarningDialog
        open={ warningOpen }
        title={ warningTitle }
        onClose={ handleApiErrorClear }
      />
    </>
  );
};

export default VariantsList;
