import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import AttributesIcon from '@resources/icons/AttributesIcon';
import AttributesArrowIcon from '@resources/icons/AttributesArrowIcon';

const useStyles = makeStyles((theme) => ({
  headerContainer: {
    backgroundColor: theme.palette.common.white,
    display: 'flex',
    alignItems: 'center',
  },
  headerLabel: {
    fontSize: '14px',
  },
}));

const FilterToggle = ({ isOpen, onClick }) => {
  const classes = useStyles();

  return (isOpen ? (
    <div className={ classes.headerContainer }>
      <IconButton
        color="inherit"
        onClick={ onClick }
      >
        <AttributesIcon />
      </IconButton>
      <span className={ classes.headerLabel }>Manage Attributes</span>
      <IconButton
        color="inherit"
        onClick={ onClick }
      >
        <AttributesArrowIcon />
      </IconButton>
    </div>
  ) : (
    <IconButton
      color="inherit"
      onClick={ onClick }
    >
      <AttributesIcon />
    </IconButton>
  ));
};

export default FilterToggle;
