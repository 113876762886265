import {
  LOAD_STYLING,
} from './actions';

export const Status = {
  Idle: 'idle',
  Loading: 'loading',
};

// TODO: check necessary props in here in case of adjusting Styling page
// At the moment the API returns just 1 entity but this interface and UI expecting array to render table
const initialState = {
  items: [],
  // Currently status and error are not used on corresponding page/modal
  status: Status.Idle,
  error: null,
};

const stylingReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_STYLING.START:
      return {
        ...state,
        status: Status.Loading,
      };
    case LOAD_STYLING.SUCCESS:
      return {
        ...state,
        status: Status.Idle,
        error: null,
        items: [action.payload.styling],
      };
    case LOAD_STYLING.FAIL:
      return {
        ...state,
        status: Status.Idle,
        error: action.payload.error,
      };

    default:
      return state;
  }
};

export default stylingReducer;
