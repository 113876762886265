import { useCallback, memo } from 'react';
import { useSelector } from 'react-redux';
import Card from '@shared/components/cards-list/Card';
import CardImage from '@shared/components/cards-list/CardImage';
import CardContent from '@shared/components/cards-list/CardContent';
import CardTitle from '@shared/components/cards-list/CardTitle';
import CardLabel from '@shared/components/cards-list/CardLabel';
import { selectCurrentCompany } from '@store/app/selectors';

const Model = ({
  className,
  item,
  controls,
  onClick,
  defaultClickable,
}) => {
  const company = useSelector(selectCurrentCompany);
  const handleClick = useCallback((evt) => {
    if (!(item.defaultContent && !company.default) || defaultClickable) {
      onClick(item, evt);
    }
  }, [onClick, item]);

  return (
    <Card
      className={ className }
      onClick={ handleClick }
      transparent={ item.hidden }
    >
      {controls}
      <CardImage
        src={ item.thumbnail }
        alt="model"
        variant="fullSize"
        withPlaceholder
      />
      <CardContent>
        <CardTitle>
          {/* TODO: Should be done with CSS */}
          {item.name.length < 20 ? item.name : `${item.name.substr(0, 20)}...`}
        </CardTitle>
      </CardContent>
      {item.defaultContent && <CardLabel>{company.default ? 'Published' : 'Default'}</CardLabel>}
    </Card>
  );
};

export default memo(Model);
