import { useCallback, useEffect, useRef, useState } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { updateProduct, createProduct } from '@store/products/actions';
import { Form } from '@shared/views/form-dialog';
import { Fieldset, fillWithDefaultValues, fillWithProductValues } from '../fieldset';
import { isVariantExistsInProduct } from '@pages/products/utils';
import { actions as appActions } from '@store/app';
import AlertDialog from '@shared/components/card-controls/AlertDialog';

const ModelForm = ({
  children,
  className,
  open,
  data,
  onSubmit,
}) => {
  const dispatch = useDispatch();

  const allEnvironments = useSelector((state) => state.scenes.environments.items);
  const formKey = useRef(1);
  const lastData = useRef(data);
  const [showMaterilasConfirm, setShowMaterialsConfirm] = useState(false);

  const generateDefaultValues = useCallback((entityData) => ({
    ...fillWithDefaultValues({ allEnvironments }),
    ...(entityData?.id ? fillWithProductValues(entityData) : {}),
  }), [allEnvironments]);

  const form = useForm({
    defaultValues: generateDefaultValues(data),
  });

  useEffect(() => {
    if (lastData.current !== data) {
      form.reset({
        ...generateDefaultValues(data),
        [Fieldset.Environment]: form.getValues(Fieldset.Environment),
      });

      lastData.current = data;
      formKey.current += 1;
    }
  }, [data, form, generateDefaultValues]);

  const handleSubmit = async (newData) => {
    if (data?.id) {
      const adjustedData = {
        ...data,
        ...newData,
      };
      const hasSuchVariant = isVariantExistsInProduct(newData.materials, adjustedData, [], false);

      if (hasSuchVariant) {
        dispatch(appActions.addMessage({ message: 'A Variation with the same materials configuration already exists', type: 'error' }));
        return;
      }
      const updatedGroups = newData.materials.map((m) => m.group?.id).join(' ');
      const oldGroups = data.materials.map((m) => m.group?.id).join(' ');

      if (updatedGroups !== oldGroups && data.childProducts?.length) {
        setShowMaterialsConfirm(newData);
        return;
      }
    }

    const result = await onSubmit(newData);
    const finalData = result ?? newData;

    dispatch(data?.id ? updateProduct({
      id: data.id,
      ...finalData,
      name: finalData.name.trim(),
      attributes: finalData.attributes?.map((attr) => attr.id),
    }, form.setError) : createProduct({
      ...finalData,
      name: finalData.name.trim(),
      attributes: finalData.attributes?.map((attr) => attr.id),
    }, form.setError));
  };

  const handleResetMaterialsConfirm = async (isConfirm) => {
    setShowMaterialsConfirm(false);
    if (isConfirm) {
      const result = await onSubmit(showMaterilasConfirm);
      const finalData = result ?? showMaterilasConfirm;

      dispatch(data?.id ? updateProduct({
        id: data.id,
        ...finalData,
        name: finalData.name.trim(),
        attributes: finalData.attributes?.map((attr) => attr.id),
      }) : createProduct({
        ...finalData,
        name: finalData.name.trim(),
        attributes: finalData.attributes?.map((attr) => attr.id),
      }));
    }
  };

  return (
    <>
      <Form
        className={ className }
        open={ open }
        onSubmit={ form.handleSubmit(handleSubmit) }
      >
        <FormProvider { ...form } key={ formKey.current }>
          {children}
        </FormProvider>
      </Form>
      <AlertDialog
        open={ !!showMaterilasConfirm }
        ariaAction="confirm-reset-materials"
        title="All product variation materials will be reset. Are you sure you want to proceed?"
        onAgree={ handleResetMaterialsConfirm.bind(null, true) }
        onDisagree={ handleResetMaterialsConfirm.bind(null, false) }
      />
    </>
  );
};

export default ModelForm;
