import { useSelector } from 'react-redux';
import DetailsContainer from '../DetailsContainer';
import SizeTextField from './SizeTextField';
import { Fieldset } from './fieldset';
import { useEffect } from 'react';
import SoftShadowsField from '@pages/scenes/child-details/SceneDetails/SoftShadowsField';
import EnvironmentTextField from './EnvironmentTextField';
import { Field } from '@shared/views/form-dialog';
import RangeSlider from '@pages/scenes/child-details/LightDetails/RangeSlider';
import AttributesTable from '@shared/components/attributes-table/AttributesTable';
import NameTextField from './NameTextField';
import { deg2rad, rad2deg } from '@shared/components/camera-solver/utils';
import KelvinColor from '@shared/utils/KelvinColor';
import ColorOption from '@pages/scenes/child-details/LightDetails/lightOptions/ColorOption';
import { selectAttributeDefinitions } from '@store/attribute-definitions/selectors';

const SceneDetails = ({
  details,
  sceneDetailsErrors,
  onChange,
  isEditable,
  scenesNameExists,
}) => {
  const attributeDefinitions = useSelector(selectAttributeDefinitions);

  const handleSizeChange = ({ name, value }) => {
    onChange({ value }, name);
  };

  const handleSoftShadowsChange = ({ name, value }) => {
    onChange({ value }, name);
  };

  const handleEnvironmentChange = (value) => {
    onChange({ value }, Fieldset.Environment);
  };

  const handleEnvironmentIntensityChange = ({ value }) => {
    onChange({ value }, Fieldset.EnvironmentIntensity);
  };

  const handleEnvironmentRotationChange = ({ value }) => {
    onChange({ value: deg2rad(value) }, Fieldset.EnvironmentRotation);
  };

  const handleShadowOpacityChange = ({ value }) => {
    onChange({ value }, Fieldset.ShadowOpacity);
  };

  const handleAttributesChange = (value) => {
    onChange({ value }, Fieldset.Attributes);
  };

  useEffect(() => {
    // Execute onChange on first render
    onChange({ value: details[Fieldset.GlobalLightIntensity] || 0 }, Fieldset.GlobalLightIntensity);
  }, []);

  return (
    <DetailsContainer isEditable={ isEditable }>
      <NameTextField
        details={ details }
        sceneDetailsErrors={ sceneDetailsErrors }
        onChange={ onChange }
        isEditable={ isEditable }
        scenesNameExists={ scenesNameExists }
      />
      <SoftShadowsField
        name={ Fieldset.SoftShadows }
        label="Soft Shadows"
        value={ details[Fieldset.SoftShadows] }
        onChange={ handleSoftShadowsChange }
      />
      {
        details[Fieldset.SoftShadows] && (
          <>
            <SizeTextField
              name={ Fieldset.Size }
              label="Size"
              min={ 1 }
              value={ details[Fieldset.Size] }
              onChange={ handleSizeChange }
            />
            <SizeTextField
              name={ Fieldset.Focus }
              label="Focus"
              min={ 0 }
              value={ details[Fieldset.Focus] }
              onChange={ handleSizeChange }
            />
            <SizeTextField
              name={ Fieldset.Samples }
              label="Samples"
              value={ details[Fieldset.Samples] }
              min={ 1 }
              max={ 50 }
              onChange={ handleSizeChange }
            />
          </>
        )
      }
      <Field>
        <RangeSlider
          label="Shadow Opacity"
          min={ 0 }
          max={ 1 }
          displayValues={ false }
          displayThumbValue
          debounce={ 100 }
          value={ details[Fieldset.ShadowOpacity] }
          onChange={ handleShadowOpacityChange }
        />
      </Field>
      <Field>
        <ColorOption
          name="ambient"
          label="Ambient Light Color"
          value={ new KelvinColor(details[Fieldset.GlobalLightColor]) }
          onChange={ ({ value }) => {
            onChange({ value: `#${value?.color}` }, Fieldset.GlobalLightColor);
          } }
        />
      </Field>
      <Field>
        <RangeSlider
          label="Ambient Light Intensity"
          min={ 0 }
          max={ 2 }
          displayValues={ false }
          displayThumbValue
          debounce={ 100 }
          value={ details[Fieldset.GlobalLightIntensity] || 0 }
          onChange={ ({ value }) => {
            onChange({ value }, Fieldset.GlobalLightIntensity);
          } }
        />
      </Field>
      <Field style={ { position: 'relative' } }>
        <EnvironmentTextField
          name={ Fieldset.Environment }
          label="Environment"
          onChange={ handleEnvironmentChange }
          value={ details[Fieldset.Environment] }
        />
      </Field>
      <Field>
        <RangeSlider
          label="HDR Brightness"
          min={ 0 }
          max={ 5 }
          displayValues={ false }
          displayThumbValue
          isDisabled={ !details[Fieldset.Environment] || !details[Fieldset.Environment].name }
          debounce={ 100 }
          value={ details[Fieldset.EnvironmentIntensity] }
          onChange={ handleEnvironmentIntensityChange }
        />
      </Field>
      <Field>
        <RangeSlider
          label="HDR Rotation"
          min={ -180 }
          max={ 180 }
          step={ 1 }
          displayValues={ false }
          displayThumbValue
          isDisabled={ !details[Fieldset.Environment] || !details[Fieldset.Environment].name }
          debounce={ 100 }
          value={ rad2deg(details[Fieldset.EnvironmentRotation]).toFixed(0) }
          onChange={ handleEnvironmentRotationChange }
        />
      </Field>
      <Field>
        <AttributesTable
          attributeDefinitions={ attributeDefinitions }
          name={ Fieldset.Attributes }
          onChange={ handleAttributesChange }
          value={ details[Fieldset.Attributes] }
        />
      </Field>
    </DetailsContainer>
  );
};

export default SceneDetails;
