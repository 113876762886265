/* eslint-disable max-len */
import { makeStyles } from '@material-ui/core';
import SvgIcon from '@material-ui/core/SvgIcon';
import { DefaultIconSizeValue, IconSize } from '@resources/icons/constants';

const useStyles = makeStyles(() => ({
  root: {
    fill: 'none',
  },
}));

const NaturalLightBehind = ({ size = IconSize.Medium, selected = false }) => {
  const classes = useStyles();
  return (
    <SvgIcon
      style={ { width: DefaultIconSizeValue[size], height: DefaultIconSizeValue[size] } }
      className={ classes.root }
      viewBox="0 0 38 35"
    >
      <path d="M31 29.5L13 9H5L18 29.5H31Z" fill={ selected ? '#ffffff' : '#EBEFF2' } />
      <path d="M1 9H17" stroke="#334D6E" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M3.34375 14.6572L14.6575 3.34352"
        stroke="#334D6E"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.6582 14.6572L3.34449 3.34352"
        stroke="#334D6E"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M9 17L9 1" stroke="#334D6E" strokeLinecap="round" strokeLinejoin="round" />
      <circle
        cx="8.9824"
        cy="8.98244"
        r="5.4824"
        transform="rotate(-90 8.9824 8.98244)"
        fill="#F0F0F0"
        stroke="#334D6E"
      />
      <circle cx="9" cy="9" r="4" fill="#334D6E" />
      <path
        d="M17 30.5V16.5H31V30.5H17Z"
        fill="#FAFAFA"
        stroke="#334D6E"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M23.198 21.07H21.768C21.5946 21.0723 21.4254 21.0159 21.288 20.91C21.1551 20.8046 21.0612 20.6578 21.0213 20.4929C20.9814 20.328 20.9979 20.1545 21.068 20C21.1345 19.8397 21.2597 19.711 21.418 19.64L35.898 12.09C36.016 12.0309 36.1461 12.0001 36.278 12C36.4093 12.0042 36.5373 12.0423 36.6495 12.1106C36.7616 12.179 36.8542 12.2752 36.918 12.39C36.9803 12.5073 37.0145 12.6373 37.018 12.77V34.5H23.278L23.198 21.07Z"
        fill={ selected ? '#EBEFF2' : '#ffffff' }
        stroke="#334D6E"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
};

export default NaturalLightBehind;
