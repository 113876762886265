import TextField from '../TextField';

const SizeTextField = ({
  name,
  label,
  value,
  onChange,
  ...props
}) => {
  const handleChange = ({ target }) => {
    let value = +target.value;
    if (props.max !== undefined && props.min !== undefined) {
      value = Math.min(Math.max(value, props.min), props.max);
    } else if (props.min !== undefined) {
      value = Math.max(value, props.min);
    } else if (props.max !== undefined) {
      value = Math.min(value, props.max);
    }
    onChange({
      name: target.name,
      value,
    });
  };

  return (
    <TextField
      type="number"
      name={ name }
      label={ label }
      step={ 1 }
      value={ value }
      onChange={ handleChange }
      { ...props }
    />
  );
};

export default SizeTextField;
