import { forwardRef, memo, useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { useFormContext } from 'react-hook-form';
import classNames from 'classnames';

import Autocomplete from '@shared/components/autocomplete/Autocomplete';
import { TextField } from '@shared/views/form-dialog';

const useStyles = makeStyles({
  textFieldRoot: {
    "& > div.MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root']": {
      paddingRight: '9px',
      '& button': {
        order: 3,
      },
      '& > div.MuiAutocomplete-endAdornment': {
        position: 'relative',
        order: 2,
      },
    },
  },
  option: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    '& > img': {
      width: '40px',
      height: '40px',
    },
  },
});

const ModelTextField = forwardRef(({
  name,
  label,
  onChange,
  value,
  disabled,
  onResetMaterials,
}, ref) => {
  const { setValue } = useFormContext();
  const classes = useStyles();
  const options = useSelector((state) => state.models.models.items);
  const defaultValue = useMemo(() => ({}), []);

  const handleChange = useCallback((_, data) => {
    if (value.name === data || (!data && value.name === undefined) || value?.id === data?.id) {
      return;
    }
    if (data && data.id) {
      onChange(data);
      onResetMaterials?.([]);
      setValue(`${name}.data`, {
        blob: data.data.blob || data.data,
        dataType: data.data.dataType || data.dataType,
        scale: data.scale,
      });
    } else {
      const option = options.find((op) => op.name === data);
      if (option) {
        onChange(option);
        onResetMaterials?.([]);
        setValue(`${name}.data`, option.data.blob ? option.data : {
          blob: option.data,
          dataType: option.dataType,
          scale: data.scale,
        });
        return;
      }
      onChange(defaultValue);
      onResetMaterials?.([]);
      setValue(`${name}.data`, {
        blob: '',
        thumbnail: '',
        scale: [1, 1, 1],
      });
    }
  }, [onChange, options, name, value, defaultValue, onResetMaterials, setValue]);

  const getOptionObject = useCallback((option) => ((typeof option === 'string' || option instanceof String)
    ? options.find((op) => op.id === option)
    : option
  ) || defaultValue, [options, defaultValue]);

  const getOptionLabel = useCallback((option) => (
    option ? (getOptionObject(option).name || '') : (option || '')
  ), [getOptionObject]);

  const getOptionSelected = useCallback((option, value) => (
    value ? option.id === getOptionObject(value).id : value
  ), [getOptionObject]);

  return (
    <Autocomplete
      freeSolo
      options={ options }
      getOptionLabel={ getOptionLabel }
      getOptionSelected={ getOptionSelected }
      onChange={ handleChange }
      onBlur={ (e) => handleChange(e, e.target.value) }
      ref={ ref }
      value={ value || defaultValue }
      disabled={ disabled }
      renderInput={ (params) => (
        <TextField
          { ...params }
          classes={ {
            root: classes.textFieldRoot,
          } }
          label={ label }
          variant="outlined"
          InputProps={ {
            ...params.InputProps,
            endAdornment: (
              <>
                {params.InputProps.endAdornment}
              </>
            ),
          } }
        />
      ) }
      renderOption={ (props, { selected }) => (
        <div className={ classNames({ selected }, classes.option) }>
          <img alt={ props.name } src={ props.thumbnail } />
          { props.name }
        </div>
      ) }
    />
  );
});

export default memo(ModelTextField);
