import Grid from '@material-ui/core/Grid';

const Range = ({ min, max }) => (
  <Grid container spacing={ 2 } justifyContent="space-between">
    <Grid item>{min}</Grid>
    <Grid item>{max}</Grid>
  </Grid>
);

export default Range;
