import { BaseFieldset, SpotLightFieldset } from './fieldset';
import OrientationOption from './lightOptions/OrientationOption';
import { ElectricLightTypeLabel, ELECTRIC_LIGHT_ORIENTATION_OPTIONS, LightType } from './constants';
import Toggle from '@shared/components/toggle';
import ColorOption from './lightOptions/ColorOption';
import IntensityOption from './lightOptions/IntensityOption';
import PositionOption from '@pages/scenes/child-details/LightDetails/lightOptions/PositionOption';
import DistanceOption from '@pages/scenes/child-details/LightDetails/lightOptions/DistanceOption';

const ElectricLightOptions = ({
  options,
  onChange,
  children,
}) => {
  const handleTypeChange = (name, value) => {
    if (value) {
      onChange({ value }, name);
    }
  };

  return (
    <>
      <PositionOption
        name={ SpotLightFieldset.Position }
        value={ options[SpotLightFieldset.Position] }
        onChange={ onChange }
      />
      <DistanceOption
        name={ SpotLightFieldset.Distance }
        value={ options[SpotLightFieldset.Distance] }
        onChange={ onChange }
      />
      <IntensityOption
        name={ SpotLightFieldset.Intensity }
        value={ options[SpotLightFieldset.Intensity] }
        onChange={ onChange }
      />
      <ColorOption
        name={ SpotLightFieldset.Color }
        value={ options[SpotLightFieldset.Color] }
        onChange={ onChange }
      />
      <Toggle
        name={ BaseFieldset.Type }
        onChange={ handleTypeChange }
        options={ [
          { key: LightType.PointLight, label: ElectricLightTypeLabel[LightType.PointLight] },
          { key: LightType.SpotLight, label: ElectricLightTypeLabel[LightType.SpotLight] },
        ] }
        value={ options[BaseFieldset.Type] }
      />
      {children}
    </>
  );
};

export default ElectricLightOptions;
