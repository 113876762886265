/* eslint-disable max-len */
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core';
import SvgIcon from '@material-ui/core/SvgIcon';
import { IconSize, DefaultIconSizeValue } from './constants';

export const IconSizeValue = {
  ...DefaultIconSizeValue,
  [IconSize.Medium]: '22px',
};

const useStyles = makeStyles(() => ({
  root: {
    fill: 'none',
  },
}));

const CompaniesIcon = ({ size = IconSize.Medium, className }) => {
  const classes = useStyles();
  return (
    <SvgIcon
      style={ { width: IconSizeValue[size], height: IconSizeValue[size] } }
      className={ classNames(classes.root, className) }
      viewBox="0 0 24 24"
    >
      <path d="m18.36 9 .6 3H5.04l.6-3h12.72M20 4H4v2h16V4zm0 3H4l-1 5v2h1v6h10v-6h4v6h2v-6h1v-2l-1-5zM6 18v-4h6v4H6z" fill="#BCC8D9" />
    </SvgIcon>
  );
};

export default CompaniesIcon;
