import { all, call, put, takeEvery } from 'redux-saga/effects';

import { usersApi } from '@api';

import { actions } from '.';

const { loadUsers, createUser, updateUser, deleteUser, renderUsers } = actions;

function* loadUsersSaga() {
  try {
    const { items } = yield call(usersApi.getUsers);
    yield put(renderUsers(items));
  } catch (e) {
    console.error(e);
  }
}

function* createUserSaga(action) {
  try {
    yield call(usersApi.createUser, action.payload);
    yield put(loadUsers());
  } catch (e) {
    console.error(e);
  }
}

function* updateUserSaga(action) {
  try {
    const { id, user } = action.payload;
    yield call(usersApi.updateUser, id, user);
    yield put(loadUsers());
  } catch (e) {
    console.error(e);
  }
}

function* deleteUserSaga(action) {
  try {
    yield call(usersApi.deleteUser, action.payload);
    yield put(loadUsers());
  } catch (e) {
    console.error(e);
  }
}

export default function* usersSaga() {
  yield all([
    takeEvery(loadUsers, loadUsersSaga),
    takeEvery(createUser, createUserSaga),
    takeEvery(updateUser, updateUserSaga),
    takeEvery(deleteUser, deleteUserSaga),
  ]);
}
