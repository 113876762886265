import { makeStyles } from '@material-ui/core/styles';
import SearchInput from '@shared/components/search-input';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
  },
}));

const DialogSearchInput = ({ value, onChange }) => {
  const classes = useStyles();

  return (
    <SearchInput className={ classes.root } value={ value } onChange={ onChange } />
  );
};

export default DialogSearchInput;
