import { forwardRef, memo, useMemo } from 'react';

import Checkbox from '@material-ui/core/Checkbox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';

import Autocomplete from '@shared/components/autocomplete/Autocomplete';
import { TextField } from '@shared/views/form-dialog';

import { EntityTypes } from '@shared/constants';

import { makeStyles } from '@material-ui/core/styles';

const icon = <CheckBoxOutlineBlankIcon />;
const checkedIcon = <CheckBoxIcon />;

// styles partially taken from off. documentation
const useStyles = makeStyles(() => ({
  root: {
    '& .MuiChip-root': {
      backgroundColor: '#e0e0e0',
    },

    '& .MuiSvgIcon-root': {
      width: '1.5em',
      height: '1.5em',
      fill: 'rgba(0, 0, 0, 0.26)',
    },

    '& .MuiSvgIcon-root:hover': {
      fill: 'rgba(0, 0, 0, 0.4)',
    },

    '& .MuiAutocomplete-tag': {
      margin: '3px',
      maxWidth: 'calc(100% - 6px)',

      '&::before': {
        left: 0,
        right: 0,
        bottom: 0,
        content: '""',
        position: 'absolute',
      },

      '& svg': {
        opacity: 1,
        cursor: 'pointer',
        margin: '0 5px 0 -6px',
      },
    },
  },
}));

const Input = (props) => (
  <TextField
    { ...props }
    label="Entity Types"
    variant="outlined"
    required
    fullWidth
  />
);

const EntityTypesField = forwardRef(({
  onChange,
  value,
  error,
  ...props
}, ref) => {
  const classes = useStyles();

  const options = useMemo(() => Object.values(EntityTypes), []);

  return (
    <>
      <Autocomplete
        { ...props }
        multiple
        className={ classes.root }
        limitTags={ 2 }
        value={ value?.length ? value : [] }
        onChange={ (_, newValue) => onChange(newValue?.length ? newValue : []) }
        id="entity-types-autocomplete"
        options={ options }
        getOptionLabel={ (option) => option }
        ref={ ref }
        renderInput={ (params) => (
          <Input
            { ...params }
            error={ !!error }
            helperText={ error?.message ?? null }
          />
        ) }
        renderOption={ (option, { selected }) => (
          <>
            <Checkbox
              icon={ icon }
              checkedIcon={ checkedIcon }
              checked={ selected }
            />
            { option }
          </>
        ) }
      />
    </>
  );
});

export default memo(EntityTypesField);
