import { makeStyles } from '@material-ui/core/styles';
import MuiCheckbox from '@material-ui/core/Checkbox';
import { forwardRef } from 'react';

const useStyles = makeStyles(() => ({
  root: {
    '& svg': {
      fontSize: '2.4rem',
    },
  },
}));

const Checkbox = forwardRef(({ name, checked, onChange, disabled }, ref) => {
  const classes = useStyles();

  return (
    <MuiCheckbox
      inputRef={ ref }
      classes={ classes }
      name={ name }
      checked={ checked }
      onChange={ onChange }
      disabled={ disabled }
    />
  );
});

export default Checkbox;
