import ColorSlider from '../ColorSlider';
import { forwardRef } from 'react';

const ColorOption = ({
  name,
  label = 'Color',
  value,
  onChange,
}, ref) => (
  <ColorSlider
    name={ name }
    label={ label }
    kelvinColor={ value }
    onChange={ onChange }
    displayValues={ false }
  />
);

export default forwardRef(ColorOption);
