import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import TreeItemLabelText from './TreeItemLabelText';
import ImagePlaceholder from '@shared/components/placeholder/ImagePlaceholder';

const useStyles = makeStyles(() => ({
  modelThumb: {
    maxWidth: '4.6rem',
    maxHeight: '3.4rem',
    border: '.1rem solid #EFEFEF',
  },
  modelName: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    width: '100px',
  },
  placeholder: {
    width: '34px',
    height: '34px',

    '& svg': {
      width: '30px',
      height: '30px',
    },
  },
}));

const ModelView = ({ name, src, onClick, withPlaceholder = false }) => {
  const classes = useStyles();

  return (
    <Grid container alignItems="center" spacing={ 1 }>
      <Grid item>
        {!src && withPlaceholder ? (<ImagePlaceholder className={ classes.placeholder } />) : (
          <img
            className={ classes.modelThumb }
            src={ src }
            alt={ name }
            onClick={ onClick }
          />
        )}
      </Grid>
      <Grid item xs className={ classes.modelName }>
        <TreeItemLabelText noWrap>
          {name}
        </TreeItemLabelText>
      </Grid>
    </Grid>
  );
};

export default ModelView;
