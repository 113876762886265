import { useEffect, useState, useCallback, memo, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSceneBox } from '@zolak/zolak-viewer';

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

import useNavigate from '@shared/router/useNavigate';
import { mongoObjectId, valueToFixedNumber } from '@shared/utils';

import { createScene, updateScene, cleanUpScene } from '@store/scenes/actions';
import { selectCurrentCompany } from '@store/app/selectors';
import { selectScenes } from '@store/scenes/selectors';

import SplitPane from '@shared/components/split-pane/SplitPane';
import Pane from '@shared/components/split-pane/Pane';
import ZViewer from '@shared/z-viewer/ZViewer';
import Backdrop from '@shared/components/backdrop';
import CircularProgress from '@shared/components/circular-progress';

import KelvinColor from '@shared/utils/KelvinColor';
import { ObjectType } from '@shared/constants';
import getDistanceFromPointToPlane from '@shared/utils/getDistanceFromPointToPlane';

import ModelsListDialog from './ModelsListDialog';
import InteriorsListDialog from './InteriorsListDialog';
import SceneChildrenTree from '../children-tree';
import SceneChildDetails, { DetailsType } from '../child-details';
import Content from './Content';
import SceneToolsPanel from './SceneTools/Panel';
import DimensionsToggle, { Dimensions } from './SceneTools/DimensionsToggle';
import SceneToolsActions from './SceneTools/Actions';
import CancelButton from './SceneTools/CancelButton';
import SaveButton from './SceneTools/SaveButton';
import EmptySceneLayout from './EmptySceneView/Layout';
import EmptySceneTitle from './EmptySceneView/Title';
import EmptySceneActionButton from './EmptySceneView/ActionButton';

import {
  getBasic1SpotLightPreset,
  getBasic2SpotLightPreset,
  getSunDirectionalLightPreset,
  getMoonDirectionalLightPreset,
  getBasic1PointLightPreset,
  getBasic2PointLightPreset,
} from '../child-details/LightDetails/presets';
import getElectricLightInitialData from '../child-details/utils/getElectricLightInitialData';
import calcAngleFromDiameter from '../child-details/utils/calcAngleFromDiameter';
import calcDiameterFromAngle from '../child-details/utils/calcDiameterFromAngle';

import { ExpandVariant } from '../constants';
import {
  LightType,
  LightBasePreset,
  SpotLightPreset,
  DirectionalLightPreset,
  PointLightPreset,
  OrientationType,
  LightGroup,
} from '../child-details/LightDetails/constants';
import {
  BaseFieldset,
  SpotLightFieldset,
} from '../child-details/LightDetails/fieldset';
import classNames from 'classnames';
import useParams from '@shared/router/useParams';

const useStyles = makeStyles((theme) => ({
  sceneGlobalsPanel: {
    marginTop: theme.spacing(6),
  },
  mainScreen: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  viewersContainer: {
    position: 'relative',
    height: '100%',
    overflow: 'hidden',
  },
  zViewer: {
    height: '100%',
    margin: '0 auto',
  },
  zViewer2d: {
    margin: theme.spacing(0, 20),
    height: 'calc(100% - 104px)',
    position: 'relative',
    overflow: 'hidden',
  },
  previewZViewer: {
    visibility: 'hidden',
    position: 'absolute',
    left: 0,
    top: 0,
    zIndex: -1,
  },
  hiddenCanvas: {
    width: '512px',
    height: '512px',
    position: 'absolute',
    visibility: 'hidden',
  },
}));

const INITIAL_MODEL_DATA = {
  model: null,
  position: [0, 0, 0],
  scale: [1, 1, 1],
  rotation: [0, 0, 0],
  hidden: false,
};


const selectInteriors = (state) => state.interiors.interiors;

const selectScene = (state) => state.scenes.scene;

const hasInteriors = (interiors) => interiors.items.length > 0;

const isElectricLight = (type) => [LightType.SpotLight, LightType.PointLight].includes(type);

const SceneEdit = () => {
  const classes = useStyles();
  const rootRef = useRef();
  const threeRef = useRef();

  const [scene, setScene] = useState({
    name: '',
    env: null,
    envIntensity: 1,
    envRotation: 0,
    softShadows: false,
    samples: 20,
    size: 20,
    focus: 0,
    attributes: [],
    shadowOpacity: 0.2,
    translatedFields: {},
    globalLightColor: '#ffffff',
    globalLightIntensity: 1,
  });
  const [interior, setInterior] = useState(null);
  const [models, setModels] = useState([]);
  const [lights, setLights] = useState([]);
  const [detailsData, setDetailsData] = useState({});
  const [interiorsOpen, setInteriorsOpen] = useState(false);
  const [addModelsOpen, setAddModelsOpen] = useState(false);
  const [changeModelOpen, setChangeModelOpen] = useState(false);
  const [viewerMode, setViewerMode] = useState(Dimensions.TwoDimensional);
  const [canvasRef, setCanvasRef] = useState(null);
  const [readyToSave, setReadyToSave] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [disableControls, setDisableControls] = useState(false);
  const [allModelsLocked, setAllModelsLocked] = useState(true);
  const [expandedTreeItems, setExpandedTreeItems] = useState([]);
  const [sceneDetailsErrors, setSceneDetailsErrors] = useState({
    name: '',
  });
  const [sceneScreenShot, setSceneScreenShot] = useState('');

  const dispatch = useDispatch();

  const interiors = useSelector(selectInteriors);
  const data = useSelector(selectScene);
  const company = useSelector(selectCurrentCompany);
  const scenes = useSelector(selectScenes);
  const params = useParams();
  const previewCanvasRef = useRef(null);

  const apiError = useSelector((state) => state.scenes.error);

  const isEditable = useMemo(() => !(
    data.defaultContent && !company.default
  ), [data, company]);

  const sceneBox = useSceneBox(interior?.layout);

  useEffect(() => () => {
    dispatch(cleanUpScene());
  }, []);

  const handleToggleLockAllModels = useCallback((locked) => {
    setAllModelsLocked(locked);
    setModels((prevModels) => prevModels.map((model) => ({
      ...model,
      locked,
    })));
    setDetailsData((prevDetails) => ({
      ...prevDetails,
      data: {
        ...prevDetails.data,
        locked,
      },
    }));
  }, []);


  useEffect(() => {
    if (data) {
      const currentInterior = interiors?.items?.find((item) => item.id === data.interior?.id)
        || data.interior;
      const currentScene = {
        id: data.id,
        name: data.name || '',
        env: data.env,
        envIntensity: (data.envIntensity !== undefined ? data.envIntensity : 1),
        envRotation: data.envRotation || 0,
        softShadows: !!data.softShadows,
        samples: data.samples,
        size: data.size,
        focus: data.focus,
        attributes: data.attributes,
        translatedFields: data.translatedFields,
        shadowOpacity: data.shadowOpacity,
        url: data.url,
        globalLightColor: data.globalLightColor,
        globalLightIntensity: data.globalLightIntensity ?? 0,
      };
      setScene((prevScene) => ({
        ...prevScene,
        ...currentScene,
      }));
      setInterior(currentInterior);
      setModels(data.models);
      setLights((prevLights) => data.lights.map((light) => {
        const kelvinColor = new KelvinColor(light.color);
        const colorByType = light[BaseFieldset.ColorByType];
        const prevLight = prevLights.find(({ id }) => id === light.id);
        const isPrevSelected = prevLight && prevLight.selected;
        const isSelected = isPrevSelected
            || (data.detailsData?.type === DetailsType.Light
              && data.detailsData?.data?.name === light?.name);

        return {
          ...light,
          color: kelvinColor,
          selected: isSelected,
          [BaseFieldset.ColorByType]: {
            ...colorByType,
            [LightType.DirectionalLight]: colorByType[
              LightType.DirectionalLight
            ]
              ? new KelvinColor(colorByType[LightType.DirectionalLight])
              : '',
            [LightType.PointLight]: colorByType[LightType.PointLight]
              ? new KelvinColor(colorByType[LightType.PointLight])
              : '',
            [LightType.SpotLight]: colorByType[LightType.SpotLight]
              ? new KelvinColor(colorByType[LightType.SpotLight])
              : '',
          },
        };
      }));
      setDetailsData({
        type: DetailsType.Scene,
        data: currentScene,
        interior: currentInterior,
      });
      if (data.detailsData) {
        setDetailsData({
          ...data.detailsData,
          data: {
            ...data.detailsData.data,
            ...(data.detailsData.type === DetailsType.Light
              ? {
                ...(data.lights.find(
                  (light) => light[BaseFieldset.Name] === data.detailsData.data?.[BaseFieldset.Name],
                ) || {}),
                [SpotLightFieldset.Color]: new KelvinColor(
                  (data.detailsData.data?.[SpotLightFieldset.Color]?.map || {})[
                    data.detailsData.data?.[SpotLightFieldset.Color]?.kelvin
                  ],
                ),
                [SpotLightFieldset.SelectSpotCenter]: false,
              }
              : {}),
          },
        });
      }
      setIsSaving(false);
      handleToggleLockAllModels(data.allModelsLocked);
    }
  }, [data, interiors, handleToggleLockAllModels]);

  const handleViewerMode = (_, newMode) => {
    setViewerMode(newMode);
  };

  useEffect(() => {
    if (readyToSave) {
      const action = scene.id ? updateScene : createScene;
      let sceneProps = {
        id: scene.id,
        name: scene.name,
        env: scene.env,
        envIntensity: scene.envIntensity,
        envRotation: scene.envRotation,
        width: scene.width,
        height: scene.height,
        depth: scene.depth,
        softShadows: scene.softShadows,
        samples: scene.samples,
        size: scene.size,
        focus: scene.focus,
        shadowOpacity: scene.shadowOpacity,
        attributes: scene.attributes?.map((attr) => attr.id),
        url: scene.url,
        interior,
        models,
        globalLightColor: scene.globalLightColor,
        globalLightIntensity: scene.globalLightIntensity,
        lights: lights.map((light) => ({
          ...light,
          color: light.color.color,
          isShadow: light.isShadow,
          [BaseFieldset.ColorByType]: {
            ...light[BaseFieldset.ColorByType],
            [LightType.DirectionalLight]:
              light[BaseFieldset.ColorByType][LightType.DirectionalLight]
                ?.color || '',
            [LightType.PointLight]:
              light[BaseFieldset.ColorByType][LightType.PointLight]?.color
              || '',
            [LightType.SpotLight]:
              light[BaseFieldset.ColorByType][LightType.SpotLight]?.color || '',
          },
        })),
        allModelsLocked,
        translatedFields: scene.translatedFields,
        detailsData,
      };
      if (sceneScreenShot) {
        sceneProps = {
          ...sceneProps,
          image: {
            src: sceneScreenShot,
          },
        };
        setSceneScreenShot('');
      }
      dispatch(action(sceneProps));

      setReadyToSave((prevReadyToSave) => !prevReadyToSave);
      setDisableControls((prevDisableControls) => !prevDisableControls);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [canvasRef, readyToSave, viewerMode]);

  useEffect(() => {
    if (apiError) {
      setIsSaving(false);
    }
  }, [apiError]);

  const handleSaveClick = () => {
    if (!scene.name) {
      setSceneDetailsErrors((prevErrors) => ({
        ...prevErrors,
        name: 'Name is required',
      }));
      setDetailsData({
        type: DetailsType.Scene,
        data: scene,
        interior,
      });
    }

    if (!interior) return;

    setReadyToSave((prevReadyToSave) => !prevReadyToSave);
    setIsSaving(true);
    setDisableControls((prevDisableControls) => !prevDisableControls);
  };

  const handleGenerateClick = () => {
    if (canvasRef && threeRef.current) {
      const dataUrl = `${threeRef.current?.gl.domElement.toDataURL()}`;
      setSceneScreenShot(dataUrl);
    }
  };

  const selectModel = (type, data) => {
    setModels((prevModels) => prevModels.map((prevModel) => {
      if (type === DetailsType.Model && data && data.id === prevModel.id) {
        return {
          ...prevModel,
          selected: true,
        };
      }

      return {
        ...prevModel,
        selected: false,
      };
    }));
  };

  const selectLight = (type, data) => {
    setLights((prevLights) => prevLights.map((prevLight) => (type === DetailsType.Light && data?.id === prevLight.id
      ? { ...prevLight, selected: true }
      : {
        ...prevLight,
        selected: false,
        [SpotLightFieldset.SelectSpotCenter]: false,
      })));
  };

  const getLightName = () => {
    const matchingLights = lights
      .map((light) => light[BaseFieldset.Name])
      .filter((name) => !!name.match(/^Light [0-9]+$/));
    const maxIndex = Math.max(
      ...matchingLights.map((name) => +name.split(' ')[1]),
    );
    return `Light ${maxIndex !== -Infinity ? maxIndex + 1 : 1}`;
  };

  const deselectModels = () => {
    setModels((prevModels) => prevModels.map((prevModel) => ({
      ...prevModel,
      selected: false,
    })));
  };

  const deselectLights = () => {
    setLights((prevLights) => prevLights.map((prevLight) => ({
      ...prevLight,
      selected: false,
      [SpotLightFieldset.SelectSpotCenter]: false,
    })));
  };

  const handleObjectClick = useCallback((type, object) => {
    if (!type && !object) {
      deselectModels();
      deselectLights();
      return;
    }
    if (type === DetailsType.Model) {
      deselectLights();
      selectModel(type, object);
      setExpandedTreeItems((prevExpanded) => [
        ...prevExpanded,
        ExpandVariant.Models,
      ]);
    }

    if (type === DetailsType.Interior) {
      deselectModels();
      deselectLights();
    }

    setDetailsData({
      type,
      data: object,
    });
  }, []);

  const handleAddModel = useCallback(() => {
    if (!expandedTreeItems.includes(ExpandVariant.Models)) {
      setExpandedTreeItems((prevExpanded) => [
        ...prevExpanded,
        ExpandVariant.Models,
      ]);
    }
    setAddModelsOpen(true);
  }, [expandedTreeItems]);

  const handleChangeModel = useCallback(() => {
    if (isEditable) {
      setChangeModelOpen(true);
    }
  }, [isEditable]);

  const toggleObjectHidden = (setState, item, additionalData = {}) => {
    setState((prevState) => prevState.map((prevItem) => ({
      ...prevItem,
      hidden: prevItem.id === item.id ? !prevItem.hidden : prevItem.hidden,
      ...additionalData,
    })));
    setDetailsData((prevDetails) => ({
      ...prevDetails,
      data: {
        ...prevDetails.data,
        hidden: !prevDetails.data.hidden,
        ...additionalData,
      },
    }));
  };

  const handleLinkModel = useCallback((model) => {
    toggleObjectHidden(setModels, model);
  }, []);

  const handleLinkLight = useCallback((light) => {
    toggleObjectHidden(setLights, light, {
      [SpotLightFieldset.SelectSpotCenter]: false,
    });
  }, []);

  const handleModelsListDialogSubmit = useCallback((modelObject) => {
    const modelData = {
      ...INITIAL_MODEL_DATA,
      locked: allModelsLocked,
      id: mongoObjectId(),
      scale:
        Array.isArray(modelObject.scale) && modelObject.scale.length === 3
          ? modelObject.scale
          : [1, 1, 1],
      model: modelObject,
      position: [],
      rotation: [0, 0, 0],
      selected: true,
    };
    setModels((prevModels) => {
      let isNew = true;
      const newModels = prevModels.reduce((acc, model) => {
        if (model.id === modelData.id) {
          isNew = false;
          acc.push(modelData);
        } else {
          acc.push({ ...model, selected: false });
        }

        return acc;
      }, []);

      if (isNew) {
        newModels.push(modelData);
      }

      return newModels;
    });
    deselectLights();
    setDetailsData({
      type: DetailsType.Model,
      data: modelData,
    });
    setAddModelsOpen(false);
  }, [scene, allModelsLocked]);

  const handleModelChangeSubmit = useCallback((modelObject) => {
    const modelData = {
      locked: allModelsLocked,
      model: modelObject,
      hidden: false,
      scale: [1, 1, 1],
      id: mongoObjectId(),
    };
    setModels((prevModels) => prevModels.map((model) => (model.selected ? { ...model, ...modelData } : model)));
    setChangeModelOpen(false);
  }, [allModelsLocked]);

  const handleAddModelDialogClose = useCallback(() => {
    setAddModelsOpen(false);
  }, []);

  const handleChangeModelDialogClose = useCallback(() => {
    setChangeModelOpen(false);
  }, []);

  const handleDeleteModel = useCallback((model) => {
    setModels((prevModels) => prevModels.filter((prev) => prev.id !== model.id));
    setDetailsData({
      type: DetailsType.Interior,
      data: interior,
    });
  }, [interior]);

  const handleCopyModel = useCallback((model) => {
    handleModelsListDialogSubmit(model.model);
  }, [handleModelsListDialogSubmit]);

  const handleAddLight = useCallback(() => {
    if (!expandedTreeItems.includes(ExpandVariant.Lights)) {
      setExpandedTreeItems((prevExpanded) => [
        ...prevExpanded,
        ExpandVariant.Lights,
      ]);
    }

    const light = {
      id: mongoObjectId(),
      [BaseFieldset.Name]: getLightName(),
      [BaseFieldset.Type]: LightType.DirectionalLight,
      [BaseFieldset.Hidden]: false,
      [BaseFieldset.IntensityByType]: {},
      [BaseFieldset.IsShadowByType]: {},
      [BaseFieldset.ColorByType]: {},
      [BaseFieldset.PositionByType]: {},
      [BaseFieldset.OrientationByType]: {},
      [BaseFieldset.DistanceByType]: {},
      selected: true,
      isShadow: true,
      ...getBasic1SpotLightPreset(sceneBox),
      ...getBasic1PointLightPreset(sceneBox),
    };
    deselectModels();
    setLights([
      ...lights.map((prevLight) => ({
        ...prevLight,
        selected: false,
        [SpotLightFieldset.SelectSpotCenter]: false,
      })),
      light,
    ]);
    setDetailsData({
      type: DetailsType.Light,
      data: light,
    });
    setExpandedTreeItems((prevExpanded) => [
      ...prevExpanded,
      ExpandVariant.Lights,
    ]);
  }, [lights, expandedTreeItems]);

  const handleDeleteLight = useCallback((id) => {
    setLights((prevLights) => prevLights.filter((light) => light.id !== id));
    setDetailsData({
      type: DetailsType.Interior,
      data: interior,
    });
  }, [interior]);

  const handleSceneChildrenTreeSelect = useCallback((type, data) => {
    const newDetails = { type, interior };

    if (
      detailsData.data
      && detailsData.type === DetailsType.Light
      && !detailsData.data[BaseFieldset.Name]?.trim()
    ) {
      setLights((prevLights) => prevLights.map((light) => (detailsData.data.id === light.id
        ? { ...light, name: getLightName() }
        : light)));
    }

    switch (type) {
      case DetailsType.Interior:
        newDetails.data = interior;
        break;
      case DetailsType.Scene:
        newDetails.data = scene;
        setScene((prevScene) => ({ ...prevScene, ...newDetails.data }));
        break;
      default:
        newDetails.data = data;
        break;
    }
    selectModel(type, data);
    selectLight(type, data);
    setDetailsData(newDetails);
  }, [interior, scene, detailsData]);

  const handleLinkInterior = useCallback(() => {
    setInteriorsOpen(true);
  }, []);

  const handleLoadInteriorClick = () => {
    if (hasInteriors(interiors)) {
      setInteriorsOpen(true);
    }
  };

  const handleSceneChildDetailsImageClick = useCallback(() => {
    if (hasInteriors(interiors)) {
      setInteriorsOpen(true);
    }
  }, [interiors]);

  const getLightDefaultPreset = (lightType) => {
    const lightPresetsMap = {
      [LightType.SpotLight]: getBasic1SpotLightPreset(sceneBox),
      [LightType.DirectionalLight]: getSunDirectionalLightPreset(sceneBox),
      [LightType.PointLight]: getBasic1PointLightPreset(sceneBox),
    };

    const lightPreset = lightPresetsMap[lightType];
    if (!lightPreset) return null;

    return lightPreset;
  };

  const getLightTypedDetailsData = (type) => ({
    ...(detailsData.data[BaseFieldset.IntensityByType][type] !== undefined
      ? {
        [SpotLightFieldset.Intensity]:
            detailsData.data[BaseFieldset.IntensityByType][type],
      }
      : {}),
    ...(detailsData.data[BaseFieldset.IsShadowByType][type] !== undefined
      ? {
        [SpotLightFieldset.IsShadow]:
            detailsData.data[BaseFieldset.IsShadowByType][type],
      }
      : {}),
    ...(detailsData.data[BaseFieldset.ColorByType][type]
      ? {
        [SpotLightFieldset.Color]:
            typeof detailsData.data[BaseFieldset.ColorByType][type] === 'string'
              ? new KelvinColor(
                detailsData.data[BaseFieldset.ColorByType][type],
              )
              : detailsData.data[BaseFieldset.ColorByType][type],
      }
      : {}),
    ...(detailsData.data[BaseFieldset.PositionByType][type]
    && detailsData.data[BaseFieldset.PositionByType][type].length
      ? {
        [SpotLightFieldset.Position]:
            detailsData.data[BaseFieldset.PositionByType][type],
      }
      : {}),
    ...(detailsData.data[BaseFieldset.OrientationByType][type]
      ? {
        [BaseFieldset.Orientation]:
            detailsData.data[BaseFieldset.OrientationByType][type],
      }
      : {}),
    ...(detailsData.data[BaseFieldset.DistanceByType][type]
      ? {
        [SpotLightFieldset.Distance]:
            detailsData.data[BaseFieldset.DistanceByType][type],
      }
      : {}),
  });

  const handleLightTypeChange = useCallback(
    (field, value) => {
      const lightPreset = getLightDefaultPreset(value);
      const isElectricLights = isElectricLight(value) && isElectricLight(detailsData.data.type);

      return {
        ...detailsData,
        data: {
          ...detailsData.data,
          ...(isElectricLights
            ? getElectricLightInitialData(sceneBox, detailsData.data)
            : lightPreset || {}),
          ...getLightTypedDetailsData(value),
          [BaseFieldset.DistanceByType]: {
            ...detailsData.data[BaseFieldset.DistanceByType],
            [detailsData.data.type]: detailsData.data.distance,
          },
          [field]: value,
        },
      };
    }, [detailsData, sceneBox],
  );

  const handleLightPresetChange = useCallback(
    (field, value) => {
      const getLightPreset = (lightType) => {
        const lightsPresetsMap = {
          [LightType.SpotLight]: {
            [SpotLightPreset.Basic1]: getBasic1SpotLightPreset(
              sceneBox,
            ),
            [SpotLightPreset.Basic2]: getBasic2SpotLightPreset(),
          },
          [LightType.DirectionalLight]: {
            [DirectionalLightPreset.Sun]:
              getSunDirectionalLightPreset(sceneBox),
            [DirectionalLightPreset.Moon]: getMoonDirectionalLightPreset(),
          },
          [LightType.PointLight]: {
            [PointLightPreset.Basic1]: getBasic1PointLightPreset(
              sceneBox,
            ),
            [PointLightPreset.Basic2]: getBasic2PointLightPreset(),
          },
        };

        const lightPresetsMap = lightsPresetsMap[lightType];
        if (!lightPresetsMap) return null;

        const lightPreset = lightPresetsMap[value];
        if (!lightPreset) return null;

        return lightPreset;
      };

      const lightPreset = detailsData.data[BaseFieldset.Type]
        ? getLightPreset(detailsData.data[BaseFieldset.Type])
        : null;

      return {
        ...detailsData,
        data: {
          ...detailsData.data,
          ...(lightPreset || {}),
          [field]: value,
        },
      };
    }, [detailsData],
  );

  const handleLightOrientationChange = useCallback(
    (field, value) => {
      let newPosition = [0, 0, 0];

      const top = sceneBox.ceiling[0].y;
      const bottom = sceneBox.floor[0].y;
      const left = 0;
      const right = 0;
      const back = 0;
      const front = 0;

      switch (value) {
        case OrientationType.Behind:
          newPosition = [(left + right) / 2, (top + bottom) / 2, back];
          break;
        case OrientationType.Front:
          newPosition = [
            (left + right) / 2,
            (top + bottom) / 2,
            front,
          ];
          break;
        case OrientationType.Top:
          newPosition = [
            (left + right) / 2,
            top,
            (back + front) / 2,
          ];
          break;
        case OrientationType.Left:
          newPosition = [left, (top + bottom) / 2, (back + front) / 2];
          break;
        case OrientationType.Right:
          newPosition = [
            right,
            (top + bottom) / 2,
            (back + front) / 2,
          ];
          break;
        default:
          break;
      }

      return {
        ...detailsData,
        data: {
          ...detailsData.data,
          [field]: value,
          position: newPosition,
        },
      };
    }, [detailsData, sceneBox],
  );

  const handleLightGroupChange = useCallback(
    (field, value) => {
      const type = value === LightGroup.Electric.key
        ? LightType.PointLight
        : LightType.DirectionalLight;
      const preset = getLightDefaultPreset(type);
      return {
        ...detailsData,
        data: {
          ...detailsData.data,
          ...(preset || {}),
          ...getLightTypedDetailsData(type),
          [SpotLightFieldset.Angle]:
            detailsData.data[SpotLightFieldset.Angle]
            || preset[SpotLightFieldset.Angle],
          [SpotLightFieldset.Target]:
            detailsData.data[SpotLightFieldset.Target]
            || preset[SpotLightFieldset.Target],
          [BaseFieldset.Type]: type,
          [field]: value,
        },
      };
    }, [detailsData],
  );

  const handleSceneChildDetailsChange = useCallback(
    ({ value }, field) => {
      let newDetails = {
        ...detailsData,
      };

      if (detailsData.type === DetailsType.Scene) {
        // allows setting nested values as "a.b.c" (for objects or arrays)
        // ref: https://stackoverflow.com/a/48589524
        const setValue = (object, path, value) => {
          const way = path.replace(/\[/g, '.').replace(/\]/g, '').split('.');
          const last = way.pop();

          way.reduce((o, k, i, kk) => (
            o[k] = o[k] || (Number.isFinite(i + 1 in kk ? kk[i + 1] : last) ? [] : {})
          ), object)[last] = value;
        };
        setValue(newDetails.data, field, value);

        newDetails = {
          ...newDetails,
          data: {
            ...newDetails.data,
          },
        };
      } else {
        newDetails = {
          ...detailsData,
          data: {
            ...detailsData.data,
            [field]: value,
          },
        };
      }

      const arrToInteger = (arr) => arr.map((value) => valueToFixedNumber(value, 2));

      if (detailsData.type === DetailsType.Light) {
        newDetails = {
          ...detailsData,
          data: {
            ...detailsData.data,
            [BaseFieldset.Preset]: LightBasePreset.Custom,
            [field]: value,
          },
        };

        if (field === BaseFieldset.Type) {
          newDetails = handleLightTypeChange(field, value);
        } else if (field === BaseFieldset.Preset) {
          newDetails = handleLightPresetChange(field, value);
        } else if (field === BaseFieldset.Orientation) {
          newDetails = handleLightOrientationChange(field, value);
        } else if (field === BaseFieldset.Group) {
          newDetails = handleLightGroupChange(field, value);
        }
      }

      switch (detailsData.type) {
        case DetailsType.Scene:
          if (newDetails.data.name) {
            setSceneDetailsErrors((prevErrors) => ({
              ...prevErrors,
              name: '',
            }));
          }
          setDetailsData(newDetails);
          setScene((prevScene) => ({ ...prevScene, ...newDetails.data }));
          break;
        case DetailsType.Interior:
          setDetailsData(newDetails);
          setInterior({ ...newDetails.data });
          break;
        case DetailsType.Model:
          setDetailsData(newDetails);
          setModels((prevModels) => prevModels.map((model) => {
            if (model.id === newDetails.data.id) {
              return {
                ...newDetails.data,
                position: arrToInteger(newDetails.data.position),
                rotation: arrToInteger(newDetails.data.rotation),
                selected: model.selected,
              };
            }

            return model;
          }));
          break;
        case DetailsType.Light:
          setDetailsData({
            ...newDetails,
            data: {
              ...newDetails.data,
              [BaseFieldset.IntensityByType]: {
                ...detailsData.data[BaseFieldset.IntensityByType],
                ...newDetails.data[BaseFieldset.IntensityByType],
                [newDetails.data.type]: newDetails.data.intensity,
              },
              [BaseFieldset.IsShadowByType]: {
                ...detailsData.data[BaseFieldset.IsShadowByType],
                ...newDetails.data[BaseFieldset.IsShadowByType],
                [newDetails.data.type]: newDetails.data.isShadow,
              },
              [BaseFieldset.ColorByType]: {
                ...detailsData.data[BaseFieldset.ColorByType],
                ...newDetails.data[BaseFieldset.ColorByType],
                [newDetails.data.type]: newDetails.data.color,
              },
              [BaseFieldset.PositionByType]: {
                ...detailsData.data[BaseFieldset.PositionByType],
                ...newDetails.data[BaseFieldset.PositionByType],
                [newDetails.data.type]: newDetails.data.position,
              },
              [BaseFieldset.OrientationByType]: {
                ...detailsData.data[BaseFieldset.OrientationByType],
                ...newDetails.data[BaseFieldset.OrientationByType],
                [newDetails.data.type]: newDetails.data.orientation,
              },
              [BaseFieldset.DistanceByType]: {
                ...detailsData.data[BaseFieldset.DistanceByType],
                ...newDetails.data[BaseFieldset.DistanceByType],
                [newDetails.data.type]: newDetails.data.distance,
              },
            },
          });
          setLights((prevLights) => prevLights.map((light) => {
            if (light.id === newDetails.data.id) {
              return {
                ...newDetails.data,
                [BaseFieldset.IntensityByType]: {
                  ...light[BaseFieldset.IntensityByType],
                  [newDetails.data.type]: newDetails.data.intensity,
                },
                [BaseFieldset.IsShadowByType]: {
                  ...light[BaseFieldset.IsShadowByType],
                  [newDetails.data.type]: newDetails.data.isShadow,
                },
                [BaseFieldset.ColorByType]: {
                  ...light[BaseFieldset.ColorByType],
                  [newDetails.data.type]: newDetails.data.color,
                },
                [BaseFieldset.PositionByType]: {
                  ...light[BaseFieldset.PositionByType],
                  [newDetails.data.type]: newDetails.data.position,
                },
                [BaseFieldset.OrientationByType]: {
                  ...light[BaseFieldset.OrientationByType],
                  [newDetails.data.type]: newDetails.data.orientation,
                },
                [BaseFieldset.DistanceByType]: {
                  ...light[BaseFieldset.DistanceByType],
                  [newDetails.data.type]: newDetails.data.distance,
                },
                hidden: light.hidden,
                selected: true,
              };
            }

            return { ...light, [SpotLightFieldset.SelectSpotCenter]: false };
          }));
          break;
        default:
          break;
      }
    }, [detailsData, interior, scene, sceneBox],
  );

  const handleInteriorsListDialogSubmit = useCallback((interior) => {
    setInteriorsOpen(false);
    setInterior(interior);
    setDetailsData(({
      type: DetailsType.Interior,
      data: interior,
    }));
    if (viewerMode !== Dimensions.ThreeDimensional) {
      setViewerMode(Dimensions.ThreeDimensional);
    }
  }, [viewerMode]);

  const handleInteriorsListDialogClose = useCallback(() => {
    setInteriorsOpen(false);
  }, []);

  useEffect(() => {
    setDetailsData((prevDetailsData) => {
      if (prevDetailsData) {
        if (prevDetailsData.type === DetailsType.Interior) {
          return {
            type: DetailsType.Interior,
            data: interior,
          };
        }

        return {
          ...prevDetailsData,
          interior,
        };
      }

      return prevDetailsData;
    });
  }, [interior]);

  const handleObjectUpdate = useCallback(
    (type, object, data, fields) => {
      const getNewAngle = (light) => {
        const isAngleChanged = fields.includes(SpotLightFieldset.Target)
          && fields.includes(SpotLightFieldset.Distance);
        const prevDiameter = calcDiameterFromAngle(
          light[SpotLightFieldset.Distance],
          light[SpotLightFieldset.Angle],
        );
        return isAngleChanged
          ? calcAngleFromDiameter(
            prevDiameter,
            data[SpotLightFieldset.Distance],
          )
          : light.angle;
      };

      if (type === ObjectType.Model) {
        setModels((prevModels) => prevModels.map((model) => {
          if (model.id === object.id) {
            return { ...object, ...data, ...(data.position ? { position: [...data.position] } : {}) };
          }
          return model;
        }));
        if (detailsData.type === DetailsType.Model) {
          setDetailsData((prevDetails) => ({
            ...prevDetails,
            type: DetailsType.Model,
            data: {
              ...object,
              ...data,
            },
            ...(data.position ? { position: [...data.position] } : {}),
          }));
        }
      }

      if (type === ObjectType.Light) {
        setLights((prevLights) => prevLights.map((light) => {
          if (light.id === object.id) {
            return {
              ...light,
              ...(data.position ? { position: [...data.position] } : {}),
              ...(data.target ? { target: [...data.target] } : {}),
              ...(data.distance ? { distance: data.distance } : {}),
              [BaseFieldset.PositionByType]: {
                ...light[BaseFieldset.PositionByType],
                [light.type]: data.position,
              },
              [BaseFieldset.DistanceByType]: {
                ...light[BaseFieldset.DistanceByType],
                [light.type]: data.distance,
              },
              [SpotLightFieldset.Angle]: getNewAngle(light),
            };
          }
          return { ...light, [SpotLightFieldset.SelectSpotCenter]: false };
        }));
        if (detailsData.type === DetailsType.Light) {
          setDetailsData((prevDetails) => ({
            ...prevDetails,
            type: DetailsType.Light,
            data: {
              ...object,
              ...data,
              [BaseFieldset.PositionByType]: {
                ...prevDetails.data[BaseFieldset.PositionByType],
                [prevDetails.data.type]: data.position,
              },
              [BaseFieldset.DistanceByType]: {
                ...prevDetails.data[BaseFieldset.DistanceByType],
                [prevDetails.data.type]: data.distance,
              },
              [SpotLightFieldset.Angle]: getNewAngle(prevDetails.data),
              [SpotLightFieldset.Color]: new KelvinColor(object.color.substr(2)),
            },
          }));
        }
      }
    }, [detailsData.type],
  );

  useEffect(() => {
    if (!models || !models.length) {
      setIsLoaded(true);
    }
  }, [models]);

  const handleModelsTreeItemLabelClick = () => {
    setExpandedTreeItems((prevExpanded) => [
      ...prevExpanded,
      ExpandVariant.Models,
    ]);
  };

  const handleLightsTreeItemLabelClick = () => {
    setExpandedTreeItems((prevExpanded) => [
      ...prevExpanded,
      ExpandVariant.Lights,
    ]);
  };

  const handleNodeToggle = (event, nodeIds) => {
    setExpandedTreeItems(nodeIds);
  };

  const { goBack } = useNavigate();
  const handleCancelClick = () => {
    goBack();
  };

  const handleModelsLoad = useCallback((loaded) => {
    setIsLoaded(loaded);
  }, []);

  const scenesNameExists = scenes?.some((item) => scene.name.toLowerCase() === item.name.toLowerCase() && scene.id !== item.id);

  const isSaveDisabled = !isLoaded
    || isSaving
    || !interior
    || scene.name.trim() === ''
    || scenesNameExists;

  return (
    <Content>
      {viewerMode === Dimensions.ThreeDimensional ? (
        <SplitPane
          split="vertical"
          defaultSizes={ [1, 5, 2] }
          minSize={ [220, 50, 250] }
        >
          <Pane>
            <Grid
              className={ classes.sceneGlobalsPanel }
              container
              spacing={ 2 }
              direction="column"
            >
              <Grid item>
                <SceneChildrenTree
                  isEditable={ isEditable }
                  isDisabled={ !isLoaded }
                  models={ models }
                  allModelsLocked={ allModelsLocked }
                  lights={ lights }
                  interior={ interior }
                  selected={ detailsData }
                  onAddModel={ handleAddModel }
                  onDeleteModel={ handleDeleteModel }
                  onLockAllModels={ handleToggleLockAllModels }
                  onUnlockAllModels={ handleToggleLockAllModels }
                  onAddLight={ handleAddLight }
                  onDeleteLight={ handleDeleteLight }
                  onSelect={ handleSceneChildrenTreeSelect }
                  onLinkInterior={ handleLinkInterior }
                  onLinkModel={ handleLinkModel }
                  onLinkLight={ handleLinkLight }
                  onChangeModel={ handleChangeModel }
                  expanded={ expandedTreeItems }
                  onModelsTreeItemLabelClick={ handleModelsTreeItemLabelClick }
                  onLightsTreeItemLabelClick={ handleLightsTreeItemLabelClick }
                  onNodeToggle={ handleNodeToggle }
                />
              </Grid>
            </Grid>
          </Pane>

          <Pane className={ classes.mainScreen }>
            <SceneToolsPanel>
              <DimensionsToggle
                value={ viewerMode }
                onChange={ handleViewerMode }
              />

              <SceneToolsActions>
                <CancelButton onClick={ handleCancelClick }>Cancel</CancelButton>
                <SaveButton disabled={ isSaveDisabled || !isEditable } onClick={ handleSaveClick }>
                  Save
                </SaveButton>
              </SceneToolsActions>
            </SceneToolsPanel>
            {interior && (
              <div ref={ rootRef } className={ classes.viewersContainer }>
                <ZViewer
                  rootRef={ rootRef }
                  threeRef={ threeRef }
                  className={ classes.zViewer }
                  sceneUrl={ data?.url }
                  env={ scene.env }
                  envIntensity={ scene.envIntensity }
                  envRotation={ scene.envRotation }
                  interior={ interior }
                  lights={ lights }
                  globalLightColor={ scene.globalLightColor }
                  globalLightIntensity={ scene.globalLightIntensity }
                  models={ models }
                  viewerMode={ viewerMode }
                  softShadows={ scene.softShadows }
                  size={ scene.size }
                  samples={ scene.samples }
                  focus={ scene.focus }
                  shadowOpacity={ scene.shadowOpacity }
                  onObjectClick={ handleObjectClick }
                  onObjectUpdate={ handleObjectUpdate }
                  onObjectDelete={ handleDeleteModel }
                  onObjectHide={ handleLinkModel }
                  onObjectCopy={ handleCopyModel }
                  setCanvasRef={ setCanvasRef }
                  disableControls={ disableControls || !isEditable }
                  disableToolbar={ !isEditable }
                  onLoad={ handleModelsLoad }
                  displayIndicators
                  toneMapping={ company?.toneMapping }
                  toneMappingExposure={ company?.toneMappingExposure }
                />
              </div>
            )}
            {
              !interior && !params.id && (
                <EmptySceneLayout>
                  <EmptySceneTitle>
                    Select Interior from your gallery
                  </EmptySceneTitle>
                  <EmptySceneActionButton
                    disabled={ !hasInteriors(interiors) }
                    onClick={ handleLoadInteriorClick }
                  >
                    Load Interior
                  </EmptySceneActionButton>
                </EmptySceneLayout>
              )
            }
          </Pane>
          <Pane>
            {isSaving && (
              <Backdrop className={ classes.backdrop }>
                <CircularProgress size="large" />
              </Backdrop>
            )}
            <SceneChildDetails
              scene={ scene }
              data={ detailsData }
              sceneBox={ sceneBox }
              sceneDetailsErrors={ sceneDetailsErrors }
              onImageClick={ handleSceneChildDetailsImageClick }
              onChange={ handleSceneChildDetailsChange }
              isEditable={ isEditable }
              scenesNameExists={ scenesNameExists }
            />
          </Pane>
        </SplitPane>
      ) : (
        <div className={ classes.mainScreen }>
          <SceneToolsPanel>
            <DimensionsToggle value={ viewerMode } onChange={ handleViewerMode } />
            <SceneToolsActions>
              <SaveButton disabled={ isSaveDisabled || !isEditable } onClick={ handleGenerateClick }>
                Generate Preview
              </SaveButton>
              <CancelButton onClick={ handleCancelClick }>Cancel</CancelButton>
              <SaveButton
                disabled={ isSaveDisabled || !isEditable }
                onClick={ handleSaveClick }
              >
                Save
              </SaveButton>
            </SceneToolsActions>
          </SceneToolsPanel>
          {!canvasRef && interior && (
            <Backdrop className={ classes.backdrop }>
              <CircularProgress size="large" />
            </Backdrop>
          )}
          <div ref={ rootRef } className={ classes.zViewer2d }>
            {interior && (
              <>
                <ZViewer
                  rootRef={ rootRef }
                  threeRef={ threeRef }
                  className={ classes.zViewer }
                  sceneUrl={ data?.url }
                  env={ scene.env }
                  envIntensity={ scene.envIntensity }
                  envRotation={ scene.envRotation }
                  interior={ interior }
                  lights={ lights }
                  globalLightColor={ scene.globalLightColor }
                  globalLightIntensity={ scene.globalLightIntensity }
                  models={ models }
                  viewerMode={ viewerMode }
                  softShadows={ scene.softShadows }
                  size={ scene.size }
                  samples={ scene.samples }
                  focus={ scene.focus }
                  shadowOpacity={ scene.shadowOpacity }
                  onObjectClick={ handleObjectClick }
                  onObjectUpdate={ handleObjectUpdate }
                  onObjectDelete={ handleDeleteModel }
                  onObjectHide={ handleLinkModel }
                  onObjectCopy={ handleCopyModel }
                  setCanvasRef={ setCanvasRef }
                  disableControls={ disableControls }
                  onLoad={ handleModelsLoad }
                  displayIndicators
                  toneMapping={ company?.toneMapping }
                  toneMappingExposure={ company?.toneMappingExposure }
                />
              </>
            )}
            {
              !interior && !params.id && (
                <EmptySceneLayout>
                  <EmptySceneTitle>
                    Select Interior from your gallery
                  </EmptySceneTitle>
                  <EmptySceneActionButton
                    disabled={ !hasInteriors(interiors) }
                    onClick={ handleLoadInteriorClick }
                  >
                    Load Interior
                  </EmptySceneActionButton>
                </EmptySceneLayout>
              )
            }
          </div>
        </div>
      )}
      <InteriorsListDialog
        open={ interiorsOpen }
        onSubmit={ handleInteriorsListDialogSubmit }
        onClose={ handleInteriorsListDialogClose }
      />
      <ModelsListDialog
        open={ addModelsOpen }
        onSubmit={ handleModelsListDialogSubmit }
        onClose={ handleAddModelDialogClose }
      />
      <ModelsListDialog
        open={ changeModelOpen }
        onSubmit={ handleModelChangeSubmit }
        onClose={ handleChangeModelDialogClose }
      />
    </Content>
  );
};

export default memo(SceneEdit);
