import { forwardRef, useCallback } from 'react';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import Typography from '@material-ui/core/Typography';
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';
import { makeStyles } from '@material-ui/core/styles';
import Checkbox from '@shared/components/checkbox';

const useStyles = makeStyles(() => ({
  root: {
    padding: 8,
  },
  attributeLabel: {
    fontWeight: 700,
    fontSize: '14px',
    lineHeight: '21px',
    padding: '8px 0',
  },
  label: {
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '21px',
    padding: '8px 0',
  },
}));

const AttributesTable = forwardRef(({
  attributeDefinitions,
  onChange,
  value,
}, ref) => {
  const classes = useStyles();

  const handleChange = useCallback((checkedAttribute, checked) => {
    let updatedValue;
    if (checked) {
      updatedValue = [...value, checkedAttribute];
    } else {
      updatedValue = value.filter((valueAttribute) => valueAttribute.id !== checkedAttribute.id);
    }
    onChange(updatedValue);
  }, [onChange, value]);

  return (
    <div className={ classes.root } ref={ ref }>
      {
        attributeDefinitions.map((attributeDefinition) => (
          <Accordion key={ attributeDefinition.id }>
            <AccordionSummary expandIcon={ <ArrowDropDown /> }>
              <Typography className={ classes.attributeLabel }>{ attributeDefinition.name }</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <FormGroup>
                { attributeDefinition.attributes.sort((a, b) => a.value.localeCompare(b.value)).map((attribute) => (
                  <FormControlLabel
                    key={ attribute.id }
                    label={ <Typography className={ classes.label }>{ attribute.value }</Typography> }
                    control={ (
                      <Checkbox
                        value={ attribute.value }
                        checked={ value.some((attr) => attr.id == attribute.id) }
                        onChange={ (_, checked) => handleChange(attribute, checked) }
                      />
                    ) }
                  />
                )) }
              </FormGroup>
            </AccordionDetails>
          </Accordion>
        ))
      }
    </div>
  );
});

export default AttributesTable;
