import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { loadInteriorsList } from '@store/interiors/actions';
import { loadScene, loadScenesList } from '@store/scenes/actions';
import { loadAttributeDefinitions } from '@store/attribute-definitions/actions';

import { actions } from '@store/app';

import SceneEditPage from '@pages/scenes/edit';
import SceneList from '@pages/scenes/list';
import withAuth from '@shared/components/route/withAuth';
import useActions from '@shared/hooks/useActions';
import { loadProductsList } from '@store/products/actions';
import { EntityTypes } from '@shared/constants';

export const ScenesList = withAuth(() => {
  const dispatch = useDispatch();
  const { showMenu } = useActions(actions);

  useEffect(() => {
    showMenu();
    dispatch(loadScenesList());
    dispatch(loadInteriorsList());
    dispatch(loadAttributeDefinitions([EntityTypes.Interior]));
  }, [dispatch, showMenu]);

  return <SceneList />;
});

export const SceneNew = withAuth(() => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadInteriorsList());
    dispatch(loadProductsList());
    dispatch(loadAttributeDefinitions([EntityTypes.Interior]));
  }, [dispatch]);

  return <SceneEditPage />;
});

export const SceneEdit = withAuth(() => {
  const { id } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadScene(id));
    dispatch(loadInteriorsList());
    dispatch(loadProductsList());
    dispatch(loadAttributeDefinitions([EntityTypes.Interior]));
  }, [dispatch, id]);

  return <SceneEditPage />;
});
