import { Dialog, DialogActions, DialogTitle } from '@shared/components/dialog';
import Button from '@shared/components/button';

const WarningDialog = ({ open, title, onClose }) => (
  <Dialog
    open={ open }
    maxWidth="xs"
    onClose={ onClose }
    aria-labelledby="default-alert-dialog-title"
  >
    <DialogTitle id="default--alert-dialog-title">{title}</DialogTitle>
    <DialogActions>
      <Button onClick={ onClose }>OK</Button>
    </DialogActions>
  </Dialog>
);

export default WarningDialog;
