import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  root: {
    width: '2.6rem',
    height: '2.6rem',
    objectFit: 'cover',
    borderRadius: '.4rem',
  },
}));

const InteriorImage = ({
  src,
  onClick,
}) => {
  const classes = useStyles();

  return (
    <img
      className={ classes.root }
      src={ src }
      alt="interior"
      onClick={ onClick }
    />
  );
};

export default InteriorImage;
