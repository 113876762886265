import { useCallback, useState } from 'react';
import { useController } from 'react-hook-form';
import ImageFullSize from '@shared/components/image-full-size';
import {
  Preview as FormDialogPreview,
  ModelViewer,
} from '@shared/views/form-dialog';
import { WireframeMaterialName } from '@shared/constants';
import DownloadIcon from '@shared/icons/DownloadIcon';
import { makeStyles } from '@material-ui/core/styles';
import { GLTFExporter } from 'three/addons/exporters/GLTFExporter.js';

function save(blob, filename) {
  const link = document.createElement('a');
  link.href = URL.createObjectURL(blob);
  link.download = filename;
  link.click();
}

function saveString(text, filename) {
  save(new Blob([text], { type: 'text/plain' }), filename);
}


function saveArrayBuffer(buffer, filename) {
  save(new Blob([buffer], { type: 'application/octet-stream' }), filename);
}

const useStyles = makeStyles((theme) => ({
  downloadButton: {
    position: 'absolute',
    top: theme.spacing(1),
    right: theme.spacing(2) + 36,
    padding: theme.spacing(0.5),
    display: 'flex',
    backgroundColor: '#E2EAF3',
    border: '1px solid #767676',
    borderRadius: '2px',
    width: '36px',
    height: '36px',
    alignItems: 'center',
    justifyContent: 'center',
    lineHeight: '36px',
    cursor: 'pointer',
  },
}));

const Preview = ({
  name,
  supportedFormats,
  control,
  onLoad,
  onLoadSize,
  renewable,
  materials,
  generateThumbnail = false,
  renderModelViewerExtraContent,
  environment,
  fileName,
  size,
}) => {
  const classes = useStyles();
  const {
    field: { onChange, value, ...inputProps },
  } = useController({
    name,
    control,
    rules: { validate: (value) => !!value.blob || 'Required' },
  });

  const [fullScreenObject, setFullScreenObject] = useState();
  const [scene, setScene] = useState();

  const handleLoad = useCallback((object) => {
    setFullScreenObject(object);
  }, []);

  const onFrame = useCallback((object) => {
    object?.traverse((element) => {
      if (element.isMesh) {
        if (!element.userData.wireframeToApply && element.userData.wireframedMaterial && element.material.name === WireframeMaterialName) {
          element.material.dispose();
          element.material = element.userData.wireframedMaterial;

          delete element.userData.wireframedMaterial;
        } else if (element.userData.wireframeToApply && !element.userData.wireframedMaterial) {
          element.userData.wireframedMaterial = element.material;
          element.material = element.userData.wireframeToApply;
        } else if (element.userData.wireframeToApply && element.userData.wireframedMaterial && element.material.name !== WireframeMaterialName) {
          if (element.userData.wireframedMaterial.length) {
            element.userData.wireframedMaterial.forEach((m) => {
              m.dispose();
            });
          } else {
            element.userData.wireframedMaterial.dispose();
          }
          element.userData.wireframedMaterial = element.material;
          element.material = element.userData.wireframeToApply;
        }
      }
    });
  }, []);

  const renderFullScreenContent = useCallback(() => (
    <>
      { renderModelViewerExtraContent && renderModelViewerExtraContent(fullScreenObject) }

      <ModelViewer
        value={ value }
        materials={ materials.map((item) => ({ ...item })) }
        environment={ environment }
        generateThumbnail={ false }
        onLoad={ handleLoad }
        size={ size }
      />
    </>
  ), [renderModelViewerExtraContent, value, materials, environment, handleLoad, fullScreenObject, size]);

  const handleDownload = () => {
    const exporter = new GLTFExporter();
    scene.traverse((node) => {
      if (node.isMesh && node.material?.map?.image?.depth) {
        node.visible = false;
      }
    });
    exporter.parse(
      scene,
      (result) => {
        const name = `${fileName?.toLowerCase() || 'model'}`;
        if (result instanceof ArrayBuffer) {
          saveArrayBuffer(result, `${name}.glb`);
        } else {
          const output = JSON.stringify(result, null, 2);
          saveString(output, `${name}.gltf`);
        }
      },
      (error) => {
        console.log('An error happened during export', error);
      },
      {},
    );
    scene.traverse((node) => {
      if (node.isMesh && node.material?.map?.image?.depth) {
        node.visible = true;
      }
    });
  };

  const handleSceneLoad = (object, camera, gl, scene) => {
    onLoad && onLoad(object, camera, gl, scene);
    setScene(scene);
  };

  return (
    <FormDialogPreview
      value={ value }
      supportedFormats={ supportedFormats }
      disabled
      renderFileViewer={ ({ value }) => (
        <ImageFullSize renderFullScreenContent={ renderFullScreenContent }>
          {renderModelViewerExtraContent && renderModelViewerExtraContent()}

          <ModelViewer
            value={ value }
            onChange={ onChange }
            onLoad={ handleSceneLoad }
            onLoadSize={ onLoadSize }
            onFrame={ onFrame }
            materials={ materials }
            environment={ environment }
            generateThumbnail={ generateThumbnail }
            size={ size }
          />
          <button
            className={ classes.downloadButton }
            type="button"
            onClick={ handleDownload }
          >
            <DownloadIcon />
          </button>
        </ImageFullSize>
      ) }
      onChange={ onChange }
      renewable={ renewable }
      { ...inputProps }
    />
  );
};

export default Preview;
