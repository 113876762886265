/* eslint-disable max-len */
import { makeStyles } from '@material-ui/core';
import SvgIcon from '@material-ui/core/SvgIcon';
import { DefaultIconSizeValue, IconSize } from '@resources/icons/constants';

const useStyles = makeStyles(() => ({
  root: {
    fill: 'none',
  },
}));

const NaturalLightFront = ({ size = IconSize.Medium, selected = false }) => {
  const classes = useStyles();
  return (
    <SvgIcon
      style={ { width: DefaultIconSizeValue[size], height: DefaultIconSizeValue[size] } }
      className={ classes.root }
      viewBox="0 0 36 35"
    >
      <path
        d="M17.0175 16.1152V30.4685C17.0175 30.57 16.9771 30.6674 16.9053 30.7391C16.8336 30.8109 16.7362 30.8512 16.6347 30.8512H3.62103C3.51952 30.8512 3.42217 30.8109 3.35039 30.7391C3.27861 30.6674 3.23828 30.57 3.23828 30.4685V17.1857"
        stroke="#334D6E"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.20083 17.0728H1.76861C1.59654 17.0735 1.42926 17.0163 1.29375 16.9102C1.15824 16.8042 1.06241 16.6556 1.02171 16.4884C0.981022 16.3212 0.997845 16.1452 1.06947 15.9887C1.14109 15.8323 1.26334 15.7045 1.41648 15.626L15.8999 8.0858C16.0167 8.02529 16.1471 7.99589 16.2786 8.00046C16.41 8.00503 16.5381 8.0434 16.6504 8.11187C16.7628 8.18034 16.8556 8.27661 16.9199 8.39138C16.9841 8.50615 17.0178 8.63555 17.0175 8.7671V23.5797"
        stroke="#334D6E"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8 34V20H22V34H8Z"
        fill="#EBEFF2"
        stroke="#334D6E"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path opacity="0.8" d="M9 33L23 9H31L21 33H9Z" fill={ selected ? '#ffffff' : '#EBEFF2' } />
      <path d="M19 9H35" stroke="#334D6E" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M21.3438 14.6572L32.6575 3.34352"
        stroke="#334D6E"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M32.6582 14.6572L21.3445 3.34352"
        stroke="#334D6E"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M27 17V1" stroke="#334D6E" strokeLinecap="round" strokeLinejoin="round" />
      <circle
        cx="26.9824"
        cy="8.98244"
        r="5.4824"
        transform="rotate(-90 26.9824 8.98244)"
        fill="#F0F0F0"
        stroke="#334D6E"
      />
      <circle cx="27" cy="9" r="4" fill="#334D6E" />
    </SvgIcon>
  );
};

export default NaturalLightFront;
