import { useMemo, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Box, FormControl, Tabs, Tab } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Fields, TextField } from '@shared/views/form-dialog';
import AttributesTable from './AttributesTable';
import EntityTypesField from './EntityTypeField';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    display: 'flex',
    height: '100%',
  },
  tabs: {
    borderRight: 1,
    borderColor: 'divider',
    flex: '0 0 auto',
  },
  tabPanel: {
    padding: '8px 20px',
    overflow: 'auto',
    flex: '1 1 auto',
  },
  header: {
    alignItems: 'center',
    display: 'flex',
  },
  headerLabel: {
    fontSize: '18px',
    padding: '8px',
  },
  formControl: {
    margin: theme.spacing(1, 0),
  },
  label: {
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '21px',
    padding: '8px 0',
  },
  addIcon: {
    fontSize: '2rem',
  },
  groupField: {
    position: 'relative',
  },
}));

const FormFieldset = ({ attributeDefinition, lang }) => {
  const classes = useStyles();

  const { control } = useFormContext();
  const [tab, setTab] = useState(0);

  const handleTabChange = (_, newValue) => {
    setTab(newValue);
  };

  const useLang = useMemo(() => lang && lang !== 'en', [lang]);
  const currentTranslation = useMemo(() => attributeDefinition?.translatedFields[lang], [attributeDefinition, lang]);

  return (
    <Box className={ classes.root }>
      <Tabs
        orientation="vertical"
        variant="scrollable"
        value={ tab }
        onChange={ handleTabChange }
        aria-label="Attribute definitions tabs"
        className={ classes.tabs }
      >
        <Tab label="Main" />
        <Tab label="Values" />
      </Tabs>
      <div
        role="tabpanel"
        className={ classes.tabPanel }
        hidden={ tab !== 0 }
        id="vertical-tabpanel-0"
        aria-labelledby="vertical-tab-0"
      >
        <Fields>
          <div className={ classes.headerLabel }>Main</div>
          <FormControl fullWidth variant="outlined" className={ classes.formControl }>
            <Controller
              name={ useLang ? `translatedFields.${lang}.name` : 'name' }
              key={ useLang ? `translatedFields.${lang}.name` : 'name' }
              render={ ({ field, fieldState: { error } }) => (
                <TextField
                  { ...field }
                  id={ useLang ? `translatedFields.${lang}.name` : 'name' }
                  helperText={ error?.message ?? null }
                  variant="outlined"
                  label="Name"
                  required
                  error={ !!error }
                />
              ) }
              defaultValue={ useLang ? (currentTranslation?.name || '') : (attributeDefinition?.name || '') }
              control={ control }
            />
          </FormControl>
          <FormControl fullWidth variant="outlined" className={ classes.formControl }>
            <Controller
              name="entityTypes"
              control={ control }
              defaultValue={ attributeDefinition?.entityTypes || [] }
              render={ ({ field, fieldState: { error } }) => (
                <EntityTypesField
                  { ...field }
                  error={ error }
                />
              ) }
              rules={ {
                required: 'Required',
              } }
            />
          </FormControl>
        </Fields>
      </div>
      <div
        role="tabpanel"
        className={ classes.tabPanel }
        hidden={ tab !== 1 }
        id="vertical-tabpanel-1"
        aria-labelledby="vertical-tab-1"
      >
        <AttributesTable
          classes={ classes }
          lang={ lang }
        />
      </div>
    </Box>
  );
};

export default FormFieldset;
