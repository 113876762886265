import { useCallback, useMemo } from 'react';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import IconButton from '@material-ui/core/IconButton';
import Add from '@material-ui/icons/Add';

import DeleteIcon from '@resources/icons/DeleteIcon';

import {
  Fields,
  TextField,
  GroupField,
} from '@shared/views/form-dialog';

const AttributesTable = ({
  classes,
  lang,
}) => {
  const { formState: { errors: fieldsErrors }, control } = useFormContext();

  const { fields, append, remove } = useFieldArray(
    {
      control,
      name: 'attributes',
    },
  );

  const useLang = useMemo(() => lang && lang !== 'en', [lang]);
  const currentTranslation = useCallback((index) => fields[index].translatedFields[lang]);

  const handleAdd = useCallback(() => {
    append({ id: fields.length, value: '', translatedFields: {} });
  }, [fields, append]);

  const handleDelete = useCallback((index) => () => {
    remove(index);
  }, [remove]);

  return (
    <>
      <div className={ classes.header }>
        <div className={ classes.headerLabel }>Values</div>
        <IconButton aria-label="add" onClick={ handleAdd }>
          <Add className={ classes.addIcon } />
        </IconButton>
      </div>
      {
        fields.map((item, index) => (
          <div
            key={ item.id }
          >
            <Fields>
              <GroupField
                sizes={ [3, 7, 2] }
                className={ classes.groupField }
              >
                <div className={ classes.label }>Value { index + 1 }</div>
                <Controller
                  name={ useLang ? `attributes[${index}].translatedFields.${lang}.value` : `attributes[${index}].value` }
                  key={ useLang ? `attributes[${index}].translatedFields.${lang}.value` : `attributes[${index}].value` }
                  control={ control }
                  defaultValue={ useLang ? (currentTranslation?.value || '') : (item?.value || '') }
                  render={ ({ field }) => (
                    <TextField
                      { ...field }
                      id={ useLang ? `attributes[${index}].translatedFields.${lang}.value` : `attributes[${index}].value` }
                      helperText={ fieldsErrors.attributes?.[index]?.value?.message ?? null }
                      variant="outlined"
                      label="Value"
                      required
                      error={ useLang
                        ? false
                        : !!fieldsErrors.attributes?.[index] }
                    />
                  ) }
                  rules={ {
                    required: 'Required',
                  } }
                />
                <IconButton aria-label="delete" disabled={ !!item.predefined } onClick={ handleDelete(index) }>
                  <DeleteIcon />
                </IconButton>
              </GroupField>
            </Fields>
          </div>
        ))
      }
    </>
  );
};

export default AttributesTable;
