import Konva from 'konva';
import { ReactNode, useState, useMemo, useRef } from 'react';
import { If, Then } from 'react-if';
import {Stage, Layer, Group, Text, Image, Line} from 'react-konva';
import { Palette, Point2, isNone } from '../../../utils';
import {
  SolverStep,
  SolverStepInput,
  SolverStepResult,
  ImageData,
  Point2D,
} from '../../../types';

import DraggablePoint from '../../../components/DraggablePoint';
import Stack from '@shared/components/stack';
import Button from '@shared/components/button';
import VPSolver from "@shared/components/camera-solver/solvers/vp-solver/VPSolver";

const POINT_SIZE = 8;
const LABEL_COLOR = '#FFFFFF';
const LABEL_BOX_SIZE = 16;
const LABEL_BOX_OFFSET = [0, 1];
const LABEL_FONT_SIZE = 10;
const PADDING = 20;

interface Input extends SolverStepInput {
    image: ImageData | undefined
}

interface Result extends SolverStepResult {
    floorPoints: Point2D[]
    points: Point2D[]
}

interface ComponentProps {
    input: Input
    solver: VPSolver
    onComplete?: (result: Result) => void
    onCancel?: () => void
}

function Component({ input: { image }, solver, onComplete, onCancel }: ComponentProps) {
  const imageRef = useRef<any>(null);
  const stageRef = useRef<any>(null);

  const [[width, height, naturalWidth, naturalHeight], setSize] = useState([0, 0, 0, 0]);
  const [points, setPoints] = useState<Point2D[]>(solver.points ?? []);

  const floorPoints = useMemo<Point2D[]>(() => points.map((point) =>
    Point2.rel({
      x: point.x - PADDING,
      y: point.y - PADDING,
    }, { width: naturalWidth - PADDING * 2, height: naturalHeight - PADDING * 2 })), [points, naturalWidth, naturalHeight]);

  const isLoaded = useMemo(() => (width > 0 && height > 0), [width, height]);
  const isReady = useMemo(() => (floorPoints.length > 2), [floorPoints]);

  const handleImageLoad = () => {
    const el: HTMLImageElement = imageRef.current!;

    if (el) {
      setSize([el.clientWidth, el.clientHeight, el.naturalWidth, el.naturalHeight]);
    }
  };

  const handleStageClick = (event: any) => {
    if (!stageRef.current || (event.evt.button === 2 || event.evt.which == 3)) {
      return;
    }
    const { x, y } = stageRef.current.getRelativePointerPosition()!;

    if (isNone(x, y)) {
      return;
    }
    setPoints([ ...points, Point2.p(x + PADDING, y + PADDING) ]);
  };

  const handlePointClick = (event: any, id: number) => {
    event.cancelBubble = true;
    if ((event.evt.button === 2 || event.evt.which == 3)) {
      event.evt.preventDefault();
      event.evt.stopPropagation();
      setPoints((points) => {
        return [...points.slice(0, id), ...points.slice(id + 1)];
      })
    }
  };

  const handlePointChanged = (id: number, point: Point2D) => {
    setPoints((points) => {
      points[id] = point;
      return [...points];
    });
  };

  return (
    <Stack direction="vertical" gap={ 1 }>
      <div
        style={ {
          position: 'relative',
          overflow: 'hidden',
          lineHeight: 0,
        } }
      >
        <img
          ref={ imageRef }
          style={ {
            maxWidth: '100%',
            maxHeight: '100%',
            visibility: 'hidden',
          } }
          src={ image!.url! }
          onLoad={ handleImageLoad }
        />
        <If condition={ isLoaded }>
          <Then>
            <Stage
              style={ {
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
              } }
              width={ width }
              height={ height }
              scaleX={ width / naturalWidth }
              scaleY={ height / naturalHeight }
              onClick={ handleStageClick }
              onContextMenu={ (e) => e.evt.preventDefault() }
            >
              <Layer>
                {
                  imageRef.current && <Image
                    ref={ stageRef }
                    x={PADDING}
                    y={PADDING}
                    width={imageRef.current.naturalWidth - PADDING * 2}
                    height={imageRef.current.naturalHeight - PADDING * 2}
                    image={imageRef.current}
                  />
                }
                {points.map((point, id) => (
                  <Group key={ id } id={ `point-${id}` }>
                    <DraggablePoint
                      id={ id }
                      position={ point }
                      color={ Palette.floorColor() }
                      size={ POINT_SIZE }
                      onChanged={ (point) => handlePointChanged(id, point) }
                      onClick={ handlePointClick }
                      scale={(1 / (width / naturalWidth))}
                    />
                  </Group>
                ))}
                <Line
                  listening={ false }
                  points={ Point2.flatten(...points) }
                  stroke={ Palette.floorColor() }
                  strokeWidth={ 2 / (width / naturalWidth) }
                  closed={ false }
                />
              </Layer>
            </Stage>
          </Then>
        </If>
      </div>
      <Stack
        direction="row"
        gap={ 2 }
      >
        <Button onClick={ () => onCancel && onCancel() }>Back</Button>
        <Button onClick={ () => onComplete && onComplete({ floorPoints, points }) } variant="contained" disabled={ !isReady }>Continue</Button>
      </Stack>
    </Stack>
  );
}

class FloorStep extends SolverStep<Input, Result> {
  get label() {
    return 'Specify Floor Corner Points';
  }

  component(): ReactNode {
    return (
      <Component
        input={ this.input! }
        solver={ this.solver as VPSolver }
        onComplete={ (result) => this.complete(result) }
        onCancel={ () => this.cancel() }
      />
    );
  }
}

export default FloorStep;
