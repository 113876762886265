import React, { useEffect, useState } from 'react';
import { Accordion, AccordionSummary, AccordionDetails, Dialog,
  DialogActions, DialogContent, DialogTitle, FormControl, makeStyles, TextField } from '@material-ui/core';
import { Controller, useForm } from 'react-hook-form';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import Button from '@shared/components/button';

import useActions from '@shared/hooks/useActions';
import { actions } from '@store/companies';
import { Field, Fields } from '@shared/views/form-dialog';
import ToneMappingTextField from '@pages/studio/ToneMappingTextField';
import RangeSlider from '@pages/scenes/child-details/LightDetails/RangeSlider';
import Checkbox from '@shared/components/checkbox';
import { isCompanyAdmin } from '@shared/models';
import { useSelector } from 'react-redux';

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: '2rem',
    userSelect: 'none',
  },
  content: {
    width: '600px',
    userSelect: 'none',
  },
  actions: {
    padding: theme.spacing(2),
    justifyContent: 'center',
  },
  settingsContetn: {
    display: 'flex',
    flexDirection: 'column',
    padding: '14px',
    gap: '16px',
    width: '100%',
  },
  settingsOption: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
}));

export const ActionType = {
  CREATE: 'Create',
  UPDATE: 'Update',
};

const CompanyModal = ({ open, onClose, type, company }) => {
  const { createCompany, updateCompany } = useActions(actions);
  const { control, formState: { errors: fieldsErrors }, handleSubmit, reset } = useForm();
  const curUser = useSelector((state) => state.app.user);

  const classes = useStyles();

  useEffect(() => {
    reset({
      name: company?.name || '',
      toneMapping: company?.toneMapping || 'CustomToneMapping',
      toneMappingExposure: company?.toneMappingExposure || 1,
      downloadWatermark: company?.downloadWatermark ?? false,
      sceneWatermark: company?.sceneWatermark ?? true,
      backgroundsAccess: company?.backgroundsAccess ?? false,
      studioAccess: company?.studioAccess ?? false,
      scenesAccess: company?.scenesAccess ?? true,
      publicApiAccess: company?.publicApiAccess ?? false,
      shoppingCartIntegration: company?.shoppingCartIntegration ?? false,
      desktopVisionAccess: company?.desktopVisionAccess ?? true,
      mobileVisionAccess: company?.mobileVisionAccess ?? true,
      variationsAccess: company?.variationsAccess ?? false,
    });
  }, [company, reset]);

  const vvv = isCompanyAdmin(curUser);

  const submit = (data) => {
    onClose();
    setExpandedAll(false);
    if (type === ActionType.CREATE) createCompany(data);
    if (type === ActionType.UPDATE) updateCompany({ id: company.id, company: data });
  };

  const onSubmit = handleSubmit(submit);

  const [expandedAll, setExpandedAll] = useState(false);

  const handleAccordionChange1 = () => {
    setExpandedAll(!expandedAll);
  };
  const handleDownloadCheck = () => {
    setCheckboxValueDownload((prevValue) => !prevValue);
  };

  return (
    <Dialog open={ open } onClose={ onClose }>
      <DialogTitle className={ classes.title } disableTypography>{type} Company</DialogTitle>
      <DialogContent className={ classes.content }>
        <form noValidate onSubmit={ onSubmit }>
          <Fields>
            <Field>
              <FormControl fullWidth variant="outlined">
                <Controller
                  name="name"
                  control={ control }
                  defaultValue={ company?.name || '' }
                  rules={ { required: 'Required' } }
                  render={ ({ field }) => (
                    <TextField
                      { ...field }
                      id="name"
                      helperText={ fieldsErrors.name?.message ?? null }
                      variant="outlined"
                      label="Name"
                      required
                      InputProps={ {
                        readOnly: vvv,
                      } }
                      error={ !!fieldsErrors.name }
                    />
                  ) }
                />
              </FormControl>
            </Field>
            <Accordion
              expanded={ expandedAll }
              onChange={ handleAccordionChange1 }
              style={ {
                left: '10px',
                marginRight: '18px',
                marginBottom: '10px',
                width: '100%',
              } }
            >
                <AccordionSummary
                  expandIcon={ <ExpandMoreIcon /> }
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <span>Company Settings</span>
                </AccordionSummary>
                  <AccordionDetails>
                  <div className={ classes.settingsContetn }>
                <Field>
                  <FormControl fullWidth variant="outlined">
                  {!vvv ? (
                    <Controller
                      name="toneMapping"
                      control={ control }
                      defaultValue="CustomToneMapping"
                      rules={ {
                        required: 'Required',
                      } }
                      render={ ({ field }) => (
                        <ToneMappingTextField
                          { ...field }
                          label="Tone Mapping"
                          required
                        />
                      ) }
                    />
                  ) : (
                    <Controller
                      name="toneMapping"
                      control={ control }
                      defaultValue="CustomToneMapping"
                      rules={ {
                        required: 'Required',
                      } }
                      render={ ({ field }) => (
                          <TextField
                            { ...field }
                            id="toneMapping"
                            variant="outlined"
                            label="toneMapping"
                            required
                            InputProps={ {
                              readOnly: true,
                            } }
                            error={ !!fieldsErrors.toneMapping }
                          />
                      ) }
                    />
                  )}
                  </FormControl>
                </Field>
                <Field>
                  <FormControl fullWidth variant="outlined">
                    <Controller
                      name="toneMappingExposure"
                      defaultValue="1"
                      render={ ({ field }) => (
                        <RangeSlider
                          label="Tone Mapping Exposure"
                          isDisabled={ vvv }
                          min={ 0 }
                          max={ 10 }
                          displayValues={ false }
                          displayThumbValue
                          debounce={ 100 }
                          { ...field }
                          onChange={ ({ value }) => {
                            field.onChange(value);
                          } }
                        />
                      ) }
                      control={ control }
                    />
                  </FormControl>
                </Field>
                <Field>
                  <FormControl fullWidth variant="outlined">
                    <Controller
                      name="downloadWatermark"
                      control={ control }
                      defaultValue={ false }
                      render={ ({ field: { onChange, value, ...field } }) => (
                        <div className={ classes.settingsOption }>
                          <span>Download result watermark enabled</span>
                          <Checkbox
                            { ...field }
                            checked={ value }
                            disabled={ vvv }
                            onChange={ (e) => onChange(e.target.checked) }
                          />
                        </div>
                      ) }
                    />
                  </FormControl>
                </Field>
                <Field>
                  <FormControl fullWidth variant="outlined">
                    <Controller
                      name="sceneWatermark"
                      control={ control }
                      defaultValue={ false }
                      render={ ({ field: { onChange, value, ...field } }) => (
                        <div className={ classes.settingsOption }>
                          <span>Components watermark enabled</span>
                          <Checkbox
                            { ...field }
                            disabled={ vvv }
                            checked={ value }
                            onChange={ (e) => onChange(e.target.checked) }
                          />
                        </div>
                      ) }
                    />
                  </FormControl>
                </Field>
                <Field>
                  <FormControl fullWidth variant="outlined">
                    <Controller
                      name="backgroundsAccess"
                      control={ control }
                      defaultValue={ false }
                      render={ ({ field: { onChange, value, ...field } }) => (
                        <div className={ classes.settingsOption }>
                          <span>Background editor accessible</span>
                          <Checkbox
                            { ...field }
                            disabled={ vvv }
                            checked={ value }
                            onChange={ (e) => onChange(e.target.checked) }
                          />
                        </div>
                      ) }
                    />
                  </FormControl>
                </Field>
                <Field>
                  <FormControl fullWidth variant="outlined">
                    <Controller
                      name="studioAccess"
                      control={ control }
                      defaultValue={ false }
                      render={ ({ field: { onChange, value, ...field } }) => (
                        <div className={ classes.settingsOption }>
                          <span>Render studio accessible</span>
                          <Checkbox
                            { ...field }
                            disabled={ vvv }
                            checked={ value }
                            onChange={ (e) => onChange(e.target.checked) }
                          />
                        </div>
                      ) }
                    />
                  </FormControl>
                </Field>
                <Field>
                  <FormControl fullWidth variant="outlined">
                    <Controller
                      name="scenesAccess"
                      control={ control }
                      defaultValue={ false }
                      render={ ({ field: { onChange, value, ...field } }) => (
                        <div className={ classes.settingsOption }>
                          <span>Scenes editor accessible</span>
                          <Checkbox
                            { ...field }
                            disabled={ vvv }
                            checked={ value }
                            onChange={ (e) => onChange(e.target.checked) }
                          />
                        </div>
                      ) }
                    />
                  </FormControl>
                </Field>
                <Field>
                  <FormControl fullWidth variant="outlined">
                    <Controller
                      name="publicApiAccess"
                      control={ control }
                      defaultValue={ false }
                      render={ ({ field: { onChange, value, ...field } }) => (
                        <div className={ classes.settingsOption }>
                          <span>Public API accessible</span>
                          <Checkbox
                            { ...field }
                            disabled={ vvv }
                            checked={ value }
                            onChange={ (e) => onChange(e.target.checked) }
                          />
                        </div>
                      ) }
                    />
                  </FormControl>
                </Field>
                <Field>
                  <FormControl fullWidth variant="outlined">
                    <Controller
                      name="shoppingCartIntegration"
                      control={ control }
                      defaultValue={ false }
                      render={ ({ field: { onChange, value, ...field } }) => (
                        <div className={ classes.settingsOption }>
                          <span>Shopping cart integration enabled</span>
                          <Checkbox
                            { ...field }
                            disabled={ vvv }
                            checked={ value }
                            onChange={ (e) => onChange(e.target.checked) }
                          />
                        </div>
                      ) }
                    />
                  </FormControl>
                </Field>
                <Field>
                  <FormControl fullWidth variant="outlined">
                    <Controller
                      name="desktopVisionAccess"
                      control={ control }
                      defaultValue={ false }
                      render={ ({ field: { onChange, value, ...field } }) => (
                        <div className={ classes.settingsOption }>
                          <span>Desktop Vision accessible</span>
                          <Checkbox
                            { ...field }
                            disabled={ vvv }
                            checked={ value }
                            onChange={ (e) => onChange(e.target.checked) }
                          />
                        </div>
                      ) }
                    />
                  </FormControl>
                </Field>
                <Field>
                  <FormControl fullWidth variant="outlined">
                    <Controller
                      name="mobileVisionAccess"
                      control={ control }
                      defaultValue={ false }
                      render={ ({ field: { onChange, value, ...field } }) => (
                        <div className={ classes.settingsOption }>
                          <span>Mobile Vision accessible</span>
                          <Checkbox
                            { ...field }
                            disabled={ vvv }
                            checked={ value }
                            onChange={ (e) => onChange(e.target.checked) }
                          />
                        </div>
                      ) }
                    />
                  </FormControl>
                </Field>
                <Field>
                  <FormControl fullWidth variant="outlined">
                    <Controller
                      name="variationsAccess"
                      control={ control }
                      defaultValue={ false }
                      render={ ({ field: { onChange, value, ...field } }) => (
                        <div className={ classes.settingsOption }>
                          <span>Configurator accessible</span>
                          <Checkbox
                            { ...field }
                            disabled={ vvv }
                            checked={ value }
                            onChange={ (e) => onChange(e.target.checked) }
                          />
                        </div>
                      ) }
                    />
                  </FormControl>
                </Field>
              </div>
            </AccordionDetails>
          </Accordion>
          </Fields>
        </form>
      </DialogContent>
      <DialogActions className={ classes.actions }>
        <Button color="secondary" variant="outlined" onClick={ onClose }>Cancel</Button>
        {!vvv && <Button onClick={ onSubmit }>Save</Button> }
      </DialogActions>
    </Dialog>
  );
};

export default CompanyModal;
