/* eslint-disable max-len */

const ProductsIcon = () => (
  <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_7361_35074)">
    <path d="M3.32047 3.44505V18.5534L18.5992 18.4659L18.678 3.44505H3.32047ZM3.07922 2.19922H18.9192C19.1526 2.19922 19.3764 2.30223 19.5415 2.4856C19.7065 2.66897 19.7992 2.91767 19.7992 3.177V18.8214C19.7992 19.0808 19.7065 19.3295 19.5415 19.5128C19.3764 19.6962 19.1526 19.7992 18.9192 19.7992H3.07922C2.84583 19.7992 2.622 19.6962 2.45696 19.5128C2.29193 19.3295 2.19922 19.0808 2.19922 18.8214V3.177C2.19922 2.91767 2.29193 2.66897 2.45696 2.4856C2.622 2.30223 2.84583 2.19922 3.07922 2.19922ZM5.71922 6.11033H8.35922V15.8881H5.71922V6.11033ZM9.23922 6.11033H10.9992V15.8881H9.23922V6.11033ZM11.8792 6.11033H12.7592V15.8881H11.8792V6.11033ZM13.6392 6.11033H16.2792V15.8881H13.6392V6.11033Z" fill="#BCC8D9" />
    </g>
    <defs>
      <clipPath id="clip0_7361_35074">
        <rect width="22" height="22" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default ProductsIcon;
