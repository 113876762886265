const ENDPOINT = '/companies';

class CompaniesApi {
  constructor(http) {
    this.http = http;
  }

  getCompanies = () => this.http.get(ENDPOINT)

  getCurrent = () => this.http.get(`${ENDPOINT}/current`)

  createCompany = (company) => this.http.post(ENDPOINT, company)

  updateCompany = (id, company) => this.http.put(`${ENDPOINT}/${id}`, company)

  generateCompanyApiToken = (id) => this.http.put(`${ENDPOINT}/${id}/apiToken`)

  deleteCompany = (id) => this.http.delete(`${ENDPOINT}/${id}`)

  getCompany = (id) => this.http.get(`${ENDPOINT}/${id}`)
}

export default CompaniesApi;
