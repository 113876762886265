import { useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import PageContainer from '@shared/components/page-parts/Container';
import PageHead from '@shared/components/page-parts/Head';
import PageContent from '@shared/components/page-parts/Content';
import TopNav from '@shared/components/top-nav/Nav';
import CardsList from '@shared/components/cards-list/CardsList';
import CardItem from '@shared/components/cards-list/CardItem';
import CopySceneModal from '../../modal/copy/FormDialog';

import {
  deleteScene,
  updateScene,
  hideScene,
} from '@store/scenes/actions';
import { actions } from '@store/app';
import { interiorsSelector } from '@store/interiors/selectors';

import useNavigate from '@shared/router/useNavigate';
import useActions from '@shared/hooks/useActions';

import Scene from './Scene';
import Controls from './Controls';

import { ROUTES } from '@app/routes/routesUrls';
import Breadcrumbs from '@shared/components/breadcrumbs';
import AddNew from '@shared/components/cards-list/AddNew';


const getSceneSrc = (scene, interiors) => {
  if (scene.url) {
    return scene.url;
  }

  // TODO: Remove in future, made for backward compatibility
  return (
    interiors.find((interior) => scene.interior === interior.id)?.url
    || '../assets/images/no_image.png'
  );
};

const SceneListGrid = ({ data }) => {
  const { goTo } = useNavigate();
  const dispatch = useDispatch();
  const { hideMenu } = useActions(actions);

  const [copyModalData, setCopyModalData] = useState(null);

  const interiors = useSelector(interiorsSelector);

  const handleCreateScene = () => {
    hideMenu();
    goTo(`${ROUTES.SCENES.ROOT}${ROUTES.SCENES.NEW}`);
  };

  const handleSceneClick = (item) => {
    hideMenu();
    goTo(`${ROUTES.SCENES.ROOT}${ROUTES.SCENES.EDIT(item.id)}`);
  };

  const handleSceneEdit = (item) => () => {
    hideMenu();
    goTo(`${ROUTES.SCENES.ROOT}${ROUTES.SCENES.EDIT(item.id)}`);
  };

  const handleHideScene = useCallback((item) => () => {
    dispatch(hideScene(item.id));
  }, [dispatch]);

  const handleCopyScene = useCallback((item) => () => {
    setCopyModalData({ ...item });
  }, []);

  const handleCopyModalClose = useCallback(() => {
    setCopyModalData(null);
  }, []);

  const handleSceneDelete = (item) => () => {
    dispatch(deleteScene(item.id));
  };

  const handleShareScene = useCallback((item) => () => {
    dispatch(updateScene({
      ...item,
      defaultContent: !item.defaultContent,
      attributes: item.attributes?.map((attr) => attr.id),
    }));
  }, [dispatch]);

  const breadcrumbsItems = useMemo(() => [
    { name: 'Interiors', link: ROUTES.SCENES },
  ], []);

  return (
    <PageContainer>
      <PageHead>
        <TopNav>
          <Breadcrumbs items={ breadcrumbsItems } />
        </TopNav>
      </PageHead>
      <PageContent>
        {data.length > 0 ? (
          <CardsList>
            <CardItem>
              <AddNew onClick={ handleCreateScene } />
            </CardItem>
            {data.map((item) => (
              <CardItem key={ item.id }>
                <Scene
                  item={ item }
                  src={ getSceneSrc(item, interiors) }
                  controls={ (
                    <Controls
                      onEdit={ handleSceneEdit(item) }
                      onDelete={ handleSceneDelete(item) }
                      onHide={ handleHideScene(item) }
                      onCopy={ handleCopyScene(item) }
                      onShare={ handleShareScene(item) }
                      item={ item }
                    />
                  ) }
                  onClick={ handleSceneClick }
                />
              </CardItem>
            ))}
          </CardsList>
        ) : (
          <CardsList>
            <CardItem>
              <AddNew onClick={ handleCreateScene } />
            </CardItem>
          </CardsList>
        )}
      </PageContent>
      <CopySceneModal
        open={ !!copyModalData }
        data={ copyModalData }
        onClose={ handleCopyModalClose }
      />
    </PageContainer>
  );
};

export default SceneListGrid;
