import { useController } from 'react-hook-form';
import ImageFullSize from '@shared/components/image-full-size';
import {
  Preview as FormDialogPreview,
  ModelViewer,
} from '@shared/views/form-dialog';
import { makeStyles } from '@material-ui/core/styles';
import DownloadIcon from '@shared/icons/DownloadIcon';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { DefaultLightPresetsData } from '@pages/products/constants';

const useStyles = makeStyles((theme) => ({
  downloadButton: {
    position: 'absolute',
    top: theme.spacing(1),
    left: theme.spacing(1),
    padding: theme.spacing(0.5),
    display: 'flex',
    backgroundColor: '#E2EAF3',
    border: '1px solid #767676',
    borderRadius: '2px',
    width: '36px',
    height: '36px',
    alignItems: 'center',
    justifyContent: 'center',
    lineHeight: '36px',
    cursor: 'pointer',
  },
}));

const Preview = ({
  name,
  supportedFormats,
  control,
  onLoadSize,
  renewable,
  scale,
  fileName,
}) => {
  const classes = useStyles();
  const allEnvironments = useSelector((state) => state.scenes.environments.items);
  const environment = allEnvironments?.find((item) => item.uid === DefaultLightPresetsData[1].uid);

  const {
    field: { onChange, value, ...inputProps },
  } = useController({
    name,
    control,
    rules: { validate: (value) => !!value.blob || 'Required' },
  });

  const handleDownload = () => {
    axios.get(value.blob, {
      headers: {
        'Content-Type': 'application/octet-stream',
      },
      responseType: 'blob',
    })
      .then((response) => {
        const a = document.createElement('a');
        const url = window.URL.createObjectURL(response.data);
        a.href = url;
        a.download = `${fileName?.toLowerCase() || 'model'}.${value.dataType?.toLowerCase() || 'gltf'}`;
        a.click();
      })
      .catch((err) => {
        console.log('error', err);
      });
  };

  return (
    <FormDialogPreview
      value={ value }
      supportedFormats={ supportedFormats }
      disabled={ !!value.blob }
      renderFileViewer={ ({ value }) => (
        <ImageFullSize>
          <ModelViewer
            value={ value }
            onChange={ onChange }
            onLoadSize={ onLoadSize }
            scale={ scale }
            environment={ environment }
          />
          <button
            className={ classes.downloadButton }
            type="button"
            onClick={ handleDownload }
          >
            <DownloadIcon />
          </button>
        </ImageFullSize>
      ) }
      onChange={ onChange }
      renewable={ renewable }
      { ...inputProps }
    />
  );
};

export default Preview;
