import { useState, useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import Dialog from '../ListDialog/Dialog';
import DialogSearchInput from '../ListDialog/DialogSearchInput';
import DialogContent from '../ListDialog/DialogContent';
import DialogActions from '../ListDialog/DialogActions';
import CloseButton from '../ListDialog/CloseButton';
import SubmitButton from '../ListDialog/SubmitButton';
import InteriorsList from './InteriorsList';
import useSelectItem from '../ListDialog/useSelectItem';
import TopNav from '@shared/components/top-nav/Nav';
import filterData from '@app/filter/filterData';

const selectInteriors = (state) => state.interiors.interiors;

const filterBySearch = (
  model,
  search,
) => search === '' || model.name.toLowerCase().includes(search.toLowerCase());

const filterInteriors = (interiors, {
  search,
}) => {
  const filteredInteriors = interiors.filter((
    interior,
  ) => filterBySearch(interior, search))
    .filter(({ hidden, layout }) => !hidden && layout && layout.floorPoints?.length);

  return filterData(filteredInteriors, '');
};

const InteriorsListDialog = ({
  open,
  onSubmit,
  onClose,
}) => {
  const interiors = useSelector(selectInteriors);

  const [interior, setInterior] = useState(null);

  const [search, setSearch] = useState('');

  const handleCloseClick = () => {
    onClose();
  };

  const handleChooseClick = () => {
    onSubmit(interior);
  };

  const filteredInteriors = useMemo(() => filterInteriors(interiors.items, {
    search,
  }), [interiors.items, search]);

  const handleSearchChange = useCallback(({ target }) => {
    setSearch(target.value);
  }, []);

  const [contentRef, handleSelectInterior] = useSelectItem(setInterior);

  return (
    <Dialog
      onClose={ handleCloseClick }
      open={ open }
    >
      <TopNav>
        <DialogSearchInput
          value={ search }
          onChange={ handleSearchChange }
        />
      </TopNav>
      <DialogContent ref={ contentRef }>
        <InteriorsList
          interiors={ filteredInteriors }
          selectedInterior={ interior }
          onItemClick={ handleSelectInterior }
        />
      </DialogContent>
      <DialogActions>
        <CloseButton onClick={ handleCloseClick }>
          Cancel
        </CloseButton>
        <SubmitButton disabled={ !interior } onClick={ handleChooseClick }>
          Choose
        </SubmitButton>
      </DialogActions>
    </Dialog>
  );
};

export default InteriorsListDialog;
