const ENDPOINT = '/styling';

class StylingApi {
  constructor(http) {
    this.http = http;
  }

  getStyling = () => this.http.get(ENDPOINT);

  createStyling = (data) => this.http.post(ENDPOINT, data);

  updateStyling = (data) => this.http.put(ENDPOINT, data);
}

export default StylingApi;
