/* eslint-disable max-len */
import { makeStyles } from '@material-ui/core';
import SvgIcon from '@material-ui/core/SvgIcon';
import { DefaultIconSizeValue, IconSize } from '@resources/icons/constants';

const useStyles = makeStyles(() => ({
  root: {
    fill: 'none',
  },
}));

const ElectricLightRight = ({ size = IconSize.Medium, selected = false }) => {
  const classes = useStyles();
  return (
    <SvgIcon
      style={ { width: DefaultIconSizeValue[size], height: DefaultIconSizeValue[size] } }
      className={ classes.root }
      viewBox="0 0 32 32"
    >
      <g clipPath="url(#clip0_1086_9183)">
        <path
          d="M32 0C29.8988 0 27.8183 0.413853 25.8771 1.21793C23.9359 2.022 22.172 3.20055 20.6863 4.68629C19.2006 6.17203 18.022 7.93586 17.2179 9.87707C16.4139 11.8183 16 13.8989 16 16C16 18.1012 16.4139 20.1817 17.2179 22.1229C18.022 24.0641 19.2006 25.828 20.6863 27.3137C22.172 28.7994 23.9359 29.978 25.8771 30.7821C27.8183 31.5861 29.8989 32 32 32L32 16L32 0Z"
          fill={ selected ? '#ffffff' : '#EBEFF2' }
        />
        <path
          d="M31 31.5C31.2761 31.5 31.5 31.2761 31.5 31L31.5 1C31.5 0.723858 31.2761 0.5 31 0.5L1 0.5C0.723856 0.5 0.5 0.723858 0.5 1L0.5 31C0.5 31.2761 0.723856 31.5 1 31.5L31 31.5Z"
          stroke="#334D6E"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <mask id="path-4-inside-1_1086_9183" fill="white">
          <path d="M32 32V0H0L0 32L32 32Z" />
        </mask>
        <path d="M30 32L30 0H34V32H30Z" fill="#334D6E" mask="url(#path-4-inside-1_1086_9183)" />
        <mask id="path-6-inside-2_1086_9183" fill="white">
          <path d="M31.0176 10.0352C30.232 10.0352 29.454 10.1899 28.7282 10.4906C28.0024 10.7912 27.3429 11.2319 26.7874 11.7874C26.2319 12.3429 25.7912 13.0024 25.4906 13.7282C25.1899 14.454 25.0352 15.232 25.0352 16.0176C25.0352 16.8032 25.1899 17.5811 25.4906 18.307C25.7912 19.0328 26.2319 19.6923 26.7874 20.2478C27.3429 20.8033 28.0024 21.244 28.7282 21.5446C29.454 21.8453 30.232 22 31.0176 22L31.0176 16.0176V10.0352Z" />
        </mask>
        <path
          d="M31.0176 10.0352C30.232 10.0352 29.454 10.1899 28.7282 10.4906C28.0024 10.7912 27.3429 11.2319 26.7874 11.7874C26.2319 12.3429 25.7912 13.0024 25.4906 13.7282C25.1899 14.454 25.0352 15.232 25.0352 16.0176C25.0352 16.8032 25.1899 17.5811 25.4906 18.307C25.7912 19.0328 26.2319 19.6923 26.7874 20.2478C27.3429 20.8033 28.0024 21.244 28.7282 21.5446C29.454 21.8453 30.232 22 31.0176 22L31.0176 16.0176V10.0352Z"
          stroke="#334D6E"
          strokeWidth="2"
          mask="url(#path-6-inside-2_1086_9183)"
        />
        <path
          d="M31.0176 12.0283C30.4938 12.0283 29.9752 12.1315 29.4913 12.3319C29.0075 12.5324 28.5678 12.8261 28.1975 13.1965C27.8271 13.5668 27.5333 14.0065 27.3329 14.4904C27.1325 14.9742 27.0293 15.4929 27.0293 16.0166C27.0293 16.5404 27.1325 17.059 27.3329 17.5429C27.5333 18.0267 27.8271 18.4664 28.1975 18.8367C28.5678 19.2071 29.0075 19.5009 29.4913 19.7013C29.9752 19.9017 30.4938 20.0049 31.0176 20.0049L31.0176 16.0166V12.0283Z"
          fill="#334D6E"
        />
        <path d="M23.5391 16.0176H25.5332" stroke="#334D6E" strokeLinecap="round" />
        <path d="M25.8018 21.4805L27.2118 20.0704" stroke="#334D6E" strokeLinecap="round" />
        <path d="M25.8018 10.5537L27.2118 11.9638" stroke="#334D6E" strokeLinecap="round" />
      </g>
      <defs>
        <clipPath id="clip0_1086_9183">
          <rect width="32" height="32" rx="1" fill="white" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
};

export default ElectricLightRight;
