import classNames from 'classnames';
import { makeStyles } from '@material-ui/core';

const Direction = {
  Vertical: 'vertical',
  Horizontal: 'horizontal',
};

const getGapSize = (gap) => 8 * gap;

const availableGaps = [
  0, 0.5, 1, 1.5, 2, 2.5, 3, 3.5, 4, 4.5, 5, 5.5, 6, 6.5, 7, 7.5, 8, 8.5,
];

const getGapStyleName = (gap) => `gap-${gap}`;

const createGapStyles = () => availableGaps.reduce((acc, gap) => {
  acc[getGapStyleName(gap)] = {
    gap: `${getGapSize(gap)}px`,
  };
  return acc;
}, {});

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    alignSelf: 'stretch',
  },
  vertical: {
    flex: '1 1 auto',
    flexDirection: 'column',
  },
  horizontal: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  ...createGapStyles(),
}));

const Stack = ({
  children,
  className = null,
  direction = Direction.Vertical,
  gap = 0,
  as: Component = 'div',
}) => {
  const classes = useStyles();

  return (
    <Component
      className={ classNames(
        className,
        classes.root,
        classes.gap,
        classes[getGapStyleName(gap)],
        {
          [classes.vertical]: direction === Direction.Vertical,
          [classes.horizontal]: direction === Direction.Horizontal,
        },
      ) }
    >
      {children}
    </Component>
  );
};

export default Stack;
