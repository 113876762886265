import React, { useEffect, useMemo, useState } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, FormControl, makeStyles, TextField, InputAdornment } from '@material-ui/core';
import { Controller, useForm } from 'react-hook-form';


import Button from '@shared/components/button';

import useActions from '@shared/hooks/useActions';
import { actions } from '@store/material-groups';
import { Field, Fields } from '@shared/views/form-dialog';
import { AVAILABLE_LANGUAGES, translationsToFields } from '@shared/utils/translations';
import TranslationsDropdown from '@shared/components/translations/TranslationsDropdown';

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: '2rem',
  },
  content: {
    width: '400px',
  },
  actions: {
    padding: theme.spacing(2),
    justifyContent: 'center',
  },
}));

export const ActionType = {
  CREATE: 'Create',
  UPDATE: 'Update',
};

const GroupModal = ({ open, onClose, type, group }) => {
  const { createGroup, updateGroup } = useActions(actions);
  const { control, formState: { errors: fieldsErrors }, handleSubmit, reset } = useForm();
  const [lang, setLang] = useState(AVAILABLE_LANGUAGES.find((lng) => lng.key === 'en'));
  const useLang = useMemo(() => lang && lang.key !== 'en', [lang]);
  const classes = useStyles();

  const currentTranslation = useMemo(() => {
    return translationsToFields(group?.translations)[lang.key];
  }, [group, lang]);

  useEffect(() => {
    reset({
      name: group?.name || '',
      translatedFields: translationsToFields(group?.translations),
    });
  }, [open, group, reset]);

  const submit = (data) => {
    onClose({
      ...data,
      id: group?.id,
    });
    if (type === ActionType.CREATE) createGroup(data);
    if (type === ActionType.UPDATE) updateGroup({ id: group.id, group: data });
  };

  const onSubmit = handleSubmit(submit);

  return (
    <Dialog open={ open } onClose={ onClose }>
      <DialogTitle className={ classes.title } disableTypography>{type} Part Type</DialogTitle>
      <DialogContent className={ classes.content }>
        <form noValidate onSubmit={ onSubmit }>
          <Fields>
            <Field>
              <FormControl fullWidth variant="outlined">
                <Controller
                  name={ useLang ? `translatedFields.${lang.key}.value` : 'name' }
                  key={ useLang ? `translatedFields.${lang.key}.value` : 'name' }
                  control={ control }
                  defaultValue={ useLang ? (currentTranslation?.value || '') : (group?.name || '') }
                  rules={ { required: 'Required' } }
                  render={ ({ field }) => (
                    <TextField
                      { ...field }
                      id={ useLang ? `translatedFields.${lang.key}.value` : 'name' }
                      helperText={ fieldsErrors.name?.message ?? null }
                      variant="outlined"
                      label="Name"
                      required
                      error={ !!fieldsErrors.name }
                      InputProps={ {
                        endAdornment: (
                          <InputAdornment position="end">
                            <TranslationsDropdown
                              onLangChange={ setLang }
                              selectedLanguage={ lang }
                              variant="key"
                            />
                          </InputAdornment>
                        ),
                      } }
                    />
                  ) }
                />
              </FormControl>
            </Field>
          </Fields>
        </form>
      </DialogContent>
      <DialogActions className={ classes.actions }>
        <Button color="secondary" variant="outlined" onClick={ onClose }>Cancel</Button>
        <Button onClick={ onSubmit }>Save</Button>
      </DialogActions>
    </Dialog>
  );
};

export default GroupModal;
