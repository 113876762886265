import { forwardRef, memo, useCallback, useMemo } from 'react';

import Autocomplete from '@shared/components/autocomplete/Autocomplete';
import { TextField } from '@shared/views/form-dialog';

const PresetTextField = forwardRef(({
  name,
  label,
  onChange,
  value,
}, ref) => {
  const options = ['rembrandt', 'portrait', 'upfront', 'soft'];
  const defaultValue = useMemo(() => ('rembrandt'), []);

  const handleChange = useCallback((_, data) => {
    if (data) {
      onChange(data);
    } else {
      const option = options.find((op) => op === data);
      if (option) {
        onChange(option);
        return;
      }
      if (value === data) {
        return;
      }
      onChange(defaultValue);
    }
  }, [onChange, options, name, value, defaultValue]);

  return (
    <Autocomplete
      options={ options }
      onChange={ handleChange }
      onBlur={ (e) => handleChange(e, e.target.value) }
      ref={ ref }
      value={ value || defaultValue }
      renderInput={ (params) => (
        <TextField
          { ...params }
          label={ label }
          variant="outlined"
          InputProps={ {
            ...params.InputProps,
          } }
        />
      ) }
    />
  );
});

export default memo(PresetTextField);
