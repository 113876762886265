import { useMemo } from 'react';
import { useDispatch } from 'react-redux';

import PageContainer from '@shared/components/page-parts/Container';
import PageHead from '@shared/components/page-parts/Head';
import PageContent from '@shared/components/page-parts/Content';
import TopNav from '@shared/components/top-nav/Nav';
import CardsList from '@shared/components/cards-list/CardsList';
import CardItem from '@shared/components/cards-list/CardItem';
import useNavigate from '@shared/router/useNavigate';

import Model from './Model';
import Controls from './Controls';

import {
  renderStudio,
  deleteStudio,
} from '@store/studios/actions';

import { ROUTES } from '@app/routes/routesUrls';
import Breadcrumbs from '@shared/components/breadcrumbs';
import AddNew from '@shared/components/cards-list/AddNew';

const StudioListGrid = ({ data }) => {
  const dispatch = useDispatch();

  const { goTo } = useNavigate();

  const handleCreateModel = () => {
    dispatch(renderStudio({
      name: '',
      presets: 'rembrandt',
      toneMapping: 'NeutralToneMapping',
      toneMappingExposure: '1',
      environment: null,
      intensity: 0.5,
      shadows: 'contact',
      cameras: [],
      materials: [],
    }));
    goTo(`${ROUTES.STUDIO.ROOT}${ROUTES.STUDIO.NEW}`);
  };

  const handleEditStudioFactory = (item) => () => {
    dispatch(renderStudio(item));
    goTo(`${ROUTES.STUDIO.ROOT}/${item.id}`);
  };

  const handleCopyStudioFactory = (item) => () => {
    dispatch(renderStudio({
      ...item,
      id: undefined,
    }));
    goTo(`${ROUTES.STUDIO.ROOT}${ROUTES.STUDIO.NEW}`);
  };

  const handleDeleteStudioFactory = (item) => () => {
    const { id } = item;
    dispatch(deleteStudio(id));
  };

  const breadcrumbsItems = useMemo(() => [
    { name: 'Studios', link: ROUTES.STUDIO.ROOT },
  ], []);

  return (
    <PageContainer>
      <PageHead>
        <TopNav>
          <Breadcrumbs items={ breadcrumbsItems } />
        </TopNav>
      </PageHead>
      <PageContent>
        {data.length > 0 ? (
          <CardsList>
            <CardItem>
              <AddNew onClick={ handleCreateModel } />
            </CardItem>
            {data.map((item) => (
              <CardItem key={ item.id }>
                <Model
                  item={ item }
                  controls={ (
                    <Controls
                      onEdit={ handleEditStudioFactory(item) }
                      onCopy={ handleCopyStudioFactory(item) }
                      onDelete={ handleDeleteStudioFactory(item) }
                      item={ item }
                    />
                  ) }
                  onClick={ handleEditStudioFactory(item) }
                />
              </CardItem>
            ))}
          </CardsList>
        ) : (
          <CardsList>
            <CardItem>
              <AddNew onClick={ handleCreateModel } />
            </CardItem>
          </CardsList>
        )}
      </PageContent>
    </PageContainer>
  );
};

export default StudioListGrid;
