import { useEffect, useMemo, useState } from 'react';
import { Dialog, DialogActions, DialogTitle } from '@shared/components/dialog';
import Button from '@shared/components/button';
import TextField from '@shared/components/text-field';


const WarningUploadDialog = ({ open, title, onClose, onUpdate, onAdd, value, options }) => {
  const [isNewName, setIsNewName] = useState(false);
  const [newName, setNewName] = useState(value?.file?.name);

  useEffect(() => {
    setNewName(value?.file?.name);
  }, [value]);

  const isDisabled = useMemo(() => {
    if (value?.file?.name === newName) {
      return true;
    }
    return options.some((option) => option.name === newName);
  }, [value, options, newName]);

  return (
    <Dialog
      open={ open }
      maxWidth="xs"
      onClose={ onClose }
      aria-labelledby="default-alert-dialog-title"
    >
      <DialogTitle id="default--alert-dialog-title">{title}</DialogTitle>
      <DialogActions>
        <Button onClick={ onClose }>Cancel</Button>
        <Button onClick={ onUpdate }>Replace All</Button>
        <Button onClick={ () => setIsNewName(true) }>Create New</Button>
      </DialogActions>
      { isNewName && (
        <DialogActions>
          <TextField
            error={ isDisabled }
            value={ newName }
            onChange={ (event) => {
              setNewName(event.target.value);
            } }
          />
          <Button
            onClick={ () => {
              onAdd(newName);
              setIsNewName(false);
            } }
            disabled={ isDisabled }
          >Confirm
          </Button>
        </DialogActions>
      )}
    </Dialog>
  );
};

export default WarningUploadDialog;
