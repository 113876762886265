import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { setAttributesFilterValue, toggleAttributesFilter } from '@store/attribute-definitions/actions';
import {
  selectAttributeDefinitions,
  selectAttributesFilterValue,
  selectAttributesShowFilter,
} from '@store/attribute-definitions/selectors';
import FilterToggle from './FilterToggle';
import AttributesFilter from './AttributesFilter';

const useStyles = makeStyles(() => ({
  grid: {
    height: '100%',
    width: '100%',
  },
  gridXs0: {
    flexGrow: 0,
    maxWidth: '5%',
    flexBasis: '5%',
  },
  gridXs2: {
    flexGrow: 0,
    maxWidth: '16.66%',
    flexBasis: '16.66%',
  },
  gridXs10: {
    flexGrow: 0,
    maxWidth: '83.33%',
    flexBasis: '83.33%',
  },
  gridXs12: {
    flexGrow: 0,
    maxWidth: '95%',
    flexBasis: '95%',
  },
}));

const FilterContainer = ({ children }) => {
  const dispatch = useDispatch();

  const classes = useStyles();

  const attributesFilterValue = useSelector(selectAttributesFilterValue);
  const showAttributesFilter = useSelector(selectAttributesShowFilter);
  const attributeDefinitions = useSelector(selectAttributeDefinitions);

  useEffect(() => {
    dispatch(setAttributesFilterValue([]));
  }, [dispatch]);

  const handleToggleAttributesFilter = () => {
    dispatch(toggleAttributesFilter());
  };

  const handleChangeAttributesFilterValue = (value) => {
    dispatch(setAttributesFilterValue(value));
  };

  return attributeDefinitions?.length === 0 ? (
    <>
      { children }
    </>
  ) : (
    <Grid container spacing={ 2 } className={ classes.grid }>
      <Grid item className={ showAttributesFilter ? classes.gridXs2 : classes.gridXs0 }>
        <FilterToggle
          isOpen={ showAttributesFilter }
          onClick={ handleToggleAttributesFilter }
        />
        {showAttributesFilter
          && (
            <AttributesFilter
              value={ attributesFilterValue }
              onChange={ handleChangeAttributesFilterValue }
            />
          )}
      </Grid>
      <Grid item className={ showAttributesFilter ? classes.gridXs10 : classes.gridXs12 }>
        {children}
      </Grid>
    </Grid>
  );
};

export default FilterContainer;
