import { Stepper, Step, StepButton, CircularProgress } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { makeStyles } from '@material-ui/core/styles';
import { useState, useMemo, useEffect, useCallback } from 'react';
import { Switch, Case, Default } from 'react-if';
import { AbstractStep, Solver, SolverResult, ImageData, SolverHolder } from './types';
import { loadImage, doWith, isNone } from './utils';

import ChooseSolverStep from './steps/ChooseSolverStep';
import PreviewStep from './steps/PreviewStep';
import Stack from '../stack';
import {avaliableSolvers} from "@shared/components/camera-solver/solvers";


const useStyles = makeStyles(() => ({
  backgroundSteps: {
    '& .MuiStepLabel-iconContainer': {
      '& .MuiStepIcon-root': {
        width: '1.5em',
        height: '1.5em',
        '& .MuiStepIcon-text': {
          fontSize: '0.95em',
          fill: '#fff'
        },
      },
    },
    '& .MuiStepLabel-labelContainer .MuiStepLabel-label': {
      fontSize: '1.3rem',
    },
  },
}));

interface CameraSolverProps {
    image: string
    layout: SolverResult
    roomHeight: number
    refHeight: number
    onResult?: (result: SolverResult) => void
    onCancel?: () => void
}

function CameraSolver({ image: imageBuffer, layout, roomHeight, refHeight, onResult, onCancel }: CameraSolverProps) {
  const classes = useStyles();
  const [error, setError] = useState<Error>();
  const [solver, setSolver] = useState<Solver<any>>();
  const [solverResult, setSolverResult] = useState<SolverResult>();
  const [image, setImage] = useState<ImageData>();
  const [activeStep, setActiveStep] = useState<AbstractStep>();

  const onChooseSolver = useCallback((data: any, holder: SolverHolder<any>) => {
    const solver = holder.instance({
      image,
      layout: data,
      onBackward: () =>  setActiveStep(solver.activeStep!),
      onForward: () => setActiveStep(solver.activeStep!),
      onCancel: () => setActiveStep(firstStep),
      onComplete: (result, error) => {
        doWith(result, setSolverResult);
        doWith(error!, setError);
      },
    });

    setSolver(solver);
    return solver;
  }, [image]);

  useEffect(() => {
    if (image && layout && layout.type !== undefined) {
      const solver = onChooseSolver(layout, avaliableSolvers.find((item) => item.id === layout.type)!);
      setActiveStep(solver.steps[0]);
    }
  }, [layout, image])

  const onComplete = useCallback((result: SolverResult) => onResult?.(result!), []);
  const onPreviewCancel = () => setActiveStep(allSteps[allSteps.length - 2]);

  const firstStep = useMemo<AbstractStep>(() => new ChooseSolverStep(onChooseSolver.bind(null, null), onCancel!), [image, solver]);
  const lastStep = useMemo<AbstractStep>(() => new PreviewStep(image!, solverResult!, onComplete, onPreviewCancel), [image, solverResult]);
  const allSteps = useMemo<AbstractStep[]>(() => [firstStep, ...(solver?.steps ?? []), lastStep], [firstStep, lastStep, solver]);

  useEffect(() => setActiveStep(firstStep), [image]);
  useEffect(() => { doWith(solver?.activeStep, (step) => setActiveStep(step!)); }, [solver]);
  useEffect(() => { doWith(solverResult, () => setActiveStep(lastStep)); }, [solverResult, lastStep]);
  useEffect(() => {
    loadImage(imageBuffer)
      .then((image) => setImage(image))
      .catch((error) => setError(error));
  }, []);

  return (
    <Switch>
      <Case condition={ !isNone(error) }>
        <Alert severity="error">{error?.toString()}</Alert>
      </Case>
      <Case condition={ image != undefined }>
        <Stack direction="vertical" gap={ 4 }>
          <Stepper nonLinear activeStep={ allSteps.indexOf(activeStep!) } style={ { padding: 0 } }>
            {allSteps.map((step) => (
              <Step key={ step.id } completed={ step.isCompleted }>
                <StepButton color="inherit" className={ classes.backgroundSteps }>
                  {step.label}
                </StepButton>
              </Step>
            ))}
          </Stepper>
          {activeStep?.component(roomHeight, refHeight)}
        </Stack>
      </Case>
      <Default>
        <div style={ { display: 'flex', justifyContent: 'center', height: '100%', width: '100%' } }>
          <CircularProgress />
        </div>
      </Default>
    </Switch>
  );
}

export default CameraSolver;
