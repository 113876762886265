import { makeStyles } from '@material-ui/core';
import classNames from 'classnames';
import Stack from '@shared/components/stack';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1, 2),
  },
  disabled: {
    pointerEvents: 'none',
  },
}));

const DetailsContainer = ({
  children,
  isEditable,
}) => {
  const classes = useStyles();

  return (
    <Stack className={ classNames(classes.root, { [classes.disabled]: !isEditable }) } gap={ 2 }>
      {children}
    </Stack>
  );
};

export default DetailsContainer;
