import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

const mapChildrenThroughFragment = (children, callback) => React.Children.toArray(children).reduce((acc, child) => {
  if (child.type === React.Fragment) {
    React.Children.forEach(child.props.children, (childOfChild, i) => {
      acc.push(callback(childOfChild, childOfChild?.key || i));
    });
  } else {
    acc.push(callback(child, child.key));
  }

  return acc;
}, []);

const useStyles = makeStyles((theme) => ({
  root: {
    background: theme.palette.common.white,
    borderRadius: 20,
    width: 'fit-content',
    height: 'fit-content',
    marginLeft: theme.spacing(1),
  },
}));

const Controls = ({ children }) => {
  const classes = useStyles();
  return (
    <Grid className={ classes.root } container>
      {mapChildrenThroughFragment(children, (child, key) => (
        <Grid key={ key } item>
          {child}
        </Grid>
      ))}
    </Grid>
  );
};

export default Controls;
