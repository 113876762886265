import { all, call, put, takeEvery } from 'redux-saga/effects';

import { materialGroupsApi } from '@api';

import { actions } from '.';

const { loadGroups, createGroup, updateGroup, deleteGroup, renderGroups } = actions;

function* loadGroupsSaga() {
  try {
    const { items } = yield call(materialGroupsApi.getGroups);
    yield put(renderGroups(items));
  } catch (e) {
    console.error(e);
  }
}

function* createGroupSaga(action) {
  try {
    yield call(materialGroupsApi.createGroup, action.payload);
    yield put(loadGroups());
  } catch (e) {
    console.error(e);
  }
}

function* updateGroupSaga(action) {
  try {
    const { id, group } = action.payload;
    yield call(materialGroupsApi.updateGroup, id, group);
    yield put(loadGroups());
  } catch (e) {
    console.error(e);
  }
}

function* deleteGroupSaga(action) {
  try {
    const { id, group } = action.payload;
    yield call(materialGroupsApi.deleteGroup, id, group);
    yield put(loadGroups());
  } catch (e) {
    console.error(e);
  }
}

export default function* groupsSaga() {
  yield all([
    takeEvery(loadGroups, loadGroupsSaga),
    takeEvery(createGroup, createGroupSaga),
    takeEvery(updateGroup, updateGroupSaga),
    takeEvery(deleteGroup, deleteGroupSaga),
  ]);
}
