import classNames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@shared/components/paper';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
}));

const Form = ({ children, className, onSubmit }) => {
  const classes = useStyles();

  return (
    <Paper
      className={ classNames(className, classes.root) }
      noValidate
      onSubmit={ onSubmit }
      component="form"
    >
      {children}
    </Paper>
  );
};

export default Form;
