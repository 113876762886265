import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';

const useStyles = makeStyles(() => ({
  root: {
    height: '100%',
  },
}));

const Image = ({ value, alt, className }) => {
  const classes = useStyles();

  return <img className={ classNames(classes.root, className) } src={ value.blob } alt={ alt } />;
};

export default Image;
