import MuiTreeItem from '@material-ui/lab/TreeItem';
import { animated, useSpring } from 'react-spring/web.cjs';
import { alpha, makeStyles } from '@material-ui/core/styles';
import Collapse from '@material-ui/core/Collapse';

const TransitionComponent = (props) => {
  const style = useSpring({
    from: { opacity: 0, transform: 'translate3d(20px,0,0)' },
    to: { opacity: props.in ? 1 : 0, transform: `translate3d(${props.in ? 0 : 20}px,0,0)` },
  });

  return (
    <animated.div style={ style }>
      <Collapse { ...props } />
    </animated.div>
  );
};

const useStyles = makeStyles((theme) => ({
  iconContainer: {
    '& .close': {
      opacity: 0.3,
    },
  },
  group: {
    marginLeft: 7,
    paddingLeft: 18,
    borderLeft: `1px dashed ${alpha(theme.palette.text.primary, 0.4)}`,
  },
  label: {
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
  },
  selected: {
    backgroundColor: '#CDD6E3',
  },
}));

const TreeItem = ({
  children,
  nodeId,
  label,
  onClick,
}) => {
  const classes = useStyles();

  return (
    <MuiTreeItem
      classes={ classes }
      nodeId={ nodeId }
      label={ label }
      TransitionComponent={ TransitionComponent }
      onClick={ onClick }
    >
      {children}
    </MuiTreeItem>
  );
};

export default TreeItem;
