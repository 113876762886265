import {
  useEffect,
  useState,
  memo,
} from 'react';
import { useSelector } from 'react-redux';

import TreeView from './TreeView';
import RootTreeItem from './RootTreeItem';
import TreeItem from './TreeItem';
import SceneControls from './SceneControls';
import TreeItemLabel from './TreeItemLabel';
import TreeItemLabelContent from './TreeItemLabelContent';
import TreeItemLabelActions from './TreeItemLabelActions';
import TreeItemLabelText from './TreeItemLabelText';
import AddButton from './AddButton';
import ModelView from './ModelView';
import InteriorImage from './InteriorImage';

import { ReactComponent as ClosedEyeIcon } from '@images/icons/closed_eye.svg';
import { ReactComponent as EyeIcon } from '@images/icons/eye.svg';
import { ReactComponent as TrashIcon } from '@images/icons/trash.svg';
import { ExpandVariant } from '../constants';
import { Tooltip, Typography } from '@material-ui/core';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';

const selectInteriors = (state) => state.interiors.interiors;

const hasInteriors = (interiors) => interiors.items.length > 0;

const hasModels = (models) => models.length > 0;

const SceneChildrenTree = ({
  isEditable,
  isDisabled,
  interior,
  models,
  lights,
  selected,
  onAddModel,
  onDeleteModel,
  onAddLight,
  onDeleteLight,
  onSelect,
  onLinkModel,
  onLinkInterior,
  onChangeModel,
  expanded,
  onModelsTreeItemLabelClick,
  onLightsTreeItemLabelClick,
  onNodeToggle,
  onLinkLight,
}) => {
  const [selection, setSelection] = useState([]);

  const interiors = useSelector(selectInteriors);

  const apiError = useSelector((state) => state.scenes.error);
  const [modelErrors, setModelErrors] = useState({});

  useEffect(() => {
    if (apiError && apiError.type === 'PUT') {
      apiError.data.message.map((messageObj) => {
        if (messageObj?.property == 'models') {
          messageObj.messages.map((nestedObj) => {
            const nestedIndex = parseInt(nestedObj.property) + 1;
            nestedObj.messages.map((nestedMessageObj) => {
              modelErrors[nestedIndex] = nestedMessageObj.message;
              setModelErrors(modelErrors);
            });
          });
        }
      });
    }
  }, [apiError]);

  useEffect(() => {
    setModelErrors({});
  }, [models]);

  useEffect(() => {
    if (selected) {
      setSelection(selected.data?.id || selected.type || []);
    } else {
      setSelection([]);
    }
  }, [selected]);

  const handleLinkInterior = () => {
    if (isDisabled) {
      return;
    }

    onLinkInterior?.();
  };

  const handleLinkInteriorAddIconClick = (event) => {
    event.stopPropagation();

    if (isDisabled) {
      return;
    }

    if (hasInteriors(interiors)) {
      onLinkInterior?.();
    }
  };

  const handleModelsTreeItemLabelClick = (event) => {
    event.stopPropagation();

    if (isDisabled) {
      return;
    }

    onModelsTreeItemLabelClick();
  };

  const handleAddModelAddIconClick = (event) => {
    event.stopPropagation();

    if (isDisabled) {
      return;
    }

    if (interior) {
      onAddModel();
    }
  };

  const handleLightsTreeItemLabelClick = (event) => {
    event.stopPropagation();

    if (isDisabled) {
      return;
    }

    onLightsTreeItemLabelClick();
  };

  const handleAddLightAddIconClick = (event) => {
    event.stopPropagation();

    if (isDisabled) {
      return;
    }

    if (hasModels(models)) {
      onAddLight();
    }
  };

  const handleSceneTreeItemLabelClick = (event) => {
    event.stopPropagation();

    if (isDisabled) {
      return;
    }

    onSelect(ExpandVariant.Scene);
  };

  const handleInteriorTreeItemLabelClick = (event) => {
    event.stopPropagation();

    if (isDisabled) {
      return;
    }

    if (hasInteriors(interiors)) {
      onSelect(ExpandVariant.Interior);
    }
  };

  const handleNodeSelect = (_, nodeIds) => {
    if (isDisabled) {
      return;
    }

    if (!nodeIds?.includes(selected?.type)) {
      setSelection(nodeIds);
    }
  };

  const handleModelTreeItemLabelClickFactory = (model) => () => {
    onSelect('model', model);
  };


  const handleLinkModelClickFactory = (model) => () => {
    if (isDisabled) {
      return;
    }

    onLinkModel?.(model);
  };

  const handleDeleteModelClickFactory = (model) => (evt) => {
    evt.stopPropagation();

    if (isDisabled) {
      return;
    }

    setModelErrors({});
    onDeleteModel?.(model);
  };

  const handleLightItemClickFactory = (light) => () => {
    if (isDisabled) {
      return;
    }

    onSelect('light', light);
  };

  const handleLinkLightClickFactory = (light) => () => {
    if (isDisabled) {
      return;
    }

    onLinkLight(light);
  };

  const handleDeleteLightClickFactory = (light) => () => {
    if (isDisabled) {
      return;
    }

    onDeleteLight(light.id);
  };

  const handleModelChange = (model) => () => {
    if (isDisabled) {
      return;
    }

    setModelErrors({});
    onChangeModel(model);
  };

  const handleNodeToggle = (...args) => {
    if (isDisabled) {
      return;
    }

    onNodeToggle(...args);
  };

  return (
    <TreeView
      expanded={ expanded }
      selected={ selection }
      onNodeToggle={ handleNodeToggle }
      onNodeSelect={ handleNodeSelect }
    >
      <RootTreeItem
        disabled={ isDisabled }
        nodeId={ ExpandVariant.Scene }
        label={ (
          <TreeItemLabel onClick={ handleSceneTreeItemLabelClick }>
            <TreeItemLabelContent>
              <TreeItemLabelText>
                Scene
              </TreeItemLabelText>
            </TreeItemLabelContent>
          </TreeItemLabel>
        ) }
      />
      <RootTreeItem
        disabled={ isDisabled || !hasInteriors(interiors) }
        nodeId={ ExpandVariant.Interior }
        label={ (
          <TreeItemLabel onClick={ handleInteriorTreeItemLabelClick }>
            <TreeItemLabelContent>
              <TreeItemLabelText>
                Interior
              </TreeItemLabelText>
            </TreeItemLabelContent>
            {isEditable && (
              <TreeItemLabelActions>
                {interior ? (
                  <InteriorImage
                    src={ interior.url }
                    onClick={ handleLinkInterior }
                  />
                ) : (
                  <AddButton
                    disabled={ !hasInteriors(interiors) }
                    onClick={ handleLinkInteriorAddIconClick }
                  />
                )}
              </TreeItemLabelActions>
            )}
          </TreeItemLabel>
        ) }
      />
      <RootTreeItem
        disabled={ isDisabled || !interior }
        nodeId={ ExpandVariant.Models }
        label={ (
          <TreeItemLabel>
            <TreeItemLabelContent>
              <TreeItemLabelText onClick={ handleModelsTreeItemLabelClick }>
                Models
              </TreeItemLabelText>
            </TreeItemLabelContent>
            <TreeItemLabelActions>
              {isEditable && (
                <SceneControls>
                  {Object.keys(modelErrors).length > 0 && (
                    <Tooltip
                      title={ (
                        <ul>
                          { Object.keys(modelErrors).map((key) => (
                            <li><Typography front-size={ 30 }>Model {key}: {modelErrors[key]}</Typography></li>
                          )) }
                        </ul>
                      ) }
                      color="error"
                    >
                      <ErrorOutlineIcon />
                    </Tooltip>
                  )}
                  <AddButton
                    disabled={ !interior }
                    onClick={ handleAddModelAddIconClick }
                  />
                </SceneControls>
              )}
            </TreeItemLabelActions>
          </TreeItemLabel>
        ) }
      >
        {models.map((model, index) => (
          <TreeItem
            key={ model.id }
            nodeId={ model.id }
            label={ (
              <TreeItemLabel
                wrap="nowrap"
                onClick={ handleModelTreeItemLabelClickFactory(model) }
              >
                <TreeItemLabelContent>
                  {model.model ? (
                    <ModelView
                      name={ `${index + 1}. ${model.model.name}` }
                      src={ model.model.thumbnail }
                      onClick={ handleModelChange(model) }
                      withPlaceholder
                    />
                  ) : (
                    <TreeItemLabelText>
                      Empty Model
                    </TreeItemLabelText>
                  )}
                </TreeItemLabelContent>
                <TreeItemLabelActions>
                  {isEditable && (
                    <SceneControls>
                      {model.hidden ? (
                        <ClosedEyeIcon
                          label="Hide"
                          onClick={ handleLinkModelClickFactory(model) }
                        />
                      ) : (
                        <EyeIcon
                          label="Show"
                          onClick={ handleLinkModelClickFactory(model) }
                        />
                      )}
                      <TrashIcon
                        label="Delete"
                        onClick={ handleDeleteModelClickFactory(model) }
                      />
                    </SceneControls>
                  )}
                </TreeItemLabelActions>
              </TreeItemLabel>
            ) }
          />
        ))}
      </RootTreeItem>
      <RootTreeItem
        disabled={ isDisabled || !hasModels(models) }
        nodeId={ ExpandVariant.Lights }
        label={ (
          <TreeItemLabel>
            <TreeItemLabelContent>
              <TreeItemLabelText onClick={ handleLightsTreeItemLabelClick }>
                Lights
              </TreeItemLabelText>
            </TreeItemLabelContent>
            {isEditable && (
              <TreeItemLabelActions>
                <AddButton
                  disabled={ !hasModels(models) }
                  onClick={ handleAddLightAddIconClick }
                />
              </TreeItemLabelActions>
            )}
          </TreeItemLabel>
        ) }
      >
        {lights.map((light) => (
          <TreeItem
            key={ light.id }
            nodeId={ light.id }
            label={ (
              <TreeItemLabel>
                <TreeItemLabelContent>
                  <TreeItemLabelText>
                    {light.name}
                  </TreeItemLabelText>
                </TreeItemLabelContent>
                <TreeItemLabelActions>
                  {isEditable && (
                    <SceneControls>
                      {light.hidden ? (
                        <ClosedEyeIcon
                          label="Hide"
                          onClick={ handleLinkLightClickFactory(light) }
                        />
                      ) : (
                        <EyeIcon
                          label="Show"
                          onClick={ handleLinkLightClickFactory(light) }
                        />
                      )}
                      <TrashIcon
                        label="Delete"
                        onClick={ handleDeleteLightClickFactory(light) }
                      />
                    </SceneControls>
                  )}
                </TreeItemLabelActions>
              </TreeItemLabel>
            ) }
            onClick={ handleLightItemClickFactory(light) }
          />
        ))}
      </RootTreeItem>
    </TreeView>
  );
};

export default memo(SceneChildrenTree);
