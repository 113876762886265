const AttributeDefinitionsIcon = () => (
  <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="6" y="5.29883" width="13" height="1.4" rx="0.7" fill="#BCC8D9" />
    <rect x="3" y="10.2988" width="16" height="1.4" rx="0.7" fill="#BCC8D9" />
    <rect x="3" y="15.2988" width="15" height="1.4" rx="0.7" fill="#BCC8D9" />
    <circle cx="10.5" cy="10.9004" r="1.5" fill="white" stroke="#BCC8D9" strokeWidth="1.4" />
    <circle cx="5.5" cy="5.90039" r="1.5" fill="white" stroke="#BCC8D9" strokeWidth="1.4" />
    <circle cx="16.5" cy="15.9004" r="1.5" fill="white" stroke="#BCC8D9" strokeWidth="1.4" />
  </svg>
);

export default AttributeDefinitionsIcon;
