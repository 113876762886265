import { useEffect } from 'react';
import withAuth from '@shared/components/route/withAuth';
import useActions from '@shared/hooks/useActions';
import { actions as sideMenuActions } from '@store/app/index';
import Styling from '@pages/styling/list';

const StylingRoute = withAuth(() => {
  const { showMenu } = useActions(sideMenuActions);

  useEffect(() => {
    showMenu();
  }, [showMenu]);

  return (
    <Styling />
  );
});

export default StylingRoute;
