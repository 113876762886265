import {
  all,
  call,
  put,
  select,
  takeEvery,
} from 'redux-saga/effects';
import { materialsApi } from '@api';
import {
  LOAD_MATERIALS_LIST,
  LOAD_MATERIALS_COUNTS,
  CREATE_MATERIAL,
  LOAD_MATERIAL,
  UPDATE_MATERIAL,
  DELETE_MATERIAL,
  renderMaterialsList,
  renderMaterial,
  renderMaterialsCounts,
  HIDE_MATERIAL,
  LOAD_TEXTURES_LIST,
  DELETE_TEXTURE,
  renderTexturesList,
  loadMaterialsList,
  MARK_MATERIAL_AS_DEFAULT_CONTENT,
  UNMARK_MATERIAL_AS_DEFAULT_CONTENT,
  deleteMaterialError,
  deleteTextureError,
  createMaterialSuccess,
  updateMaterialSuccess,
  createMaterialError,
  updateMaterialError,
} from './actions';
import { selectCurrentCompany } from '@store/app/selectors';
import addHiddenStateForItems from '@shared/utils/addHiddenStateForItems';
import { loadTexturesList } from '@store/materials/actions';

export function* getMaterials() {
  try {
    const data = yield call(materialsApi.loadMaterials);
    const company = yield select(selectCurrentCompany);
    yield put(renderMaterialsList(addHiddenStateForItems(data, company)));
  } catch (error) {
    console.error(error);
  }
}

export function* getTextures() {
  try {
    const data = yield call(materialsApi.loadTextures);
    yield put(renderTexturesList(data));
  } catch (error) {
    console.error(error);
  }
}

export function* deleteTexture({ id, name }) {
  try {
    yield call(materialsApi.deleteTexture, id);
    yield put(loadTexturesList());
  } catch (error) {
    if (error.response?.data) {
      yield put(deleteTextureError(name, error.response?.data));
      return;
    }
    console.error(error);
  }
}

export function* getMaterialsCounts() {
  try {
    const data = yield call(materialsApi.getMaterialsItemsCounts);
    yield put(renderMaterialsCounts(data));
  } catch (error) {
    console.error(error);
  }
}

export function* createMaterial({ material }) {
  try {
    const data = yield call(materialsApi.createMaterial, material);
    yield put(renderMaterial(data));
    yield put(loadMaterialsList());
    yield put(createMaterialSuccess());
  } catch (error) {
    if (error.response?.data) {
      yield put(createMaterialError(error.response.data));
      return;
    }
    console.error(error);
  }
}

export function* updateMaterial({ material }) {
  try {
    const data = yield call(materialsApi.updateMaterial, material);
    yield put(renderMaterial(data));
    yield put(loadMaterialsList());
    yield put(updateMaterialSuccess());
  } catch (error) {
    if (error.response?.data) {
      yield put(updateMaterialError(error.response.data));
      return;
    }
    console.error(error);
  }
}

export function* getMaterial({ id }) {
  try {
    const data = yield call(materialsApi.getMaterialById, id);
    yield put(renderMaterial(data));
  } catch (error) {
    console.error(error);
  }
}

export function* deleteMaterial({ id }) {
  try {
    yield call(materialsApi.deleteMaterialById, id);
    yield put(loadMaterialsList());
  } catch (error) {
    if (error.response?.data) {
      yield put(deleteMaterialError(id, error.response?.data));
      return;
    }
    console.error(error);
  }
}

export function* markMaterialAsDefaultContent({ payload: material }) {
  try {
    yield call(materialsApi.markMaterialAsDefaultContent, material.id);
    yield put(loadMaterialsList());
  } catch (error) {
    console.error(error);
  }
}

export function* unmarkMaterialAsDefaultContent({ payload: material }) {
  try {
    yield call(materialsApi.unmarkMaterialAsDefaultContent, material.id);
    yield put(loadMaterialsList());
  } catch (error) {
    console.error(error);
  }
}


export function* hideMaterial({ payload: material }) {
  try {
    yield call(materialsApi.hideMaterial, material.id);
    yield put(loadMaterialsList());
  } catch (error) {
    console.error(error);
  }
}

export default function* materialsSaga() {
  yield all([
    takeEvery(LOAD_MATERIALS_LIST, getMaterials),
    takeEvery(LOAD_MATERIALS_COUNTS, getMaterialsCounts),
    takeEvery(CREATE_MATERIAL, createMaterial),
    takeEvery(LOAD_MATERIAL, getMaterial),
    takeEvery(UPDATE_MATERIAL, updateMaterial),
    takeEvery(DELETE_MATERIAL, deleteMaterial),
    takeEvery(MARK_MATERIAL_AS_DEFAULT_CONTENT, markMaterialAsDefaultContent),
    takeEvery(UNMARK_MATERIAL_AS_DEFAULT_CONTENT, unmarkMaterialAsDefaultContent),
    takeEvery(HIDE_MATERIAL, hideMaterial),
    takeEvery(LOAD_TEXTURES_LIST, getTextures),
    takeEvery(DELETE_TEXTURE, deleteTexture),
  ]);
}
