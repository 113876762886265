import { makeStyles } from '@material-ui/core/styles';
import MuiTreeView from '@material-ui/lab/TreeView';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(0.5, 0),
  },
}));

const TreeView = ({
  children,
  expanded,
  selected,
  onNodeToggle,
  onNodeSelect,
}) => {
  const classes = useStyles();

  return (
    <MuiTreeView
      classes={ classes }
      expanded={ expanded }
      selected={ selected }
      onNodeToggle={ onNodeToggle }
      onNodeSelect={ onNodeSelect }
    >
      {children}
    </MuiTreeView>
  );
};

export default TreeView;
