export const LOAD_STUDIOS_LIST = 'LOAD_STUDIOS_LIST';
export const RENDER_STUDIOS_LIST = 'RENDER_STUDIOS_LIST';
export const LOAD_STUDIOS_COUNTS = 'LOAD_STUDIOS_COUNTS';
export const RENDER_STUDIOS_COUNTS = 'RENDER_STUDIOS_COUNTS';
export const RESET_STUDIOS = 'RESET_STUDIOS';
export const CREATE_STUDIO = 'CREATE_STUDIO';
export const UPDATE_STUDIO = 'UPDATE_STUDIO';
export const DELETE_STUDIO = 'DELETE_STUDIO';
export const LOAD_STUDIO = 'LOAD_STUDIO';
export const RENDER_STUDIO = 'RENDER_STUDIO';

export const SORTED_ID = 'SORTED_ID';

export function loadStudiosList() {
  return {
    type: LOAD_STUDIOS_LIST,
  };
}

export function renderStudiosList(studios) {
  return {
    type: RENDER_STUDIOS_LIST,
    studios,
  };
}

export function loadStudiosCounts() {
  return {
    type: LOAD_STUDIOS_COUNTS,
  };
}

export function renderStudiosCounts(counts) {
  return {
    type: RENDER_STUDIOS_COUNTS,
    payload: counts,
  };
}

export function resetStudios() {
  return {
    type: RESET_STUDIOS,
  };
}

export function createStudio(studio, setError) {
  return {
    type: CREATE_STUDIO,
    studio,
    setError,
  };
}

export function loadStudio(id) {
  return {
    type: LOAD_STUDIO,
    id,
  };
}

export function renderStudio(studio) {
  return {
    type: RENDER_STUDIO,
    studio,
  };
}

export function updateStudio(studio, setError) {
  return {
    type: UPDATE_STUDIO,
    studio,
    setError,
  };
}

export function deleteStudio(id) {
  return {
    type: DELETE_STUDIO,
    id,
  };
}

export function sortedId(value) {
  return {
    type: SORTED_ID,
    value,
  };
}
