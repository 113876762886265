let magnifierDiv;
let hackCanvas;
let hackCtx;
let magnifierCanvas;
let magnifierCtx;
function Magnifier(setting) {
  const { magnifierDivId } = setting;
  const magnifierDivStyle = setting.magnifierDivStyle || 'border:1px solid #ccc;background:#fff;box-shadow:5px 5px 25px #000;';

  const width = setting.width || 150;
  const height = setting.height || 150;
  let ratio = setting.ratio || 3;
  const radius = (typeof setting.radius === 'undefined') ? (width / 2) : setting.radius;
  const sightType = setting.sightType || 'rect';
  const sightSize = (typeof setting.sightSize === 'undefined') ? 5 : setting.sightSize;
  const sightColor = setting.sightColor || '#FF0000';

  const targetCanvas = setting.konva;
  let widthOffset = width * ratio / 2 - width / 2;
  let heightOffset = height * ratio / 2 - height / 2;
  let magnifierShow = false;

  let offsetX = 0;
  let offsetY = 0;

  //var borderHackWidth = width;
  //var borderHackHeight = height;

  function _setRatio(mRatio) {
    ratio = mRatio;
    widthOffset = width * ratio / 2 - width / 2;
    heightOffset = height * ratio / 2 - height / 2;
    if (magnifierShow && targetCanvas) {
      _draw();
    }
  }

  function _createCanvas(width, height) {
    const canvas = document.createElement('canvas');
    canvas.width = width;
    canvas.height = height;
    canvas.style.width = `${width}px`;
    canvas.style.height = `${height}px`;
    canvas.style.background = '#ffffff';
    return canvas;
  }


  function _init() {
    if (!magnifierDivId) {
      magnifierDiv = document.createElement('div');
      magnifierDiv.setAttribute('style', `z-index:1989;position:absolute;display:none;${magnifierDivStyle}`);
      magnifierDiv.style.width = `${width}px`;
      magnifierDiv.style.height = `${height}px`;
      magnifierDiv.style.top = `${targetCanvas.canvas._canvas.offsetTop + 10}px`;
      magnifierDiv.style.left = `${targetCanvas.canvas._canvas.offsetLeft + 10}px`;
      magnifierDiv.style.pointerEvents = 'none';
      if (radius) {
        magnifierDiv.style.borderRadius = `${radius}px`;
      }
      document.body.appendChild(magnifierDiv);
    } else {
      magnifierDiv = document.getElementById(magnifierDivId);
    }

    magnifierCanvas = _createCanvas(width, height);


    if (magnifierDiv.style.borderRadius) {
      magnifierCanvas.style.borderRadius = magnifierDiv.style.borderRadius;
    }
    magnifierDiv.appendChild(magnifierCanvas);
    magnifierCtx = magnifierCanvas.getContext('2d');

    hackCanvas = _createCanvas(targetCanvas.canvas._canvas.offsetWidth + width, targetCanvas.canvas._canvas.offsetHeight + height);
    hackCanvas.style.display = 'none';
    document.body.appendChild(hackCanvas);
    hackCtx = hackCanvas.getContext('2d');

    if (magnifierShow && targetCanvas) {
      _draw();
    }
  }

  function _updateSizes() {
    if (targetCanvas) {
      magnifierDiv.style.width = `${width}px`;
      magnifierDiv.style.height = `${height}px`;
      magnifierDiv.style.top = `${targetCanvas.canvas._canvas.offsetTop + 10}px`;
      magnifierDiv.style.left = `${targetCanvas.canvas._canvas.offsetLeft + 10}px`;

      magnifierCanvas.width = width;
      magnifierCanvas.height = height;
      magnifierCanvas.style.width = `${width}px`;
      magnifierCanvas.style.height = `${height}px`;

      hackCanvas.width = targetCanvas.canvas._canvas.offsetWidth + width;
      hackCanvas.height = targetCanvas.canvas._canvas.offsetHeight + height;
      hackCanvas.style.width = `${targetCanvas.canvas._canvas.offsetWidth + width}px`;
      hackCanvas.style.height = `${targetCanvas.canvas._canvas.offsetHeight + height}px`;
    }
  }

  function _draw() {
    hackCtx.clearRect(0, 0, hackCanvas.width, hackCanvas.height);
    hackCtx.drawImage(targetCanvas.canvas._canvas,
      width / 2,
      height / 2,
      targetCanvas.canvas._canvas.offsetWidth,
      targetCanvas.canvas._canvas.offsetHeight);
    magnifierCtx.clearRect(0, 0, magnifierCanvas.width, magnifierCanvas.height);
    magnifierCtx.drawImage(hackCanvas, offsetX, offsetY, width, height, -widthOffset, -heightOffset, width * ratio, height * ratio);
    if (sightType) {
      if (sightType == 'rect') {
        if (sightSize > 0) {
          magnifierCtx.fillStyle = sightColor;
          magnifierCtx.fillRect(width / 2 - sightSize / 2, height / 2 - sightSize / 2, sightSize, sightSize);
        }
      } else if (sightType == 'cross') {
        magnifierCtx.beginPath();
        magnifierCtx.strokeStyle = sightColor;
        magnifierCtx.moveTo(0, height / 2);
        magnifierCtx.lineTo(width, height / 2);
        magnifierCtx.stroke();

        magnifierCtx.beginPath();
        magnifierCtx.strokeStyle = sightColor;
        magnifierCtx.moveTo(width / 2, 0);
        magnifierCtx.lineTo(width / 2, height);
        magnifierCtx.stroke();
      }
    }
  }

  function _bind(event, binding) {
    if (!magnifierShow || !event) {
      return;
    }
    if (event.touches) {
      event = event.touches[0];
    }


    const { pageX } = event;
    const { pageY } = event;
    if (!magnifierDivId) {
      if (binding && typeof (binding) === 'function') {
        binding(magnifierDiv);
      } else {
        magnifierDiv.style.top = `${pageY - height / 2}px`;
        magnifierDiv.style.left = `${pageX - width / 2}px`;
      }
    }
    offsetX = event.offsetX || pageX - targetCanvas.canvas._canvas.offsetLeft;
    offsetY = event.offsetY || pageY - targetCanvas.canvas._canvas.offsetTop;
    /*
                offsetX -= width / 2;
                offsetY -= height / 2;

                offsetX += borderHackWidth / 2;
                offsetY += borderHackHeight / 2
    */
    if (magnifierShow && targetCanvas) {
      _draw();
    }
  }

  function _show(mshow) {
    magnifierShow = mshow;
    if (magnifierShow) {
      if (!magnifierDiv) {
        _init();
      } else {
        _updateSizes();
      }
      if (targetCanvas) {
        _draw();
      }
    }
    magnifierDiv.style.display = magnifierShow ? '' : 'none';
  }
  return {
    bind: _bind,
    show: _show,
    setRatio: _setRatio,
  };
}

export default Magnifier;
