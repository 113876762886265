/* eslint-disable max-len */
import SvgIcon from '@material-ui/core/SvgIcon';

const NoImageIcon = (props) => (
  <SvgIcon
    viewBox="0 0 68 58"
    { ...props }
  >
    <path fillRule="evenodd" clipRule="evenodd" d="M17.5184 0.497918C18.1628 -0.165973 19.2075 -0.165973 19.8519 0.497918L49.5519 31.0979C50.1962 31.7618 50.1962 32.8382 49.5519 33.5021C48.9075 34.166 47.8628 34.166 47.2184 33.5021L17.5184 2.90208C16.8741 2.23819 16.8741 1.16181 17.5184 0.497918Z" fill="currentColor" />
    <path fillRule="evenodd" clipRule="evenodd" d="M36.8352 10.2C36.8352 9.26112 37.5739 8.5 38.4852 8.5H38.5017C39.4129 8.5 40.1517 9.26112 40.1517 10.2C40.1517 11.1389 39.4129 11.9 38.5017 11.9H38.4852C37.5739 11.9 36.8352 11.1389 36.8352 10.2Z" fill="currentColor" />
    <path fillRule="evenodd" clipRule="evenodd" d="M25.2852 3.4C25.2852 2.46112 26.0239 1.7 26.9352 1.7H41.7852C43.5356 1.7 45.2143 2.41643 46.4521 3.69167C47.6898 4.96692 48.3852 6.69653 48.3852 8.5V23.8C48.3852 24.7389 47.6464 25.5 46.7352 25.5C45.8239 25.5 45.0852 24.7389 45.0852 23.8V8.5C45.0852 7.59826 44.7375 6.73346 44.1186 6.09584C43.4997 5.45821 42.6604 5.1 41.7852 5.1H26.9352C26.0239 5.1 25.2852 4.33888 25.2852 3.4ZM20.6379 3.67082C21.2833 3.01075 22.3253 3.0131 22.9679 3.67606L46.4524 27.9062C47.0961 28.5703 47.0956 29.6464 46.4512 30.3099C45.2134 31.5843 43.5351 32.3002 41.7852 32.3H25.2852C23.5347 32.3 21.856 31.5836 20.6183 30.3083C19.3805 29.0331 18.6852 27.3035 18.6852 25.5V8.5C18.6852 6.61168 19.4351 4.90101 20.6379 3.67082ZM22.103 7.59545C22.0261 7.88347 21.9852 8.18672 21.9852 8.5V25.5C21.9852 26.4017 22.3328 27.2665 22.9517 27.9042C23.5706 28.5418 24.4099 28.9 25.2852 28.9H41.7852C42.0762 28.9 42.3634 28.8605 42.6397 28.7842L22.103 7.59545Z" fill="currentColor" />
    <path fillRule="evenodd" clipRule="evenodd" d="M28.0896 16.5147L21.5019 23.3021C20.8575 23.966 19.8128 23.966 19.1684 23.3021C18.5241 22.6382 18.5241 21.5618 19.1684 20.8979L25.7684 14.0979L25.7911 14.075C26.8044 13.0704 28.0526 12.4614 29.4102 12.4614C30.7677 12.4614 32.0159 13.0704 33.0292 14.075L33.0519 14.0979L41.3019 22.5979C41.9462 23.2618 41.9462 24.3382 41.3019 25.0021C40.6575 25.666 39.6128 25.666 38.9684 25.0021L30.7307 16.5147C30.2171 16.0095 29.7597 15.8614 29.4102 15.8614C29.0606 15.8614 28.6032 16.0095 28.0896 16.5147Z" fill="currentColor" />
    <path fillRule="evenodd" clipRule="evenodd" d="M42.281 19.915C41.7091 19.3531 41.2082 19.2294 40.831 19.2692C39.9245 19.3647 39.1144 18.6849 39.0217 17.7509C38.929 16.8169 39.5888 15.9823 40.4953 15.8868C42.0198 15.7262 43.4456 16.3521 44.5787 17.4745L44.6019 17.4979L47.9019 20.8979C48.5462 21.5618 48.5462 22.6382 47.9019 23.3021C47.2575 23.966 46.2128 23.966 45.5684 23.3021L42.281 19.915Z" fill="currentColor" />
    <path d="M7.994 53.9992H6.398L1.596 46.7052V53.9992H0V44.1292H1.596L6.398 51.4512V44.1292H7.994V53.9992Z" fill="currentColor" />
    <path d="M13.5938 46.1872C14.3404 46.1872 15.0124 46.3459 15.6098 46.6632C16.2071 46.9806 16.6738 47.4379 17.0098 48.0352C17.3458 48.6326 17.5138 49.3326 17.5138 50.1352C17.5138 50.9472 17.3458 51.6519 17.0098 52.2492C16.6738 52.8466 16.2071 53.3039 15.6098 53.6212C15.0124 53.9386 14.3404 54.0972 13.5938 54.0972C12.8471 54.0972 12.1751 53.9386 11.5778 53.6212C10.9898 53.3039 10.5231 52.8466 10.1778 52.2492C9.84178 51.6426 9.67378 50.9379 9.67378 50.1352C9.67378 49.3326 9.84178 48.6326 10.1778 48.0352C10.5231 47.4379 10.9898 46.9806 11.5778 46.6632C12.1751 46.3459 12.8471 46.1872 13.5938 46.1872ZM13.5938 47.5732C13.1831 47.5732 12.8051 47.6666 12.4598 47.8532C12.1144 48.0399 11.8344 48.3292 11.6198 48.7212C11.4144 49.1039 11.3118 49.5752 11.3118 50.1352C11.3118 50.7046 11.4144 51.1806 11.6198 51.5632C11.8344 51.9459 12.1144 52.2306 12.4598 52.4172C12.8051 52.6039 13.1831 52.6972 13.5938 52.6972C14.0044 52.6972 14.3824 52.6039 14.7278 52.4172C15.0731 52.2306 15.3484 51.9459 15.5538 51.5632C15.7684 51.1806 15.8758 50.7046 15.8758 50.1352C15.8758 49.5752 15.7684 49.1039 15.5538 48.7212C15.3484 48.3292 15.0731 48.0399 14.7278 47.8532C14.3824 47.6666 14.0044 47.5732 13.5938 47.5732Z" fill="currentColor" />
    <path d="M24.2055 44.1152V53.9992H22.6095V44.1152H24.2055Z" fill="currentColor" />
    <path d="M35.9228 46.1592C36.8375 46.1592 37.5655 46.4486 38.1068 47.0272C38.6575 47.6059 38.9328 48.4319 38.9328 49.5052V53.9992H37.3368V49.6732C37.3368 49.0012 37.1688 48.4832 36.8328 48.1192C36.4968 47.7459 36.0348 47.5592 35.4468 47.5592C34.8215 47.5592 34.3221 47.7599 33.9488 48.1612C33.5848 48.5532 33.4028 49.1319 33.4028 49.8972V53.9992H31.8068V49.6732C31.8068 49.0012 31.6388 48.4832 31.3028 48.1192C30.9668 47.7459 30.5048 47.5592 29.9168 47.5592C29.2915 47.5592 28.7921 47.7599 28.4188 48.1612C28.0548 48.5532 27.8728 49.1319 27.8728 49.8972V53.9992H26.2768V46.2852H27.8728V47.8952C28.0688 47.3352 28.3955 46.9059 28.8528 46.6072C29.3101 46.3086 29.8468 46.1592 30.4628 46.1592C31.1068 46.1592 31.6621 46.3179 32.1288 46.6352C32.6048 46.9526 32.9501 47.4099 33.1648 48.0072C33.3888 47.4286 33.7481 46.9759 34.2428 46.6492C34.7375 46.3226 35.2975 46.1592 35.9228 46.1592Z" fill="currentColor" />
    <path d="M44.0303 46.1872C44.7303 46.1872 45.3277 46.3599 45.8223 46.7052C46.317 47.0506 46.667 47.5079 46.8723 48.0772V46.2852H48.4683V53.9992H46.8723V52.2072C46.667 52.7766 46.317 53.2339 45.8223 53.5792C45.3277 53.9246 44.7303 54.0972 44.0303 54.0972C43.3583 54.0972 42.7563 53.9386 42.2243 53.6212C41.7017 53.3039 41.291 52.8466 40.9923 52.2492C40.6937 51.6519 40.5443 50.9472 40.5443 50.1352C40.5443 49.3326 40.6937 48.6326 40.9923 48.0352C41.291 47.4379 41.7017 46.9806 42.2243 46.6632C42.7563 46.3459 43.3583 46.1872 44.0303 46.1872ZM44.5203 47.5872C43.811 47.5872 43.2417 47.8159 42.8123 48.2732C42.3923 48.7212 42.1823 49.3419 42.1823 50.1352C42.1823 50.9286 42.3923 51.5539 42.8123 52.0112C43.2417 52.4592 43.811 52.6832 44.5203 52.6832C44.9683 52.6832 45.3697 52.5806 45.7243 52.3752C46.079 52.1606 46.359 51.8619 46.5643 51.4792C46.7697 51.0966 46.8723 50.6486 46.8723 50.1352C46.8723 49.6312 46.7697 49.1879 46.5643 48.8052C46.359 48.4132 46.079 48.1146 45.7243 47.9092C45.3697 47.6946 44.9683 47.5872 44.5203 47.5872Z" fill="currentColor" />
    <path d="M53.6176 46.1872C54.3176 46.1872 54.9149 46.3599 55.4096 46.7052C55.9043 47.0506 56.2543 47.5079 56.4596 48.0772V46.2852H58.0556V54.0832C58.0556 54.8019 57.9063 55.4366 57.6076 55.9872C57.3183 56.5472 56.8983 56.9859 56.3476 57.3032C55.7969 57.6206 55.1436 57.7792 54.3876 57.7792C53.3049 57.7792 52.4276 57.5226 51.7556 57.0092C51.0929 56.4959 50.6776 55.8006 50.5096 54.9232H52.0916C52.2223 55.3806 52.4743 55.7352 52.8476 55.9872C53.2209 56.2486 53.6969 56.3792 54.2756 56.3792C54.9103 56.3792 55.4329 56.1832 55.8436 55.7912C56.2543 55.3992 56.4596 54.8299 56.4596 54.0832V52.2072C56.2543 52.7766 55.9043 53.2339 55.4096 53.5792C54.9149 53.9246 54.3176 54.0972 53.6176 54.0972C52.9456 54.0972 52.3436 53.9386 51.8116 53.6212C51.2889 53.3039 50.8783 52.8466 50.5796 52.2492C50.2809 51.6519 50.1316 50.9472 50.1316 50.1352C50.1316 49.3326 50.2809 48.6326 50.5796 48.0352C50.8783 47.4379 51.2889 46.9806 51.8116 46.6632C52.3436 46.3459 52.9456 46.1872 53.6176 46.1872ZM54.1076 47.5872C53.3983 47.5872 52.8289 47.8159 52.3996 48.2732C51.9796 48.7212 51.7696 49.3419 51.7696 50.1352C51.7696 50.9286 51.9796 51.5539 52.3996 52.0112C52.8289 52.4592 53.3983 52.6832 54.1076 52.6832C54.5556 52.6832 54.9569 52.5806 55.3116 52.3752C55.6663 52.1606 55.9463 51.8619 56.1516 51.4792C56.3569 51.0966 56.4596 50.6486 56.4596 50.1352C56.4596 49.6312 56.3569 49.1879 56.1516 48.8052C55.9463 48.4132 55.6663 48.1146 55.3116 47.9092C54.9569 47.6946 54.5556 47.5872 54.1076 47.5872Z" fill="currentColor" />
    <path d="M67.2789 49.7712C67.2789 50.0326 67.2602 50.2612 67.2229 50.4572H61.2869C61.3149 51.2506 61.5342 51.8386 61.9449 52.2212C62.3555 52.6039 62.8642 52.7952 63.4709 52.7952C64.0029 52.7952 64.4509 52.6599 64.8149 52.3892C65.1882 52.1186 65.4169 51.7592 65.5009 51.3112H67.2089C67.1062 51.8526 66.8915 52.3332 66.5649 52.7532C66.2382 53.1732 65.8135 53.5046 65.2909 53.7472C64.7682 53.9806 64.1849 54.0972 63.5409 54.0972C62.7942 54.0972 62.1315 53.9386 61.5529 53.6212C60.9835 53.3039 60.5355 52.8466 60.2089 52.2492C59.8822 51.6519 59.7189 50.9472 59.7189 50.1352C59.7189 49.3326 59.8822 48.6326 60.2089 48.0352C60.5355 47.4379 60.9835 46.9806 61.5529 46.6632C62.1315 46.3459 62.7942 46.1872 63.5409 46.1872C64.2969 46.1872 64.9549 46.3459 65.5149 46.6632C66.0842 46.9712 66.5182 47.4006 66.8169 47.9512C67.1249 48.4926 67.2789 49.0992 67.2789 49.7712ZM65.6689 49.7292C65.6875 49.2252 65.5989 48.8052 65.4029 48.4692C65.2162 48.1239 64.9549 47.8719 64.6189 47.7132C64.2922 47.5452 63.9329 47.4612 63.5409 47.4612C62.9155 47.4612 62.3929 47.6526 61.9729 48.0352C61.5529 48.4179 61.3242 48.9826 61.2869 49.7292H65.6689Z" fill="currentColor" />
  </SvgIcon>
);

export default NoImageIcon;
