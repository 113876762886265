import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import PageContainer from '@shared/components/page-parts/Container';
import PageHead from '@shared/components/page-parts/Head';
import PageContent from '@shared/components/page-parts/Content';
import TopNav from '@shared/components/top-nav/Nav';
import CardsList from '@shared/components/cards-list/CardsList';
import CardItem from '@shared/components/cards-list/CardItem';

import EditModelFormDialog from '../../modal/edit/ModelFormDialog';

import Model from './Model';
import Controls from './Controls';

import {
  deleteProduct, deleteProductError,
  hideProduct,
  markProductAsDefaultContent,
  renderProduct,
  unmarkProductAsDefaultContent,
} from '@store/products/actions';

import { ROUTES } from '@app/routes/routesUrls';
import Breadcrumbs from '@shared/components/breadcrumbs';
import AddNew from '@shared/components/cards-list/AddNew';
import { selectProduct } from '@store/products/selectors';
import {loadEnvironmentsList} from '@store/scenes/actions';

const ActionName = {
  Edit: 'Edit',
  Copy: 'Copy',
  AddNew: 'Add New',
  Idle: 'Idle',
};

const ProductListGrid = ({ data }) => {
  const dispatch = useDispatch();
  const wipItem = useSelector(selectProduct);
  const apiError = useSelector((state) => state.products.error);

  const [actionName, setActionName] = useState(null);

  const handleCreateModel = () => {
    dispatch(renderProduct({}));
    setActionName(ActionName.Edit);
  };

  const handleEditProductFactory = (item) => () => {
    dispatch(renderProduct({ ...item }));
    setActionName(ActionName.Edit);
  };

  // todo: bring back sometime
  // const handleCopyProductFactory = (item) => () => {
  //   dispatch(renderProduct({ ...item }));
  //   setActionName(ActionName.Copy);
  // };

  const handleHideProduct = useCallback((item) => () => {
    dispatch(hideProduct(item));
  }, []);

  const handleDeleteProductFactory = (item) => () => {
    const { id } = item;
    dispatch(deleteProduct(id));
  };

  const handleApiErrorClear = () => {
    dispatch(deleteProductError());
  };

  const handleFormClose = () => {
    setActionName(ActionName.Idle);
  };

  const handleShareProduct = useCallback((item) => () => {
    if (item.defaultContent) {
      dispatch(unmarkProductAsDefaultContent(item));
    } else {
      dispatch(markProductAsDefaultContent(item));
    }
  }, []);

  const breadcrumbsItems = useMemo(() => [
    { name: 'Products', link: ROUTES.PRODUCTS.ROOT },
  ], []);

  return (
    <PageContainer>
      <PageHead>
        <TopNav>
          <Breadcrumbs items={ breadcrumbsItems } />
        </TopNav>
      </PageHead>
      <PageContent>
        {data.length > 0 ? (
          <CardsList>
            <CardItem>
              <AddNew onClick={ handleCreateModel } />
            </CardItem>
            {data.map((item) => (
              <CardItem key={ item.id }>
                <Model
                  item={ item }
                  controls={ (
                    <Controls
                      apiError={ apiError }
                      onEdit={ handleEditProductFactory(item) }
                      // onCopy={ handleCopyProductFactory(item) }
                      onDelete={ handleDeleteProductFactory(item) }
                      onHide={ handleHideProduct(item) }
                      onShare={ handleShareProduct(item) }
                      onApiErrorClear={ handleApiErrorClear }
                      item={ item }
                    />
                  ) }
                  onClick={ handleEditProductFactory(item) }
                />
              </CardItem>
            ))}
          </CardsList>
        ) : (
          <CardsList>
            <CardItem>
              <AddNew onClick={ handleCreateModel } />
            </CardItem>
          </CardsList>
        )}
        <EditModelFormDialog
          open={ !!wipItem && actionName === ActionName.Edit }
          data={ wipItem }
          onClose={ handleFormClose }
        />
      </PageContent>
    </PageContainer>
  );
};

export default ProductListGrid;
