import classNames from 'classnames';
import MuiDialog from '@material-ui/core/Dialog';
import Drawer from '@material-ui/core/Drawer';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  root: {
    // the !important flag is required here to move dialog above the models actions menu
    // which has the 16740867 z-index which is set by default by three.js
    zIndex: (props) => (props.className === 'popup' ? '99999999 !important' : ''),
  },
  paperWidthMd: {
    maxWidth: 825,
  },
  paperWidthSm: {
    maxWidth: 400,
  },

  // Non MuiDialog class
  customPaper: {
    minWidth: '960px',
    maxWidth: '50%',
    overflow: 'hidden',
  },
}));

const Dialog = ({
  children,
  className,
  open,
  fullWidth,
  maxWidth,
  PaperProps,
  PaperComponent,
  drawerPaperProps = {},
  onClose,
  isDrawer,
  'aria-labelledby': ariaLabelledby,
  'aria-describedby': ariaDescribedby,
}) => {
  const { customPaper, ...classes } = useStyles({ className });
  const { className: drawerPaperClassName, ...drawerPaperRestProps } = drawerPaperProps;

  return (
    <>
      {isDrawer ? (
        <Drawer
          className={ classNames(classes.root, className) }
          disableEnforceFocus
          open={ open }
          onClose={ onClose }
          PaperProps={
            {
              className: drawerPaperClassName ? classNames(customPaper, drawerPaperClassName) : customPaper,
              ...drawerPaperRestProps,
            }
          }
          aria-labelledby={ ariaLabelledby }
          aria-describedby={ ariaDescribedby }
          anchor="right"
        >
          <PaperComponent { ...PaperProps }>
            {children}
          </PaperComponent>
        </Drawer>
      ) : (
        <MuiDialog
          className={ classNames(classes.root, className) }
          classes={ classes }
          open={ open }
          fullWidth={ fullWidth }
          maxWidth={ maxWidth }
          PaperProps={ PaperProps }
          PaperComponent={ PaperComponent }
          onClose={ onClose }
          aria-labelledby={ ariaLabelledby }
          aria-describedby={ ariaDescribedby }
        >
          {children}
        </MuiDialog>
      )}
    </>
  );
};

export default Dialog;
