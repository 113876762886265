import { forwardRef, memo, useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import { useFormContext } from 'react-hook-form';
import classNames from 'classnames';

import Autocomplete from '@shared/components/autocomplete/Autocomplete';
import { TextField } from '@shared/views/form-dialog';
import UploadIcon from '@resources/icons/UploadIcon';
import ImagePlaceholder from '@shared/components/placeholder/ImagePlaceholder';

const useStyles = makeStyles({
  textFieldRoot: {
    "& > div.MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root']": {
      paddingRight: '9px',
      '& button': {
        order: 3,
      },
      '& > div.MuiAutocomplete-endAdornment': {
        position: 'relative',
        order: 2,
      },
    },
  },
  option: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    '& > img': {
      width: '40px',
      height: '40px',
    },
  },
  previewPlaceholder: {
    width: '40px',
    height: '40px',

    '& svg': {
      width: '36px',
      height: '36px',
    },
  },
});

const ModelTextField = forwardRef(({
  name,
  label,
  onChange,
  value,
  required,
}, ref) => {
  const { setValue } = useFormContext();
  const classes = useStyles();
  const options = useSelector((state) => state.products.products.items);
  const defaultValue = useMemo(() => null, []);

  const handleChange = useCallback((_, data) => {
    if (data && data.id) {
      onChange(data);
      setValue('materials', []);
    } else {
      const option = options.find((op) => op.name === data);
      if (option) {
        onChange(option);
        setValue('materials', []);
        return;
      }
      if (!value || value.name === data) {
        return;
      }
      onChange(defaultValue);
      setValue('materials', []);
    }
  }, [onChange, options, name, value, defaultValue]);

  const getOptionObject = useCallback((option) => ((typeof option === 'string' || option instanceof String)
    ? options.find((op) => op.id === option)
    : option
  ) || defaultValue, [options, defaultValue]);

  const getOptionLabel = useCallback((option) => {
    if (!option) {
      return '';
    }
    const item = getOptionObject(option);
    return item ? item.name || '' : '';
  }, [getOptionObject]);

  const getOptionSelected = useCallback((option, value) => {
    if (value) {
      const item = getOptionObject(value);
      return item ? option.id === item.id : false;
    }
    return value;
  }, [getOptionObject]);

  return (
    <Autocomplete
      freeSolo
      options={ options }
      getOptionLabel={ getOptionLabel }
      getOptionSelected={ getOptionSelected }
      onChange={ handleChange }
      onBlur={ (e) => handleChange(e, e.target.value) }
      ref={ ref }
      value={ value || defaultValue }
      renderInput={ (params) => (
        <TextField
          { ...params }
          classes={ {
            root: classes.textFieldRoot,
          } }
          label={ label }
          variant="outlined"
          required={ required }
          InputProps={ {
            ...params.InputProps,
          } }
        />
      ) }
      renderOption={ (props, { selected }) => (
        <div className={ classNames({ selected }, classes.option) }>
          {!props.thumbnail ? (<ImagePlaceholder className={ classes.previewPlaceholder } />) : (
            <img alt={ props.name } src={ props.thumbnail } />
          )}

          { props.name }
        </div>
      ) }
    />
  );
});

export default memo(ModelTextField);
