/* eslint-disable max-len */
import { makeStyles } from '@material-ui/core';
import SvgIcon from '@material-ui/core/SvgIcon';
import { DefaultIconSizeValue, IconSize } from '@resources/icons/constants';

const useStyles = makeStyles(() => ({
  root: {
    fill: 'none',
  },
}));

const NaturalLightLeft = ({ size = IconSize.Medium, selected = false }) => {
  const classes = useStyles();
  return (
    <SvgIcon
      style={ { width: DefaultIconSizeValue[size], height: DefaultIconSizeValue[size] } }
      className={ classes.root }
      viewBox="0 0 37 34"
    >
      <path opacity="0.8" d="M35 32.5L13 8H5L21.5 32.5H35Z" fill={ selected ? '#ffffff' : '#EBEFF2' } />
      <path
        d="M22.198 19.07H20.768C20.5946 19.0723 20.4254 19.0159 20.288 18.91C20.1551 18.8046 20.0612 18.6578 20.0213 18.4929C19.9814 18.328 19.9979 18.1545 20.068 18C20.1345 17.8397 20.2597 17.711 20.418 17.64L34.898 10.09C35.016 10.0309 35.1461 10.0001 35.278 10C35.4093 10.0042 35.5373 10.0423 35.6495 10.1106C35.7616 10.179 35.8542 10.2752 35.918 10.39C35.9803 10.5073 36.0145 10.6373 36.018 10.77V32.5H22.278L22.198 19.07Z"
        fill={ selected ? '#EBEFF2' : '#ffffff' }
        stroke="#334D6E"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M35.8555 18.0342V32.2424C35.8555 32.3429 35.8156 32.4393 35.7445 32.5104C35.6735 32.5814 35.5771 32.6213 35.4766 32.6213H22.5945C22.494 32.6213 22.3976 32.5814 22.3265 32.5104C22.2555 32.4393 22.2156 32.3429 22.2156 32.2424V19.3603C22.2156 19.2598 22.2555 19.1634 22.3265 19.0924C22.3976 19.0213 22.494 18.9814 22.5945 18.9814"
        stroke="#334D6E"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22.5947 18.9812H20.7608C20.5905 18.9819 20.4249 18.9252 20.2908 18.8202C20.1566 18.7152 20.0618 18.5681 20.0215 18.4026C19.9812 18.2371 19.9979 18.0629 20.0688 17.908C20.1397 17.7531 20.2607 17.6267 20.4123 17.549L34.7494 10.0849C34.865 10.025 34.9941 9.99594 35.1242 10.0005C35.2544 10.005 35.3812 10.043 35.4924 10.1107C35.6036 10.1785 35.6954 10.2738 35.759 10.3874C35.8227 10.501 35.856 10.6291 35.8557 10.7594V25.4223"
        stroke="#334D6E"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M23.5 33.1387V18.4785H21.5V33.1387H23.5Z" fill="#334D6E" />
      <path d="M1 9H17" stroke="#334D6E" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M3.34375 14.6572L14.6575 3.34352"
        stroke="#334D6E"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.6582 14.6572L3.34449 3.34352"
        stroke="#334D6E"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M9 17L9 1" stroke="#334D6E" strokeLinecap="round" strokeLinejoin="round" />
      <circle
        cx="8.9824"
        cy="8.98244"
        r="5.4824"
        transform="rotate(-90 8.9824 8.98244)"
        fill="#F0F0F0"
        stroke="#334D6E"
      />
      <circle cx="9" cy="9" r="4" fill="#334D6E" />
    </SvgIcon>
  );
};

export default NaturalLightLeft;
