import { useCallback } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { Form } from '@shared/views/form-dialog';
import { createScene } from '@store/scenes/actions';
import { selectScenes } from '@store/scenes/selectors';
import { Fieldset, DEFAULT_VALUES } from '../fieldset';

const CopyForm = ({
  children,
  className,
  open,
  onSubmit,
  data,
}) => {
  const dispatch = useDispatch();
  const scenes = useSelector(selectScenes);

  const form = useForm({
    defaultValues: DEFAULT_VALUES,
  });

  const handleSubmit = useCallback((newData) => {
    const trimmedName = newData[Fieldset.Name].trim();
    const sceneExists = scenes?.some((item) => trimmedName.toLowerCase() === item.name.trim().toLowerCase());
    if (!sceneExists) {
      dispatch(createScene({
        ...data,
        [Fieldset.Name]: newData[Fieldset.Name].trim(),
        [Fieldset.Models]: data[Fieldset.Models].map((model) => ({ ...model, model: { id: model.model } })),
        [Fieldset.Attributes]: data[Fieldset.Attributes].map((attr) => attr.id),
        [Fieldset.DetailsData]: {},
      }));
      onSubmit();
    } else {
      form.setError(Fieldset.Name, {
        type: 'manual',
        message: 'Scene name already exists',
      });
    }
  }, [data, scenes, onSubmit, dispatch]);

  return (
    <Form
      className={ className }
      open={ open }
      onSubmit={ form.handleSubmit(handleSubmit) }
    >
      <FormProvider { ...form }>
        {children}
      </FormProvider>
    </Form>
  );
};

export default CopyForm;
