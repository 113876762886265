class LocalStorageService {
  ls = window.localStorage;

  getItem(key, parse = false) {
    const value = this.ls.getItem(key);
    try {
      return parse ? JSON.parse(value) : value;
    } catch {
      return null;
    }
  }

  setItem(key, value, stringify = false) {
    const v = stringify ? JSON.stringify(value) : value;
    this.ls.setItem(key, v);
  }

  removeItem(key) {
    this.ls.removeItem(key);
  }
}

export default new LocalStorageService();
