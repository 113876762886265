import withAuth from '@shared/components/route/withAuth';
import useActions from '@shared/hooks/useActions';
import { actions as sideMenuActions } from '@store/app/index';

import Profile from 'pages/profile';

const ProfilePage = withAuth(() => {
  const { showMenu } = useActions(sideMenuActions);
  showMenu();
  return (
    <Profile />
  );
});

export default ProfilePage;
