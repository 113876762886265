import DetailsContainer from '../DetailsContainer';
import Preview from './Preview';
import TextField from '../TextField';

import { Fieldset } from './fieldset';

const InteriorDetails = ({
  details,
  onImageClick,
  isEditable,
}) => (
  <DetailsContainer isEditable={ isEditable }>
    <Preview
      src={ details[Fieldset.Image] }
      alt="Interior"
      onClick={ onImageClick }
    />
    <TextField
      disabled
      name={ Fieldset.Name }
      label="Name"
      value={ details[Fieldset.Name] || '' }
      readOnly
      required
    />
    <TextField
      disabled
      name={ Fieldset.Width }
      label="Width"
      value={ details[Fieldset.Width] || '' }
      readOnly
      required
    />
    <TextField
      disabled
      name={ Fieldset.Height }
      label="Height"
      value={ details[Fieldset.Height] || '' }
      readOnly
      required
    />
  </DetailsContainer>
);

export default InteriorDetails;
