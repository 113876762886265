import { FormControl, Grid, makeStyles, TextField } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useLocation } from 'react-router-dom';

import Button from '@shared/components/button';
import useNavigate from '@shared/router/useNavigate';
import { extractErrorMessage } from '@shared/utils';
import { usersApi } from '@api';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    width: '100%',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  container: {
    width: '400px',
  },
}));

const Register = () => {
  const location = useLocation();
  const { goTo } = useNavigate();

  const [errorMessage, setErrorMessage] = useState();
  const [token] = useState(() => {
    const params = new URLSearchParams(location.search);
    return params.get('token');
  });

  const { control, getValues, formState: { errors: fieldsErrors }, handleSubmit } = useForm();
  const classes = useStyles();

  const onSubmit = async ({ password, firstname, lastname }) => {
    setErrorMessage(null);

    try {
      await usersApi.register({ token, password, firstname, lastname });
      goTo('/login');
    } catch (error) {
      setErrorMessage(extractErrorMessage(error));
    }
  };

  return (
    <form className={ classes.root } onSubmit={ handleSubmit(onSubmit) } noValidate>
      <Grid container className={ classes.container } spacing={ 2 }>
        <Grid item xs={ 12 }>
          <FormControl fullWidth variant="outlined">
            <Controller
              name="firstname"
              render={ ({field, fieldState: { error }}) => (
                <TextField
                  { ...field }
                  id="firstname"
                  type="text"
                  helperText={ error?.message ?? null }
                  variant="outlined"
                  label="First name"
                  required
                  error={ !!error }
                  style={ { marginBottom: '16px' } }
                />
              ) }
              control={ control }
              defaultValue=""
              rules={ { required: 'Required' } }
            />
            <Controller
              name="lastname"
              render={ ({field, fieldState: { error }}) => (
                <TextField
                  { ...field }
                  id="lastname"
                  type="text"
                  helperText={ error?.message ?? null }
                  variant="outlined"
                  label="Last name"
                  required
                  error={ !!error }
                  style={ { marginBottom: '16px' } }
                />
              ) }
              control={ control }
              defaultValue=""
              rules={ { required: 'Required' } }
            />
            <Controller
              name="password"
              render={ ({field, fieldState: { error }}) => (
                <TextField
                  { ...field }
                  id="password"
                  type="password"
                  helperText={ error?.message ?? null }
                  variant="outlined"
                  label="Password"
                  autoComplete="new-password"
                  required
                  error={ !!error }
                />
              ) }
              control={ control }
              defaultValue=""
              rules={ { required: 'Required' } }
            />
          </FormControl>
        </Grid>
        <Grid item xs={ 12 }>
          <FormControl fullWidth variant="outlined">
            <Controller
              name="rpassword"
              render={ ({ field, fieldState: { error } }) => (
                <TextField
                  { ...field }
                  id="rpassword"
                  type="password"
                  helperText={ error?.message ?? null }
                  variant="outlined"
                  label="Repeat password"
                  required
                  error={ !!error }
                />
              ) }
              control={ control }
              defaultValue=""
              rules={ {
                required: 'Required',
                validate: (v) => v === getValues('password') || 'Passwords do no match',
              } }
            />
          </FormControl>
        </Grid>
        <Grid item xs={ 12 }>
          { errorMessage && <Alert severity="error">{ errorMessage }</Alert> }
        </Grid>
        <Grid item container justifyContent="center">
          <Button type="submit">Register</Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default Register;
