export const LOAD_MODELS_LIST = 'LOAD_MODELS_LIST';
export const RENDER_MODELS_LIST = 'RENDER_MODELS_LIST';
export const LOAD_MODELS_COUNTS = 'LOAD_MODELS_COUNTS';
export const RENDER_MODELS_COUNTS = 'RENDER_MODELS_COUNTS';
export const LOAD_MODEL_SCENES = 'LOAD_MODEL_SCENES';
export const PUT_MODEL_SCENES = 'PUT_MODEL_SCENES';
export const RESET_MODEL_SCENES = 'RESET_MODEL_SCENES';
export const RESET_MODELS = 'RESET_MODELS';
export const CREATE_MODEL = 'CREATE_MODEL';
export const CREATE_MODEL_SUCCESS = 'CREATE_MODEL_SUCCESS';
export const CREATE_MODEL_ERROR = 'CREATE_MODEL_ERROR';
export const UPDATE_MODEL = 'UPDATE_MODEL';
export const UPDATE_MODEL_SUCCESS = 'UPDATE_MODEL_SUCCESS';
export const UPDATE_MODEL_ERROR = 'UPDATE_MODEL_ERROR';
export const DELETE_MODEL = 'DELETE_MODEL';
export const DELETE_MODEL_ERROR = 'DELETE_MODEL_ERROR';
export const RENDER_MODEL = 'RENDER_MODEL';
export const RESET_MODEL_API_STATE = 'RESET_MODEL_API_STATE';

export const SORTED_ID = 'SORTED_ID';
export const MARK_MODEL_AS_DEFAULT_CONTENT = 'MARK_MODEL_AS_DEFAULT_CONTENT';
export const UNMARK_MODEL_AS_DEFAULT_CONTENT = 'UNMARK_MODEL_AS_DEFAULT_CONTENT';
export const HIDE_MODEL = 'HIDE_MODEL';
export const DELETE_MODEL_ANIMATIONS = 'DELETE_MODEL_ANIMATIONS';

export function loadModelsList() {
  return {
    type: LOAD_MODELS_LIST,
  };
}

export function renderModelsList(models) {
  return {
    type: RENDER_MODELS_LIST,
    models,
  };
}

export function loadModelsCounts() {
  return {
    type: LOAD_MODELS_COUNTS,
  };
}

export function renderModelsCounts(counts) {
  return {
    type: RENDER_MODELS_COUNTS,
    payload: counts,
  };
}

export function loadModelScenes(id) {
  return {
    type: LOAD_MODEL_SCENES,
    id,
  };
}

export function putModelScenes(scenes) {
  return {
    type: PUT_MODEL_SCENES,
    payload: scenes,
  };
}

export function resetModelScenes() {
  return {
    type: RESET_MODEL_SCENES,
  };
}

export function resetModels() {
  return {
    type: RESET_MODELS,
  };
}

export function createModel(model) {
  return {
    type: CREATE_MODEL,
    model,
  };
}

export function createModelError(error) {
  return {
    type: CREATE_MODEL_ERROR,
    error,
  };
}

export function createModelSuccess() {
  return {
    type: CREATE_MODEL_SUCCESS,
  };
}

export function renderModel(model) {
  return {
    type: RENDER_MODEL,
    model,
  };
}

export function updateModel(model) {
  return {
    type: UPDATE_MODEL,
    model,
  };
}

export function updateModelError(error) {
  return {
    type: UPDATE_MODEL_ERROR,
    error,
  };
}

export function updateModelSuccess() {
  return {
    type: UPDATE_MODEL_SUCCESS,
  };
}

export function deleteModel(id) {
  return {
    type: DELETE_MODEL,
    id,
  };
}

export function deleteModelError(id, error) {
  return {
    type: DELETE_MODEL_ERROR,
    id,
    error,
  };
}

export function sortedId(value) {
  return {
    type: SORTED_ID,
    value,
  };
}

export const markModelAsDefaultContent = (model) => ({
  type: MARK_MODEL_AS_DEFAULT_CONTENT,
  payload: model,
});

export const unmarkModelAsDefaultContent = (model) => ({
  type: UNMARK_MODEL_AS_DEFAULT_CONTENT,
  payload: model,
});

export const hideModel = (model) => ({
  type: HIDE_MODEL,
  payload: model,
});

export function resetModelApiState() {
  return {
    type: RESET_MODEL_API_STATE,
  };
}

export function deleteModelAnimations(id) {
  return {
    type: DELETE_MODEL_ANIMATIONS,
    id,
  };
}
