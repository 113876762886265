import { useRef, useEffect, useMemo, forwardRef, useImperativeHandle, useLayoutEffect, Suspense } from 'react';
import { useThree } from '@react-three/fiber';
import { OrbitControls, PerspectiveCamera } from '@react-three/drei';
import useLoadObject from './hooks/useLoadObject';

import Stage from './Stage';
import { useEnvironmentLoader } from '@zolak/zolak-viewer';


function StageModel({
  value,
  materials,
  controlsRef,
  onLoad,
  onMaterialsLoad,
}) {
  const object = useLoadObject(value, materials, (object) => {
    onLoad(object, controlsRef.current);
  }, onMaterialsLoad, controlsRef.current);

  return <primitive object={ object } scale={ value.scale } />;
}

const Scene = ({
  value,
  background,
  presets,
  environment,
  envIntensity,
  ambient,
  ambientIntensity,
  intensity,
  shadows,
  materials,
  camera,
  onLoad = () => {},
  onMaterialsLoad,
}, forwardRef) => {
  const three = useThree();
  const envTexture = useEnvironmentLoader({
    files: environment?.url,
    extension: environment?.type,
    gl: three.gl,
  });

  const controlsRef = useRef();
  const cameraRef = useRef();

  const shadow = useMemo(() => ({ type: shadows, color: background, colorBlend: 2, opacity: 1 }), [shadows, background]);

  useImperativeHandle(forwardRef, () => three, [three]);

  useEffect(() => {
    three.scene.environment = envTexture;
    three.scene.environmentIntensity = envIntensity;
  }, [three, envTexture, envIntensity]);

  useEffect(() => {
    if (three.controls && camera) {
      three.camera.position.fromArray(camera.position);
      three.camera.rotation.fromArray(camera.rotation);
      three.controls.target.fromArray(camera.target);
      three.controls.update();
    }
  }, [three.controls, three.camera, camera]);

  return (
    // eslint-disable-next-line react/no-unknown-property
    <>
      <PerspectiveCamera
        ref={ cameraRef }
        position={ camera?.position }
        rotation={ camera?.rotation }
        makeDefault
      />
      {
        cameraRef.current && (
        <OrbitControls
          ref={ controlsRef }
          rotateSpeed={ 1.5 }
          camera={ cameraRef.current }
          target={ camera?.target }
          makeDefault
        />
        )
      }
      <Suspense fallback={ null }>
        <Stage
          preset={ presets || 'soft' }
          adjustCamera={ false }
          intensity={ intensity }
          ambient={ ambient }
          ambientIntensity={ ambientIntensity }
          shadows={ shadow }
        >
          <StageModel
            value={ value }
            materials={ materials }
            envIntensity={ envIntensity }
            controlsRef={ controlsRef }
            onLoad={ onLoad }
            onMaterialsLoad={ onMaterialsLoad }
          />
        </Stage>
      </Suspense>
    </>
  );
};

export default forwardRef(Scene);
