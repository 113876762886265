import { Axis } from '../../types';
import VPSolver from './VPSolver';
import VPStep from './steps/VPStep';
import ReferenceStep from './steps/ReferenceStep';
import FloorStep from './steps/FloorStep';

class VPSolverType1 extends VPSolver {

    type = 0
    steps: any[] = [
      new FloorStep(this, this.prepareFloorStep, this.completeFloorStep, this.cancelFloorStep),
      new VPStep(this, () => this.prepareVPStep(Axis.Y), this.completeVPStep, this.cancelVPStep),
    ]
}

export default VPSolverType1;
