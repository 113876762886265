import { Controller, useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';
import {
  TextField,
  PreviewContainer,
  Fields,
  Field, GroupField,
} from '@shared/views/form-dialog';
import Preview from './Preview';
import { Fieldset } from './fieldset';
import { useState } from 'react';
import { CameraSolver } from '@shared/components/camera-solver';
import Button from '@shared/components/button';
import useFormValues from '@shared/hooks/useFormValues';
import AttributesTable from '@shared/components/attributes-table/AttributesTable';
import useWarningControl from '@shared/components/card-controls/useWarningControl';
import WarningDialog from '@shared/components/card-controls/WarningDialog';
import { selectAttributeDefinitions } from '@store/attribute-definitions/selectors';


const FormFieldset = ({ isCreate, usage }) => {
  const [isLayout, setIsLayout] = useState(isCreate);
  const form = useFormContext();
  const {
    control,
    formState: { errors: fieldsErrors },
    setValue,
  } = form;

  const values = useFormValues(form);

  const attributeDefinitions = useSelector(selectAttributeDefinitions);

  const [warningOpen, {
    handleWarningControlClick,
    handleWarningClose,
  }] = useWarningControl();
  const [warningTitle, setWarningTitle] = useState('');

  return (
    <Fields style={ { height: '100%' } }>
      <GroupField sizes={ [3, 9] } style={ { height: '100%' } }>
        <Fields>
          <Field>
            <Controller
              name={ Fieldset.Name }
              control={ control }
              rules={ {
                required: 'Required',
              } }
              render={ ({ field }) => (
                <TextField
                  { ...field }
                  helperText={ fieldsErrors[Fieldset.Name]?.message }
                  label="Name"
                  required
                  error={ !!fieldsErrors[Fieldset.Name] }
                />
              ) }
            />
          </Field>
          <Field>
            <Button onClick={ () => {
              const titles = usage.map((message) => {
                if (message.reason === 'used') {
                  const usedIn = message.usedIn.map((p) => p.name).join(', ');
                  if (message.location === 'Scene') {
                    return `Re-calculate of layout will impact to interiors that use given background.
                    Given background is used in the following interiors: [${usedIn}].`;
                  }
                }
              }).filter((item) => item);
              if (titles.length) {
                handleWarningControlClick();
                setWarningTitle(titles.join('\n'));
              }
              setIsLayout(true);
            } }
            >
              Re-calculate layout
            </Button>
          </Field>
          <GroupField>
            <Controller
              name={ Fieldset.RoomHeight }
              control={ control }
              rules={ {
                required: 'Required',
              } }
              render={ ({ field }) => (
                <TextField
                  { ...field }
                  helperText={ fieldsErrors[Fieldset.RoomHeight]?.message }
                  type="number"
                  label="Room Height"
                  required
                  error={ !!fieldsErrors[Fieldset.RoomHeight] }
                />
              ) }
            />
            <Controller
              name={ Fieldset.ReferenceLength }
              control={ control }
              rules={ {
                required: 'Required',
              } }
              render={ ({ field }) => (
                <TextField
                  { ...field }
                  helperText={ fieldsErrors[Fieldset.ReferenceLength]?.message }
                  type="number"
                  label="Reference Length"
                  required
                  error={ !!fieldsErrors[Fieldset.ReferenceLength] }
                />
              ) }
            />
          </GroupField>
          <Field>
            <Controller
              name={ Fieldset.Attributes }
              control={ control }
              render={ ({ field }) => (
                <AttributesTable
                  attributeDefinitions={ attributeDefinitions }
                  { ...field }
                />
              ) }
            />
          </Field>
        </Fields>
        <PreviewContainer orientation="vertical" style={ { height: '100%' } }>
          {
            ((!control._formValues[Fieldset.Image]?.blob && isLayout) || !isLayout) && (
            <Preview
              name={ Fieldset.Image }
              supportedFormats="JPG, JPD2000, PNG"
              control={ control }
              fileName={ values.name }
            />
            )
          }
          {
            isLayout && control._formValues[Fieldset.Image]?.blob && (
              <CameraSolver
                image={ control._formValues[Fieldset.Image]?.blob }
                layout={ control._formValues.layout }
                roomHeight={ control._formValues.layout.height }
                refHeight={ control._formValues.layout.refLength }
                onCancel={ () => {
                  setIsLayout(false);
                } }
                onResult={ (result) => {
                  setValue(Fieldset.Viewport, result.viewport);
                  setValue(Fieldset.FloorPoints, result.floorPoints);
                  setValue(Fieldset.Type, result.type);
                  setValue(Fieldset.VP1Line, result.vp1Line);
                  setValue(Fieldset.VP2Line, result.vp2Line);
                  setValue(Fieldset.PrincipalPoint, result.principalPoint);
                  setValue(Fieldset.VanishingPoints, result.vanishingPoints);
                  setValue(Fieldset.ReferenceAxis, result.referenceAxis);
                  setValue(Fieldset.ReferencePoints, result.referencePoints);
                  setValue(Fieldset.RL, result.rl);
                  setValue(Fieldset.Points, result.points);
                  setIsLayout(false);
                } }
              />
            )
          }
        </PreviewContainer>
      </GroupField>
      <WarningDialog
        open={ warningOpen }
        title={ warningTitle }
        onClose={ handleWarningClose }
      />
    </Fields>
  );
};

export default FormFieldset;
