import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Logo from './Logo';

const MenuToggle = ({ isOpen, onClick, classes }) => (isOpen ? (
  <Logo onClick={ onClick } />
) : (
  <IconButton
    color="inherit"
    aria-label="Open drawer"
    onClick={ onClick }
  >
    <MenuIcon className={ classes.menuIcon } />
  </IconButton>
));

export default MenuToggle;
