import { FormDialog } from '@shared/views/form-dialog';
import Form from './Form';
import FormFieldset from '../FormFieldset';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  duplicateForm: {
    maxWidth: '382px',
    maxHeight: '276px',

    '& .MuiDialogTitle-root': {
      paddingTop: '50px',
    },

    '& .MuiDialogContent-root': {
      padding: '8px 50px',
    },

    '& .MuiDialogActions-spacing': {
      padding: '16px 50px 55px',
    },
  },
}));

const CopyFormDialog = ({
  open,
  onClose,
  data,
}) => {
  const classes = useStyles();
  const handleSubmit = () => {
    onClose();
  };

  const handleCancel = () => {
    onClose();
  };

  return (
    <FormDialog
      open={ open }
      Form={ Form }
      FormProps={ {
        className: classes.duplicateForm,
        open,
        onSubmit: handleSubmit,
        data,
      } }
      title="Duplicate of Interior"
      content={ <FormFieldset /> }
      onCancel={ handleCancel }
      onClose={ onClose }
      maxWidth="sm"
      titleAlign="center"
    />
  );
};

export default CopyFormDialog;
