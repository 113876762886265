import { useState, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import EditMaterialModal from '../../modal/edit/FormDialog';
import CreateMaterialModal from '../../modal/create/FormDialog';
import CopyMaterialModal from '../../modal/copy/FormDialog';
import PageContainer from '@shared/components/page-parts/Container';
import PageHead from '@shared/components/page-parts/Head';
import PageContent from '@shared/components/page-parts/Content';
import TopNav from '@shared/components/top-nav/Nav';
import CardsList from '@shared/components/cards-list/CardsList';
import CardItem from '@shared/components/cards-list/CardItem';
import Material from './Material';
import Controls from './Controls';

import {
  deleteMaterial,
  deleteMaterialError,
  hideMaterial,
  markMaterialAsDefaultContent,
  unmarkMaterialAsDefaultContent,
} from '@store/materials/actions';

import { ROUTES } from '@app/routes/routesUrls';
import Breadcrumbs from '@shared/components/breadcrumbs';
import AddNew from '@shared/components/cards-list/AddNew';

const ActionName = {
  Edit: 'Edit',
  Copy: 'Copy',
  AddNew: 'Add New',
};

const MaterialListGrid = ({ data }) => {
  const dispatch = useDispatch();
  const apiError = useSelector((state) => state.materials.error);

  const [actionName, setActionName] = useState('');
  const [modalData, setModalData] = useState({});

  const handleEditMaterials = useCallback((item) => () => {
    setModalData(item);
    setActionName(ActionName.Edit);
  }, []);

  const handleCopyMaterials = useCallback((item) => () => {
    setModalData(item);
    setActionName(ActionName.Copy);
  }, []);

  const handleHideMaterial = useCallback((item) => () => {
    dispatch(hideMaterial(item));
  }, [dispatch]);

  const handleShareMaterial = useCallback((item) => () => {
    if (item.defaultContent) {
      dispatch(unmarkMaterialAsDefaultContent(item));
    } else {
      dispatch(markMaterialAsDefaultContent(item));
    }
  }, [dispatch]);

  const handleCreateMaterials = useCallback(() => {
    setModalData({
      textures: {},
      configuration: {},
      attributes: [],
      translatedFields: [],
    });
    setActionName(ActionName.AddNew);
  }, []);

  const handleDeleteMaterials = useCallback((item) => () => {
    dispatch(deleteMaterial(item.id));
  }, [dispatch]);

  const handleApiErrorClear = () => {
    dispatch(deleteMaterialError());
  };

  const handleMaterialModalClose = useCallback(() => {
    setActionName('');
  }, []);

  const breadcrumbsItems = useMemo(() => [
    { name: 'Materials', link: ROUTES.MATERIALS },
  ], []);

  return (
    <PageContainer>
      <PageHead>
        <TopNav>
          <Breadcrumbs items={ breadcrumbsItems } />
        </TopNav>
      </PageHead>
      <PageContent>
        {data.length > 0 ? (
          <CardsList>
            <CardItem>
              <AddNew onClick={ handleCreateMaterials } />
            </CardItem>
            {data.map((item) => (
              <CardItem key={ item.id }>
                <Material
                  item={ item }
                  controls={ (
                    <Controls
                      apiError={ apiError }
                      onShare={ handleShareMaterial(item) }
                      onEdit={ handleEditMaterials(item) }
                      onCopy={ handleCopyMaterials(item) }
                      onHide={ handleHideMaterial(item) }
                      onDelete={ handleDeleteMaterials(item) }
                      onApiErrorClear={ handleApiErrorClear }
                      item={ item }
                    />
                  ) }
                  onClick={ handleEditMaterials(item) }
                />
              </CardItem>
            ))}
          </CardsList>
        ) : (
          <CardsList>
            <CardItem>
              <AddNew onClick={ handleCreateMaterials } />
            </CardItem>
          </CardsList>
        )}
      </PageContent>
      <EditMaterialModal
        open={ actionName === ActionName.Edit }
        data={ modalData }
        onClose={ handleMaterialModalClose }
      />
      <CreateMaterialModal
        open={ actionName === ActionName.AddNew }
        data={ modalData }
        onClose={ handleMaterialModalClose }
      />
      <CopyMaterialModal
        open={ actionName === ActionName.Copy }
        data={ modalData }
        onClose={ handleMaterialModalClose }
      />
    </PageContainer>
  );
};

export default MaterialListGrid;
