import Position from '../../Position';

const PositionOption = ({
  name,
  value,
  onChange,
  label = 'Position',
}) => (
  <Position
    label={ label }
    name={ name }
    value={ value }
    minX={ -100 }
    maxX={ 100 }
    minY={ -100 }
    maxY={ 100 }
    minZ={ -100 }
    maxZ={ 100 }
    step={ 0.1 }
    onChange={ onChange }
  />
);

export default PositionOption;
