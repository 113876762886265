import { IconButton, Snackbar } from '@material-ui/core';
import CopyIcon from '@resources/icons/CopyIcon';
import { memo, useCallback, useState } from 'react';
import EditIcon from '@resources/icons/EditIcon';

const NUM_CHARS = 10;

const CompanyToken = ({ token, onRefresh }) => {
  const [open, setOpen] = useState(false);

  const handleCopy = useCallback(() => {
    setOpen(true);
    navigator.clipboard.writeText(token);
  });

  return (
    <>
      {token
        ? (
          <>
            {token.slice(0, NUM_CHARS)}...{token.slice(-NUM_CHARS)}
          </>
        ) : (
          <>not generated</>
        )}
      <>
        <IconButton disabled={ !token } onClick={ handleCopy } color="primary">
          <CopyIcon />
        </IconButton>
        {
              onRefresh && (
              <IconButton onClick={ onRefresh } color="primary">
                <EditIcon />
              </IconButton>
              )
        }
      </>
      <Snackbar
        message="Copied to clipboard"
        anchorOrigin={ { vertical: 'bottom', horizontal: 'center' } }
        autoHideDuration={ 2000 }
        onClose={ () => setOpen(false) }
        open={ open }
      />
    </>
  );
};

export default memo(CompanyToken);
