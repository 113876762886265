import { OrientationType } from '../LightDetails/constants';
import { BaseFieldset, DirectionalLightFieldset } from '../LightDetails/fieldset';

const getNaturalLightInitialPosition = (sceneBox) => {
  const top = sceneBox.ceiling[0].y;
  const bottom = sceneBox.floor[0].y;
  const left = 0;
  const back = 0;
  const front = 0;
  return {
    [BaseFieldset.Orientation]: OrientationType.Left,
    [DirectionalLightFieldset.Position]: [left, (top + bottom) / 2, (back + front) / 2],
  };
};

export default getNaturalLightInitialPosition;
