import { useCallback, useState, useRef, useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';
import {
  FieldsetContainer,
  TextField,
  HelperText,
  PreviewContainer,
  InputsContainer,
  Fields,
  Field,
} from '@shared/views/form-dialog';
import Preview from './Preview';
import { Fieldset, InputLabelProps, scaleInRange, DEFAULT_SCALE, SCALE_TRESHOLD_PERCENT, MIN_SIZE_FIELD_VALUE } from './fieldset';
import { convertMetersToMillimeters } from '@shared/utils';
import { ModelFormats } from '../constants';
import useFormValues from '@shared/hooks/useFormValues';
import AttributesTable from '@shared/components/attributes-table/AttributesTable';
import { selectAttributeDefinitions } from '@store/attribute-definitions/selectors';
import { makeStyles } from '@material-ui/core/styles';
import AnimationsTextField from './AnimationsTextField';

const useStyles = makeStyles(() => ({
  errorText: {
    fontSize: '1rem',
    marginLeft: '0px',
    marginRight: '0px',
  },
}));

const FormFieldset = (renewable) => {
  const form = useFormContext();
  const {
    setValue,
    control,
    formState: { errors: fieldsErrors },
  } = form;
  const data = useFormValues(form);
  const classes = useStyles();
  const attributeDefinitions = useSelector(selectAttributeDefinitions);

  const [bboxSizes, setBBoxSizes] = useState(null);
  const [scale, setScale] = useState([DEFAULT_SCALE, DEFAULT_SCALE, DEFAULT_SCALE]);
  const prevBBoxSizesRef = useRef(null);

  const resetFields = () => {
    setValue(Fieldset.Width, '');
    setValue(Fieldset.Height, '');
    setValue(Fieldset.Length, '');
  };

  const handleLoadSize = (size) => {
    const width = convertMetersToMillimeters(size.width);
    const height = convertMetersToMillimeters(size.height);
    const length = convertMetersToMillimeters(size.length);

    setBBoxSizes({
      [Fieldset.Width]: width,
      [Fieldset.Height]: height,
      [Fieldset.Length]: length,
    });

    if (data && data.scale) {
      setScale(data.scale);
    }
  };

  const handleChangeSize = useCallback((currentField, onChange) => (evt) => {
    if (bboxSizes) {
      let newValue = +evt.target.value;

      if (newValue < MIN_SIZE_FIELD_VALUE && [Fieldset.Height, Fieldset.Width, Fieldset.Length].includes(currentField)) {
        newValue = MIN_SIZE_FIELD_VALUE;
      }

      setValue(currentField, newValue);

      const fieldScale = (newValue / bboxSizes[currentField]) || DEFAULT_SCALE;
      const newScale = [...scale];

      switch (currentField) {
        case 'width':
          newScale[0] = fieldScale;
          break;
        case 'height':
          newScale[1] = fieldScale;
          break;
        case 'length':
          newScale[2] = fieldScale;
          break;
      }
      setScale(newScale);

      setValue(Fieldset.Scale, newScale);
      onChange(evt);
    }
  }, [bboxSizes, setValue, scale]);

  useEffect(() => {
    if (prevBBoxSizesRef.current && bboxSizes) {
      const prevSizes = prevBBoxSizesRef.current;
      if (
        prevSizes[Fieldset.Width] !== bboxSizes[Fieldset.Width]
        || prevSizes[Fieldset.Height] !== bboxSizes[Fieldset.Height]
        || prevSizes[Fieldset.Length] !== bboxSizes[Fieldset.Length]
      ) {
        resetFields();
      }
    }
    prevBBoxSizesRef.current = bboxSizes;
  }, [bboxSizes]);

  return (
    <FieldsetContainer>
      <PreviewContainer md={ 6 } xs={ 6 }>
        <Preview
          name={ Fieldset.Data }
          fileName={ data.name }
          supportedFormats={ ModelFormats }
          control={ control }
          onLoadSize={ handleLoadSize }
          renewable={ renewable }
          scale={ data.scale }
        />
      </PreviewContainer>
      <InputsContainer>
        <Fields>
          <Field>
            <Controller
              name={ Fieldset.Name }
              control={ control }
              rules={ {
                required: 'Required',
              } }
              render={ ({ field }) => (
                <TextField
                  { ...field }
                  helperText={ fieldsErrors[Fieldset.Name]?.message }
                  label="Name"
                  required
                  error={ !!fieldsErrors[Fieldset.Name] }
                  InputLabelProps={ InputLabelProps }
                  FormHelperTextProps={ { classes: { root: classes.errorText } } }
                />
              ) }
            />
          </Field>
          <Field>
            <Controller
              name={ Fieldset.Width }
              control={ control }
              rules={ {
                min: MIN_SIZE_FIELD_VALUE,
                required: 'Required',
              } }
              render={ ({ field, fieldState: { error } }) => (
                <>
                  <TextField
                    { ...field }
                    type="number"
                    min={ MIN_SIZE_FIELD_VALUE }
                    label="Width, mm"
                    required
                    aria-describedby="width-helper-text"
                    onChange={ handleChangeSize(Fieldset.Width, field.onChange) }
                    disabled={ !bboxSizes }
                    error={ !!error }
                    helperText={ error ? error.message : null }
                    InputLabelProps={ InputLabelProps }
                    FormHelperTextProps={ { classes: { root: classes.errorText } } }
                  />
                  {/*eslint-disable-next-line no-nested-ternary */}
                  { !scaleInRange(scale[0]) && data[Fieldset.Width] && Number(data[Fieldset.Width]) === 0 ? (
                    <HelperText
                      className="error"
                      id="width-helper-text"
                    >
                      Value cannot be 0
                    </HelperText>
                  ) : !scaleInRange(scale[0]) && data[Fieldset.Width]
                    ? (
                      <HelperText
                        className="error"
                        id="width-helper-text"
                      >
                        Width is {SCALE_TRESHOLD_PERCENT}% off of original value: {bboxSizes[Fieldset.Width]}
                      </HelperText>
                    ) : null }
                </>
              ) }
            />
          </Field>
          <Field>
            <Controller
              name={ Fieldset.Height }
              control={ control }
              rules={ {
                min: MIN_SIZE_FIELD_VALUE,
                required: 'Required',
              } }
              render={ ({ field, fieldState: { error } }) => (
                <>
                  <TextField
                    { ...field }
                    type="number"
                    min={ MIN_SIZE_FIELD_VALUE }
                    label="Height, mm"
                    required
                    aria-describedby="height-helper-text"
                    onChange={ handleChangeSize(Fieldset.Height, field.onChange) }
                    disabled={ !bboxSizes }
                    error={ !!error }
                    helperText={ error ? error.message : null }
                    InputLabelProps={ InputLabelProps }
                    FormHelperTextProps={ { classes: { root: classes.errorText } } }
                  />
                  {/*eslint-disable-next-line no-nested-ternary */}
                  { !scaleInRange(scale[1]) && data[Fieldset.Height] && Number(data[Fieldset.Height]) === 0 ? (
                    <HelperText
                      className="error"
                      id="height-helper-text"
                    >
                      Value cannot be 0
                    </HelperText>
                  ) : !scaleInRange(scale[1]) && data[Fieldset.Height]
                    ? (
                      <HelperText
                        className="error"
                        id="height-helper-text"
                      >
                        Height is {SCALE_TRESHOLD_PERCENT}% off of original value: {bboxSizes[Fieldset.Height]}
                      </HelperText>
                    ) : null }
                </>
              ) }
            />
          </Field>
          <Field>
            <Controller
              name={ Fieldset.Length }
              control={ control }
              rules={ {
                min: MIN_SIZE_FIELD_VALUE,
                required: 'Required',
              } }
              render={ ({ field, fieldState: { error } }) => (
                <>
                  <TextField
                    { ...field }
                    type="number"
                    min={ MIN_SIZE_FIELD_VALUE }
                    label="Depth, mm"
                    required
                    aria-describedby="depth-helper-text"
                    onChange={ handleChangeSize(Fieldset.Length, field.onChange, field.value) }
                    disabled={ !bboxSizes }
                    error={ !!error }
                    helperText={ error ? error.message : null }
                    InputLabelProps={ InputLabelProps }
                    FormHelperTextProps={ { classes: { root: classes.errorText } } }
                  />
                  {/*eslint-disable-next-line no-nested-ternary */}
                  { !scaleInRange(scale[2]) && data[Fieldset.Length] && Number(data[Fieldset.Length]) === 0 ? (
                    <HelperText
                      className="error"
                      id="depth-helper-text"
                    >
                      Value cannot be 0
                    </HelperText>
                  ) : !scaleInRange(scale[2]) && data[Fieldset.Length]
                    ? (
                      <HelperText
                        className="error"
                        id="depth-helper-text"
                      >
                        Depth is {SCALE_TRESHOLD_PERCENT}% off of original value: {bboxSizes[Fieldset.Length]}
                      </HelperText>
                    ) : null }
                </>
              ) }
            />
          </Field>
          <Field style={ { position: 'relative' } }>
            <Controller
              name={ Fieldset.Animations }
              control={ form.control }
              defaultValue={ null }
              render={ ({ field }) => (
                <AnimationsTextField
                  { ...field }
                  id={ data.id }
                  label="Animations"
                />
              ) }
            />
          </Field>
          <Field>
            <Controller
              name={ Fieldset.Scale }
              type="hidden"
              control={ control }
              render={ () => {} }
            />
          </Field>
          <Field>
            <Controller
              name={ Fieldset.Attributes }
              control={ control }
              render={ ({ field }) => (
                <AttributesTable
                  attributeDefinitions={ attributeDefinitions }
                  { ...field }
                />
              ) }
            />
          </Field>
        </Fields>
      </InputsContainer>
    </FieldsetContainer>
  );
};

export default FormFieldset;
