import { forwardRef } from 'react';
import * as THREE from 'three';

const SIDE_COLOR = '#D0CFCF';
const FRONT_SIDE_COLOR = '#00C98F';
const SIDE_SIZE = 0.8;

const halfSize = SIDE_SIZE / 2;

const Plane = ({
  name,
  color,
  rotation,
  position,
}) => (
  <mesh
    name={ name }
    position={ position }
    rotation={ rotation }
  >
    <planeGeometry attach="geometry" args={ [SIDE_SIZE, SIDE_SIZE] } />
    <meshLambertMaterial
      attach="material"
      color={ color }
      side={ THREE.DoubleSide }
    />
  </mesh>
);

const Cube = forwardRef(({ rotation }, ref) => (
  <group ref={ ref } rotation={ rotation } position={ [0, 0, halfSize] }>
    <Plane
      name="behind"
      color={ SIDE_COLOR }
      position={ [0, 0, -halfSize] }
      rotation={ [0, 0, 0] }
    />
    <Plane
      name="front"
      color={ FRONT_SIDE_COLOR }
      position={ [0, 0, halfSize] }
      rotation={ [0, 0, 0] }
    />
    <Plane
      name="left"
      color={ SIDE_COLOR }
      position={ [-halfSize, 0, 0] }
      rotation={ [0, Math.PI / 2, 0] }
    />
    <Plane
      name="right"
      color={ SIDE_COLOR }
      position={ [halfSize, 0, 0] }
      rotation={ [0, Math.PI / 2, 0] }
    />
    <Plane
      name="bottom"
      color={ SIDE_COLOR }
      position={ [0, -halfSize, 0] }
      rotation={ [Math.PI / 2, 0, 0] }
    />
    <Plane
      name="top"
      color={ SIDE_COLOR }
      position={ [0, halfSize, 0] }
      rotation={ [Math.PI / 2, 0, 0] }
    />
  </group>
));

export default Cube;
