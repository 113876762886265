import * as THREE from 'three';
import { Canvas, CanvasProps } from '@react-three/fiber';
import { Plane, PerspectiveCamera } from '@react-three/drei';
import { SceneBox } from '@zolak/zolak-viewer';

import {ZKPosition, ZKViewport} from '../types';
import { rad2deg, deg2rad } from '../utils';
import {useMemo} from "react";

interface EnvironmentProps {
    url?: string | null
}

interface SceneProps extends CanvasProps {
    interior: {
      layout: {
        viewport: ZKViewport
        floorPoints: ZKPosition[]
      }
    }
    width: number
    height: number
    lightColor?: string | null
    lightIntensity?: number | null
}

const gl = {
  outputColorSpace: THREE.SRGBColorSpace,
  toneMapping: THREE.ACESFilmicToneMapping,
  toneMappingExposure: 0.6,
  preserveDrawingBuffer: true,
};

const shadows = { type: THREE.PCFSoftShadowMap };

function Scene({
  interior,
  width,
  height,
  lightColor = '#FFFFFF',
  lightIntensity = 1000,
  children,
  style,
  ...options
}: SceneProps) {
  const { fieldOfView, eulerAngles: { pitch, roll }, position } = interior.layout.viewport;

  const layers = useMemo(() => {
    const result = new THREE.Layers();
    result.enable(1);
    return result;
  }, []);

  const cameraProps = useMemo(() => {
    return {
      near: 0.01,
      far: 100,
      fov: rad2deg(fieldOfView.y),
      aspect: width / height,
      position: new THREE.Vector3(0, position.y, 0),
      rotation: new THREE.Euler(pitch, 0, roll),
    }
  }, [fieldOfView.x, fieldOfView.y, position.y, pitch, roll, width, height])

  return (
    <div style={style}>
      <Canvas
        gl={gl}
        shadows={ shadows }
        style={ {
          pointerEvents: 'none',
        }}
        { ...options }
      >
        <PerspectiveCamera makeDefault {...cameraProps} layers={layers}/>
        <Plane
          args={ [100, 100] }
          position={ [0, 0, 0] }
          rotation={ [deg2rad(-90), 0, 0] }
          receiveShadow
        >
          <shadowMaterial opacity={ 0.4 } />
        </Plane>
        <ambientLight
          color={ lightColor! }
          intensity={ lightIntensity! * 0.001 }
        />
        <directionalLight
          color={ lightColor! }
          intensity={ lightIntensity! * 0.0033 }
          shadow-mapSize-height={ 256 }
          shadow-mapSize-width={ 256 }
          castShadow
        />
        <SceneBox interior={ interior } />
        {children}
      </Canvas>
    </div>
  );
}

export default Scene;
