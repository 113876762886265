import {
  LOAD_ATTRIBUTE_DEFINITIONS,
  CLEAR_ATTRIBUTE_DEFINITIONS,
  ATTRIBUTES_FILTER,
} from './actions';

import { translationsToFields } from '@shared/utils/translations';

export const Status = {
  Idle: 'idle',
  Loading: 'loading',
};

const initialState = {
  items: null,
  status: Status.Idle,
  error: null,
  showFilter: false,
  filterValue: [],
};

const attributeDefinitionsReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_ATTRIBUTE_DEFINITIONS.START:
      return {
        ...state,
        status: Status.Loading,
        entityTypes: action.entityTypes,
      };
    case LOAD_ATTRIBUTE_DEFINITIONS.SUCCESS: {
      const attributeDefinitions = action.payload.attributeDefinitions
        .map((ad) => {
          ad.translatedFields = translationsToFields(ad.translations);
          ad.attributes = ad.attributes.map((attribute) => {
            attribute.translatedFields = translationsToFields(attribute.translations);
            delete attribute.translations;
            return attribute;
          });
          delete ad.translations;
          return ad;
        });
      return {
        ...state,
        status: Status.Idle,
        error: null,
        items: attributeDefinitions,
      };
    }
    case LOAD_ATTRIBUTE_DEFINITIONS.FAIL:
      return {
        ...state,
        status: Status.Idle,
        error: action.payload.error,
      };
    case CLEAR_ATTRIBUTE_DEFINITIONS:
      return initialState;
    case ATTRIBUTES_FILTER.SHOW:
      return {
        ...state,
        showFilter: true,
      };
    case ATTRIBUTES_FILTER.HIDE:
      return {
        ...state,
        showFilter: false,
      };
    case ATTRIBUTES_FILTER.TOGGLE:
      return {
        ...state,
        showFilter: !state.showFilter,
      };
    case ATTRIBUTES_FILTER.SET_VALUE:
      return {
        ...state,
        filterValue: action.payload.filterValue,
      };

    default:
      return state;
  }
};

export default attributeDefinitionsReducer;
