import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';

const useStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.common.white,
    background: theme.palette.common.icon,
    width: '2.6rem',
    height: '2.6rem',
    borderRadius: '.4rem',

    '&:hover': {
      background: theme.palette.common.icon,
      opacity: 0.8,
    },
  },
  disabled: {
    '&&': {
      color: theme.palette.common.white,
      background: theme.palette.common.icon,
    },
  },
}));

const AddButton = ({
  disabled,
  onClick,
}) => {
  const classes = useStyles();

  return (
    <IconButton
      classes={ classes }
      size="small"
      disabled={ disabled }
      onClick={ onClick }
    >
      <AddIcon color="inherit" />
    </IconButton>
  );
};

export default AddButton;
