/* eslint-disable max-len */
import { makeStyles } from '@material-ui/core';
import SvgIcon from '@material-ui/core/SvgIcon';
import { IconSize, DefaultIconSizeValue } from '../../../../../resources/icons/constants';

const useStyles = makeStyles(() => ({
  root: {
    fill: 'none',
  },
}));

const SpotCenterIcon = ({ size = IconSize.Medium, selected = false }) => {
  const classes = useStyles();
  return (
    <SvgIcon
      style={ { width: DefaultIconSizeValue[size], height: DefaultIconSizeValue[size] } }
      className={ classes.root }
      viewBox="0 0 38 31"
    >
      <path d="M38 28H0L13 16L13.5 16.5L15 17.5L17.5 18.5H19.5L21 18L23 17L24.5 16L38 28Z" fill={ selected ? '#f0f0f0' : '#C2CFE0' } />
      <ellipse cx="19" cy="28" rx="19" ry="3" fill={ selected ? '#406AFF' : '#334D6E' } />
      <circle cx="18.6777" cy="11.3225" r="7.24194" stroke={ selected ? '#f0f0f0' : '#C2CFE0' } />
      <path d="M18.6776 6.16131C17.3087 6.16131 15.9959 6.70508 15.028 7.67301C14.0601 8.64094 13.5163 9.95374 13.5163 11.3226C13.5163 12.6915 14.0601 14.0042 15.028 14.9722C15.9959 15.9401 17.3087 16.4839 18.6776 16.4839L18.6776 11.3226V6.16131Z" fill={ selected ? '#f0f0f0' : '#C2CFE0' } />
      <path d="M9 10.6772H11.5806" stroke={ selected ? '#f0f0f0' : '#C2CFE0' } strokeLinecap="round" />
      <path d="M26.4194 10.6772H29.0001" stroke={ selected ? '#f0f0f0' : '#C2CFE0' } strokeLinecap="round" />
      <path d="M18.6774 21V18.4194" stroke={ selected ? '#f0f0f0' : '#C2CFE0' } strokeLinecap="round" />
      <path d="M18.6774 3.58057V0.999921" stroke={ selected ? '#f0f0f0' : '#C2CFE0' } strokeLinecap="round" />
      <path d="M11.6068 18.0708L13.4315 16.246" stroke={ selected ? '#f0f0f0' : '#C2CFE0' } strokeLinecap="round" />
      <path d="M23.9243 5.75342L25.749 3.92863" stroke={ selected ? '#f0f0f0' : '#C2CFE0' } strokeLinecap="round" />
      <path d="M25.7487 18.0708L23.9239 16.246" stroke={ selected ? '#f0f0f0' : '#C2CFE0' } strokeLinecap="round" />
      <path d="M13.4312 5.75342L11.6064 3.92863" stroke={ selected ? '#f0f0f0' : '#C2CFE0' } strokeLinecap="round" />
    </SvgIcon>
  );
};

export default SpotCenterIcon;
