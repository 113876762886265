import RangeSlider from '../RangeSlider';

const IntensityOption = ({
  name,
  value,
  onChange,
  max = 100,
  step,
}) => (
  <RangeSlider
    label="Light Intensity"
    name={ name }
    min={ 0 }
    max={ max }
    step={ step }
    value={ value }
    onChange={ onChange }
    displayValues={ false }
    displayThumbValue
  />
);

export default IntensityOption;
