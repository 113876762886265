import React, { forwardRef, memo, useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';

import { TextField } from '@shared/views/form-dialog';
import UploadIcon from '@resources/icons/UploadIcon';
import DeleteIcon from '@resources/icons/DeleteIcon';
import { loadEnvironmentsList } from '@store/scenes/actions';

import { deleteModelAnimations } from '@store/models/actions';

const useStyles = makeStyles({
  textFieldRoot: {
    "& > div.MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root']": {
      paddingRight: '9px',
      '& button': {
        order: 3,
      },
      '& > div.MuiAutocomplete-endAdornment': {
        position: 'relative',
        order: 2,
      },
    },
  },
  option: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    '& > img': {
      width: '40px',
      height: '40px',
    },
  },
});

const AnimationsTextField = forwardRef(({
  id,
  name,
  label,
  onChange,
  value,
  required,
}, ref) => {
  const dispatch = useDispatch();
  const classes = useStyles();

  useEffect(() => {
    dispatch(loadEnvironmentsList());
  }, [dispatch]);

  const handleDelete = useCallback((value, event) => {
    event.stopPropagation();
    if (value && !value.name) {
      dispatch(deleteModelAnimations(id));
    }
    onChange('');
  }, [onChange, id, dispatch]);

  const handleUploadAnimations = () => {
    const input = document.createElement('input');
    input.type = 'file';
    input.onchange = () => {
      const files = Array.from(input.files);
      const reader = new FileReader();
      const file = files[0];
      reader.readAsDataURL(file);
      reader.onload = () => {
        const url = window.URL.createObjectURL(file);
        onChange({ url, name: file.name, type: file.name.split('.').pop(), file });
      };
    };
    input.click();
  };

  return (
    <>
      <TextField
        classes={ {
          root: classes.textFieldRoot,
        } }
        label={ label }
        variant="outlined"
        required={ required }
        InputProps={ {
          value: value?.name || value || '',
          name,
          readOnly: true,
          endAdornment: (
            <>
              {
                value && (
                <IconButton size="small" onClick={ handleDelete.bind(this, value) }>
                  <DeleteIcon />
                </IconButton>
                )
              }
              <IconButton size="small" onClick={ handleUploadAnimations }>
                <UploadIcon />
              </IconButton>
            </>
          ),
        } }
      />
    </>
  );
});

export default memo(AnimationsTextField);
