import React, { useState, useEffect } from 'react';

import Checkbox from '@shared/components/checkbox';
import FormControlLabel from '@shared/components/form-control-label';

const ShadowController = (props) => {
  const { name, value, onChange } = props;
  const [checkboxValue, setCheckboxValue] = useState(value);

  const handleShadowCheck = () => {
    setCheckboxValue((prevShadow) => !prevShadow);
  };

  useEffect(() => {
    onChange({ value: checkboxValue }, name);
  }, [checkboxValue]);

  return (
    <>
      <FormControlLabel
        control={ (
          <Checkbox
            name={ name }
            checked={ checkboxValue }
            onChange={ handleShadowCheck }
          />
        ) }
        label="Visibility of shadows spots"
      />
    </>
  );
};

export default ShadowController;
