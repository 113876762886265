import classNames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    overflowY: 'auto',
    overflowX: 'hidden',
  },
}));

const Pane = ({ children, className }) => {
  const classes = useStyles();

  return <div className={ classNames(className, classes.root) }>{children}</div>;
};

export default Pane;
