import { FormDialog } from '@shared/views/form-dialog';
import MaterialsForm from './MaterialsForm';
import FormFieldset from '../FormFieldset';

const MaterialsFormDialog = ({
  open,
  data,
  onClose,
}) => {
  const handleSubmit = (data) => {
    onClose(data);
  };

  const handleCancel = () => {
    onClose();
  };

  return (
    <FormDialog
      open={ open }
      Form={ MaterialsForm }
      FormProps={ {
        open,
        data,
        onSubmit: handleSubmit,
      } }
      title="Select Materials"
      content={ (
        <FormFieldset
          data={ data }
        />
      ) }
      onCancel={ handleCancel }
      onClose={ onClose }
      saveButtonText="Select"
      isDrawer
    />
  );
};

export default MaterialsFormDialog;
