import { Axis } from '../types';

export function colorForAxis(axis: Axis): string {
  switch (axis) {
    case Axis.X:
      return '#FF0036';
    case Axis.Y:
      return '#C9FF00';
    case Axis.Z:
      return '#006EFF';
  }
}

export function floorColor(): string {
  return '#6F00FF';
}
