/* eslint-disable max-len */
import SvgIcon from '@material-ui/core/SvgIcon';

const SizeIcon = (props) => (
  <SvgIcon viewBox="0 0 18 19" fill="none" { ...props }>
    <path
      d="M13.7812 18.5H4.21875C1.89253 18.5 0 16.6075 0 14.2812V4.71875C0 2.39253 1.89253 0.5 4.21875 0.5H13.7812C16.1075 0.5 18 2.39253 18 4.71875V14.2812C18 16.6075 16.1075 18.5 13.7812 18.5ZM4.21875 1.90625C2.66794 1.90625 1.40625 3.16794 1.40625 4.71875V14.2812C1.40625 15.8321 2.66794 17.0938 4.21875 17.0938H13.7812C15.3321 17.0938 16.5938 15.8321 16.5938 14.2812V4.71875C16.5938 3.16794 15.3321 1.90625 13.7812 1.90625H4.21875ZM14.2734 3.3125C13.8851 3.3125 13.5703 3.62729 13.5703 4.01562V5.14062H12.0586V4.01562C12.0215 3.08272 10.6892 3.08342 10.6523 4.01562V5.14062H9.07031V4.01562C9.03319 3.08272 7.70087 3.08342 7.66406 4.01562V5.14062H6.04688V4.01562C6.00975 3.08272 4.67743 3.08342 4.64062 4.01562V5.14062H3.51562C2.58272 5.17775 2.58342 6.51007 3.51562 6.54688H4.64062V8.19922H3.51562C2.58272 8.23634 2.58342 9.56866 3.51562 9.60547H4.64062V11.1875H3.51562C2.58272 11.2246 2.58342 12.5569 3.51562 12.5938H4.64062V14.1055H3.51562C2.58272 14.1426 2.58342 15.4749 3.51562 15.5117H4.64062C5.41603 15.5117 6.04688 14.8809 6.04688 14.1055V6.54688H13.5703C14.3457 6.54688 14.9766 5.91603 14.9766 5.14062V4.01562C14.9766 3.62729 14.6618 3.3125 14.2734 3.3125Z"
      fill="#334D6E"
    />
  </SvgIcon>
);

export default SizeIcon;
