import { forwardRef, useMemo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import MuiAutocomplete from '@material-ui/lab/Autocomplete';

const useAutocompleteStyles = makeStyles((theme) => ({
  inputRoot: {
    '&.MuiOutlinedInput-root': {
      padding: theme.spacing(0, 1.125),
    },
  },
  tag: {
    lineHeight: '2.1rem',
    background: 'transparent',

    '& svg': {
      opacity: 0,
      width: '1.4rem',
      marginLeft: '-1rem',
      marginRight: '-.1rem',
    },

    '&:hover svg': {
      opacity: 1,
    },
  },
}));

const useChipStyles = makeStyles(() => ({
  root: {
    '&::before': {
      content: "'#'",
      fontSize: '1.7rem',
      marginRight: '-1rem',
    },
  },
}));

const Autocomplete = forwardRef(
  ({ className, ChipProps: chipProps = {}, ...rest }, ref) => {
    const autocompleteClasses = useAutocompleteStyles();
    const chipClasses = useChipStyles();

    const ChipProps = useMemo(
      () => ({
        ...chipProps,
        classes: {
          ...(chipProps.classes || {}),
          ...chipClasses,
        },
      }),
      [chipProps, chipClasses],
    );

    return (
      <MuiAutocomplete
        className={ className }
        classes={ autocompleteClasses }
        ChipProps={ ChipProps }
        disablePortal
        { ...rest }
        ref={ ref }
      />
    );
  },
);

export default Autocomplete;
