import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import Typography from '../typography';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'absolute',
    left: 0,
    top: theme.spacing(1.5),
    padding: theme.spacing(0.8, 1.5),
    background: theme.palette.common.green,
    fontWeight: 600,
    color: theme.palette.common.darkGrey,
    boxShadow: '0px 1px 1px rgba(0, 0, 0, 0.25)',
  },
}));

const CardLabel = ({ children, className }) => {
  const classes = useStyles();
  return (
    <Typography variant="h5" paragraph className={ classNames(classes.root, className) }>
      {children}
    </Typography>
  );
};

export default CardLabel;
