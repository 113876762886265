import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import filterData from '@app/filter/filterData';
import ModelListGrid from './grid';
import { selectSortingId } from '@store/app/selectors';
import sortData from '@app/sort';
import { selectAttributesFilterValue } from '@store/attribute-definitions/selectors';
import FilterContainer from '@shared/components/attributes-filter/FilterContainer';
import filterByAttributes from '@shared/components/attributes-filter';

const selectModels = (state) => state.models.models;

const selectSearchString = (state) => state.app.searchString;

const ModelList = () => {
  const models = useSelector(selectModels);
  const sortingId = useSelector(selectSortingId);
  const searchString = useSelector(selectSearchString);
  const attributesFilterValue = useSelector(selectAttributesFilterValue);

  const items = useMemo(() => {
    const filteredItems = filterData(models.items, searchString);
    const filteredByAttributesItems = filterByAttributes(filteredItems, attributesFilterValue);
    return sortData(filteredByAttributesItems, sortingId);
  }, [models.items, searchString, sortingId, attributesFilterValue]);

  return (
    <FilterContainer>
      <ModelListGrid data={ items } />
    </FilterContainer>
  );
};

export default ModelList;
