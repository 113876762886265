import { useEffect } from 'react';
import { actions } from '@store/app';
import withAuth from '@shared/components/route/withAuth';
import useActions from '@shared/hooks/useActions';

const Home = withAuth(() => {
  const { showMenu } = useActions(actions);

  useEffect(() => {
    setTimeout(() => {
      showMenu();
    }, 3000);
  }, []);

  return (
    <div
      style={ {
        width: '100%',
        height: '100%',
        background: 'url(/assets/images/home.svg) no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center center',
      } }
    />
  );
});

export default Home;
