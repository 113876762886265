import { forwardRef, memo, useCallback, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import classNames from 'classnames';

import Autocomplete from '@shared/components/autocomplete/Autocomplete';
import { TextField } from '@shared/views/form-dialog';
import Paper from '@shared/components/paper';
import Button from '@shared/components/button';
import PlusIcon from '@resources/icons/PlusIcon';
import GroupModal from '@pages/products/modal/group/GroupModal';
import { ActionType } from '@pages/companies/modal';
import useAlertControl, { Types } from '@shared/components/card-controls/useAlertControl';
import AlertDialog from '@shared/components/card-controls/AlertDialog';
import useActions from '@shared/hooks/useActions';
import { actions } from '@store/material-groups';
import DeleteIcon from '@resources/icons/DeleteIcon';
import EditIcon from '@resources/icons/EditIcon';
import InfoIcon from '@resources/icons/InfoIcon';

const useStyles = makeStyles((theme) => ({
  createButton: {
    maxWidth: '100%',
    height: '40px',
    lineHeight: '40px',
    borderRadius: '0',
  },
  outerIcon: {
    background: 'white',
    color: 'var(--colorGreen) !important',
    width: '20px',
    height: '20px',
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '4px',
    margin: '0 10px 0 0 !important',
  },
  option: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
  },
}));

const GroupTextField = forwardRef(({
  name,
  label,
  options,
  required,
  helperText,
  error,
  value,
  onChange,
  onSelect,
}, ref) => {
  const classes = useStyles();
  const [modalOpen, setModalOpen] = useState(false);
  const [modalType, setModalType] = useState(null);
  const [modalData, setModalData] = useState(null);
  const { deleteGroup } = useActions(actions);

  const [
    alertOpen,
    { handleAlertControlClick, handleAgree, handleDisagree, ariaAction, title },
  ] = useAlertControl();

  const getOptionObject = useCallback((option) => {
    const object = options.find((op) => op.id === (option?.id || option));
    if (!object) {
      return options.find((op) => op.id === undefined);
    }
    return object;
  }, [options]);

  const handleChange = useCallback((_, data) => {
    onChange(getOptionObject(data));
    onSelect(getOptionObject(data));
  }, [onChange, onSelect, getOptionObject]);

  const onGroupCreate = () => {
    setModalOpen(true);
    setModalData(null);
    setModalType(ActionType.CREATE);
  };

  const onGroupEdit = (group, event) => {
    event.stopPropagation();
    setModalOpen(true);
    setModalType(ActionType.UPDATE);
    setModalData(group);
  };

  const onGroupDelete = (group, event) => {
    event.stopPropagation();
    handleAlertControlClick(Types.Delete, () => deleteGroup(group))();
  };

  const onModalClose = (data) => {
    setModalOpen(false);
    if (data.id) {
      const newValue = {
        ...value,
        ...data,
      };
      onChange(newValue);
      onSelect(newValue);
    }
  };

  const renderInput = useCallback(({ inputProps, ...params }) => (
    <TextField
      { ...params }
      label={ label }
      variant="outlined"
      error={ error }
      helperText={ helperText }
      required={ required }
      InputProps={ {
        ...params.InputProps,
      } }
      /* eslint-disable-next-line react/jsx-no-duplicate-props */
      inputProps={ { ...inputProps, value: inputProps?.value } }
    />
  ), [label, error, helperText]);

  const getOptionLabel = useCallback((option) => {
    return option?.name || getOptionObject(option).name;
  }, [getOptionObject]);

  const getOptionSelected = useCallback((option, value) => {
    return option.id === (value?.id || getOptionObject(value).id);
  }, [getOptionObject]);

  return (
    <>
      <Autocomplete
        disablePortal={ false }
        options={ options }
        getOptionLabel={ getOptionLabel }
        getOptionSelected={ getOptionSelected }
        onChange={ handleChange }
        PaperComponent={ ({ children, ...paperProps }) => (
          <Paper
            elevation={ 8 }
            { ...paperProps }
          >
            {children}
            <Button className={ classes.createButton } onMouseDown={ onGroupCreate }>
              <span className={ classes.outerIcon }>
                <PlusIcon />
              </span>
              Create New
            </Button>
          </Paper>
        ) }
        ref={ ref }
        value={ value }
        renderInput={ renderInput }
        renderOption={ (props, { selected }) => (
          <div className={ classNames({ selected }, classes.option) }>
            { props.name }
            {
                !!props.id && (
                <div>
                  <IconButton
                    size="small"
                    onClick={ onGroupEdit.bind(null, props) }
                  >
                    <EditIcon />
                  </IconButton>
                  <IconButton
                    size="small"
                    onClick={ onGroupDelete.bind(null, props) }
                  >
                    <DeleteIcon />
                  </IconButton>
                </div>
                )
            }
            {
              !props.id && (
              <IconButton
                size="small"
              >
                <InfoIcon />
              </IconButton>
              )
            }
          </div>
        ) }
      />
      <GroupModal
        open={ modalOpen }
        type={ modalType }
        group={ modalData }
        onClose={ onModalClose }
      />
      <AlertDialog
        open={ alertOpen }
        ariaAction={ ariaAction }
        title={ title }
        onAgree={ handleAgree }
        onDisagree={ handleDisagree }
      />
    </>
  );
});

export default memo(GroupTextField);
