import ElectricLightBehind from './icons/ElectricLightBehind';
import ElectricLightLeft from './icons/ElectricLightLeft';
import ElectricLightRight from './icons/ElectricLightRight';
import ElectricLightTop from './icons/ElectricLightTop';
import NaturalLightBehind from './icons/NaturalLightBehind';
import NaturalLightFront from './icons/NaturalLightFront';
import NaturalLightLeft from './icons/NaturalLightLeft';
import NaturalLightRight from './icons/NaturalLightRight';

export const LightType = {
  SpotLight: 'SpotLight',
  DirectionalLight: 'DirectionalLight',
  PointLight: 'PointLight',
};

export const ElectricLightTypeLabel = {
  SpotLight: 'Aimed',
  PointLight: 'Diffused',
};

export const LightGroup = {
  Electric: {
    key: 'electric',
    label: 'Electric (coming soon)',
    disabled: true,
    //Electric Light
  },
  Natural: {
    key: 'natural',
    label: 'Natural',
  },
};

export const LightBasePreset = {
  Custom: 'Custom',
};

export const SpotLightPreset = {
  Basic1: 'Basic1',
  Basic2: 'Basic2',
};

export const DirectionalLightPreset = {
  Sun: 'Sun',
  Moon: 'Moon',
};

export const PointLightPreset = {
  Basic1: 'Basic1',
  Basic2: 'Basic2',
};

export const OrientationType = {
  Behind: 'behind',
  Top: 'top',
  Front: 'front',
  Left: 'left',
  Right: 'right',
};

export const ORIENTATIONS_GENERIC_DATA = {
  // Labels for Front/Behind were swapped to be more obvious for users
  [OrientationType.Behind]: { value: OrientationType.Behind, title: 'Behind' },
  [OrientationType.Top]: { value: OrientationType.Top, title: 'Top' },
  [OrientationType.Front]: { value: OrientationType.Front, title: 'Front' },
  [OrientationType.Left]: { value: OrientationType.Left, title: 'Left' },
  [OrientationType.Right]: { value: OrientationType.Right, title: 'Right' },
};

export const ELECTRIC_LIGHT_ORIENTATION_OPTIONS = [
  {
    ...ORIENTATIONS_GENERIC_DATA[OrientationType.Top],
    icon: ElectricLightTop,
  },
  {
    ...ORIENTATIONS_GENERIC_DATA[OrientationType.Behind],
    icon: ElectricLightBehind,
  },
  {
    ...ORIENTATIONS_GENERIC_DATA[OrientationType.Left],
    icon: ElectricLightLeft,
  },
  {
    ...ORIENTATIONS_GENERIC_DATA[OrientationType.Right],
    icon: ElectricLightRight,
  },
];

export const NATURAL_LIGHT_ORIENTATION_OPTIONS = [
  {
    ...ORIENTATIONS_GENERIC_DATA[OrientationType.Left],
    icon: NaturalLightLeft,
  },
  {
    ...ORIENTATIONS_GENERIC_DATA[OrientationType.Right],
    icon: NaturalLightRight,
  },
  {
    ...ORIENTATIONS_GENERIC_DATA[OrientationType.Behind],
    icon: NaturalLightBehind,
  },
  {
    ...ORIENTATIONS_GENERIC_DATA[OrientationType.Front],
    icon: NaturalLightFront,
  },
];

export const DEFAULT_SHADOW_RADIUS = 2;
export const DEFAULT_SHADOW_BLUR = 5;
export const DEFAULT_SHADOW_BIAS = -0.002;
