import { useState, useEffect } from 'react';
import { Group } from 'react-konva';
import { Point2D, Line2D } from '../types';
import { Point2 } from '../utils';

import LineSegment from './LineSegment';

const DEFAULT_COLOR = '#359DF9';
const DEFAULT_MIDPOINT = { x: 100, y: 100 };
const DEFAULT_MARGIN = 50;

function calcPoint1(midpoint: Point2D, vanishingPoint: Point2D, m: number): Point2D {
  return Point2.add(Point2.multiply(Point2.normalized(Point2.substract(vanishingPoint, midpoint)), m), midpoint);
}

interface ReferenceLengthControlProps {
    vanishingPoint: Point2D
    midpoint?: Point2D | null
    margin?: number | null
    color?: string | null
    onReady?: (referenceLine: Line2D) => void
    onEmpty?: () => void
    point0?: Point2D
    point1?: Point2D
    length?: number,
    scale: number,
}

function ReferenceLengthControl({
  vanishingPoint,
  midpoint = DEFAULT_MIDPOINT,
  margin = DEFAULT_MARGIN,
  color = DEFAULT_COLOR,
  onReady,
  onEmpty,
  point0,
  point1,
  scale,
}: ReferenceLengthControlProps) {
  const [p0, setP0] = useState<Point2D>(point0 ?? midpoint!);
  const [p1, setP1] = useState<Point2D>(point1 ?? calcPoint1(midpoint!, vanishingPoint, margin!));
  const [isDragging, setDragging] = useState(false);

  useEffect(() => {
    if (Point2.distance(p0, p1) > 0) {
      onReady?.([p0, p1])
    }
  }, [p0, p1, isDragging]);

  useEffect(() => {
    if (vanishingPoint) {
      setP1(Point2.project(p1, [p0, vanishingPoint]));
    }
  }, [vanishingPoint])

  useEffect(() => {
    setP0(point0 ?? midpoint!)
    setP1(point1 ?? calcPoint1(midpoint!, vanishingPoint, margin!));
  }, [])

  return (
    <Group>
      <LineSegment
        color={ color }
        points={ [p0, p1] }
        endShape="notch"
        scale={ scale }
        onChanged={ ([p0, p1], isDragging) => {
          setDragging(isDragging);
          setP0(p0);
          if (vanishingPoint) {
            setP1(Point2.project(p1, [p0, vanishingPoint]));
          }
        } }
      />
    </Group>
  );
}

export default ReferenceLengthControl;
