import { createAction, createSlice } from '@reduxjs/toolkit';

const initialState = {
  groups: [{
    id: undefined,
    name: 'Non-configurable Part',
  }],
};

const loadGroups = createAction('groups/loadGroups');
const createGroup = createAction('groups/createGroup');
const updateGroup = createAction('groups/updateGroup');
const deleteGroup = createAction('groups/deleteGroup');

const materialGroupsSlice = createSlice({
  name: 'groups',
  initialState,
  reducers: {
    renderGroups: (state, action) => {
      state.groups = [...action.payload, initialState.groups[0]];
    },
  },
});

export const actions = {
  ...materialGroupsSlice.actions,
  loadGroups,
  createGroup,
  updateGroup,
  deleteGroup,
};

export default materialGroupsSlice.reducer;
