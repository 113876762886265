import { useState, useEffect, useMemo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import SliderContainer from '@shared/components/slider/SliderContainer';
import Slider from '@shared/components/slider/Slider';
import Range from '@shared/components/slider/Range';
import KelvinColor from '@shared/utils/KelvinColor';
import useDebouncedCallback from '@shared/hooks/useDebouncedCallback';
import SubTitle from '../SubTitle';

const SLIDER_COLOR_CSS_VAR = '--color-slider-value';
const SLIDER_GRADIENT_CSS_VAR = '--color-slider-gradient';

const useStyles = makeStyles(() => ({
  colorIndicator: {
    display: 'inline-block',
    width: '1.3rem',
    height: '1.3rem',
    borderRadius: '50%',
  },
}));

const useSliderStyles = makeStyles((theme) => ({
  rail: {
    background: `var(${SLIDER_GRADIENT_CSS_VAR})`,
    border: `1px solid ${theme.palette.common.divider}`,
    height: theme.spacing(0.3),
  },
  track: {
    background: 'transparent',
  },
  thumb: {
    position: 'relative',
    '&::before': {
      content: '""',
      position: 'absolute',
      left: '50%',
      top: theme.spacing(-2),
      transform: 'translateX(-50%)',
      width: theme.spacing(1.5),
      height: theme.spacing(1.5),
      border: `1px solid ${theme.palette.common.divider}`,
      borderRadius: '50%',
      background: `var(${SLIDER_COLOR_CSS_VAR})`,
    },
  },
}));

const LightDetails = ({
  name,
  label,
  kelvinColor = new KelvinColor('ffffff'),
  onChange,
  displayValues = true,
}) => {
  const [kelvin, setKelvin] = useState(kelvinColor.kelvin);

  const variables = useMemo(() => {
    const start = kelvinColor.firstColor;
    const middle = kelvinColor
      .getColorByKelvin(kelvinColor.firstKelvin + (kelvinColor.lastKelvin - kelvinColor.firstKelvin) / 2);
    const end = kelvinColor.lastColor;
    const current = kelvinColor.getColorByKelvin(kelvin);
    return {
      [SLIDER_COLOR_CSS_VAR]: `#${current}`,
      [SLIDER_GRADIENT_CSS_VAR]: `linear-gradient(90deg, #${start} 0%, #${middle} 50%, #${end} 100%)`,
    };
  }, [kelvinColor, kelvin]);

  const classes = useStyles();
  const sliderClasses = useSliderStyles();


  useEffect(() => {
    setKelvin(kelvinColor.kelvin);
  }, [kelvinColor]);

  const onDebouncedChange = useDebouncedCallback((value) => {
    onChange({ value }, name);
  }, 400);

  const handleChange = ({ value }) => {
    const colorByKelvin = new KelvinColor(kelvinColor.getColorByKelvin(value));
    setKelvin(value);
    onDebouncedChange(colorByKelvin);
  };

  return (
    <SliderContainer style={ variables }>
      <SubTitle id={ `${name}-slider` }>
        {label}
      </SubTitle>
      {displayValues && (
        <Range
          min={ (
            <span
              className={ classes.colorIndicator }
              style={ { backgroundColor: `#${kelvinColor.getColorByKelvin(kelvin)}` } }
            />
          ) }
          max={ (
            <span
              className={ classes.colorIndicator }
              style={ { backgroundColor: `#${kelvinColor.lastColor}` } }
            />
          ) }
        />
      )}
      <Slider
        name={ name }
        value={ kelvin }
        aria-labelledby={ `${name}-slider` }
        step={ kelvinColor.step }
        min={ kelvinColor.firstKelvin }
        max={ kelvinColor.lastKelvin }
        onChange={ handleChange }
        classes={ sliderClasses }
      />
    </SliderContainer>
  );
};

export default LightDetails;
