import { useWatch } from 'react-hook-form';

const useFormValues = (form) => {
  const { getValues } = form;

  return {
    ...useWatch({ control: form.control }), // subscribe to form value updates

    ...getValues(), // always merge with latest form values
  };
};

export default useFormValues;
