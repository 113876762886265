import { createAction, createSlice } from '@reduxjs/toolkit';

import { SortId } from '@app/sort/sortValues';

const initialState = {
  companies: [],
  sorting: {
    id: SortId.AtoZ,
    label: 'A-Z',
  },
};

const loadCompanies = createAction('companies/loadCompanies');
const createCompany = createAction('companies/createCompany');
const updateCompany = createAction('companies/updateCompany');
const deleteCompany = createAction('companies/deleteCompany');
const generateApiToken = createAction('companies/generateApiToken');

const companiesSlice = createSlice({
  name: 'companies',
  initialState,
  reducers: {
    renderCompanies: (state, action) => {
      state.companies = action.payload;
    },
  },
});

export const actions = {
  ...companiesSlice.actions,
  loadCompanies,
  createCompany,
  updateCompany,
  deleteCompany,
  generateApiToken,
};

export default companiesSlice.reducer;
