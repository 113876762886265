import Grid from '@material-ui/core/Grid';
import SubTitle from './SubTitle';
import AxisFields from './AxisFields';
import { displayValue } from '@shared/utils';

const Position = ({
  name,
  value,
  minX,
  maxX,
  minY,
  maxY,
  minZ,
  maxZ,
  label,
  disabled,
  onChange,
  step,
}) => {
  const handleChange = ({ name, value }) => {
    onChange({
      value,
    }, name);
  };

  return (
    <Grid container spacing={ 1 }>
      <Grid item xs={ 12 }>
        <SubTitle>
          {label}
        </SubTitle>
      </Grid>
      <Grid item xs={ 12 }>
        <AxisFields
          name={ name }
          value={ value }
          minX={ minX }
          maxX={ maxX }
          minY={ minY }
          maxY={ maxY }
          minZ={ minZ }
          maxZ={ maxZ }
          step={ step }
          disabled={ disabled }
          displayValue={ displayValue }
          onChange={ handleChange }
        />
      </Grid>
    </Grid>
  );
};

export default Position;
