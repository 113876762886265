import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    height: '100%',
    position: 'relative',
  },
}));

const Content = ({ children }) => {
  const classes = useStyles();

  return <div className={ classes.root }>{children}</div>;
};

export default Content;
