import { UserRole } from '@shared/models';

/* eslint-disable import/prefer-default-export */
export const ObjectType = {
  Light: 'light',
  Model: 'model',
};

export const EntityTypes = {
  Model: 'Model',
  Product: 'Product',
  Interior: 'Interior', // scene model
  Background: 'Background', // interior model
  Material: 'Material',
};

export const HttpStatusCode = {
  Success: 200,
  NotAuthorized: 401,
  NotFound: 404,
};

export const WireframeMaterialName = '__ZolakWireframe__';
export const DefaultMaterialName = '__ZolakMaterial__';

export const defaultTheme = {
  color1: '#00C98F', //Green
  color1Hover: '#05DB9D', //Lightgreen
  color2: '#FFFFFF', // White
  color2Hover: '#FFFFFF', // White
  color3: '#102035', //Dark, usually for texts
  color3Hover: '#00C98F', //Green
  color4: '#334D6E', // Another dark
  color4Hover: '#00C98F', // Green
  fontFamily: 'Poppins, sans-serif',
};

export const defaultUser = {
  company: '',
  email: '',
  role: UserRole.COLLABORATOR,
};

export const defaultScene = {
  name: '',
  url: '',
  interiors: null,
  models: [],
  lights: [],
  allModelsLocked: true,
  env: null,
  envIntensity: 1,
  envRotation: 0,
  softShadows: false,
  samples: 20,
  size: 20,
  focus: 0,
  attributes: [],
  shadowOpacity: 0.2,
  translatedFields: [],
  globalLight: {
    type: 'ambientLight',
    color: '#ffffff',
    intensity: 1,
  },
};
