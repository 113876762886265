export const LOAD_INTERIORS_LIST = 'LOAD_INTERIORS_LIST';
export const RENDER_INTERIORS_LIST = 'RENDER_INTERIORS_LIST';
export const LOAD_INTERIORS_COUNTS = 'LOAD_INTERIORS_COUNTS';
export const RENDER_INTERIORS_COUNTS = 'RENDER_INTERIORS_COUNTS';
export const LOAD_INTERIOR_SCENES = 'LOAD_INTERIOR_SCENES';
export const PUT_INTERIOR_SCENES = 'PUT_INTERIOR_SCENES';
export const RESET_INTERIOR_SCENES = 'RESET_INTERIOR_SCENES';
export const RESET_INTERIORS = 'RESET_INTERIORS';
export const CREATE_INTERIOR = 'CREATE_INTERIOR';
export const CREATE_INTERIOR_ERROR = 'CREATE_INTERIOR_ERROR';
export const CREATE_INTERIOR_SUCCESS = 'CREATE_INTERIOR_SUCCESS';
export const UPDATE_INTERIOR = 'UPDATE_INTERIOR';
export const UPDATE_INTERIOR_ERROR = 'UPDATE_INTERIOR_ERROR';
export const UPDATE_INTERIOR_SUCCESS = 'UPDATE_INTERIOR_SUCCESS';
export const DELETE_INTERIOR = 'DELETE_INTERIOR';
export const DELETE_INTERIOR_ERROR = 'DELETE_INTERIOR_ERROR';
export const MARK_AS_DEFAULT = 'MARK_AS_DEFAULT';
export const LOAD_INTERIOR = 'LOAD_INTERIOR';
export const GET_INTERIOR_USAGE = 'GET_INTERIOR_USAGE';
export const GET_INTERIOR_USAGE_SUCCESS = 'GET_INTERIOR_USAGE_SUCCESS';
export const RENDER_INTERIOR = 'RENDER_INTERIOR';
export const SORTED_ID = 'SORTED_ID';
export const MARK_INTERIOR_AS_DEFAULT_CONTENT = 'MARK_INTERIOR_AS_DEFAULT_CONTENT';
export const UNMARK_INTERIOR_AS_DEFAULT_CONTENT = 'UNMARK_INTERIOR_AS_DEFAULT_CONTENT';
export const HIDE_INTERIOR = 'HIDE_INTERIOR';
export const RESET_INTERIOR_API_STATE = 'RESET_INTERIOR_API_STATE';

export function loadInteriorsList() {
  return {
    type: LOAD_INTERIORS_LIST,
  };
}

export function renderInteriorsList(interiors) {
  return {
    type: RENDER_INTERIORS_LIST,
    interiors,
  };
}

export function loadInteriorsCounts() {
  return {
    type: LOAD_INTERIORS_COUNTS,
  };
}

export function renderInteriorsCounts(counts) {
  return {
    type: RENDER_INTERIORS_COUNTS,
    payload: counts,
  };
}

export function loadInteriorScenes(id) {
  return {
    type: LOAD_INTERIOR_SCENES,
    id,
  };
}

export function putInteriorScenes(scenes) {
  return {
    type: PUT_INTERIOR_SCENES,
    payload: scenes,
  };
}

export function resetInteriorScenes() {
  return {
    type: RESET_INTERIOR_SCENES,
  };
}

export function createInterior(interior) {
  return {
    type: CREATE_INTERIOR,
    interior,
  };
}

export function createInteriorError(error) {
  return {
    type: CREATE_INTERIOR_ERROR,
    error,
  };
}

export function createInteriorSuccess() {
  return {
    type: CREATE_INTERIOR_SUCCESS,
  };
}

export function renderInterior(interior) {
  return {
    type: RENDER_INTERIOR,
    interior,
  };
}

export function loadInterior(id) {
  return {
    type: LOAD_INTERIOR,
    id,
  };
}

export function getInteriorUsage(id) {
  return {
    type: GET_INTERIOR_USAGE,
    id,
  };
}

export function getInteriorUsageSuccess(data) {
  return {
    type: GET_INTERIOR_USAGE_SUCCESS,
    data,
  };
}

export function updateInterior(interior) {
  return {
    type: UPDATE_INTERIOR,
    interior,
  };
}

export function updateInteriorError(error) {
  return {
    type: UPDATE_INTERIOR_ERROR,
    error,
  };
}

export function updateInteriorSuccess() {
  return {
    type: UPDATE_INTERIOR_SUCCESS,
  };
}

export function deleteInterior(id) {
  return {
    type: DELETE_INTERIOR,
    id,
  };
}

export function deleteInteriorError(id, error) {
  return {
    type: DELETE_INTERIOR_ERROR,
    id,
    error,
  };
}

export function sortedId(value) {
  return {
    type: SORTED_ID,
    value,
  };
}

export function markAsDefault(id) {
  return {
    type: MARK_AS_DEFAULT,
    id,
  };
}

export const markInteriorAsDefaultContent = (interior) => ({
  type: MARK_INTERIOR_AS_DEFAULT_CONTENT,
  payload: interior,
});

export const unmarkInteriorAsDefaultContent = (interior) => ({
  type: UNMARK_INTERIOR_AS_DEFAULT_CONTENT,
  payload: interior,
});

export const hideInterior = (interior) => ({
  type: HIDE_INTERIOR,
  payload: interior,
});

export function resetInteriorApiState() {
  return {
    type: RESET_INTERIOR_API_STATE,
  };
}
