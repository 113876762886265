import axios from 'axios';
import storage from './storage';
import handleHttpErrors from '@shared/utils/handleHttpErrors';

class HttpService {
  client = axios.create({
    baseURL: process.env.REACT_APP_SERVER_URL,
    timeout: 50000,
    headers: {
      'Cache-Control': 'no-cache',
    },
  })

  constructor() {
    const token = storage.getItem('token');
    if (token) this.setAuthHeader(token);

    // catch token if it's refreshed by server
    this.client.interceptors.response.use(
      (res) => {
        this.setToken(res.headers['x-access-token']);
        return res;
      },
      (error) => {
        this.setToken(error.response?.headers?.['x-access-token']);
        return Promise.reject(error);
      },
    );
  }

  setToken = (token) => {
    if (!token) return;
    this.setAuthHeader(token);
    storage.setItem('token', token);
  }

  removeToken = () => {
    delete this.client.defaults.headers.common.Authorization;
    storage.removeItem('token');
  }

  setAuthHeader = (token) => {
    this.client.defaults.headers.common.Authorization = `Bearer ${token}`;
  }

  request = async ({ url, method, data, params, res, onError = handleHttpErrors }) => {
    const response = await this.client.request({
      url,
      method,
      data,
      params,
    });
    return res ? response : response.data;
  }

  get = (url, options) => this.request({ url, method: 'GET', ...options })

  post = (url, data, options) => this.request({ url, data, method: 'POST', ...options })

  put = (url, data, options) => this.request({ url, data, method: 'PUT', ...options })

  delete = (url, options) => this.request({ url, method: 'DELETE', ...options })

  patch = (url, data, options) => this.request({ url, data, method: 'PATCH', ...options })
}

export default new HttpService();
