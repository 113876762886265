import { useCallback, useMemo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import classNames from 'classnames';
import { AVAILABLE_LANGUAGES } from '@shared/utils/translations';

const DEFAULT_MENU_PROPS = {
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'left',
  },
  getContentAnchorEl: null,
};

const useStyles = makeStyles((theme) => ({
  item: {
    fontWeight: 500,
    fontSize: 14,
    color: theme.palette.common.paleBlue,
  },
  selected: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    color: theme.palette.common.darkGrey,
  },
  select: {
    display: 'flex',
    justifyContent: 'center',
  },
  icon: {
    top: `calc(50% - ${theme.spacing(1.1)}px)`,
  },
  menuPaper: {
    display: 'flex',
    justifyContent: 'center',
    boxShadow: 'none',
    borderRadius: 0,
  },
}));

const TranslationsDropdown = ({
  onLangChange,
  selectedLanguage,
  variant = 'label',
}) => {
  const classes = useStyles();

  const handleChange = useCallback((e) => {
    const key = e.target.value;
    const newSelection = AVAILABLE_LANGUAGES.find((v) => v.key === key);
    onLangChange(newSelection);
  }, [onLangChange]);

  const menuProps = useMemo(() => ({
    ...DEFAULT_MENU_PROPS,
    classes: { paper: classes.menuPaper },
  }), [classes.menuPaper]);

  const showLable = (value) => {
    if (variant === 'key') return value.key.toUpperCase();
    return value.label;
  };

  return (
    <>
      <Select
        value={ selectedLanguage.key }
        onChange={ handleChange }
        MenuProps={ menuProps }
        disableUnderline
        classes={ { select: classes.select } }
        inputProps={ { classes: { icon: classes.icon } } }
      >
        {AVAILABLE_LANGUAGES.map((lang) => {
          const selected = selectedLanguage.key === lang.key;
          return (
            <MenuItem key={ lang.key } value={ lang.key } className={ classNames(classes.item, { [classes.selected]: selected }) }>
              {selected
                ? (
                  <>
                    {showLable(lang)}
                  </>
                )
                : showLable(lang)}
            </MenuItem>
          );
        })}
      </Select>
    </>
  );
};

export default TranslationsDropdown;
