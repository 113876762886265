const AttributesArrowIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_7383_35290)">
      <path d="M10.8273 12.0007L15.7773 16.9507L14.3633 18.3647L7.99934 12.0007L14.3633 5.63672L15.7773 7.05072L10.8273 12.0007Z" fill="#334D6E" />
    </g>
    <defs>
      <clipPath id="clip0_7383_35290">
        <rect width="24" height="24" fill="white" transform="translate(24) rotate(90)" />
      </clipPath>
    </defs>
  </svg>

);

export default AttributesArrowIcon;
