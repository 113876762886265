import { useState, useMemo } from 'react';

import TextField from '../TextField';
import { Fieldset } from './fieldset';

import { AVAILABLE_LANGUAGES } from '@shared/utils/translations';
import { InputAdornment } from '@material-ui/core';
import TranslationsDropdown from '@shared/components/translations/TranslationsDropdown';

const NameTextField = ({
  details,
  sceneDetailsErrors,
  onChange,
  scenesNameExists,
}) => {
  const [selectedLanguage, setSelectedLanguage] = useState(AVAILABLE_LANGUAGES.find((lng) => lng.key === 'en'));
  const useLang = useMemo(() => selectedLanguage && selectedLanguage.key !== 'en', [selectedLanguage]);

  const handleTextChange = ({ target }) => {
    onChange({ value: target.value }, target.name);
  };

  return (
    <>
      <TextField
        label="Name"
        name={ useLang
          ? `${Fieldset.TranslatedFields}.${selectedLanguage.key}.${Fieldset.Name}`
          : Fieldset.Name }
        key={ useLang
          ? `${Fieldset.TranslatedFields}.${selectedLanguage.key}.${Fieldset.Name}`
          : Fieldset.Name }
        value={ useLang
          ? details[Fieldset.TranslatedFields][selectedLanguage.key]?.[Fieldset.Name]
          : details[Fieldset.Name] }
        required
        error={
          !!sceneDetailsErrors[Fieldset.Name]
          || scenesNameExists
        }
        helperText={
          sceneDetailsErrors[Fieldset.Name]
          || scenesNameExists && 'This name already exists'
        }
        onChange={ handleTextChange }
        InputProps={ {
          endAdornment: (
            <InputAdornment position="end">
              <TranslationsDropdown
                onLangChange={ setSelectedLanguage }
                selectedLanguage={ selectedLanguage }
                variant="key"
              />
            </InputAdornment>
          ),
        } }
      />
    </>
  );
};

export default NameTextField;
