import * as THREE from 'three';
import { useEffect, useRef } from 'react';
import { ZKPosition } from '../types';

interface ModelObjectProps {
    position?: ZKPosition | null
    rotation?: number | null
}

function ModelObject({ position = { x: 0, y: 0, z: 0 }, rotation = 0 }: ModelObjectProps) {
  const meshRef = useRef<THREE.Mesh>(null);

  useEffect(() => {
    const box3 = new THREE.Box3().setFromObject(meshRef.current!);
    const center = new THREE.Vector3();
    const size = new THREE.Vector3();

    box3.getCenter(center);
    box3.getSize(size);

    meshRef.current!.scale.set(1, 1, -1);
    meshRef.current!.position.set(position!.x - center!.x, position!.y - center.y + size.y / 2.0, position!.z - center!.z);
    meshRef.current!.rotation.set(0, rotation!, 0);
  }, [position, rotation]);

  return (
    <mesh ref={ meshRef }>
      <boxGeometry args={ [1, 1, 1] } />
      <meshStandardMaterial color="white" transparent opacity={0.5}/>
    </mesh>
  );
}

export default ModelObject;
