import { Axis } from '../../types';
import VPSolver from './VPSolver';
import VPStep from './steps/VPStep';
import ReferenceStep from './steps/ReferenceStep';
import FloorStep from './steps/FloorStep';

class VPSolverType2 extends VPSolver {

    type = 1
    steps: any[] = [
      new FloorStep(this, this.prepareFloorStep, this.completeFloorStep, this.cancelFloorStep),
      new VPStep(this, () => this.prepareVPStep(Axis.Z), this.completeVPStep, this.cancelVPStep),
    ]
}

export default VPSolverType2;
