import { makeStyles } from '@material-ui/core/styles';
import SvgIcon from '@material-ui/core/SvgIcon';

const useStyles = makeStyles(() => ({
  root: {
    fill: 'none',
    stroke: 'currentColor',
  },
}));

const CopyIcon = ({ fontSize = 'inherit' }) => {
  const classes = useStyles();

  return (
    <SvgIcon viewBox="0 0 16 16" className={ classes.root } fontSize={ fontSize }>
      <path
        d="M10.5 10.4996H13.5V2.49957H5.5V5.49957"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.5 5.49963H2.5V13.4996H10.5V5.49963Z"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
};

export default CopyIcon;
