import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import EditIcon from '@resources/icons/EditIcon';
import CopyIcon from '@resources/icons/CopyIcon';
import DeleteIcon from '@resources/icons/DeleteIcon';

import CardControls from '@shared/components/card-controls';
import Control from '@shared/components/card-controls/Control';
import AlertDialog from '@shared/components/card-controls/AlertDialog';
import WarningDialog from '@shared/components/card-controls/WarningDialog';
import useControls from '@shared/components/card-controls/useControls';
import useAlertControl, { Types } from '@shared/components/card-controls/useAlertControl';
import useWarningControl from '@shared/components/card-controls/useWarningControl';
import { selectCurrentCompany } from '@store/app/selectors';

const Controls = ({
  onEdit,
  onCopy,
  onDelete,
  item,
}) => {
  const company = useSelector(selectCurrentCompany);
  const {
    open,
    handleMoreIconClick,
    handleClickOutside,
    handleControlClick,
  } = useControls();

  const [alertOpen, {
    handleAlertControlClick,
    handleAgree,
    handleDisagree,
    ariaAction,
    title,
  }] = useAlertControl();

  const [warningOpen, {
    handleWarningClose,
  }] = useWarningControl();

  const [deleteProcessing, setDeleteProcessing] = useState(false);
  const [deleteTitle, setDeleteTitle] = useState('');

  const handleDeleteClick = (event) => {
    event.stopPropagation();
    setDeleteProcessing(true);
  };

  const handleDialogsClick = (event) => {
    event.stopPropagation();
  };

  useEffect(() => {
    if (deleteProcessing) {
      handleAlertControlClick(Types.Delete, onDelete)();
      setDeleteProcessing(false);
    }
  }, [
    deleteProcessing,
    deleteTitle,
    handleAlertControlClick,
    onDelete,
  ]);

  return (
    <>
      <CardControls
        open={ open }
        onMoreIconClick={ handleMoreIconClick }
        onClickOutside={ handleClickOutside }
      >
        <Control onClick={ handleControlClick(onEdit) }>
          <EditIcon />
        </Control>
        <Control onClick={ handleControlClick(onCopy) }>
          <CopyIcon />
        </Control>
        <Control onClick={ handleDeleteClick }>
          <DeleteIcon />
        </Control>
      </CardControls>
      <div onClick={ handleDialogsClick }>
        <AlertDialog
          open={ alertOpen }
          ariaAction={ ariaAction }
          title={ title }
          onAgree={ handleControlClick(handleAgree) }
          onDisagree={ handleDisagree }
        />
        <WarningDialog
          open={ warningOpen }
          title={ deleteTitle }
          onClose={ handleWarningClose }
        />
      </div>
    </>
  );
};

export default Controls;
