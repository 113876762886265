import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles(() => ({
  item: {
    display: 'flex',
  },
}));

const SceneControls = ({ children }) => {
  const classes = useStyles();

  return (
    <Grid container alignItems="center" spacing={ 1 }>
      {React.Children.map(children, (child) => (
        <Grid className={ classes.item } item>
          {child}
        </Grid>
      ))}
    </Grid>
  );
};

export default SceneControls;
