import { useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import MoreIcon from '@resources/icons/MoreIcon';
import Controls from './Controls';
import Control from './Control';
import useOnClickOutside from '@shared/hooks';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'absolute',
    top: theme.spacing(1.5),
    right: theme.spacing(1.5),
    zIndex: theme.zIndex.drawer,
    display: 'flex',
  },
}));

const CardControls = ({
  children, open, onMoreIconClick, onClickOutside,
}) => {
  const containerRef = useRef(null);
  const classes = useStyles();

  useOnClickOutside(containerRef, onClickOutside);

  return (
    <div className={ classes.root } ref={ containerRef }>
      {open && (
        <Controls>
          {children}
        </Controls>
      )}
      <Controls>
        <Control onClick={ onMoreIconClick }>
          <MoreIcon size="small" />
        </Control>
      </Controls>
    </div>
  );
};

export default CardControls;
