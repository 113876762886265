/* eslint-disable max-len */

const ScenesIcon = () => (
  <svg
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0)">
      <path
        d="M4.7808 2.2H2.71888C2.43422 2.2 2.20343 2.43079 2.20343 2.71548V4.81177C2.20343 5.09647 2.43419 5.32726 2.71892 5.32726C3.00361 5.32726 3.23441 5.09647 3.23441 4.81177V3.23097H4.78083C5.06553 3.23097 5.29632 3.00018 5.29632 2.71548C5.29629 2.43079 5.06549 2.2 4.7808 2.2Z"
        fill="#BCC8D9"
      />
      <path
        d="M19.2821 2.2H17.2201C16.9354 2.2 16.7047 2.43079 16.7047 2.71548C16.7047 3.00018 16.9354 3.23097 17.2201 3.23097H18.7666V4.81177C18.7666 5.09647 18.9974 5.32726 19.2821 5.32726C19.5667 5.32726 19.7975 5.09647 19.7975 4.81177V2.71548C19.7975 2.43079 19.5667 2.2 19.2821 2.2Z"
        fill="#BCC8D9"
      />
      <path
        d="M4.77986 18.7641H3.23343V17.1833C3.23343 16.8986 3.00263 16.6678 2.71794 16.6678C2.43325 16.6678 2.20245 16.8986 2.20245 17.1833V19.2796C2.20245 19.5643 2.43321 19.7951 2.71794 19.7951H4.77986C5.06455 19.7951 5.29534 19.5643 5.29534 19.2796C5.29534 18.9949 5.06455 18.7641 4.77986 18.7641Z"
        fill="#BCC8D9"
      />
      <path
        d="M19.2821 16.6678C18.9974 16.6678 18.7666 16.8986 18.7666 17.1833V18.7641H17.2201C16.9354 18.7641 16.7047 18.9949 16.7047 19.2796C16.7047 19.5643 16.9354 19.7951 17.2201 19.7951H19.2821C19.5667 19.7951 19.7975 19.5643 19.7975 19.2796V17.1833C19.7975 16.8986 19.5667 16.6678 19.2821 16.6678Z"
        fill="#BCC8D9"
      />
      <path
        d="M18.4377 6.42891L11.2584 2.26947C11.0986 2.17686 10.9014 2.17686 10.7416 2.26947L3.55934 6.42887C3.40101 6.52062 3.30219 6.69143 3.30219 6.87458C3.30219 6.87561 3.30229 6.87664 3.30229 6.87764V15.125C3.30229 15.3099 3.40277 15.483 3.56457 15.5741L10.7416 19.7305C10.8189 19.7753 10.9109 19.8 11.0001 19.8C11.0931 19.8 11.184 19.7736 11.2585 19.7305L18.4326 15.574C18.4338 15.5733 18.435 15.5726 18.4362 15.572C18.6025 15.452 18.6921 15.3512 18.6946 15.1312C18.6946 15.1292 18.6948 15.1271 18.6948 15.1251V6.87771C18.6948 6.87668 18.6949 6.87564 18.6949 6.87465C18.6949 6.69133 18.596 6.52048 18.4377 6.42891ZM10.4845 18.3901L4.85301 15.1274L9.19454 12.6376C9.44153 12.496 9.52691 12.181 9.38525 11.934C9.24366 11.687 8.92865 11.6017 8.68167 11.7433L4.33316 14.2352V7.76561L10.4845 11.2983V18.3901ZM11 10.4055L4.85226 6.8731L11 3.31123L17.1478 6.8731L11 10.4055ZM17.6672 14.2352L13.3184 11.7433C13.0714 11.6017 12.7564 11.687 12.6148 11.934C12.4731 12.181 12.5585 12.496 12.8055 12.6376L17.147 15.1273L11.5155 18.3901V11.2983L17.6672 7.76561V14.2352H17.6672Z"
        fill="#BCC8D9"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect
          width="17.6"
          height="17.6"
          fill="white"
          transform="translate(2.20001 2.2)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default ScenesIcon;
