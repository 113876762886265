import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware } from 'redux';
import createSagaMiddleware from 'redux-saga';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from '@material-ui/core/styles';
import { ShaderChunk } from 'three';

import App from './app';

import { rootReducer, rootSaga } from '@store';
import reportWebVitals from './reportWebVitals';

import theme from './theme';

import './index.css';

const sagaMiddleware = createSagaMiddleware();

export const store = createStore(rootReducer, applyMiddleware(sagaMiddleware));

sagaMiddleware.run(rootSaga);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <Provider store={ store }>
      <ThemeProvider theme={ theme }>
        <CssBaseline />
        <App />
      </ThemeProvider>
    </Provider>
  </BrowserRouter>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

// Custom Tone Mapping
ShaderChunk.tonemapping_pars_fragment = ShaderChunk.tonemapping_pars_fragment.replace(
  'vec3 CustomToneMapping( vec3 color ) { return color; }', `
      float startCompression = 0.8;
      float desaturation = 0.5;
      vec3 CustomToneMapping( vec3 color ) {
        color *= toneMappingExposure;
        
        float d = 1. - startCompression;

        float peak = max(color.r, max(color.g, color.b));
        if (peak < startCompression) return color;

        float newPeak = 1. - d * d / (peak + d - startCompression);
        float invPeak = 1. / peak;
        
        float extraBrightness = dot(color * (1. - startCompression * invPeak), vec3(1, 1, 1));
        
        color *= newPeak * invPeak;
        float g = 1. - 3. / (desaturation * extraBrightness + 3.);
        return mix(color, vec3(1, 1, 1), g);
      }`,
);
