

const SliderContainer = ({
  children,
  className,
  ...props
}) => (
  <div { ...props } className={ className }>
    {children}
  </div>
);

export default SliderContainer;
