import { useForm, FormProvider } from 'react-hook-form';
import { Form } from '@shared/views/form-dialog';

const MaterialsForm = ({
  children,
  className,
  open,
  data,
  onSubmit,
}) => {
  const form = useForm({
    defaultValues: data,
  });

  const handleSubmit = async (newData) => {
    onSubmit(newData);
  };

  return (
    <Form
      className={ className }
      open={ open }
      onSubmit={ form.handleSubmit(handleSubmit) }
    >
      <FormProvider { ...form }>
        {children}
      </FormProvider>
    </Form>
  );
};

export default MaterialsForm;
