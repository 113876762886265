import {
  RENDER_STUDIOS_LIST,
  RENDER_STUDIOS_COUNTS,
  RESET_STUDIOS,
  RENDER_STUDIO,
  SORTED_ID,
} from './actions';
import { SortId } from '@app/sort/sortValues';

const defaultStudios = {
  items: [],
  total: 0,
};

const initialState = {
  studios: defaultStudios,
  sorting: {
    id: SortId,
    label: 'A-Z',
  },
  studio: {
    name: '',
    presets: 'rembrandt',
    environment: 'apartment',
    intensity: 0.5,
    shadows: 'contact',
    cameras: [],
    materials: [],
  },
  counts: [],
  sortedId: SortId.AtoZ,
};

export default function studiosReducer(state = initialState, action) {
  switch (action.type) {
    case RENDER_STUDIOS_LIST:
      return {
        ...state,
        studios: action.studios,
      };
    case RENDER_STUDIOS_COUNTS:
      return {
        ...state,
        counts: action.payload,
      };
    case RESET_STUDIOS:
      return {
        ...state,
        studios: defaultStudios,
      };
    case RENDER_STUDIO:
      return {
        ...state,
        studio: action.studio,
      };
    case SORTED_ID:
      return {
        ...state,
        sortedId: action.value,
      };
    default:
      return state;
  }
}
