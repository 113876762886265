import { memo } from 'react';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';

const useStyles = makeStyles(() => ({
  root: {
    cursor: 'pointer',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
  },
  transparent: {
    opacity: 0.6,
  },
}));

const Card = ({ children, className, onClick, transparent = false }) => {
  const classes = useStyles();

  return (
    <Paper
      className={ classNames(className, classes.root, { [classes.transparent]: transparent }) }
      onClick={ onClick }
    >
      {children}
    </Paper>
  );
};

export default memo(Card);
