import SharedDialog from '@shared/components/dialog/Dialog';

const Dialog = ({ children, open, onClose }) => (
  <SharedDialog
    className="popup"
    maxWidth="md"
    fullWidth
    open={ open }
    onClose={ onClose }
  >
    {children}
  </SharedDialog>
);

export default Dialog;
