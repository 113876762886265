import { FormDialog } from '@shared/views/form-dialog';
import EditModelForm from './Form';
import FormFieldset from '../FormFieldset';
import { useDispatch } from 'react-redux';
import { resetModelApiState } from '@store/models/actions';


const EditFormDialog = ({
  open,
  data,
  onClose,
}) => {
  const dispatch = useDispatch();
  const handleSubmit = () => {
    dispatch(resetModelApiState());
    onClose();
  };

  const handleCancel = () => {
    dispatch(resetModelApiState());
    onClose();
  };

  return (
    <FormDialog
      open={ open }
      Form={ EditModelForm }
      FormProps={ {
        open,
        data,
        onSubmit: handleSubmit,
      } }
      title="Edit Model"
      content={ <FormFieldset renewable /> }
      onCancel={ handleCancel }
      onClose={ onClose }
      isDrawer
    />
  );
};

export default EditFormDialog;
