import { Suspense, useMemo, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Canvas as RTFCanvas } from '@react-three/fiber';
import * as THREE from 'three';

import Backdrop from '@shared/components/backdrop';
import CircularProgress from '@shared/components/circular-progress';
import Toggle from '@shared/components/toggle';
import Scene from './Scene';
import TextureSizeController from '@pages/materials/modal/TextureSizeController';
import { Fieldset } from '@pages/materials/modal/fieldset';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    height: '280px',
    position: 'relative',
  },
  button: {
    width: '100%',
  },
  canvas: {
    cursor: 'pointer',
    minHeight: 'inherit',
    height: '305px !important',

    '& canvas': {
      width: '100% !important',
      height: 'auto !important',
    },
  },
}));

export default ({ value, toneMapping, toneMappingExposure, environment }) => {
  const classes = useStyles();
  const [type, setType] = useState('sphere');

  const glConfig = useMemo(
    () => ({
      antialias: true,
      preserveDrawingBuffer: true,
      toneMapping: THREE[toneMapping],
      toneMappingExposure,
    }),
    [toneMapping, toneMappingExposure],
  );

  return (
    <Suspense
      fallback={ (
        <Backdrop className={ classes.backdrop }>
          <CircularProgress size="large" />
        </Backdrop>
      ) }
    >
      <div className={ classes.root }>
        <TextureSizeController name={ Fieldset.TextureSize } disabled={ !value.id } />
        <RTFCanvas
          className={ classes.canvas }
          gl={ glConfig }
        >
          <Scene value={ value } type={ type } textureSize={ value.textureSize } environment={ environment } />
        </RTFCanvas>
        <br />
        <Toggle
          name="group"
          className={ classes.button }
          onChange={ (name, value) => {
            if (value) {
              setType(value);
            }
          } }
          options={ [{ key: 'sphere', label: 'Sphere' }, { key: 'cube', label: 'Cube' }] }
          value={ type }
        />
      </div>
    </Suspense>
  );
};
