import Button from '@shared/components/button';

const ActionButton = ({
  children, className, disabled, onClick,
}) => (
  <Button className={ className } disabled={ disabled } onClick={ onClick }>
    {children}
  </Button>
);

export default ActionButton;
