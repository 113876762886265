import { useState, useCallback } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import FullSizeIcon from '@shared/icons/fullSizeIcon';
import CompactViewIcon from '@shared/icons/compactViewIcon';

const useStyles = makeStyles((theme) => ({
  imageContainer: {
    height: '100%',
    width: '100%',
  },
  fullSizeToggleButton: {
    position: 'absolute',
    top: theme.spacing(1),
    right: theme.spacing(1),
    padding: theme.spacing(0.5),
    display: 'flex',
    backgroundColor: '#E2EAF3',
    border: '1px solid #767676',
    borderRadius: '2px',
    cursor: 'pointer',
  },
  paper: {
    maxHeight: '83.4vh',
    maxWidth: '81vw',
    '& img': {
      height: '83.4vh !important',
      width: '81vw',
    },
  },
}));

const ImageFullSize = ({ children, renderFullScreenContent }) => {
  const classes = useStyles();
  const [isFullSizeMode, setIsFullSizeMode] = useState(false);

  const handleFullSizeIconClick = useCallback((event) => {
    event.stopPropagation();
    setIsFullSizeMode(true);
  }, []);

  const handleCompactViewIconClick = useCallback((event) => {
    event.stopPropagation();
    setIsFullSizeMode(false);
  }, []);

  const handleDialogClose = useCallback(() => {
    setIsFullSizeMode(false);
  }, []);

  return (
    <>
      <div className={ classes.imageContainer }>
        {children}
        <button
          className={ classes.fullSizeToggleButton }
          type="button"
          onClick={ handleFullSizeIconClick }
        >
          <FullSizeIcon />
        </button>
      </div>
      <Dialog
        open={ isFullSizeMode }
        fullScreen
        classes={ {
          paper: classes.paper,
        } }
        onClose={ handleDialogClose }
      >
        <>
          {isFullSizeMode && (renderFullScreenContent ? renderFullScreenContent() : children)}
          <button
            className={ classes.fullSizeToggleButton }
            type="button"
            onClick={ handleCompactViewIconClick }
          >
            <CompactViewIcon />
          </button>
        </>
      </Dialog>
    </>
  );
};

export default ImageFullSize;
