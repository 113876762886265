/* eslint-disable max-len */
import SvgIcon from '@material-ui/core/SvgIcon';

const DropdownItemSelectedIcon = (props) => (
  <SvgIcon
    viewBox="0 0 10 10"
    { ...props }
  >
    <path d="M9.85567 0.618501C9.68367 0.461501 9.40167 0.459501 9.22767 0.618501L3.55967 6.2505L0.61967 4.6905C0.42267 4.6235 0.19767 4.6945 0.08467 4.8605C-0.03633 5.0305 -0.02733 5.2615 0.10967 5.4225L3.07767 9.3355C3.16767 9.4385 3.29467 9.4995 3.42767 9.4995L3.47667 9.4975C3.60267 9.4845 3.71867 9.4195 3.79367 9.3215L9.90267 1.2385C10.0497 1.0515 10.0277 0.777501 9.85567 0.618501Z" fill="currentColor" />
  </SvgIcon>
);

export default DropdownItemSelectedIcon;
