import {
  RENDER_MODELS_LIST,
  RENDER_MODELS_COUNTS,
  PUT_MODEL_SCENES,
  RESET_MODEL_SCENES,
  RESET_MODELS,
  RENDER_MODEL,
  SORTED_ID,
  DELETE_MODEL_ERROR,
  UPDATE_MODEL,
  CREATE_MODEL,
  CREATE_MODEL_ERROR,
  UPDATE_MODEL_ERROR,
  CREATE_MODEL_SUCCESS,
  UPDATE_MODEL_SUCCESS,
  RESET_MODEL_API_STATE,
} from './actions';
import { SortId } from '@app/sort/sortValues';

const defaultModels = {
  items: [],
  total: 0,
};

const initialState = {
  models: defaultModels,
  sorting: {
    id: SortId,
    label: 'A-Z',
  },
  model: {
    name: '',
    url: '',
    width: '',
    height: '',
    type: '',
  },
  counts: [],
  scenes: null,
  sortedId: SortId.AtoZ,
  state: 'initial',
  error: null,
};

export default function modelsReducer(state = initialState, action) {
  switch (action.type) {
    case RENDER_MODELS_LIST:
      return {
        ...state,
        models: action.models,
      };
    case RENDER_MODELS_COUNTS:
      return {
        ...state,
        counts: action.payload,
      };
    case PUT_MODEL_SCENES:
      return {
        ...state,
        scenes: action.payload,
      };
    case RESET_MODEL_SCENES:
      return {
        ...state,
        scenes: null,
      };
    case RESET_MODELS:
      return {
        ...state,
        models: defaultModels,
      };
    case RENDER_MODEL:
      return {
        ...state,
        model: action.model,
      };
    case SORTED_ID:
      return {
        ...state,
        sortedId: action.value,
      };
    case CREATE_MODEL:
    case UPDATE_MODEL:
      return {
        ...state,
        state: 'processing',
      };
    case DELETE_MODEL_ERROR:
      if (action.id && action.error) {
        return {
          ...state,
          error: {
            id: action.id,
            data: action.error,
            type: 'DELETE',
          },
          state: 'error',
        };
      }
      return {
        ...state,
        error: null,
        state: 'success',
      };
    case CREATE_MODEL_ERROR:
      if (action.error) {
        return {
          ...state,
          error: {
            data: action.error,
            type: 'CREATE',
          },
          state: 'error',
        };
      }
      return {
        ...state,
        error: null,
        state: 'success',
      };
    case UPDATE_MODEL_ERROR:
      if (action.error) {
        return {
          ...state,
          error: {
            data: action.error,
            type: 'UPDATE',
          },
          state: 'error',
        };
      }
      return {
        ...state,
        error: null,
        state: 'success',
      };
    case CREATE_MODEL_SUCCESS:
    case UPDATE_MODEL_SUCCESS:
      return {
        ...state,
        state: 'success',
      };
    case RESET_MODEL_API_STATE:
      return {
        ...state,
        state: 'initial',
        error: null,
      };
    default:
      return state;
  }
}
