import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import {
  loadInteriorsList,
} from '@store/interiors/actions';
import { loadAttributeDefinitions } from '@store/attribute-definitions/actions';

import withAuth from '@shared/components/route/withAuth';

import InteriorList from '@pages/interiors/list';

import { actions } from '@store/app';
import useActions from '@shared/hooks/useActions';
import { EntityTypes } from '@shared/constants';

export const BackgroundsList = withAuth(() => {
  const dispatch = useDispatch();
  const { showMenu } = useActions(actions);

  useEffect(() => {
    showMenu();
    dispatch(loadInteriorsList());
    dispatch(loadAttributeDefinitions([EntityTypes.Background]));
  }, [dispatch, showMenu]);

  return <InteriorList />;
});
