import classNames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import ImagePlaceholder from '@shared/components/placeholder/ImagePlaceholder';

const useStyles = makeStyles(() => ({
  root: {
    maxWidth: '100%',
    height: '19.1rem',
    objectFit: 'cover',
    borderTopLeftRadius: 'inherit',
    borderTopRightRadius: 'inherit',
    overflowClipMargin: 'unset',
    overflow: 'hidden',
  },
  fullSize: {
    objectFit: 'contain',
  },
  placeholder: {
    height: '19.1rem',
  },
}));

const CardImage = ({ src, alt, variant = 'stretch', withPlaceholder = false }) => {
  const classes = useStyles();

  return !src && withPlaceholder ? (<ImagePlaceholder className={ classes.placeholder } />) : (
    <img
      className={ classNames(classes.root, {
        [classes.fullSize]: variant === 'fullSize',
      }) }
      src={ src }
      alt={ alt }
      loading="lazy"
    />
  );
};

export default CardImage;
