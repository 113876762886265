import {
  all, put, takeEvery, call, select,
} from 'redux-saga/effects';
import {
  LOAD_SCENES_LIST,
  CREATE_SCENE,
  LOAD_SCENE,
  UPDATE_SCENE,
  DELETE_SCENE,
  renderScenesList,
  renderScene,
  HIDE_SCENE,
  hideScene as hideSceneAction,
  LOAD_ENVIRONMENTS_LIST,
  renderEnvironmentsList,
  DELETE_ENVIRONMENT,
  loadEnvironmentsList,
  loadScenesList,
  deleteEnvironmentError,
  putSceneError,
} from './actions';

import { scenesApi } from '@api';

import { ROUTES } from '@app/routes/routesUrls';
import { selectCurrentCompany } from '@store/app/selectors';
import addHiddenStateForItems from '@shared/utils/addHiddenStateForItems';
import { deleteProductError } from '@store/products/actions';

export function* getScenes() {
  try {
    const data = yield call(scenesApi.getScenes);
    const company = yield select(selectCurrentCompany);
    yield put(renderScenesList(addHiddenStateForItems(data, company)));
  } catch (error) {
    console.error(error);
  }
}

export function* getEnvironments() {
  try {
    const data = yield call(scenesApi.getEnvironments);
    yield put(renderEnvironmentsList(data));
  } catch (error) {
    console.error(error);
  }
}

export function* createScene({ scene }) {
  try {
    const data = yield call(scenesApi.createScene, scene);
    const { scene: { id } } = yield put(renderScene(data));
    yield put(hideSceneAction(id));
    if (!scene.url) {
      window.location.replace(`${ROUTES.SCENES.ROOT}${ROUTES.SCENES.EDIT(data.id)}`);
    }
  } catch (error) {
    if (error.response?.data) {
      yield put(putSceneError(scene, error.response?.data));
      return;
    }
    console.error(error);
  }
}

export function* editScene({ scene }) {
  try {
    const data = yield call(scenesApi.editScene, scene);
    if (window.location.pathname.startsWith(`${ROUTES.SCENES.ROOT}/`)) {
      yield put(renderScene(data));
    }
    yield put(loadScenesList());
  } catch (error) {
    if (error.response?.data) {
      yield put(putSceneError(scene, error.response?.data));
      return;
    }
    console.error(error);
  }
}

export function* getScene({ id }) {
  try {
    const data = yield call(scenesApi.getScene, id);
    yield put(renderScene(data));
  } catch (error) {
    console.error(error);
  }
}

export function* deleteScene({ id }) {
  try {
    yield call(scenesApi.deleteScene, id);
    yield put(loadScenesList());
  } catch (error) {
    console.error(error);
  }
}

export function* deleteEnvironment({ id }) {
  try {
    yield call(scenesApi.deleteEnvironment, id);
    yield put(loadEnvironmentsList());
  } catch (error) {
    if (error.response?.data) {
      yield put(deleteEnvironmentError(id, error.response?.data));
      return;
    }
    console.error(error);
  }
}

export function* hideScene({ payload: id }) {
  try {
    yield call(scenesApi.hideScene, id);
    yield put(loadScenesList());
  } catch (error) {
    console.error(error);
  }
}

export default function* scenesSaga() {
  yield all([
    takeEvery(LOAD_SCENES_LIST, getScenes),
    takeEvery(LOAD_ENVIRONMENTS_LIST, getEnvironments),
    takeEvery(CREATE_SCENE, createScene),
    takeEvery(LOAD_SCENE, getScene),
    takeEvery(UPDATE_SCENE, editScene),
    takeEvery(DELETE_SCENE, deleteScene),
    takeEvery(DELETE_ENVIRONMENT, deleteEnvironment),
    takeEvery(HIDE_SCENE, hideScene),
  ]);
}
