import { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    color: theme.palette.common.icon,
    '&:hover': {
      color: theme.palette.common.green,
    },
  },
}));

const IconToggler = ({
  activeIcon,
  inactiveIcon,
  isActive,
  size = 'small',
}) => {
  const classes = useStyles();
  const [isHovered, setIsHovered] = useState(false);

  const Icon = (isActive && isHovered) || (!isActive && !isHovered)
    ? inactiveIcon
    : activeIcon;

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <div
      className={ classes.root }
      onMouseEnter={ handleMouseEnter }
      onMouseLeave={ handleMouseLeave }
    >
      <Icon size={ size } />
    </div>
  );
};

export default IconToggler;

