import { useState, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import EditInteriorModal from '../../modal/edit/FormDialog';
import CreateInteriorModal from '../../modal/create/FormDialog';
import CopyInteriorModal from '../../modal/copy/FormDialog';
import PageContainer from '@shared/components/page-parts/Container';
import PageHead from '@shared/components/page-parts/Head';
import PageContent from '@shared/components/page-parts/Content';
import TopNav from '@shared/components/top-nav/Nav';
import CardsList from '@shared/components/cards-list/CardsList';
import CardItem from '@shared/components/cards-list/CardItem';
import Interior from './Interior';
import Controls from './Controls';

import {
  deleteInterior,
  resetInteriorScenes,
  markAsDefault,
  hideInterior,
  markInteriorAsDefaultContent,
  unmarkInteriorAsDefaultContent, deleteInteriorError,
} from '@store/interiors/actions';

import { ROUTES } from '@app/routes/routesUrls';
import Breadcrumbs from '@shared/components/breadcrumbs';
import AddNew from '@shared/components/cards-list/AddNew';

const ActionName = {
  Edit: 'Edit',
  Copy: 'Copy',
  AddNew: 'Add New',
};

const InteriorListGrid = ({ data }) => {
  const dispatch = useDispatch();
  const apiError = useSelector((state) => state.interiors.error);

  const [actionName, setActionName] = useState('');
  const [modalData, setModalData] = useState({});

  const handleEditInteriors = useCallback((item) => () => {
    setModalData(item);
    setActionName(ActionName.Edit);
  }, []);

  const handleCopyInteriors = useCallback((item) => () => {
    setModalData(item);
    setActionName(ActionName.Copy);
  }, []);

  const handleHideInterior = useCallback((item) => () => {
    dispatch(hideInterior(item));
  }, []);

  const handleCreateInteriors = useCallback(() => {
    setModalData({
      attributes: [],
    });
    setActionName(ActionName.AddNew);
  }, []);

  const resetScenes = () => {
    dispatch(resetInteriorScenes());
  };

  const handleDeleteInteriors = useCallback((item) => () => {
    dispatch(deleteInterior(item.id));
  }, [dispatch]);

  const handleApiErrorClear = () => {
    dispatch(deleteInteriorError());
  };

  const handleMarkAsDefaultInterior = useCallback((item) => {
    dispatch(markAsDefault(item.id));
  }, [dispatch]);

  const handleInteriorModalClose = useCallback(() => {
    setActionName('');
  }, []);

  const handleShareInterior = useCallback((item) => () => {
    if (item.defaultContent) {
      dispatch(unmarkInteriorAsDefaultContent(item));
    } else {
      dispatch(markInteriorAsDefaultContent(item));
    }
  }, []);

  const breadcrumbsItems = useMemo(() => [
    { name: 'Backgrounds', link: ROUTES.INTERIORS },
  ], []);

  return (
    <PageContainer>
      <PageHead>
        <TopNav>
          <Breadcrumbs items={ breadcrumbsItems } />
        </TopNav>
      </PageHead>
      <PageContent>
        {data.length > 0 ? (
          <CardsList>
            <CardItem>
              <AddNew onClick={ handleCreateInteriors } />
            </CardItem>
            {data.map((item) => (
              <CardItem key={ item.id }>
                <Interior
                  item={ item }
                  controls={ (
                    <Controls
                      apiError={ apiError }
                      onEdit={ handleEditInteriors(item) }
                      onCopy={ handleCopyInteriors(item) }
                      onHide={ handleHideInterior(item) }
                      resetScenes={ resetScenes }
                      onDelete={ handleDeleteInteriors(item) }
                      onMarkAsDefault={ () => handleMarkAsDefaultInterior(item) }
                      onShare={ handleShareInterior(item) }
                      onApiErrorClear={ handleApiErrorClear }
                      item={ item }
                    />
                  ) }
                  onClick={ handleEditInteriors(item) }
                />
              </CardItem>
            ))}
          </CardsList>
        ) : (
          <CardsList>
            <CardItem>
              <AddNew onClick={ handleCreateInteriors } />
            </CardItem>
          </CardsList>
        )}
      </PageContent>
      <EditInteriorModal
        open={ actionName === ActionName.Edit }
        data={ modalData }
        onClose={ handleInteriorModalClose }
      />
      <CreateInteriorModal
        open={ actionName === ActionName.AddNew }
        data={ modalData }
        onClose={ handleInteriorModalClose }
      />
      <CopyInteriorModal
        open={ actionName === ActionName.Copy }
        data={ modalData }
        onClose={ handleInteriorModalClose }
      />
    </PageContainer>
  );
};

export default InteriorListGrid;
