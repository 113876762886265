/* eslint-disable max-len */
import SvgIcon from '@material-ui/core/SvgIcon';

const LightbulbIcon = (props) => (
  <SvgIcon
    viewBox="0 0 13 19"
    { ...props }
  >
    <path d="M4.85323 14.2141H5.68766V9.92816H7.31266V14.2141H8.1471C8.25435 13.1837 8.75241 12.3334 9.56085 11.4051C9.65266 11.3005 10.2368 10.6619 10.3059 10.5711C10.8798 9.8147 11.2395 8.90273 11.3436 7.94016C11.4477 6.97759 11.292 6.00358 10.8944 5.13028C10.4968 4.25699 9.87344 3.51993 9.09619 3.00398C8.31893 2.48804 7.41935 2.2142 6.50104 2.21399C5.58273 2.21378 4.68304 2.48722 3.90558 3.00281C3.12811 3.51841 2.50449 4.25519 2.10654 5.1283C1.70859 6.00142 1.55248 6.97536 1.6562 7.93798C1.75991 8.90059 2.11924 9.81273 2.69279 10.5693C2.76266 10.6611 3.34848 11.3005 3.43866 11.4042C4.24791 12.3334 4.74598 13.1837 4.85323 14.2141ZM4.87516 15.9284V16.7856H8.12516V15.9284H4.87516ZM1.42529 11.6425C0.66007 10.6339 0.180498 9.41768 0.041818 8.13405C-0.0968623 6.85042 0.110991 5.55156 0.641434 4.38711C1.17188 3.22265 2.00333 2.23997 3.04002 1.55226C4.0767 0.864554 5.27644 0.499793 6.50104 0.5C7.72564 0.500207 8.92527 0.865373 9.96175 1.55343C10.9982 2.24149 11.8294 3.22445 12.3595 4.38909C12.8896 5.55372 13.097 6.85264 12.9579 8.13623C12.8189 9.41982 12.3389 10.6358 11.5734 11.6442C11.0697 12.306 9.75016 13.3569 9.75016 14.6427V16.7856C9.75016 17.2403 9.57895 17.6764 9.27421 17.9979C8.96946 18.3194 8.55614 18.5 8.12516 18.5H4.87516C4.44419 18.5 4.03086 18.3194 3.72612 17.9979C3.42137 17.6764 3.25016 17.2403 3.25016 16.7856V14.6427C3.25016 13.3569 1.92985 12.306 1.42529 11.6425Z" fill="currentColor" />
  </SvgIcon>
);

export default LightbulbIcon;
