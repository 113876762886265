import { ROUTES } from '@app/routes/routesUrls';
import { HttpStatusCode } from '@shared/constants';

const handleHttpErrors = (err) => {
  if (err?.response?.status === HttpStatusCode.NotAuthorized
    && !err?.request?.responseURL?.endsWith('/refresh')
    && !window.location.pathname.endsWith(ROUTES.LOGIN)) {
    window.location.replace(ROUTES.LOGIN);
  }
};

export default handleHttpErrors;
