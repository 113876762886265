/* eslint-disable max-len */

const InteriorsIcon = () => (
  <svg
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0)">
      <path
        d="M19.2361 19.8C19.5504 19.8 19.8 19.5684 19.8 19.2768V2.72319C19.8 2.43158 19.5412 2.2 19.2361 2.2H2.76388C2.45884 2.2 2.20001 2.43158 2.20001 2.72319V19.2768C2.20001 19.5684 2.45884 19.8 2.76388 19.8H19.2361ZM3.33699 10.8885H7.69077V13.5474C7.69077 13.839 7.94959 14.0706 8.25463 14.0706H10.316C10.6303 14.0706 10.8798 13.839 10.8798 13.5474C10.8798 13.2557 10.621 13.0242 10.316 13.0242H8.8185V7.18323C8.8185 6.89161 8.55968 6.66004 8.25463 6.66004C7.94959 6.66004 7.69077 6.89161 7.69077 7.18323V9.8421H3.33699V3.25497H18.6723V9.8421H15.6866V6.54853C15.6866 6.25692 15.4277 6.02534 15.1227 6.02534H13.0614C12.7471 6.02534 12.4975 6.25692 12.4975 6.54853C12.4975 6.84015 12.7563 7.07173 13.0614 7.07173H14.5496V9.8421H12.3681C12.0538 9.8421 11.8042 10.0737 11.8042 10.3653C11.8042 10.6569 12.063 10.8885 12.3681 10.8885H18.663V18.745H14.3093V16.7209C14.3093 16.4292 14.0504 16.1977 13.7454 16.1977C13.4403 16.1977 13.1815 16.4292 13.1815 16.7209V18.745H3.33699V10.8885Z"
        fill="#BCC8D9"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect
          width="17.6"
          height="17.6"
          fill="white"
          transform="translate(2.20001 2.2)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default InteriorsIcon;
