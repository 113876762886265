import { mapValues } from 'lodash';
import { useMemo } from 'react';
import { useDispatch } from 'react-redux';

const useActions = (actions) => {
  const dispatch = useDispatch();

  const boundActions = useMemo(
    () => mapValues(actions, (a) => (...args) => dispatch(a(...args))),
    [actions, dispatch],
  );

  return boundActions;
};

export default useActions;
