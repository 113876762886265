import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';

const useStyles = makeStyles((theme) => ({
  root: {
    background: '#fafafa',
    boxShadow: 'none',
    margin: '0 0 1.4rem 0',
    padding: theme.spacing(3, 4.5),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
}));

const Panel = ({ children }) => {
  const classes = useStyles();

  return <Paper classes={ classes }>{children}</Paper>;
};

export default Panel;
