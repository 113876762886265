import React, { useCallback, useEffect, useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Snackbar from '@material-ui/core/Snackbar';
import { Alert } from '@material-ui/lab';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import Menu from '@shared/components/menu';
import TopBar from '@shared/components/topbar';
import Routes from './routes';

import useActions from '@shared/hooks/useActions';
import { actions } from '@store/app';


import '@resources/styles/main.scss';
import './index.css';

const styles = (theme) => ({
  root: {
    display: 'flex',
    width: '100%',
    height: '100%',
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(1),
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    'padding-top': '64px',
    position: 'relative',
  },
  alert: {
    maxWidth: '350px',
    fontSize: '14px',
  },
});

const App = ({ classes }) => {
  const loading = useSelector((state) => state.app.loading);
  const user = useSelector((state) => state.app.user);
  const messages = useSelector((state) => state.app.messages);
  const { initApp, removeMessage } = useActions(actions);

  const handleSnackbarClose = useCallback(() => {
    removeMessage();
  }, [removeMessage]);

  const handleAlertClose = useCallback(() => {
    removeMessage();
  }, [removeMessage]);

  useEffect(() => {
    initApp();
  }, [initApp]);

  const [restSearch, setRestSearch] = useState(false);

  return loading ? null : (
    <React.StrictMode>
      <div className={ classes.root }>
        { user && (
        <>
          <TopBar resetSearch={ restSearch } />
          <Menu open onSelect={ () => (setRestSearch(!restSearch)) } />
        </>
        )}
        <main className={ classes.content }>
          <Routes />
        </main>

        {
          messages.length && (
          <Snackbar
            open={ !!messages.length }
            autoHideDuration={ 3000 }
            onClose={ handleSnackbarClose }
            anchorOrigin={ { vertical: 'bottom', horizontal: 'center' } }
          >
            <Alert onClose={ handleAlertClose } severity={ messages[0]?.type || 'info' } className={ classes.alert }>
              {messages[0]?.message}
            </Alert>
          </Snackbar>
          )
        }
      </div>
    </React.StrictMode>
  );
};

App.propTypes = {
  classes: PropTypes.shape({
    root: PropTypes.string,
    content: PropTypes.string,
  }),
};

export default withStyles(styles, { withTheme: true })(App);
