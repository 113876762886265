import classNames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  squareWrapper: {
    width: '100%',
    paddingTop: '100%',
    position: 'relative',
  },
  squareFiller: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
  },
}));

export default function SquareWrapper({ className, children, wrap = true }) {
  const classes = useStyles();

  return wrap ? (
    <div className={ classes.squareWrapper }>
      <div className={ classNames(classes.squareFiller, className) }>
        { children }
      </div>
    </div>
  ) : children;
}
