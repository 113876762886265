import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
  },
}));

const Container = ({ children }) => {
  const classes = useStyles();

  return <div className={ classes.root }>{children}</div>;
};

export default Container;
