import React, { useRef } from 'react';
import useOutsideClickListener from '@shared/hooks/useOutsideClickListener';

export default function OutsideClickAwaredDiv({ onClickOutside, ...restProps }) {
  const wrapperRef = useRef(null);
  useOutsideClickListener(wrapperRef, onClickOutside);

  return (
    <div ref={ wrapperRef } { ...restProps } />
  );
}
