// eslint-disable-next-line import/prefer-default-export
export const ROUTES = {
  HOME: '/',
  PROFILE: '/profile',
  LOGIN: '/login',
  REGISTER: '/register',
  INTERIORS: '/interiors',
  MODELS: '/models',
  SCENES: {
    ROOT: '/scenes',
    NEW: '/create/new',
    EDIT: (id = ':id') => `/${id}`,
  },
  COMPANIES: '/companies',
  USERS: '/users',
  PRODUCTS: {
    ROOT: '/products',
  },
  MATERIALS: '/materials',
  STUDIO: {
    ROOT: '/studios',
    NEW: '/create/new',
    EDIT: (id = ':id') => `/${id}`,
  },
  ATTRIBUTE_DEFINITIONS: '/attributeDefinitions',
  STYLING: '/styling',
};
