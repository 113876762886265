import { memo } from 'react';
import Toggle from '@shared/components/toggle';
import DetailsContainer from '../DetailsContainer';
import TextField from '../TextField';
import {
  BaseFieldset, DirectionalLightFieldset, PointLightFieldset, SpotLightFieldset,
} from './fieldset';
import {
  LightType,
  LightGroup,
} from './constants';

import SpotLightOptions from './SpotLightOptions';
import DirectionalLightOptions from './DirectionalLightOptions';
import ElectricLightOptions from './ElectricLightOptions';
import ShadowController from './shadow/Shadow';

const LightOptions = ({
  details,
  sceneBox,
  onChange,
}) => {
  switch (details[BaseFieldset.Type]) {
    case LightType.SpotLight:
      return details[BaseFieldset.Preset] && (
        <ElectricLightOptions options={ details } onChange={ onChange }>
          <SpotLightOptions
            options={ details }
            sceneBox={ sceneBox }
            onChange={ onChange }
          />
          <ShadowController name={ SpotLightFieldset.IsShadow } value={ details[SpotLightFieldset.IsShadow] } onChange={ onChange } />
        </ElectricLightOptions>
      );

    case LightType.DirectionalLight:
      return details[BaseFieldset.Preset] && (
        <DirectionalLightOptions
          options={ details }
          sceneBox={ sceneBox }
          onChange={ onChange }
        >
          <ShadowController name={ DirectionalLightFieldset.IsShadow } value={ details[DirectionalLightFieldset.IsShadow] } onChange={ onChange } />
        </DirectionalLightOptions>
      );

    case LightType.PointLight:
      return details[BaseFieldset.Preset] && (
        <ElectricLightOptions options={ details } onChange={ onChange }>
          <ShadowController name={ PointLightFieldset.IsShadow } value={ details[PointLightFieldset.IsShadow] } onChange={ onChange } />
        </ElectricLightOptions>
      );

    default:
      return null;
  }
};

const LightDetails = ({
  details,
  sceneBox,
  onChange,
  isEditable,
}) => {
  const type = details[BaseFieldset.Type];

  const handleTextChange = ({ target }) => {
    onChange({ value: target.value }, target.name);
  };

  const handleGroupChange = (name, value) => {
    if (value) {
      onChange({ value }, name);
    }
  };

  const lightGroup = [LightType.SpotLight, LightType.PointLight].includes(type)
    ? LightGroup.Electric.key
    : LightGroup.Natural.key;

  return (
    <DetailsContainer key={ details.id } isEditable={ isEditable }>
      <TextField
        label="Name"
        name={ BaseFieldset.Name }
        value={ details[BaseFieldset.Name] }
        onChange={ handleTextChange }
      />
      <Toggle
        name={ BaseFieldset.Group }
        onChange={ handleGroupChange }
        options={ [LightGroup.Electric, LightGroup.Natural] }
        value={ lightGroup }
      />
      <LightOptions
        details={ details }
        sceneBox={ sceneBox }
        onChange={ onChange }
      />
    </DetailsContainer>
  );
};

export default memo(LightDetails);
