/* eslint-disable max-classes-per-file */
export const getHeightAndWidthFromFile = (file) => new Promise((resolve) => {
  const fileAsDataURL = window.URL.createObjectURL(file);
  const img = new Image();
  img.onload = () => {
    resolve({
      height: img.height,
      width: img.width,
    });
  };
  img.src = fileAsDataURL;
});

export const getHeightAndWidthFromURL = (url) => new Promise((resolve) => {
  const img = new Image();
  img.onload = () => {
    resolve({
      height: img.height,
      width: img.width,
    });
  };
  img.src = url;
});

export const convertFileToBase64 = (file) => new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);

  reader.onload = () => resolve(reader.result);
  reader.onerror = reject;
});

export const mongoObjectId = function () {
  const timestamp = (new Date().getTime() / 1000 | 0).toString(16);
  return timestamp + 'xxxxxxxxxxxxxxxx'.replace(/[x]/g, () => (Math.random() * 16 | 0).toString(16)).toLowerCase();
};

export const convertMillimetersToMeters = (millimeters) => millimeters / 1000;

export const formatSize = (value, decimalPlaces = 2) => value.toFixed(decimalPlaces);

export const convertMetersToMillimeters = (meters) => Number(formatSize(meters * 1000));

export const withDefaultModelSize = (size) => size || 0;

export const valueToFixedNumber = (value, fractionDigits = 0) => (Number.isNaN(value) ? 0 : Number(Number(value).toFixed(fractionDigits)));

export const displayValue = (value) => (value && !Number.isNaN(value) && value !== '-' ? valueToFixedNumber(value, 2) : value);

export const convertQuaternionToRadiansAngle = (quaternion, axis) => {
  const angle = 2 * Math.acos(quaternion.w);
  return (quaternion[axis] / Math.sqrt(1 - (quaternion.w ** 2))) * angle;
};

export class Color {
  static hexToRgb(hex) {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? {
      r: parseInt(result[1], 16),
      g: parseInt(result[2], 16),
      b: parseInt(result[3], 16),
    } : null;
  }

  static componentToHex(c) {
    const hex = parseInt(c, 10).toString(16).padStart(2, '0');
    return hex.length === 1 ? `0${hex}` : hex;
  }

  static rgbToHex(r, g, b) {
    return this.componentToHex(r) + this.componentToHex(g) + this.componentToHex(b);
  }
}

export class ModelColor {
  static hexToModelHex(hex) {
    return `0x${hex}`;
  }

  static modelHexToHex(hex) {
    return hex.slice(2);
  }
}

export const extractErrorMessage = (error) => error.response?.data?.message || error.message;

export const calcSceneDiagonal = (
  sceneBox,
) => {
  const top = sceneBox.ceiling[0].y;
  const bottom = sceneBox.floor[0].y;
  const left = 0;
  const right = 0;
  const back = 0;
  const front = 0;
  return Math.sqrt(
    Math.abs(right - left) ** 2
    + Math.abs(top - bottom) ** 2
    + Math.abs(front - back) ** 2,
  );
};
