import {
  useState, useCallback, useMemo, memo, useEffect,
} from 'react';
import { DefaultViewer, ViewerTools, ImageFit, useInteriorFit } from '@zolak/zolak-viewer';
import * as BufferGeometryUtils from 'three/addons/utils/BufferGeometryUtils.js';

import useEventListener from '@shared/hooks/event-listener';
import { ModelColor } from '@shared/utils/utils';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import { ThemeProvider } from 'theming';
import { defaultTheme } from '@shared/constants';
import { useInterval } from '@shared/hooks/useInterval';

const useStyles = makeStyles(() => ({
  zViewerInnerWrapper: {
    position: 'absolute',
    height: '100%',
    width: '100%',
  },
}));

const ZViewer = ({
  rootRef,
  className,
  env,
  envIntensity,
  envRotation,
  interior,
  lights,
  globalLightColor,
  globalLightIntensity,
  models,
  viewerMode,
  softShadows,
  size,
  samples,
  focus,
  shadowOpacity,
  onObjectClick,
  onObjectUpdate,
  onObjectDelete,
  onObjectHide,
  onObjectCopy,
  setCanvasRef,
  disableControls,
  onLoad,
  displayIndicators = false,
  disableToolbar = false,
  sceneUrl,
  threeRef,
  toneMapping = 'ACESFilmicToneMapping',
  toneMappingExposure = 1,
}) => {
  const classes = useStyles();
  const [zViewerElement, setZViewerElement] = useState(null);
  const [objects, setObjects] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const [defaultCamera, setDefaultCamera] = useState({
    position: [0, 0, 0],
    rotation: [0, 0, 0],
  });
  const [enableObjectsMove, setEnableObjectsMove] = useState(false);
  const [textureMemoryUsage, setTextureMemoryUsage] = useState(0);

  const [, interiorStyles] = useInteriorFit({
    root: rootRef.current,
    interior,
    type: 'contain',
  });

  useEffect(() => {
    setDefaultCamera({
      position: [0, 0, 0],
      rotation: [0, 0, 0],
    });
  }, [viewerMode]);

  useEffect(() => {
    const selectedModel = models.find((model) => model.selected);

    if (!selectedModel || selectedModel.hidden) {
      setEnableObjectsMove(false);
    }
  }, [models]);

  useEventListener('zolak:update:light', (event) => {
    setEnableObjectsMove(event?.detail?.isHoverLight);
  }, zViewerElement);

  const handleSetCanvasRef = useCallback((node) => {
    if (setCanvasRef) {
      setCanvasRef(node);
    }

    setZViewerElement(node);
  }, [setCanvasRef]);

  const viewerData = useMemo(() => ({
    url: sceneUrl,
    camera: interior.layout?.viewport || defaultCamera,
    interior,
    models,
    lights: lights.map((light) => ({
      ...light,
      color: ModelColor.hexToModelHex(light.color.color),
      // VG
    })),
    globalLight: {
      type: 'ambientLight',
      color: globalLightColor,
      intensity: globalLightIntensity,
    },
    objectClickable: displayIndicators,
    disableToolbar,
    env,
    envIntensity,
    envRotation,
    softShadows: {
      enabled: softShadows,
      size,
      samples,
      focus,
    },
    shadowOpacity,
    toneMapping,
    toneMappingExposure,
  }), [
    sceneUrl,
    defaultCamera,
    interior,
    lights,
    models,
    displayIndicators,
    disableToolbar,
    env,
    envIntensity,
    envRotation,
    softShadows,
    size,
    samples,
    focus,
    shadowOpacity,
    toneMapping,
    toneMappingExposure,
    globalLightColor,
    globalLightIntensity,
  ]);
  const userRoom = useMemo(() => ({
    ...viewerData,
    getItems: () => viewerData.models.map((model) => ({
      ...model,
      type: 'model',
    })),
    getItemById: (id) => ({
      ...viewerData.models.find((model) => model.id === id),
      type: 'model',
    }),
    getItemCategories: (item) => item.attributes
      ?.find((a) => a.definition.name == 'Category')?.value?.enumValue ?? viewerData.categories,
    getSelectedItem: () => ({
      ...viewerData.models.find((model) => model.selected),
      type: 'model',
    }),
  }), [viewerData]);

  const handleMoveClick = useCallback(() => {
    setEnableObjectsMove((prevEnabled) => !prevEnabled);
  }, []);

  const handleToolbarClose = () => {
    onObjectClick();
    setEnableObjectsMove(false);
  };

  useInterval(() => {
    let result = 0;
    const counted = [];
    objects.forEach((object) => {
      object.traverse((mesh) => {
        if (mesh.isMesh && mesh.textureGpuUsage && counted.indexOf(mesh.material.uuid) === -1) {
          result += mesh.textureGpuUsage;
          counted.push(mesh.material.uuid);
        }
      });
    });
    setTextureMemoryUsage(Math.floor((result / (1024 * 1024)) * 100) / 100);
  }, 5000);

  const modelsMemoryUsage = useMemo(() => {
    let result = 0;
    const counted = [];
    objects.forEach((object) => {
      object.traverse((mesh) => {
        if (mesh.isMesh && mesh.geometry && counted.indexOf(mesh.geometry.uuid) === -1) {
          result += BufferGeometryUtils.estimateBytesUsed(mesh.geometry);
          counted.push(mesh.geometry.uuid);
        }
      });
    });
    return Math.floor((result / (1024 * 1024)) * 100) / 100;
  }, [objects]);

  return (
    <ThemeProvider theme={ defaultTheme }>
      <ImageFit>
        <div className={ className } style={ interiorStyles }>
          <div className={ classNames(classes.zViewerInnerWrapper, 'DraggableItemsWrapper') }>
            <DefaultViewer
              ref={ handleSetCanvasRef }
              threeRef={ threeRef }
              data={ viewerData }
              mode={ viewerMode }
              objectClickable={ displayIndicators }
              onObjectClick={ onObjectClick }
              onObjectUpdate={ onObjectUpdate }
              onLoad={ (isLoaded, objects) => {
                setObjects([...objects]);
                setIsLoaded(isLoaded);
                onLoad(isLoaded);
              } }
              disableControls={ disableControls }
              editable
              enableObjectsMove={ enableObjectsMove }
            />
            {
              isLoaded && (
                <ViewerTools
                  threeRef={ threeRef }
                  userRoom={ userRoom }
                  isPreviewModeActive={ disableControls }
                  objects={ objects }
                  enableObjectsMove={ enableObjectsMove }
                  mode="3d"
                  onObjectUpdate={ onObjectUpdate }
                  onObjectDelete={ onObjectDelete }
                  onObjectHide={ onObjectHide }
                  onObjectClick={ onObjectClick }
                  onObjectCopy={ onObjectCopy }
                  onMove={ handleMoveClick }
                  onClose={ handleToolbarClose }
                />
              )
            }
            <div style={ { position: 'absolute', bottom: 0, left: 0 } }>
              VRAM Usage:
              Textures { textureMemoryUsage } Mb
              Models { modelsMemoryUsage } Mb
              Total { Math.floor((textureMemoryUsage + modelsMemoryUsage) * 100) / 100 } Mb
            </div>
          </div>
        </div>
      </ImageFit>
    </ThemeProvider>
  );
};

export default memo(ZViewer);
