import { forwardRef } from 'react';
import MuiDialogContent from '@material-ui/core/DialogContent';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  dividers: {
    background: theme.palette.background.default,
  },
}));

const DialogContent = forwardRef(({
  children,
  className,
  dividers,
}, ref) => {
  const classes = useStyles();

  return (
    <MuiDialogContent
      ref={ ref }
      className={ className }
      classes={ classes }
      dividers={ dividers }
    >
      {children}
    </MuiDialogContent>
  );
});

export default DialogContent;
