import { useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import PageContainer from '@shared/components/page-parts/Container';
import PageHead from '@shared/components/page-parts/Head';
import PageContent from '@shared/components/page-parts/Content';
import TopNav from '@shared/components/top-nav/Nav';
import CardsList from '@shared/components/cards-list/CardsList';
import CardItem from '@shared/components/cards-list/CardItem';

import CreateModelFormDialog from '../../modal/create/FormDialog';
import EditModelFormDialog from '../../modal/edit/FormDialog';
import CopyModelFormDialog from '../../modal/copy/FormDialog';

import Model from './Model';
import Controls from './Controls';

import {
  deleteModel, deleteModelError,
  hideModel,
  markModelAsDefaultContent,
  resetModelScenes,
  unmarkModelAsDefaultContent,
} from '@store/models/actions';

import { ROUTES } from '@app/routes/routesUrls';
import Breadcrumbs from '@shared/components/breadcrumbs';
import AddNew from '@shared/components/cards-list/AddNew';

const ActionName = {
  Edit: 'Edit',
  Copy: 'Copy',
  AddNew: 'Add New',
  Idle: 'Idle',
};

const ModelListGrid = ({ data }) => {
  const dispatch = useDispatch();
  const apiError = useSelector((state) => state.models.error);

  const [actionName, setActionName] = useState(null);

  const [wipItem, setWipItem] = useState(null);

  const handleCreateModel = () => {
    setActionName(ActionName.AddNew);
  };

  const handleEditModelFactory = useCallback((item) => () => {
    setWipItem(item);
    setActionName(ActionName.Edit);
  }, []);

  const handleCopyModelFactory = useCallback((item) => () => {
    setWipItem(item);
    setActionName(ActionName.Copy);
  }, []);

  const handleHideModel = useCallback((item) => () => {
    dispatch(hideModel(item));
  }, [dispatch]);

  const resetScenes = () => {
    dispatch(resetModelScenes());
  };

  const handleDeleteModelFactory = (item) => () => {
    const { id } = item;
    dispatch(deleteModel(id));
  };

  const handleApiErrorClear = () => {
    dispatch(deleteModelError());
  };

  const handleFormClose = () => {
    setActionName(ActionName.Idle);
  };

  const handleShareModel = useCallback((item) => () => {
    if (item.defaultContent) {
      dispatch(unmarkModelAsDefaultContent(item));
    } else {
      dispatch(markModelAsDefaultContent(item));
    }
  }, [dispatch]);

  const breadcrumbsItems = useMemo(() => [
    { name: 'Models', link: ROUTES.MODELS },
  ], []);

  return (
    <PageContainer>
      <PageHead>
        <TopNav>
          <Breadcrumbs items={ breadcrumbsItems } />
        </TopNav>
      </PageHead>
      <PageContent>
        {data.length > 0 ? (
          <CardsList>
            <CardItem>
              <AddNew onClick={ handleCreateModel } />
            </CardItem>
            {data.map((item) => (
              <CardItem key={ item.id }>
                <Model
                  item={ item }
                  controls={ (
                    <Controls
                      apiError={ apiError }
                      onEdit={ handleEditModelFactory(item) }
                      onCopy={ handleCopyModelFactory(item) }
                      resetScenes={ resetScenes }
                      onDelete={ handleDeleteModelFactory(item) }
                      onHide={ handleHideModel(item) }
                      onShare={ handleShareModel(item) }
                      onApiErrorClear={ handleApiErrorClear }
                      item={ item }
                    />
                  ) }
                  onClick={ handleEditModelFactory(item) }
                />
              </CardItem>
            ))}
          </CardsList>
        ) : (
          <CardsList>
            <CardItem>
              <AddNew onClick={ handleCreateModel } />
            </CardItem>
          </CardsList>
        )}
        <CreateModelFormDialog
          open={ actionName === ActionName.AddNew }
          onClose={ handleFormClose }
        />
        <EditModelFormDialog
          open={ actionName === ActionName.Edit }
          data={ wipItem }
          onClose={ handleFormClose }
        />
        <CopyModelFormDialog
          open={ actionName === ActionName.Copy }
          data={ wipItem }
          onClose={ handleFormClose }
        />
      </PageContent>
    </PageContainer>
  );
};

export default ModelListGrid;
