import { makeStyles } from '@material-ui/core';
import SpotCenterIcon from '@pages/scenes/child-details/LightDetails/icons/SpotCenterIcon';
import Toggle from '@shared/components/toggle';
import { SpotLightFieldset } from '../fieldset';
import { SubTitle } from '@shared/views/form-dialog';

const useLabelStyles = makeStyles((theme) => ({
  text: {
    marginLeft: theme.spacing(1),
  },
}));

const SelectSpotCenterLabel = ({ isActive }) => {
  const classes = useLabelStyles();
  return (
    <>
      <SpotCenterIcon size="large" selected={ isActive } />
      <span className={ classes.text }>Set the point</span>
    </>
  );
};

const SelectSpotCenterOption = ({
  name,
  value,
  onChange,
  label,
}) => (
  <>
    <SubTitle align="center">{label}</SubTitle>
    <Toggle
      name={ name }
      onChange={ onChange }
      options={ [
        { key: SpotLightFieldset.SelectSpotCenter, label: <SelectSpotCenterLabel isActive={ !!value } /> },
      ] }
      value={ value ? SpotLightFieldset.SelectSpotCenter : null }
    />
  </>
);

export default SelectSpotCenterOption;
