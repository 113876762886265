import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import EditIcon from '@resources/icons/EditIcon';
import DeleteIcon from '@resources/icons/DeleteIcon';

import CardControls from '@shared/components/card-controls';
import Control from '@shared/components/card-controls/Control';
import AlertDialog from '@shared/components/card-controls/AlertDialog';
import useControls from '@shared/components/card-controls/useControls';
import useAlertControl, {
  Types,
} from '@shared/components/card-controls/useAlertControl';
import IconToggler from '@shared/components/icon-toggler';
import ClosedEye from '@resources/icons/ClosedEye';
import OpenedEye from '@resources/icons/OpenedEye';
import CopyIcon from '@resources/icons/CopyIcon';
import ShareIcon from '@resources/icons/ShareIcon';
import { selectCurrentCompany } from '@store/app/selectors';

const Controls = ({
  onEdit,
  onDelete,
  onHide,
  onCopy,
  onShare,
  item,
}) => {
  const company = useSelector(selectCurrentCompany);
  const {
    open, handleMoreIconClick, handleClickOutside, handleControlClick,
  } = useControls();

  const [
    alertOpen,
    {
      handleAlertControlClick, handleAgree, handleDisagree, ariaAction, title,
    },
  ] = useAlertControl();

  const [deleteProcessing, setDeleteProcessing] = useState(false);

  useEffect(() => {
    if (deleteProcessing) {
      handleAlertControlClick(Types.Delete, onDelete)();
      setDeleteProcessing(false);
    }
  }, [
    deleteProcessing,
    handleAlertControlClick,
    onDelete,
  ]);

  const handleDeleteClick = (event) => {
    event.stopPropagation();
    setDeleteProcessing(true);
  };

  return (
    <>
      <CardControls
        open={ open }
        onMoreIconClick={ handleMoreIconClick }
        onClickOutside={ handleClickOutside }
      >
        {company?.default && !item.hidden && (
          <Control onClick={ handleControlClick(onShare) }>
            <ShareIcon size="small" />
          </Control>
        )}
        {item.defaultContent
          ? (
            <>
              {
                !company.default && (
                <Control onClick={ handleControlClick(onHide) }>
                  <IconToggler
                    activeIcon={ ClosedEye }
                    inactiveIcon={ OpenedEye }
                    isActive={ item.hidden }
                  />
                </Control>
                )
              }
              {company.default && (
                <Control onClick={ handleControlClick(onEdit) }>
                  <EditIcon />
                </Control>
              )}
              <Control onClick={ handleControlClick(onCopy) }>
                <CopyIcon />
              </Control>
              {company.default && (
                <Control onClick={ handleDeleteClick }>
                  <DeleteIcon />
                </Control>
              )}
            </>
          ) : (
            <>
              <Control onClick={ handleControlClick(onHide) }>
                <IconToggler
                  activeIcon={ ClosedEye }
                  inactiveIcon={ OpenedEye }
                  isActive={ item.hidden }
                />
              </Control>
              <Control onClick={ handleControlClick(onEdit) }>
                <EditIcon />
              </Control>
              <Control onClick={ handleControlClick(onCopy) }>
                <CopyIcon />
              </Control>
              <Control onClick={ handleDeleteClick }>
                <DeleteIcon />
              </Control>
            </>
          )}
      </CardControls>
      <AlertDialog
        open={ alertOpen }
        ariaAction={ ariaAction }
        title={ title }
        onAgree={ handleControlClick(handleAgree) }
        onDisagree={ handleDisagree }
      />
    </>
  );
};

export default Controls;
