import * as math from 'mathjs';
import * as Point2 from './point2';
import { Matrix } from 'mathjs';
import { Point2D, Point3D } from '../types';

const N = 0.01;
const F = 10;
const EYE = math.matrix([
  [1, 0, 0, 0],
  [0, 1, 0, 0],
  [0, 0, 1, 0],
  [0, 0, 0, 1],
]);

export interface ProjectionProps {
    width: number
    height: number
    focalLength: number
    principalPoint: Point2D,
}

export function projectionTransform(viewProps: ProjectionProps): Matrix {
  const { focalLength, principalPoint, width, height } = viewProps;
  const P = Point2.ndc(principalPoint, { width, height });
  const s = 2 * focalLength / width;

  return math.matrix([
    [s, 0, -P.x, 0],
    [0, s, -P.y, 0],
    [0, 0, -(F + N) / (F - N), -2 * F * N / (F - N)],
    [0, 0, -1, 0],
  ]);
}

export function unproject(point: Point3D, viewProps: ProjectionProps, viewTransform: Matrix = EYE): Point3D {
  const transform = math.inv(math.multiply(projectionTransform(viewProps), viewTransform));
  const clip = math.multiply(transform, math.matrix([[point.x], [point.y], [point.z], [1]]));

  return {
    x: clip.get([0, 0]) / clip.get([3, 0]),
    y: clip.get([1, 0]) / clip.get([3, 0]),
    z: clip.get([2, 0]) / clip.get([3, 0]),
  };
}
