import Typography from '@material-ui/core/Typography';

const SubTitle = ({
  children,
  id,
  ...props
}) => (
  <Typography
    id={ id }
    variant="h5"
    component="h3"
    gutterBottom
    { ...props }
  >
    {children}
  </Typography>
);

export default SubTitle;
