import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import ToggleButton from '@material-ui/lab/ToggleButton';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    border: 'none',
    background: theme.palette.common.lightBlue,
    fontSize: '14px',
    color: theme.palette.common.darkBlue,
    textTransform: 'none',
    cursor: 'pointer',
    '&.Mui-selected': {
      borderRadius: theme.spacing(0.5),
      background: theme.palette.common.darkGrey,
      color: theme.palette.common.white,
      zIndex: 1,
      '&:hover': {
        background: theme.palette.common.darkGrey,
        color: theme.palette.common.white,
      },
    },
  },
}));

const Toggle = ({
  name,
  value,
  className,
  onChange,
  options,
  size = 'small',
  isMultiSelectable = false,
}) => {
  const classes = useStyles();

  const handleChange = (_, value) => {
    onChange(name, value);
  };

  return (
    <ToggleButtonGroup
      className={ className }
      value={ value }
      exclusive={ !isMultiSelectable }
      onChange={ handleChange }
    >
      {options.map(({ key, label, disabled }) => (
        <ToggleButton
          key={ key }
          size={ size }
          value={ key }
          classes={ classes }
          disabled={ disabled }
        >
          {label}
        </ToggleButton>
      ))}
    </ToggleButtonGroup>
  );
};

export default Toggle;
