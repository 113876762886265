import { forwardRef } from 'react';
import SharedDialogContent from '@shared/components/dialog/DialogContent';

const DialogContent = forwardRef(({
  children,
}, ref) => (
  <SharedDialogContent ref={ ref } dividers>
    {children}
  </SharedDialogContent>
));


export default DialogContent;
