/* eslint-disable react/no-unknown-property */
import * as React from 'react';
import {
  useBounds,
  Bounds,
  AccumulativeShadows,
  ContactShadows,
  Center,
  RandomizedLight,
} from '@react-three/drei';

const presets = {
  rembrandt: {
    main: [1, 2, 1],
    fill: [-2, -0.5, -2],
  },
  portrait: {
    main: [-1, 2, 0.5],
    fill: [-1, 0.5, -1.5],
  },
  upfront: {
    main: [0, 2, 1],
    fill: [-1, 0.5, -1.5],
  },
  soft: {
    main: [-2, 4, 4],
    fill: [-1, 0.5, -1.5],
  },
};

function Refit({ radius, adjustCamera }) {
  const api = useBounds();
  React.useEffect(() => {
    if (adjustCamera) api.refresh().clip().fit();
  }, [radius, adjustCamera]);
  return null;
}

function Stage({
  children,
  center,
  adjustCamera = true,
  intensity = 0.5,
  ambient,
  ambientIntensity = 0.15,
  shadows = 'contact',
  preset = 'rembrandt',
  ...props
}) {
  const shadowsApi = React.useRef();
  const config = typeof preset === 'string' ? presets[preset] : preset;
  const [{ radius, height }, set] = React.useState({ radius: 0, width: 0, height: 0, depth: 0 });
  const shadowBias = shadows?.bias ?? -0.0001;
  const normalBias = shadows?.normalBias ?? 0;
  const shadowSize = shadows?.size ?? 1024;
  const shadowOffset = shadows?.offset ?? 0;
  const contactShadow = shadows === 'contact' || shadows?.type === 'contact';
  const accumulativeShadow = shadows === 'accumulative' || shadows?.type === 'accumulative';
  const shadowSpread = { ...(typeof shadows === 'object' ? shadows : {}) };
  const onCentered = React.useCallback((props) => {
    const { width, height, depth, boundingSphere } = props;
    set({ radius: boundingSphere.radius, width, height, depth });
    if (center?.onCentered) center.onCentered(props);
  }, []);
  return (
    <>
      <ambientLight intensity={ ambientIntensity } color={ `#${ambient?.color}` } />
      <spotLight
        penumbra={ 1 }
        position={ [config.main[0] * radius, config.main[1] * radius, config.main[2] * radius] }
        intensity={ intensity }
        castShadow={ !!shadows }
        shadow-bias={ shadowBias }
        shadow-normalBias={ normalBias }
        shadow-mapSize={ shadowSize }
      />
      <pointLight
        position={ [config.fill[0] * radius, config.fill[1] * radius, config.fill[2] * radius] }
        intensity={ intensity }
      />
      <Bounds fit={ !!adjustCamera } clip={ !!adjustCamera } margin={ Number(adjustCamera) } { ...props }>
        <Refit radius={ radius } adjustCamera={ adjustCamera } />
        <Center { ...center } position={ [0, shadowOffset / 2, 0] } onCentered={ onCentered }>
          {children}
        </Center>
      </Bounds>
      <group position={ [0, -height / 2 - shadowOffset / 2, 0] }>
        {contactShadow && (
          <ContactShadows scale={ radius * 4 } far={ radius } blur={ 2 } { ...shadowSpread } />
        )}
        {accumulativeShadow && (
          <AccumulativeShadows
            ref={ shadowsApi }
            temporal={ false }
            frames={ 100 }
            alphaTest={ 0.31 }
            toneMapped
            scale={ radius * 6 }
            { ...shadowSpread }
          >
            <RandomizedLight
              amount={ shadowSpread.amount ?? 8 }
              radius={ shadowSpread.radius ?? radius }
              ambient={ shadowSpread.ambient ?? 0.5 }
              intensity={ shadowSpread.intensity ?? 1 }
              position={ [config.main[0] * radius, config.main[1] * radius, config.main[2] * radius] }
              size={ radius * 4 }
              bias={ -shadowBias }
              mapSize={ shadowSize }
            />
          </AccumulativeShadows>
        )}
      </group>
    </>
  );
}

export default Stage;
