import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import SceneListGrid from '@pages/scenes/list/grid';
import { searchStringSelector, selectSortingId } from '@store/app/selectors';
import sortData from '@app/sort';
import filterData from '@app/filter/filterData';
import { selectScenes } from '@store/scenes/selectors';
import { selectAttributesFilterValue } from '@store/attribute-definitions/selectors';
import FilterContainer from '@shared/components/attributes-filter/FilterContainer';
import filterByAttributes from '@shared/components/attributes-filter';
import FunctionalityUnavailable from '@pages/FuntionalityUnavailable';

const SceneList = () => {
  const companySelector = (state) => state.app.company;

  const scenes = useSelector(selectScenes);
  const sortingId = useSelector(selectSortingId);
  const searchString = useSelector(searchStringSelector);
  const attributesFilterValue = useSelector(selectAttributesFilterValue);
  const company = useSelector(companySelector);

  const items = useMemo(() => {
    const filteredItems = filterData(scenes, searchString);
    const filteredByAttributesItems = filterByAttributes(filteredItems, attributesFilterValue);
    return sortData(filteredByAttributesItems, sortingId);
  }, [scenes, searchString, sortingId, attributesFilterValue]);

  return (
    <>
      {company?.scenesAccess ? (
        <FilterContainer>
          <SceneListGrid data={ items } />
        </FilterContainer>
      ) : (
        <>
          <FunctionalityUnavailable />
          <SceneListGrid data={ items } />
        </>
      )}
    </>
  );
};

export default SceneList;
