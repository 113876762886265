import { useCallback, useState } from 'react';

const useControls = () => {
  const [open, setOpen] = useState(false);

  const handleMoreIconClick = useCallback((event) => {
    event.stopPropagation();
    setOpen((prevOpen) => !prevOpen);
  }, []);

  const handleClickOutside = useCallback(() => {
    setOpen(false);
  }, []);

  const handleControlClick = (callback) => (event) => {
    event.stopPropagation();
    setOpen(false);
    callback();
  };

  return {
    open,
    handleMoreIconClick,
    handleClickOutside,
    handleControlClick,
  };
};

export default useControls;
