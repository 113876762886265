import { convertFileToBase64 } from '@shared/utils';

const ENDPOINT = '/interiors';

class InteriorsApi {
  constructor(http) {
    this.http = http;
  }

  loadInteriors = () => this.http.get(`${ENDPOINT}`);

  getInteriorById = (id) => this.http.get(`${ENDPOINT}/${id}`);

  getInteriorUsageById = (id) => this.http.get(`${ENDPOINT}/${id}/usage`);

  getInteriorsItemsCounts = () => this.http.get(`${ENDPOINT}/counts`);

  loadInteriorScenes = (id) => this.http.get(`${ENDPOINT}/${id}/scenes`);

  createInterior = async (interior) => {
    const image = interior.image?.[0];
    delete interior.image;

    const data = await this.http.post(ENDPOINT, {
      ...interior,
      id: undefined,
    });

    if (image) {
      const picture64 = await convertFileToBase64(image);
      const dataUpload = await this.http.post(`${ENDPOINT}/${data.id}/upload`, {
        src: picture64,
        title: `${image.name}`,
        type: image.type,
      });
      data.url = dataUpload.url;
    }

    return data;
  };

  updateInterior = async (interior) => {
    const image = interior.image?.[0];
    delete interior.image;

    const data = await this.http.put(`${ENDPOINT}/${interior.id}`, interior);

    if (image) {
      const picture64 = await convertFileToBase64(image);
      const dataUpload = await this.http.post(`${ENDPOINT}/${data.id}/upload`, {
        src: picture64,
        title: `${image.name}`,
        type: image.type,
      });
      data.url = dataUpload.url;
    }

    return data;
  };

  deleteInteriorById = (id) => this.http.delete(`${ENDPOINT}/${id}`);

  markInteriorAsDefault = (id) => this.http.put(`${ENDPOINT}/markAsDefault`, { id });

  markInteriorAsDefaultContent = (id) => this.http.put(`${ENDPOINT}/${id}/markAsDefaultContent`);

  unmarkInteriorAsDefaultContent = (id) => this.http.put(`${ENDPOINT}/${id}/unmarkAsDefaultContent`);

  hideInterior = (id) => this.http.put(`${ENDPOINT}/${id}/hide`);
}

export default InteriorsApi;
