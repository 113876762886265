import { memo, useCallback } from 'react';
import Grid from '@material-ui/core/Grid';
import SubTitle from '../SubTitle';
import AxisFields from '../AxisFields';
import Control from './Control';
import XRotateIcon from '@resources/icons/Xrotate';
import YRotateIcon from '@resources/icons/Yrotate';
import ZRotateIcon from '@resources/icons/Zrotate';
import { displayValue } from '@shared/utils';

const Rotation = ({
  name,
  value = [0, 0, 0],
  label,
  disabled = [false, false, false],
  onChange,
  step,
}) => {
  const handleXControlChange = useCallback(({
    name: fieldName,
    value: newValue,
  }) => {
    onChange({
      value: [newValue || 0, value[1], value[2]],
    }, fieldName);
  }, [onChange, value]);

  const handleYControlChange = useCallback(({
    name: fieldName,
    value: newValue,
  }) => {
    onChange({
      value: [value[0], newValue || 0, value[2]],
    }, fieldName);
  }, [onChange, value]);

  const handleZControlChange = useCallback(({
    name: fieldName,
    value: newValue,
  }) => {
    onChange({
      value: [value[0], value[1], newValue || 0],
    }, fieldName);
  }, [onChange, value]);

  const handleChange = useCallback(({
    name: fieldName,
    value: [x, y, z],
  }) => {
    onChange({
      value: [x || 0, y || 0, z || 0],
    }, fieldName);
  }, [onChange]);

  return (
    <Grid container spacing={ 1 }>
      <Grid item xs={ 12 }>
        <SubTitle>{label}</SubTitle>
      </Grid>
      <Grid item xs={ 12 }>
        <AxisFields
          name={ name }
          value={ value }
          step={ step }
          disabled={ disabled }
          xAxisControl={ (
            <Control
              name={ name }
              value={ value[0] }
              disabled={ disabled[0] }
              icon={ <XRotateIcon /> }
              onChange={ handleXControlChange }
            />
          ) }
          yAxisControl={ (
            <Control
              name={ name }
              value={ value[1] }
              disabled={ disabled[1] }
              icon={ <YRotateIcon /> }
              onChange={ handleYControlChange }
            />
          ) }
          zAxisControl={ (
            <Control
              name={ name }
              value={ value[2] }
              disabled={ disabled[2] }
              icon={ <ZRotateIcon /> }
              onChange={ handleZControlChange }
            />
          ) }
          displayValue={ displayValue }
          onChange={ handleChange }
        />
      </Grid>
    </Grid>
  );
};

export default memo(Rotation);
