import { useMemo } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(0.8),
    color: theme.palette.common.icon,

    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
  label: {
    fontSize: '1.5rem',
  },
}));

const Control = ({ children, onClick }) => {
  const classes = useStyles();

  const iconButtonClasses = useMemo(
    () => ({
      label: classes.label,
    }),
    [classes.label],
  );

  return (
    <IconButton
      className={ classes.root }
      classes={ iconButtonClasses }
      onClick={ onClick }
    >
      {children}
    </IconButton>
  );
};

export default Control;
