import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import withAuth from '@shared/components/route/withAuth';
import useActions from '@shared/hooks/useActions';
import { actions } from '@store/users';
import { actions as companiesActions } from '@store/companies';
import { actions as sideMenuActions } from '@store/app/index';
import { isAdmin, isCompanyAdmin } from '@shared/models';

import UsersList from '@pages/users/list';

const Users = withAuth(() => {
  const curUser = useSelector((state) => state.app.user);
  const { loadUsers } = useActions(actions);
  const { loadCompanies } = useActions(companiesActions);
  const { showMenu } = useActions(sideMenuActions);

  useEffect(() => {
    showMenu();

    if (isAdmin(curUser) || isCompanyAdmin(curUser)) loadUsers();
    if (isAdmin(curUser)) loadCompanies();
  }, [showMenu, loadUsers, loadCompanies, curUser]);

  return <UsersList />;
});

export default Users;
