import { useCallback, useState, useMemo } from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { useSelector } from 'react-redux';
import Box from '@material-ui/core/Box';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import {
  TextField,
  Fields,
  Field,
  GroupField,
  PreviewContainer,
} from '@shared/views/form-dialog';
import Preview from './Preview';
import { Fieldset, InputLabelProps } from './fieldset';
import { makeStyles } from '@material-ui/core/styles';
import AttributesTable from '@shared/components/attributes-table/AttributesTable';
import VariantMaterialTable from '@pages/products/modal/VariantMaterialTable';
import { ModelFormats } from '../constants';
import useProductPreview from '@pages/products/hooks/useProductPreview';
import CurrencyTextField from './CurrencyTextField';
import { AVAILABLE_LANGUAGES } from '@shared/utils/translations';
import TranslatedFieldsGroup from '@shared/components/translations/TranslatedGroupFields';
import { selectAttributeDefinitionsForVariants } from '@store/attribute-definitions/selectors';

const useStyles = makeStyles((theme) => ({
  tab: {
    padding: theme.spacing(2.5),
    minWidth: '157px',

    '& .MuiTab-wrapper': {
      alignItems: 'flex-start',
      color: '#5F6B79',
      fontSize: '1.8rem',
      lineHeight: '27px',
      textTransform: 'initial',
    },

    '&.Mui-selected': {
      backgroundColor: '#E2EAF3',

      '& .MuiTab-wrapper': {
        color: theme.palette.common.darkBlue,
      },
    },
  },
  description: {
    '& input': {
      height: '77px',
      paddingTop: '0',
    },
  },
  previewContainer: {
    justifyContent: 'center',
    padding: theme.spacing(0, 2.5, 0, 0.5),
    maxWidth: '722px',
    flexBasis: 'calc(100vh - 241px)', // 722px for full hd non full screen
  },
}));

// TODO: refactoring; need to reuse the same logic in FormFieldset and here
const VariantFormFieldset = ({
  renewable,
}) => {
  const classes = useStyles();
  const {
    control,
    formState: { errors: fieldsErrors },
  } = useFormContext();
  const [tab, setTab] = useState(0);
  const [object, setObject] = useState();
  const [camera, setCamera] = useState();
  const [gl, setGl] = useState();
  const [scene, setScene] = useState();
  const [cameraInitialState, setCameraInitialState] = useState();

  const attributeDefinitions = useSelector(selectAttributeDefinitionsForVariants);

  const name = useWatch({ control, name: Fieldset.Name });
  const { renderLightPresetsController, renderThumbnailController, renderActions } = useProductPreview({ object, gl, name });

  const currentEnvironment = useWatch({ control, name: Fieldset.Environment });
  const materials = useWatch({ control, name: Fieldset.Materials });

  const [selectedLanguage, setSelectedLanguage] = useState(AVAILABLE_LANGUAGES.find((lng) => lng.key === 'en'));

  const useLang = useMemo(() => selectedLanguage && selectedLanguage.key !== 'en', [selectedLanguage]);
  const defaultResolution = 1024;

  const handleTabChange = useCallback((event, newValue) => {
    setTab(newValue);
  }, []);

  // TODO: extract styles to classes
  return (
    <Box style={ { borderTop: '1px solid #E2EAF3', borderBottom: '1px solid #E2EAF3', flexGrow: 1, display: 'flex', height: '100%' } }>
      <Tabs
        orientation="vertical"
        variant="scrollable"
        value={ tab }
        onChange={ handleTabChange }
        aria-label="Material tabs"
        style={ { borderRight: '1px solid #E2EAF3', flex: '0 0 auto' } }
        TabIndicatorProps={ { style: { display: 'none' } } }
      >
        <Tab label="Details" className={ classes.tab } />
        <Tab label="Visualization" className={ classes.tab } />
        <Tab label="Attributes" className={ classes.tab } />
      </Tabs>
      <div
        role="tabpanel"
        style={ { padding: '27px 20px 27px 20px', overflow: 'auto', flex: '1 1 auto', maxWidth: '370px' } }
        hidden={ tab !== 0 }
        id="vertical-tabpanel-0"
        aria-labelledby="vertical-tab-0"
      >
        <Fields>
          { tab !== 1 ? (
            <Field>
              { renderThumbnailController() }
            </Field>
          ) : null }

          <Field>
            <Controller
              name={ Fieldset.ExternalId }
              control={ control }
              rules={ {
                required: 'Required',
              } }
              render={ ({ field }) => (
                <TextField
                  { ...field }
                  label="ID (SKU)"
                  helperText={ fieldsErrors[Fieldset.ExternalId]?.message }
                  required
                  InputLabelProps={ InputLabelProps }
                  error={ !!fieldsErrors[Fieldset.ExternalId] }
                />
              ) }
            />
          </Field>
          <TranslatedFieldsGroup
            onLangChange={ setSelectedLanguage }
            selectedLanguage={ selectedLanguage }
          >
            <Field>
              <Controller
                name={ useLang
                  ? `${Fieldset.TranslatedFields}.${selectedLanguage.key}.${Fieldset.Name}`
                  : Fieldset.Name }
                key={ useLang
                  ? `${Fieldset.TranslatedFields}.${selectedLanguage.key}.${Fieldset.Name}`
                  : Fieldset.Name }
                control={ control }
                render={ ({ field }) => (
                  <TextField
                    { ...field }
                    helperText={ useLang
                      ? fieldsErrors[`${Fieldset.TranslatedFields}.${selectedLanguage.key}.${Fieldset.Name}`]?.message
                      : fieldsErrors[Fieldset.Name]?.message }
                    label="Name"
                    error={ useLang
                      ? !!fieldsErrors[`${Fieldset.TranslatedFields}.${selectedLanguage.key}.${Fieldset.Name}`]
                      : !!fieldsErrors[Fieldset.Name] }
                    InputLabelProps={ InputLabelProps }
                  />
                ) }
              />
            </Field>
            <GroupField>
              <Controller
                name={ useLang
                  ? `${Fieldset.TranslatedFields}.${selectedLanguage.key}.${Fieldset.Price}`
                  : Fieldset.Price }
                key={ useLang
                  ? `${Fieldset.TranslatedFields}.${selectedLanguage.key}.${Fieldset.Price}`
                  : Fieldset.Price }
                control={ control }
                render={ ({ field }) => (
                  <TextField
                    { ...field }
                    label="Price"
                    InputLabelProps={ InputLabelProps }
                  />
                ) }
              />
              <Controller
                name={ useLang
                  ? `${Fieldset.TranslatedFields}.${selectedLanguage.key}.${Fieldset.Currency}`
                  : Fieldset.Currency }
                key={ useLang
                  ? `${Fieldset.TranslatedFields}.${selectedLanguage.key}.${Fieldset.Currency}`
                  : Fieldset.Currency }
                control={ control }
                render={ ({ field }) => (
                  <CurrencyTextField
                    { ...field }
                    label="Currency"
                    InputLabelProps={ InputLabelProps }
                  />
                ) }
              />
            </GroupField>
            <Field>
              <Controller
                name={ useLang
                  ? `${Fieldset.TranslatedFields}.${selectedLanguage.key}.${Fieldset.Description}`
                  : Fieldset.Description }
                key={ useLang
                  ? `${Fieldset.TranslatedFields}.${selectedLanguage.key}.${Fieldset.Description}`
                  : Fieldset.Description }
                control={ control }
                render={ ({ field }) => (
                  <TextField
                    { ...field }
                    multiline
                    className={ classes.description }
                    label="Description (text/html)"
                    InputLabelProps={ InputLabelProps }
                  />
                ) }
              />
            </Field>
          </TranslatedFieldsGroup>
        </Fields>
      </div>
      <div
        role="tabpanel"
        style={ { padding: '27px 20px 27px 20px', overflow: 'auto', flex: '1 1 auto', maxWidth: '370px' } }
        hidden={ tab !== 1 }
        id="vertical-tabpanel-1"
        aria-labelledby="vertical-tab-1"
      >
        <Fields>
          { tab === 1 ? (
            <Field>
              { renderThumbnailController() }
            </Field>
          ) : null }

          <Controller
            name={ Fieldset.Model }
            type="hidden"
            control={ control }
            render={ () => {} }
          />

          <VariantMaterialTable
            name={ Fieldset.Materials }
            control={ control }
            object={ object }
            camera={ camera }
            cameraInitialState={ cameraInitialState }
            gl={ gl }
            scene={ scene }
          />
        </Fields>
      </div>

      <div
        role="tabpanel"
        style={ { padding: '28px 20px 28px 24px', overflow: 'auto', flex: '1 1 auto', maxWidth: '370px' } }
        hidden={ tab !== 2 }
        id="vertical-tabpanel-2"
        aria-labelledby="vertical-tab-2"
      >
        <div style={ { fontSize: '18px', padding: '0 8px 8px', color: '#334D6E' } }>Manage Attributes </div>
        <Controller
          name={ Fieldset.Attributes }
          control={ control }
          render={ ({ field }) => (
            <AttributesTable
              attributeDefinitions={ attributeDefinitions }
              { ...field }
            />
          ) }
        />
      </div>

      <PreviewContainer className={ classes.previewContainer }>
        <Preview
          name={ Fieldset.ModelData }
          fileName={ name }
          supportedFormats={ ModelFormats }
          generateThumbnail={ false }
          renderModelViewerExtraContent={ renderLightPresetsController }
          onLoad={ (object, camera, gl, scene) => {
            setObject(object);
            setCamera(camera);
            setCameraInitialState({
              position: [...camera.position],
              aspect: camera.aspect,
            });
            setGl(gl);
            setScene(scene);
          } }
          control={ control }
          renewable={ renewable }
          materials={ materials }
          environment={ currentEnvironment }
          size={ defaultResolution }
        />

        { renderActions() }
      </PreviewContainer>
    </Box>
  );
};

export default VariantFormFieldset;
