import { memo } from 'react';
import SceneDetails from './SceneDetails';
import InteriorDetails from './InteriorDetails';
import ModelDetails from './ModelDetails';
import LightDetails from './LightDetails';
import { DetailsType } from './DetailsType';

const ChildDetails = ({
  scene,
  data,
  sceneBox,
  sceneDetailsErrors,
  onChange,
  onImageClick,
  isEditable,
  scenesNameExists,
}) => {
  const details = data ? data.data : null;
  const type = data ? data.type : null;

  if (!details) return null;

  switch (type) {
    case DetailsType.Scene:
      return (
        <SceneDetails
          details={ details }
          sceneDetailsErrors={ sceneDetailsErrors }
          onChange={ onChange }
          isEditable={ isEditable }
          scenesNameExists={ scenesNameExists }
        />
      );
    case DetailsType.Interior:
      return (
        <InteriorDetails
          details={ details }
          onImageClick={ onImageClick }
          isEditable={ isEditable }
        />
      );
    case DetailsType.Model:
      return (
        <ModelDetails
          details={ details }
          onChange={ onChange }
          isEditable={ isEditable }
        />
      );
    case DetailsType.Light:
      return (
        <LightDetails
          details={ details }
          sceneBox={ sceneBox }
          onChange={ onChange }
          isEditable={ isEditable }
        />
      );

    default:
      return null;
  }
};

export default memo(ChildDetails);
