import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { useMemo } from 'react';
import classNames from 'classnames';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    flexShrink: 0,
    margin: '0 auto',
  },
}));

const PreviewContainer = ({ children, orientation = 'horizontal', md, xs, className, ...rest }) => {
  const classes = useStyles();

  const calculatedMd = useMemo(() => (md ?? (orientation === 'horizontal' ? 6 : 12)), [orientation, md]);

  return (
    <Grid item md={ calculatedMd } xs={ xs ?? 12 } className={ classNames(classes.root, className) } { ...rest }>
      {children}
    </Grid>
  );
};

export default PreviewContainer;
