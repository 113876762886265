import { useSelector } from 'react-redux';

import { makeStyles } from '@material-ui/core/styles';
import SearchInput from '@shared/components/search-input';

import { actions } from '@store/app';
import useActions from '@shared/hooks/useActions';
import MenuToggle from './MenuToggle';
import { SortId } from '@app/sort/sortValues';
import Sorting from './Sorting';
import { selectSortingId } from '@store/app/selectors';
import { useLocation } from 'react-router-dom';
import { ROUTES } from '@app/routes/routesUrls';
import { useEffect, useState } from 'react';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    backgroundColor: theme.palette.common.white,
    zIndex: theme.zIndex.drawer + 1,
  },
  content: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'stretch',
    borderBottom: `.1rem solid ${theme.palette.grey[200]}`,
  },
  leftSide: {
    display: 'flex',
  },
  menuIcon: {
    fontSize: '3rem',
    color: theme.palette.common.darkGrey,
  },
}));

const selectMenuOpen = (state) => state.app.menuOpen;

const SORTING_OPTIONS = [
  {
    id: SortId.Newest,
    label: 'Last Updated',
  },
  {
    id: SortId.Latest,
    label: 'First Updated',
  },
  {
    id: SortId.AtoZ,
    label: 'A to Z',
  },
  {
    id: SortId.ZtoA,
    label: 'Z to A',
  },
];

const AppTopBar = ({ resetSearch }) => {
  const [searchValue, setSearchValue] = useState('');

  useEffect(() => {
    setSearchValue('');
    search('');
  }, [resetSearch]);

  const location = useLocation();

  const classes = useStyles();

  const { toggleMenu, search, sortedId } = useActions(actions);

  const isMenuOpen = useSelector(selectMenuOpen);
  const sortingId = useSelector(selectSortingId);

  const isFilteringEnabled = !location.pathname.startsWith(`${ROUTES.SCENES.ROOT}/`);

  const handleSearchChange = ({ target }) => {
    search(target.value);
    setSearchValue(target.value);
  };

  const handleSortingChange = (evt) => {
    sortedId(evt?.target?.value);
  };

  return (
    <header className={ classes.root }>
      <div className={ classes.content }>
        <div className={ classes.leftSide }>
          <MenuToggle
            isOpen={ isMenuOpen }
            onClick={ toggleMenu }
            classes={ classes }
          />
          {isFilteringEnabled && <SearchInput value={ searchValue } onChange={ handleSearchChange } />}
        </div>
        {isFilteringEnabled && (
          <Sorting
            onChange={ handleSortingChange }
            options={ SORTING_OPTIONS }
            selectedOption={ sortingId }
          />
        )}
      </div>
    </header>
  );
};

export default AppTopBar;
