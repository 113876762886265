import { If, Then } from 'react-if';
import { Group, Circle, Line, Text } from 'react-konva';
import { Point2D } from '../types';
import { Point2, isNone } from '../utils';

import DraggablePoint from './DraggablePoint';

const LABEL_COLOR = '#FFFFFF';
const LABEL_BOX_SIZE = 16;
const LABEL_BOX_OFFSET = [0, 1];
const LABEL_FONT_SIZE = 10;
const LABEL_BACK_COLOR = '#000000';
const LABEL_BACK_RADIUS = LABEL_BOX_SIZE * 0.5;
const LABEL_BACK_OPACITY = 0.4;

interface LineSegmentProps {
    points: [Point2D, Point2D]
    enabled?: boolean | null
    label?: string | null
    color?: string | null
    startShape?: 'point' | 'notch' | 'cross' | null
    endShape?: 'point' | 'notch' | 'cross' | null
    onChanged?: (points: [Point2D, Point2D], isDragging: boolean, event: any) => void,
    scale: number,
}

function LineSegment({
  enabled = true,
  label,
  points,
  color,
  startShape = 'point',
  endShape = 'point',
  onChanged,
  scale,
}: LineSegmentProps) {
  const labelPoint = Point2.midpoint(...points);
  const direction = Point2.normalized(Point2.substract(points[1], points[0]));

  return (
    <Group>
      <DraggablePoint
        color={ color! }
        shape={ startShape! }
        enabled={ enabled! }
        position={ points[0] }
        direction={ direction }
        onChanged={ (point, isDragging, id, event) => onChanged?.([point, points[1]], isDragging, event) }
        scale={ scale }
      />
      <DraggablePoint
        color={ color! }
        shape={ endShape! }
        enabled={ enabled! }
        position={ points[1] }
        direction={ direction }
        onChanged={ (point, isDragging, id, event) => onChanged?.([points[0], point], isDragging, event) }
        scale={ scale }
      />
      <Line
        listening={ false }
        points={ Point2.flatten(...points) }
        stroke={ color! }
        strokeWidth={ 2 * scale }
      />
      <If condition={ !isNone(label) }>
        <Then>
          <Circle
            listening={ false }
            x={ labelPoint.x }
            y={ labelPoint.y }
            radius={ LABEL_BACK_RADIUS * scale }
            fill={ LABEL_BACK_COLOR }
            opacity={ LABEL_BACK_OPACITY }
          />
          <Text
            listening={ false }
            x={ labelPoint.x - LABEL_BOX_SIZE * scale * 0.5 + LABEL_BOX_OFFSET[0] }
            y={ labelPoint.y - LABEL_BOX_SIZE * scale * 0.5 + LABEL_BOX_OFFSET[1] }
            width={ LABEL_BOX_SIZE * scale }
            height={ LABEL_BOX_SIZE * scale }
            text={ label! }
            fill={ LABEL_COLOR }
            align="center"
            verticalAlign="middle"
            fontSize={ LABEL_FONT_SIZE * scale }
          />
        </Then>
      </If>
    </Group>
  );
}

export default LineSegment;
