import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
}));

const Nav = ({ children }) => {
  const classes = useStyles();
  return (
    <div className={ classes.root }>
      {children}
    </div>
  );
};

export default Nav;
