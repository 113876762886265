import { useForm, FormProvider } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { createModel } from '@store/models/actions';
import { Form } from '@shared/views/form-dialog';
import { fillWithDefaultValues, fillWithModelValues } from '../fieldset';
import { ModelType } from '../../constants';
import { useEffect } from 'react';
import { actions as appActions } from '@store/app';

const CopyForm = ({
  children,
  className,
  open,
  data,
  onSubmit,
}) => {
  const dispatch = useDispatch();
  const apiState = useSelector((state) => state.models.state);
  const apiError = useSelector((state) => state.models.error);

  const form = useForm({
    defaultValues: {
      ...fillWithDefaultValues(),
      ...fillWithModelValues(data),
    },
  });

  const handleSubmit = async (newData) => {
    dispatch(createModel({
      type: ModelType.ThreeDimensional,
      ...newData,
      name: newData.name.trim(),
      attributes: newData.attributes?.map((attr) => attr.id),
    }));
  };

  useEffect(() => {
    if (apiError && apiError.type === 'CREATE') {
      const titles = apiError.data.message.map((message) => {
        if (message.reason === 'unique') {
          form.setError(message.property, {});
          return `Property '${message.property}' should be unique`;
        }
      });
      dispatch(appActions.addMessage({ message: titles, type: 'error' }));
    }
  }, [dispatch, apiError]);

  useEffect(() => {
    if (apiState === 'success') {
      onSubmit();
    }
  }, [dispatch, apiState]);

  return (
    <Form
      className={ className }
      open={ open }
      onSubmit={ form.handleSubmit(handleSubmit) }
    >
      <FormProvider { ...form }>
        {children}
      </FormProvider>
    </Form>
  );
};

export default CopyForm;
