import { useMemo } from 'react';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import CheckIcon from '@resources/icons/CheckIcon';

const DEFAULT_MENU_PROPS = {
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'left',
  },
  getContentAnchorEl: null,
};

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    minHeight: '100%',
    borderLeft: `1px solid ${theme.palette.common.lightBlue}`,
    borderRight: `1px solid ${theme.palette.common.lightBlue}`,
  },
  placeholder: {
    fontSize: 12,
    fontWeight: 400,
    textTransform: 'uppercase',
    color: theme.palette.common.semiLightGrey,
  },
  item: {
    fontWeight: 500,
    fontSize: 14,
    color: theme.palette.common.paleBlue,
  },
  selected: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    color: theme.palette.common.darkGrey,
  },
  select: {
    display: 'flex',
    justifyContent: 'center',
    width: theme.spacing(16.25),
  },
  icon: {
    top: `calc(50% - ${theme.spacing(1.1)}px)`,
    right: theme.spacing(6),
  },
  menuPaper: {
    width: theme.spacing(25),
    right: 0,
    left: 'auto !important',
    top: `${theme.spacing(7.75)}px !important`,
    boxShadow: 'none',
    borderRadius: 0,
  },
}));

const renderValue = (classes) => () => <span className={ classes.placeholder }>Sort By</span>;

const Sorting = ({ options, selectedOption, onChange }) => {
  const classes = useStyles();
  const menuProps = useMemo(() => ({
    ...DEFAULT_MENU_PROPS,
    classes: { paper: classes.menuPaper },
  }), [classes.menuPaper]);
  return (
    <div className={ classes.root }>
      <Select
        value={ selectedOption }
        onChange={ onChange }
        renderValue={ renderValue(classes) }
        MenuProps={ menuProps }
        disableUnderline
        classes={ { select: classes.select } }
        inputProps={ { classes: { icon: classes.icon } } }
      >
        {options.map(({ id, label }) => {
          const selected = selectedOption === id;
          return (
            <MenuItem key={ id } value={ id } className={ classNames(classes.item, { [classes.selected]: selected }) }>
              {selected
                ? (
                  <>
                    {label}
                    <CheckIcon size="small" />
                  </>
                )
                : label}
            </MenuItem>
          );
        })}
      </Select>
    </div>
  );
};

export default Sorting;
