import { makeStyles } from '@material-ui/core';
import Button from '@shared/components/button';

const useStyles = makeStyles((theme) => ({
  root: {
    width: theme.spacing(20.25),
    maxWidth: theme.spacing(20.25),
    height: theme.spacing(6),
    padding: '0 !important',
  },
}));

const ActionButton = ({ children, ...props }) => {
  const classes = useStyles();
  return <Button { ...props } className={ classes.root }>{children}</Button>;
};

export default ActionButton;
