import { useCallback, useMemo, useState } from 'react';
import { Controller, useController, useFormContext } from 'react-hook-form';
import { makeStyles } from '@material-ui/core/styles';

import MaterialTextField from './MaterialTextField';
import { Fieldset, InputLabelProps } from './fieldset';

import {
  Fields,
  Field, GroupField, TextField,
} from '@shared/views/form-dialog';
import { getWireframeMaterial } from '@pages/products/utils';
import useFormValues from '@shared/hooks/useFormValues';
import { IconButton, Popover } from '@material-ui/core';
import SettingsIcon from '@material-ui/icons/Settings';
import Box from '@material-ui/core/Box';
import { deg2rad, rad2deg } from '@shared/components/camera-solver/utils';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    padding: '0 8px',
  },
  label: {
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '21px',
    padding: '20px 0',
    color: theme.palette.common.darkGrey,
    textDecoration: 'underline',
    cursor: 'pointer',
    '&:hover': {
      color: theme.palette.common.green,
    },
  },
  popup: {
    padding: '1.4rem',
    maxWidth: '253px',
  },
}));

const VariantMaterialTable = ({
  name,
  control,
  object,
}) => {
  const classes = useStyles();
  const [popovers, setPopovers] = useState([]);

  const {
    field: { onChange, value, ...inputProps },
  } = useController({
    name,
    control,
  });

  const form = useFormContext();

  const {
    setValue,
    formState: { errors: fieldsErrors },
  } = form;

  const values = useFormValues(form);

  const meshes = useMemo(() => {
    const result = [];
    if (!object) {
      return result;
    }
    object.traverse((element) => {
      if (element.isMesh) {
        result.push(element);
      }
    });

    return result;
  }, [object]);

  const groups = useMemo(() => {
    return [...value.reduce((a, c) => {
      if (c.group.id) {
        a.set(c.group.id, c.group);
      } else {
        a.set(value.indexOf(c), c.group);
      }
      return a;
    }, new Map()).values()];
  }, [value]);

  const onClickHandler = useCallback((indexes) => {
    value.forEach((item, index) => {
      if (indexes.indexOf(index) > -1) {
        const wireframeMaterial = getWireframeMaterial();
        wireframeMaterial.wireframe = true;
        meshes[index].userData.wireframeToApply = wireframeMaterial;
      }
    });
  }, [value, meshes]);

  const mouseLeaveHandler = useCallback((indexes) => {
    value.forEach((item, index) => {
      if (indexes.indexOf(index) > -1 && meshes[index].userData.wireframeToApply) {
        meshes[index].userData.wireframeToApply.dispose();
        delete meshes[index].userData.wireframeToApply;
      }
    });
  }, [value, meshes]);

  const handleMaterialSelect = useCallback((indexes, material) => {
    const result = value.map((item, index) => {
      if (indexes.indexOf(index) > -1) {
        return {
          ...item,
          material,
        };
      }
      return item;
    });
    setValue(Fieldset.Materials, result);
  }, [value, setValue]);

  const handleUVChange = (indexes, position, event) => {
    const result = value.map((item, index) => {
      if (indexes.indexOf(index) > -1) {
        return {
          ...item,
          [position]: Number.parseFloat(event.target?.value || event),
        };
      }
      return item;
    });
    setValue(Fieldset.Materials, result);
  };

  return (
    <div className={ classes.root }>
      {
        groups.map((group, index) => {
          const groupIndex = group.id
            ? value.findIndex((i) => i.group.id === group.id)
            : value.findIndex((i) => i.group === group);
          const indexes = group.id
            ? value.map((e, i) => (e.group.id === group.id ? i : ''))
              .filter(String)
            : [groupIndex];
          return (
            <div
                  /* eslint-disable-next-line react/no-array-index-key */
              key={ index }
            >
              <div
                className={ classes.label }
                onClick={ onClickHandler.bind(this, indexes) }
                onMouseLeave={ mouseLeaveHandler.bind(this, indexes) }
              >{group.name}
              </div>
              <Fields>
                <GroupField
                  sizes={ [10, 2] }
                  style={ { position: 'relative' } }
                >
                  <Controller
                    name={ `${name}[${groupIndex}].material` }
                    control={ control }
                    defaultValue={ { name: '' } }
                    rules={ {
                      required: 'Required',
                    } }
                    render={ ({ field }) => (
                      <MaterialTextField
                        { ...field }
                        helperText={ fieldsErrors[`${name}[${groupIndex}].material`]?.message }
                        label="Material"
                        required
                        error={ !!fieldsErrors[`${name}[${groupIndex}].material`] }
                        onSelect={ handleMaterialSelect.bind(this, indexes) }
                      />
                    ) }
                  />
                  <IconButton onClick={ (event) => {
                    setPopovers((items) => {
                      items[index] = event.currentTarget;
                      return [...items];
                    });
                  } }
                  >
                    <SettingsIcon
                      size="large"
                      color="primary"
                    />
                  </IconButton>
                  <Popover
                    open={ !!popovers[index] }
                    anchorEl={ popovers[index] }
                    onClose={ () => {
                      setPopovers((items) => {
                        items[index] = null;
                        return [...items];
                      });
                    } }
                    anchorOrigin={ {
                      vertical: 'bottom',
                      horizontal: 'center',
                    } }
                    transformOrigin={ {
                      vertical: 'top',
                      horizontal: 'center',
                    } }
                  >
                    <Box className={ classes.popup }>
                      <Fields>
                        <GroupField
                          sizes={ [6, 6] }
                          style={ { position: 'relative' } }
                        >
                          <Controller
                            name={ `${name}[${groupIndex}].uvScaleX` }
                            control={ control }
                            defaultValue={ 1 }
                            render={ ({ field }) => (
                              <TextField
                                { ...field }
                                label="UV Scale X"
                                onValueChange={ handleUVChange.bind(this, indexes, 'uvScaleX') }
                                type="number"
                                step="0.05"
                                InputLabelProps={ InputLabelProps }
                              />
                            ) }
                          />
                          <Controller
                            name={ `${name}[${groupIndex}].uvScaleY` }
                            control={ control }
                            defaultValue={ 1 }
                            render={ ({ field }) => (
                              <TextField
                                { ...field }
                                label="UV Scale Y"
                                onValueChange={ handleUVChange.bind(this, indexes, 'uvScaleY') }
                                type="number"
                                step="0.05"
                                InputLabelProps={ InputLabelProps }
                              />
                            ) }
                          />
                        </GroupField>
                        <GroupField
                          sizes={ [6, 6] }
                          style={ { position: 'relative' } }
                        >
                          <Controller
                            name={ `${name}[${groupIndex}].uvOffsetX` }
                            control={ control }
                            defaultValue={ 0 }
                            render={ ({ field }) => (
                              <TextField
                                { ...field }
                                label="UV Offset X"
                                onValueChange={ handleUVChange.bind(this, indexes, 'uvOffsetX') }
                                type="number"
                                min={ 0 }
                                max={ 1 }
                                step="0.05"
                                InputLabelProps={ InputLabelProps }
                              />
                            ) }
                          />
                          <Controller
                            name={ `${name}[${groupIndex}].uvOffsetY` }
                            control={ control }
                            defaultValue={ 0 }
                            render={ ({ field }) => (
                              <TextField
                                { ...field }
                                label="UV Offset Y"
                                onValueChange={ handleUVChange.bind(this, indexes, 'uvOffsetY') }
                                type="number"
                                min={ 0 }
                                max={ 1 }
                                step="0.05"
                                InputLabelProps={ InputLabelProps }
                              />
                            ) }
                          />
                        </GroupField>
                        <Field style={ { position: 'relative' } }>
                          <Controller
                            name={ `${name}[${groupIndex}].rotation` }
                            control={ control }
                            defaultValue={ 0 }
                            render={ ({ field }) => (
                              <TextField
                                { ...field }
                                label="UV Rotation"
                                value={ Math.round(rad2deg(field.value)) }
                                onChange={ (event) => {
                                  setValue(`${name}[${groupIndex}].rotation`, deg2rad(event.target.value));
                                  handleUVChange.bind(this, indexes, 'rotation')(deg2rad(event.target.value));
                                } }
                                type="number"
                                min={ 0 }
                                max={ 360 }
                                step="1"
                                InputLabelProps={ InputLabelProps }
                              />
                            ) }
                          />
                        </Field>
                      </Fields>
                    </Box>
                  </Popover>
                </GroupField>
              </Fields>
            </div>
          );
        })
      }
    </div>
  );
};

export default VariantMaterialTable;
