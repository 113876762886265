import React, { useMemo } from 'react';
import {
  makeStyles,
} from '@material-ui/core';
import { useWatch } from 'react-hook-form';
import { Button as ViewerButton } from '@zolak/zolak-viewer';
import classNames from 'classnames';

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
    borderLeft: '2px solid gray',
    display: 'flex',
    flexDirection: 'column',
    overflowY: 'auto',
  },
  title: {
    fontSize: '16px',
    width: '100%',
    textAlign: 'center',
    borderBottom: '2px solid gray',
    flexGrow: 0,
    flexShrink: 0,
  },
  content: {
    flexGrow: 1,
    width: '100%',
    display: 'flex',
    gap: '8px',
    padding: '8px',
  },
  buttonsWrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: '4px',
  },
  textsWrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
  },
}), { name: 'StylingPreview' });

const StylingPreview = () => {
  const classes = useStyles();
  const allFormFieldValues = useWatch();

  const css = useMemo(() => `
    .StylingPreview-buttonPrimary {
      background-color: ${allFormFieldValues.color1};
      color: ${allFormFieldValues.color2};
      font-family: ${allFormFieldValues.fontFamily}, sans-serif !important;
    }

    .StylingPreview-buttonPrimary > svg {
      color: ${allFormFieldValues.color2};
    }

    .StylingPreview-buttonPrimary:hover {
      background-color: ${allFormFieldValues.color1Hover};
      color: ${allFormFieldValues.color2Hover};
    }
    
    .StylingPreview-buttonSecondary {
      border-color: ${allFormFieldValues.color4};
      background-color: ${allFormFieldValues.color2};
      color: ${allFormFieldValues.color3};
      font-family: ${allFormFieldValues.fontFamily}, sans-serif !important;
    }

    .StylingPreview-buttonSecondary:hover {
      border-color: ${allFormFieldValues.color4Hover};
      background-color: ${allFormFieldValues.color2Hover};
      color: ${allFormFieldValues.color3Hover};
    }
    
    .StylingPreview-buttonSecondary:hover > svg {
      color: ${allFormFieldValues.color3Hover};
    }
    
    .StylingPreview-background {
      background-color: ${allFormFieldValues.color2};
    }
    
    .StylingPreview-textSample1 {
      font-weight: bold;
      font-size: 20px;
      line-height: 30px;
      color: ${allFormFieldValues.color3};
      font-family: ${allFormFieldValues.fontFamily}, sans-serif !important;
    }
    
    .StylingPreview-textSample2 {
      font-size: 16px;
      color: ${allFormFieldValues.color4};
      font-family: ${allFormFieldValues.fontFamily}, sans-serif !important;
    }
    
    .StylingPreview-info {
      padding: 15px;
      border-radius: 6px;
      font-size: 12px;
      font-weight: 500;
      line-height: 16px;
      letter-spacing: 0px;
      background-color: ${allFormFieldValues.color4};
      color: ${allFormFieldValues.color2};
      font-family: ${allFormFieldValues.fontFamily}, sans-serif !important;
    }
  `, [allFormFieldValues]);

  return (
    <>
      <style type="text/css">
        {css}
      </style>

      <div className={ classes.root }>
        <div className={ classes.title }>Preview</div>

        <div className={ classNames(classes.content, 'StylingPreview-background') }>
          <div className={ classes.buttonsWrapper }>
            <ViewerButton className="StylingPreview-buttonPrimary">Example 1</ViewerButton>
            <ViewerButton className="StylingPreview-buttonPrimary" disabled>Example 2</ViewerButton>
            <ViewerButton className="StylingPreview-buttonSecondary" secondary>Example 3</ViewerButton>
            <ViewerButton className="StylingPreview-buttonSecondary" secondary disabled>Example 4</ViewerButton>
          </div>
          <div className={ classes.textsWrapper }>
            <div className="StylingPreview-textSample1">
              Lorem ipsum.
            </div>
            <div className="StylingPreview-textSample2">
              {/* eslint-disable-next-line max-len */}
              In this section, you can adjust the base and hover colors for the component. The base color sets the tone for buttons, headers, and backgrounds. The hover color appears when users hover over elements like buttons. The text here shows the font style used in the component.
            </div>
            <div className="StylingPreview-info">
              {/* eslint-disable-next-line max-len */}
             In this section, you can adjust the base and hover colors for the component. The base color sets the tone for buttons, headers, and backgrounds. The hover color appears when users hover over elements like buttons. The text here shows the font style used in the component.
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default StylingPreview;
