import { memo, Suspense, useMemo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Canvas as RTFCanvas } from '@react-three/fiber';
import * as THREE from 'three';

import Backdrop from '@shared/components/backdrop';
import CircularProgress from '@shared/components/circular-progress';
import Scene from './Scene';
import ui from './uiTunnel';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    height: '100%',
  },
  canvas: {
    cursor: 'pointer',
    minHeight: 'inherit',

    '& canvas': {
      width: '100% !important',
      height: 'auto !important',
      '@media(min-width: 1280px)': {
        padding: 0.7,
      },
    },
  },
}));

export default memo(({ value, materials, scale, size, onLoad, onFrame, environment, toneMapping, toneMappingExposure }) => {
  const classes = useStyles();

  const glConfig = useMemo(
    () => ({
      antialias: true,
      preserveDrawingBuffer: true,
      toneMapping: THREE[toneMapping],
      toneMappingExposure,
    }),
    [toneMapping, toneMappingExposure],
  );

  return (
    <Suspense
      fallback={ (
        <Backdrop className={ classes.backdrop }>
          <CircularProgress size="large" />
        </Backdrop>
          ) }
    >
      <ui.Out />
      <div className={ classes.root }>
        {
                  value && (
                  <RTFCanvas className={ classes.canvas } gl={ glConfig }>
                    <Scene
                      value={ value }
                      materials={ materials }
                      onLoad={ onLoad }
                      onFrame={ onFrame }
                      environment={ environment }
                      scale={ scale }
                      size={ size }
                    />
                  </RTFCanvas>
                  )
              }
      </div>
    </Suspense>
  );
});
