import SvgIcon from '@material-ui/core/SvgIcon';

const PlusIcon = (props) => (
  <SvgIcon fontSize="inherit" viewBox="0 0 10 10" style={ { width: 10, height: 10 } } { ...props }>
    <path
      d="M2.1582 4.78345H8.4082"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.2832 1.65845V7.90845"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);

export default PlusIcon;
