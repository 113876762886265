import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  root: {
    position: 'absolute',
    top: '0',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    width: '100%',
    height: '100%',
    padding: '20px',
    background: 'transparent',
    backdropFilter: 'blur(20px)',
    zIndex: '9999',
  },
  message: {
    fontWeight: '600',
    lineHeight: '35px',
  },
}));

const FunctionalityUnavailable = () => {
  const classes = useStyles();

  return (
    <div className={ classes.root }>
        <p className={ classes.message }>
            Functionality is not available for your company.<br />
            Contact the support team.
        </p>
    </div>
  );
};

export default FunctionalityUnavailable;
