import { useCallback, useRef } from 'react';

const OFFSET = 10;

const getDiff = (itemBounds, containerBounds) => {
  if (itemBounds.bottom > containerBounds.bottom) {
    return itemBounds.bottom - containerBounds.bottom + OFFSET;
  }

  if (itemBounds.top < containerBounds.top) {
    return itemBounds.top - containerBounds.top - OFFSET;
  }

  return 0;
};

const useSelectItem = (setItem) => {
  const contentRef = useRef(null);

  const handleSelectItem = useCallback((item, evt) => {
    const itemBounds = evt.currentTarget.getBoundingClientRect() || {};
    const containerBounds = contentRef.current?.getBoundingClientRect() || {};
    const diff = getDiff(itemBounds, containerBounds);
    contentRef.current?.scrollTo(containerBounds.x, contentRef.current?.scrollTop + diff);
    setItem(item);
  }, []);

  return [contentRef, handleSelectItem];
};

export default useSelectItem;
