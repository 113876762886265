import { FormDialog } from '@shared/views/form-dialog';
import Form from './Form';
import FormFieldset from '../FormFieldset';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import { getInteriorUsage, resetInteriorApiState } from '@store/interiors/actions';
import { useEffect } from 'react';

const useStyles = makeStyles((theme) => ({
  drawerPaper: {
    minWidth: '1250px',
    maxWidth: '66%',
  },
}));

const EditFormDialog = ({
  open,
  data,
  onClose,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const usage = useSelector((state) => state.interiors.usage);
  const handleSubmit = () => {
    dispatch(resetInteriorApiState());
    onClose();
  };

  const handleCancel = () => {
    dispatch(resetInteriorApiState());
    onClose();
  };

  useEffect(() => {
    if (data.id) {
      dispatch(getInteriorUsage(data.id));
    }
  }, [dispatch, data]);

  return (
    <FormDialog
      open={ open }
      Form={ Form }
      FormProps={ {
        open,
        data,
        onSubmit: handleSubmit,
      } }
      title="Edit Background"
      content={ <FormFieldset isCreate={ false } usage={ usage } /> }
      onCancel={ handleCancel }
      onClose={ onClose }
      maxWidth="sm"
      titleAlign="center"
      isDrawer
      drawerPaperProps={ { className: classes.drawerPaper } }
    />
  );
};

export default EditFormDialog;
