import { useMemo } from 'react';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import MuiDialogActions from '@material-ui/core/DialogActions';

export const Justify = {
  Center: 'center',
  Right: 'right',
};

const JustifyMap = {
  [Justify.Center]: 'center',
  [Justify.Right]: 'flex-end',
};

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2, 4.5, 5),
    width: '100%',
    justifyContent: ({ justify }) => JustifyMap[justify],
  },
  [Justify.Center]: {
    justifyContent: JustifyMap[Justify.Center],
  },
  [Justify.Right]: {
    justifyContent: JustifyMap[Justify.Right],
  },
}));

const DialogActions = ({ children, className, justify = Justify.Center }) => {
  const classes = useStyles({ justify });
  const muiClasses = useMemo(() => ({ root: classes.root }), [classes.root]);

  return (
    <MuiDialogActions
      className={ classNames(className, classes[justify]) }
      classes={ muiClasses }
    >
      {children}
    </MuiDialogActions>
  );
};

export default DialogActions;
