import React, { useState, useEffect, useCallback } from 'react';

import Checkbox from '@shared/components/checkbox';
import FormControlLabel from '@shared/components/form-control-label';

const SoftShadowsField = (props) => {
  const { name, value, onChange, label, disabled } = props;
  const [checkboxValue, setCheckboxValue] = useState(value);

  const toggleValue = useCallback(() => {
    setCheckboxValue((prevState) => {
      const newValue = !prevState;

      onChange({ name, value: newValue });

      return newValue;
    });
  }, [onChange, name]);

  return (
    <>
      <FormControlLabel
        control={ (
          <Checkbox
            name={ name }
            checked={ checkboxValue }
            onChange={ toggleValue }
            disabled={ disabled }
          />
        ) }
        label={ label || 'Custom shadows' }
      />
    </>
  );
};

export default SoftShadowsField;
