import * as Point3 from './point3';
import { Point3D, Line3D, Plane3D } from '../types';

export function intersection(plane: Plane3D, line: Line3D): Point3D {
  const p01 = Point3.substract(plane[1], plane[0]);
  const p02 = Point3.substract(plane[2], plane[0]);
  const lab = Point3.substract(line[1], line[0]);
  const a = Point3.dot(Point3.cross(p01, p02), Point3.substract(line[0], plane[0]));
  const b = -Point3.dot(lab, Point3.cross(p01, p02));
  const t = a / b;
  return Point3.add(line[0], Point3.multiply(lab, t));
}

export function shortestLineBetweenLines(line1: Line3D, line2: Line3D): Line3D {
  function d(m: number, n: number, o: number, p: number): number {
    const allPoints = [line1[0], line1[1], line2[0], line2[1]];
    const pm = allPoints[m - 1];
    const pn = allPoints[n - 1];
    const po = allPoints[o - 1];
    const pp = allPoints[p - 1];
    return (pm.x - pn.x) * (po.x - pp.x) + (pm.y - pn.y) * (po.y - pp.y) + (pm.z - pn.z) * (po.z - pp.z);
  }

  const muaNumerator = d(1, 3, 4, 3) * d(4, 3, 2, 1) - d(1, 3, 2, 1) * d(4, 3, 4, 3);
  const muaDenominator = d(2, 1, 2, 1) * d(4, 3, 4, 3) - d(4, 3, 2, 1) * d(4, 3, 2, 1);
  const mua = muaNumerator / muaDenominator;
  const mub = (d(1, 3, 4, 3) + mua * d(4, 3, 2, 1)) / d(4, 3, 4, 3);

  return <Line3D>[
    Point3.p(
      line1[0].x + mua * (line1[1].x - line1[0].x),
      line1[0].y + mua * (line1[1].y - line1[0].y),
      line1[0].z + mua * (line1[1].z - line1[0].z),
    ),
    Point3.p(
      line2[0].x + mub * (line2[1].x - line2[0].x),
      line2[0].y + mub * (line2[1].y - line2[0].y),
      line2[0].z + mub * (line2[1].z - line2[0].z),
    ),
  ];
}
