import { forwardRef, memo, useCallback } from 'react';
import Autocomplete from '@shared/components/autocomplete/Autocomplete';
import { TextField } from '@shared/views/form-dialog';

const FontTextField = forwardRef(({
  label,
  options,
  helperText,
  error,
  onChange,
  ...rest
}, ref) => {
  const handleChange = useCallback((event, data) => {
    onChange(data);
  }, [onChange]);

  return (
    <Autocomplete
      options={ options }
      renderInput={ (params) => (
        <TextField
          { ...params }
          label={ label }
          helperText={ helperText }
          error={ !!error }
          fullWidth
          required
        />
      ) }
      onChange={ handleChange }
      ref={ ref }
      disablePortal={ false }
      { ...rest }
    />
  );
});

export default memo(FontTextField);
