import { stringify } from 'query-string';
import { translatedFieldsToArray } from '@shared/utils/translations';

const ENDPOINT = '/materials';

class MaterialsApi {
  constructor(http) {
    this.http = http;
  }

  loadMaterials = () => {
    const query = { populate: ['textures'] };
    return this.http.get(`${ENDPOINT}?${stringify(query)}`);
  };

  loadTextures = () => this.http.get('/textures');

  deleteTexture = (id) => this.http.delete(`/textures/${id}`);

  getMaterialById = (id) => this.http.get(`${ENDPOINT}/${id}`);

  getMaterialsItemsCounts = () => this.http.get(`${ENDPOINT}/counts`);

  createMaterial = async (material) => {
    const { textures } = material;

    const translations = translatedFieldsToArray(material.translatedFields);
    delete material.translatedFields;

    if (textures.map) {
      material.url = textures.map.url;
    }
    // eslint-disable-next-line no-restricted-syntax
    for (const key of Object.keys(textures)) {
      const texture = textures[key];
      if (texture && texture.id) {
        textures[key] = texture.id;
      } else {
        textures[key] = undefined;
      }
    }

    return this.http.post(`${ENDPOINT}?populate=textures.map`, {
      ...material,
      translations,
      id: undefined,
    });
  };

  updateMaterial = async (material) => {
    const { textures } = material;

    const translations = translatedFieldsToArray(material.translatedFields);
    delete material.translatedFields;

    if (textures.map) {
      material.url = textures.map.url;
    }
    // eslint-disable-next-line no-restricted-syntax
    for (const key of Object.keys(textures)) {
      const texture = textures[key];
      if (texture && texture.id) {
        textures[key] = texture.id;
      } else {
        textures[key] = undefined;
      }
    }
    return this.http.put(`${ENDPOINT}/${material.id}`, {
      ...material,
      translations,
    });
  };

  deleteMaterialById = (id) => this.http.delete(`${ENDPOINT}/${id}`);

  markMaterialAsDefaultContent = (id) => this.http.put(`${ENDPOINT}/${id}/markAsDefaultContent`)

  unmarkMaterialAsDefaultContent = (id) => this.http.put(`${ENDPOINT}/${id}/unmarkAsDefaultContent`)

  hideMaterial = (id) => this.http.put(`${ENDPOINT}/${id}/hide`);

  checkTexture = (textureName) => this.http.post('/textures/usage', {
    name: textureName,
  })

  createTexture = async (texture, image) => {
    const formData = new FormData();
    formData.append('image', image);
    formData.append('name', texture.name);
    formData.append('type', texture.type);
    return this.http.post('/textures', formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
  }

  updateTexture = async (id, image) => {
    const formData = new FormData();
    formData.append('image', image);
    return this.http.put(`/textures/${id}`, formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
  }
}

export default MaterialsApi;
