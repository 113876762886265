import {useState, useMemo, useEffect} from 'react';
import { Switch, Case } from 'react-if';
import { Group, Circle, Line } from 'react-konva';
import { Point2D } from '../types';
import { Point2 } from '../utils';
import Magnifier from "@shared/components/magnifier/magnifier";

const POINT_SIZE = 8;
const CROSS_SIZE = 4;
const NOTCH_SIZE = 6;
const DRAG_SIZE = 10;

interface DraggablePointProps {
    id?: number
    enabled?: boolean
    position: Point2D
    direction?: Point2D
    color: string
    shape?: 'point' | 'notch' | 'cross'
    size?: number | null
    dragSize?: number | null
    onChanged?: (position: any, isDragging: any, id: any, event: any) => void
    onClick?: (event: any, id: number) => void,
    scale: number,
}

function DraggablePoint({
  id,
  enabled = true,
  position,
  direction: vector = Point2.zero(),
  color,
  shape = 'point',
  size,
  dragSize = DRAG_SIZE,
  onChanged,
  onClick,
  scale,
}: DraggablePointProps) {
  const [dragPosition, setDragPosition] = useState<Point2D>();
  const [magnifier, setMagnifier] = useState<any>();

  useEffect(() => {
    return () => {
      setMagnifier(null);
    }
  }, []);

  const handleDragStart = ({ target, evt }: any) => {
    if (!magnifier) {
      const newMagnifier = Magnifier({
        konva: target.getLayer(),
      });
      newMagnifier.bind(evt);
      newMagnifier.show(true);
      setMagnifier(newMagnifier);
    } else {
      magnifier.bind(evt);
      magnifier.show(true);
    }
    setDragPosition({ x: position.x - target.x(), y: position.y - target.y() });
  };

  const handleDragMove = (event: any) => {
    const { target } = event;
    const position = {
      x: target.x() + dragPosition!.x,
      y: target.y() + dragPosition!.y,
    };
    magnifier?.bind(event.evt);
    onChanged?.(position, true, id, event);
  };

  const handleDragEnd = (event: any) => {
    setDragPosition(undefined);
    magnifier?.show(false);
    onChanged?.(position, false, id, event);
  };

  const normal = Point2.normal(vector);
  const margin = (size: number, direction: 'along' | 'across') => {
    switch (direction) {
      case 'along':
        return Point2.multiply(vector, size);
      case 'across':
        return Point2.multiply(normal, size);
    }
  };

  const elementSize = useMemo(() => {
    switch (shape) {
      case 'point':
        return size ?? POINT_SIZE;
      case 'notch':
        return size ?? NOTCH_SIZE;
      case 'cross':
        return size ?? CROSS_SIZE;
    }
  }, []);

  return (
    <Group id={ `${id}` }>
      <Circle
        draggable={ enabled }
        radius={ dragSize! * scale }
        strokeWidth={ 2 * scale }
        x={ position.x }
        y={ position.y }
        onClick={ (event) => onClick?.(event, id!) }
        onDragStart={ handleDragStart }
        onDragMove={ handleDragMove }
        onDragEnd={ handleDragEnd }
      />
      <Switch>
        <Case condition={ shape == 'point' }>
          <Circle
            listening={ false }
            radius={ elementSize * scale }
            strokeWidth={ 2 * scale }
            stroke={ color }
            x={ position.x }
            y={ position.y }
          />
        </Case>
        <Case condition={ shape == 'cross' }>
          <Line
            listening={ false }
            points={
                            Point2.flatten(
                              Point2.add(position, Point2.p(margin(-elementSize, 'across').x, margin(-elementSize, 'along').y)),
                              Point2.add(position, Point2.p(margin(elementSize, 'across').x, margin(elementSize, 'along').y)),
                            )
                        }
            stroke={ color }
            strokeWidth={ 2 * scale }
          />
          <Line
            listening={ false }
            points={
                            Point2.flatten(
                              Point2.add(position, Point2.p(margin(elementSize * scale, 'across').x, margin(-elementSize, 'along').y)),
                              Point2.add(position, Point2.p(margin(-elementSize * scale, 'across').x, margin(elementSize, 'along').y)),
                            )
                        }
            stroke={ color }
            strokeWidth={ 2 * scale }
          />
        </Case>
        <Case condition={ shape == 'notch' }>
          <Line
            listening={ false }
            points={
                            Point2.flatten(
                              Point2.add(position, margin(elementSize * scale, 'across')),
                              Point2.add(position, margin(elementSize * scale, 'along')),
                              Point2.add(position, margin(-elementSize * scale, 'across')),
                            )
                        }
            stroke={ color }
            strokeWidth={ 2 * scale }
          />
        </Case>
      </Switch>
    </Group>
  );
}

export default DraggablePoint;
