import { WireframeMaterialName } from '@shared/constants';
import { LineBasicMaterial } from 'three';

export const makeMaterialsString = (materials, defaultAliases = []) => materials.reduce((acc, material, index) => {
  const materialIdentifier = material?.material?.id && defaultAliases[index] !== material.material.id
    ? material.material.id : 'default';

  return `${acc}-${materialIdentifier}`;
}, '');

export const mergeMaterials = (variantMaterials, productMaterials) => {
  const changedGroups = {};

  productMaterials.forEach((item, index) => {
    if (variantMaterials[index]?.group !== item.group) {
      changedGroups[item.group] = true;
    }
  });

  return productMaterials.map((item, index) => ({
    ...(changedGroups[item.group] || !variantMaterials[index].material ? {} : variantMaterials[index]),
    uvScaleX: item.uvScaleX,
    uvScaleY: item.uvScaleY,
    uvOffsetX: item.uvOffsetX,
    uvOffsetY: item.uvOffsetY,
    rotation: item.rotation,
  }));
};

export const isVariantExistsInProduct = (mergedVariantMaterials, product, excludeVariantIds = [], withProductComparision = true) => {
  let result = false;

  const defaultAliases = product.materials.map((item) => item?.id);
  const currentMaterialsString = makeMaterialsString(mergedVariantMaterials, defaultAliases);

  if (withProductComparision && currentMaterialsString === makeMaterialsString(product.materials, defaultAliases)) {
    result = true;
  } else {
    for (let i = 0; i < product.childProducts.length; i++) {
      if (excludeVariantIds.includes(product.childProducts[i].id)) {
        // eslint-disable-next-line no-continue
        continue;
      }

      if (makeMaterialsString(product.childProducts[i].materials, defaultAliases) === currentMaterialsString) {
        result = true;
        break;
      }
    }
  }

  return result;
};

export const getWireframeMaterial = (name = WireframeMaterialName) => (new LineBasicMaterial({ name, color: 0x00FF66 }));

// Basic values comparision
export const isEqualValues = (value1, value2) => {
  if (Array.isArray(value1)) {
    return value1.reduce((acc, item, index) => acc && (item === value2[index]), true);
  } if ((typeof value1 === 'number' || typeof value2 === 'number')) {
    return value1 == value2;
  }

  return value1 === value2;
};

export const isFormDirty = ({
  fieldValues,
  defaultValues = {},
  maxLevels = 4,
  currentLevel = 1,
  idKey,
  parentKey = '',
  dirtyFields = {},
  checkAll = false,
  dirtyCheckExclusion = [],
  formIdKeysMap = {},
}) => {
  const goDeeper = currentLevel < maxLevels;
  let result = false;

  if (idKey && typeof fieldValues === 'object' && !Array.isArray(fieldValues)) {
    const localDirty = !isEqualValues(fieldValues[idKey], defaultValues[idKey]);

    if (localDirty) {
      dirtyFields[`${parentKey}.${idKey}`] = true;
    }

    return localDirty;
  }

  for (const key in fieldValues) {
    if (dirtyCheckExclusion.includes(parentKey ?? key) || dirtyCheckExclusion.includes(`${parentKey}.${key}`)) {
      continue;
    }

    const valueType = typeof fieldValues[key];

    if (valueType === 'object' && goDeeper) {
      let nextParentKey = Array.isArray(fieldValues) ? parentKey : `${parentKey}.${key}`;
      if (nextParentKey.startsWith('.')) {
        nextParentKey = nextParentKey.slice(1);
      }

      const nextIdKey = formIdKeysMap[nextParentKey];

      if (dirtyCheckExclusion.includes(nextParentKey)) {
        continue;
      }

      const localDirty = isFormDirty({
        fieldValues: fieldValues[key],
        defaultValues: defaultValues[key],
        idKey: nextIdKey,
        parentKey: nextParentKey,
        currentLevel: currentLevel + 1,
        dirtyFields,
        checkAll,
        maxLevels,
        dirtyCheckExclusion,
        formIdKeysMap,
      });

      result = result || localDirty;

      if (localDirty && !checkAll) {
        return true;
      }
    } else {
      const localDirty = !isEqualValues(fieldValues[key], defaultValues[key]);

      result = result || localDirty;

      if (localDirty) {
        dirtyFields[`${parentKey}.${key}`] = true;

        if (!checkAll) {
          return true;
        }
      }
    }
  }

  return result;
};
