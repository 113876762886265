import { makeStyles } from '@material-ui/core';
import Subtitle from '../card-controls/Subtitle';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'block',
    position: 'relative',
    width: '100%',
    height: '100%',
    minHeight: theme.spacing(30),
    border: `${theme.spacing(0.25)}px dashed ${theme.palette.common.divider}`,
    cursor: 'pointer',
  },
  info: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  button: {
    position: 'relative',
    display: 'block',
    width: theme.spacing(5),
    height: theme.spacing(5),
    background: theme.palette.common.green,
    margin: '0 auto',
    marginBottom: theme.spacing(1),
    borderRadius: theme.shape.borderRadius,
  },
  plus: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  name: {
    width: 'max-content',
    fontWeight: 600,
  },
}));

const AddNew = ({ onClick }) => {
  const classes = useStyles();
  return (
    <div aria-label="create" onClick={ onClick } className={ classes.root }>
      <div className={ classes.info }>
        <div className={ classes.button }>
          <img
            className={ classes.plus }
            src="/assets/images/icons/plus.svg"
            alt="plus"
          />
        </div>
        <Subtitle className={ classes.name } align="center" color="textPrimary">
          Add new
        </Subtitle>
      </div>
    </div>
  );
};

export default AddNew;
