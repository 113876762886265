import classNames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => {
  const selectionIndicator = `0px 0px 0px 1.5px ${theme.palette.primary.main}, 0px 0px 0px 16px transparent, 1px -14px 6px 0px rgba(0,0,0,0)`;

  return {
    root: {
      borderRadius: '.2rem',
      '-webkit-box-shadow': selectionIndicator,
      boxShadow: selectionIndicator,
    },
  };
});

const withSelectable = (WrappedComponent) => ({ selected, className, ...rest }) => {
  const classes = useStyles();

  return (
    <WrappedComponent
      className={ classNames(className, {
        [classes.root]: selected,
      }) }
      { ...rest }
    />
  );
};

export default withSelectable;
