import { useRef, useEffect, useMemo } from 'react';
import * as THREE from 'three';
import { useThree } from '@react-three/fiber';
import { OrbitControls, useTexture, Stage } from '@react-three/drei';

const Scene = ({ type = 'sphere', value, textureSize, environment }) => {
  const { gl } = useThree();
  const availableTextures = Object.keys(value.textures)
    .filter((key) => value.textures[key] && value.textures[key].url)
    .reduce((acc, key) => {
      const { url } = value.textures[key];
      return ({ ...acc, [key]: textureSize ? `${url}?size=${textureSize}` : url });
    }, {});
  const textures = useTexture(availableTextures);

  const envConfig = useMemo(() => (environment ? {
    files: `${environment.url}?name=${environment.name.replace(' ', '_')}.${environment.type}`,
  } : null), [environment]);

  const controlsRef = useRef();
  const meshRef = useRef();

  useEffect(() => {
    const material = new THREE.MeshPhysicalMaterial();
    Object.keys(textures).forEach((key) => {
      material[key] = textures[key];
      if (key === 'map') {
        material[key].colorSpace = THREE.SRGBColorSpace;
      } else {
        material[key].colorSpace = THREE.NoColorSpace;
      }
      material[key].wrapS = THREE.RepeatWrapping;
      material[key].wrapT = THREE.RepeatWrapping;
      const raportX = value.raportY ? parseFloat(value.raportX) || 1 : 1;
      const raportY = value.raportY ? parseFloat(value.raportY) || 1 : 1;
      material[key].repeat.set(1 / raportX, 1 / raportY);
      material[key].needsUpdate = true;
    });
    Object.keys(value.configuration).forEach((key) => {
      if (['color', 'sheenColor'].indexOf(key) > -1) {
        material[key] = new THREE.Color(value.configuration[key]);
        return;
      }
      material[key] = value.configuration[key];
    });
    meshRef.current.material = material;
    if (meshRef.current.material.alphaMap) {
      meshRef.current.material.transparent = true;
    }
  }, [value, textures]);

  return (
    <>
      <OrbitControls ref={ controlsRef } rotateSpeed={ 1.5 } />
      <Stage adjustCamera={ type === 'sphere' ? 1.2 : 1.5 } intensity={ 0.5 } shadows="contact" environment={ envConfig }>
        {
          type === 'sphere' ? (
            <mesh ref={ meshRef }>
              <sphereGeometry args={ [1, 512, 512] } />
              <meshStandardMaterial color="blue" />
            </mesh>
          ) : (
            <mesh ref={ meshRef }>
              <boxGeometry args={ [1, 1, 1] } />
              <meshStandardMaterial color="orange" />
            </mesh>
          )
        }
      </Stage>
    </>
  );
};

export default Scene;
