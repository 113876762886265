import {
  all,
  call,
  put,
  select,
  takeEvery,
} from 'redux-saga/effects';
import { interiorsApi } from '@api';
import {
  LOAD_INTERIORS_LIST,
  LOAD_INTERIORS_COUNTS,
  LOAD_INTERIOR_SCENES,
  CREATE_INTERIOR,
  LOAD_INTERIOR,
  UPDATE_INTERIOR,
  DELETE_INTERIOR,
  renderInteriorsList,
  renderInterior,
  renderInteriorsCounts,
  putInteriorScenes,
  MARK_AS_DEFAULT,
  MARK_INTERIOR_AS_DEFAULT_CONTENT,
  UNMARK_INTERIOR_AS_DEFAULT_CONTENT,
  HIDE_INTERIOR,
  loadInteriorsList,
  deleteInteriorError,
  createInteriorSuccess,
  updateInteriorSuccess,
  createInteriorError,
  updateInteriorError, GET_INTERIOR_USAGE, getInteriorUsageSuccess,
} from './actions';
import { selectCurrentCompany } from '@store/app/selectors';
import addHiddenStateForItems from '@shared/utils/addHiddenStateForItems';

export function* getInteriors() {
  try {
    const data = yield call(interiorsApi.loadInteriors);
    const company = yield select(selectCurrentCompany);
    yield put(renderInteriorsList(addHiddenStateForItems(data, company)));
  } catch (error) {
    console.error(error);
  }
}

export function* getInteriorsCounts() {
  try {
    const data = yield call(interiorsApi.getInteriorsItemsCounts);
    yield put(renderInteriorsCounts(data));
  } catch (error) {
    console.error(error);
  }
}

export function* getInteriorScenes({ id }) {
  try {
    const data = yield call(interiorsApi.loadInteriorScenes, id);
    yield put(putInteriorScenes(data));
  } catch (error) {
    console.error(error);
  }
}

export function* createInterior({ interior }) {
  try {
    const data = yield call(interiorsApi.createInterior, interior);
    yield put(renderInterior(data));
    yield put(loadInteriorsList());
    yield put(createInteriorSuccess());
  } catch (error) {
    if (error.response?.data) {
      yield put(createInteriorError(error.response?.data));
      return;
    }
    console.error(error);
  }
}

export function* updateInterior({ interior }) {
  try {
    yield call(interiorsApi.updateInterior, interior);
    yield put(loadInteriorsList());
    yield put(updateInteriorSuccess());
  } catch (error) {
    if (error.response?.data) {
      yield put(updateInteriorError(error.response?.data));
      return;
    }
    console.error(error);
  }
}

export function* getInterior({ id }) {
  try {
    const data = yield call(interiorsApi.getInteriorById, id);
    yield put(renderInterior(data));
  } catch (error) {
    console.error(error);
  }
}

export function* getInteriorUsage({ id }) {
  try {
    const data = yield call(interiorsApi.getInteriorUsageById, id);
    yield put(getInteriorUsageSuccess(data));
  } catch (error) {
    console.error(error);
  }
}

export function* deleteInterior({ id }) {
  try {
    yield call(interiorsApi.deleteInteriorById, id);
    yield put(loadInteriorsList());
  } catch (error) {
    if (error.response?.data) {
      yield put(deleteInteriorError(id, error.response?.data));
      return;
    }
    console.error(error);
  }
}

export function* markAsDefault({ id }) {
  try {
    yield call(interiorsApi.markInteriorAsDefault, id);
  } catch (error) {
    console.error(error);
  }
}

export function* markInteriorAsDefaultContent({ payload: interior }) {
  try {
    yield call(interiorsApi.markInteriorAsDefaultContent, interior.id);
    yield put(loadInteriorsList());
  } catch (error) {
    console.error(error);
  }
}

export function* unmarkInteriorAsDefaultContent({ payload: interior }) {
  try {
    yield call(interiorsApi.unmarkInteriorAsDefaultContent, interior.id);
    yield put(loadInteriorsList());
  } catch (error) {
    console.error(error);
  }
}

export function* hideInterior({ payload: interior }) {
  try {
    yield call(interiorsApi.hideInterior, interior.id);
    yield put(loadInteriorsList());
  } catch (error) {
    console.error(error);
  }
}

export default function* interiorsSaga() {
  yield all([
    takeEvery(LOAD_INTERIORS_LIST, getInteriors),
    takeEvery(LOAD_INTERIORS_COUNTS, getInteriorsCounts),
    takeEvery(LOAD_INTERIOR_SCENES, getInteriorScenes),
    takeEvery(CREATE_INTERIOR, createInterior),
    takeEvery(LOAD_INTERIOR, getInterior),
    takeEvery(GET_INTERIOR_USAGE, getInteriorUsage),
    takeEvery(UPDATE_INTERIOR, updateInterior),
    takeEvery(DELETE_INTERIOR, deleteInterior),
    takeEvery(MARK_AS_DEFAULT, markAsDefault),
    takeEvery(MARK_INTERIOR_AS_DEFAULT_CONTENT, markInteriorAsDefaultContent),
    takeEvery(UNMARK_INTERIOR_AS_DEFAULT_CONTENT, unmarkInteriorAsDefaultContent),
    takeEvery(HIDE_INTERIOR, hideInterior),
  ]);
}
