import { memo } from 'react';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import InputBase from '@material-ui/core/InputBase';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/SearchOutlined';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
  },
  input: {
    width: '100%',
    fontSize: '1.4rem',
    letterSpacing: '0.01em',
    color: theme.palette.common.darkGrey,
    padding: theme.spacing(2, 0.5),
    '&::placeholder': {
      color: theme.palette.common.grey,
    },
  },
  icon: {
    color: theme.palette.common.divider,
  },
}));

const SearchInput = ({ className, value, onChange }) => {
  const classes = useStyles();

  return (
    <div className={ classNames(classes.root, className) }>
      <IconButton
        className={ classes.icon }
        aria-label="Open drawer"
      >
        <SearchIcon fontSize="large" />
      </IconButton>
      <InputBase
        className={ classes.input }
        type="search"
        placeholder="Search"
        onChange={ onChange }
        value={ value }
      />
    </div>
  );
};

export default memo(SearchInput);
