import { useCallback, useState } from 'react';

const useWarningControl = () => {
  const [warningOpen, setWarningOpen] = useState(false);

  const handleWarningControlClick = (event) => {
    event?.stopPropagation();
    setWarningOpen(true);
  };

  const handleWarningClose = useCallback((event) => {
    event?.stopPropagation();
    setWarningOpen(false);
  }, []);

  return [warningOpen, {
    handleWarningControlClick,
    handleWarningClose,
  }];
};

export default useWarningControl;
