/* eslint-disable max-len */

import SvgIcon from '@material-ui/core/SvgIcon';

const DeleteIcon = ({ fontSize = 'inherit' }) => (
  <SvgIcon fontSize={ fontSize } viewBox="0 0 12 14">
    <path
      strokeWidth=".5"
      d="M8 0H4C3.26467 0 2.66667 0.58305 2.66667 1.3V1.95H0V3.25H1.33333V11.7C1.33333 12.4169 1.93133 13 2.66667 13H9.33333C10.0687 13 10.6667 12.4169 10.6667 11.7V3.25H12V1.95H9.33333V1.3C9.33333 0.58305 8.73533 0 8 0ZM4 1.3H8V1.95H4V1.3ZM9.33333 11.7H2.66667V3.25H9.33333V11.7Z"
    />
    <path strokeWidth=".5" d="M4 4.55H5.33333V10.4H4V4.55Z" />
    <path strokeWidth=".5" d="M6.66667 4.55H8V10.4H6.66667V4.55Z" />
  </SvgIcon>
);

export default DeleteIcon;
