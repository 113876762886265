import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import TextField from './TextField';

const useStyles = makeStyles(() => ({
  axisGridItem: {
    display: 'flex',
    alignItems: 'center',
  },
}));

export const Axis = {
  X: 'x',
  Y: 'y',
  Z: 'z',
};

const AxisFields = ({
  name,
  value: [x, y, z],
  minX,
  maxX,
  minY,
  maxY,
  minZ,
  maxZ,
  xAxisControl,
  yAxisControl,
  zAxisControl,
  displayValue = (value) => value,
  disabled = [false, false, false],
  onChange,
  step = 1,
}) => {
  const classes = useStyles();

  const handleXChange = ({ target }) => {
    onChange({
      name,
      axisName: target.name,
      value: [target.value, y, z],
    });
  };

  const handleYChange = ({ target }) => {
    onChange({
      name,
      axisName: target.name,
      value: [x, target.value, z],
    });
  };

  const handleZChange = ({ target }) => {
    onChange({
      name,
      axisName: target.name,
      value: [x, y, target.value],
    });
  };

  return (
    <Grid container spacing={ 1 }>
      <Grid item xs={ 12 } className={ classes.axisGridItem }>
        {xAxisControl}
        <TextField
          label="x:"
          name={ Axis.X }
          value={ displayValue(x) }
          min={ minX }
          max={ maxX }
          step={ step }
          disabled={ disabled[0] }
          onChange={ handleXChange }
        />
      </Grid>
      <Grid item xs={ 12 } className={ classes.axisGridItem }>
        {yAxisControl}
        <TextField
          label="y:"
          name={ Axis.Y }
          value={ displayValue(y) }
          min={ minY }
          max={ maxY }
          step={ step }
          disabled={ disabled[1] }
          onChange={ handleYChange }
        />
      </Grid>
      <Grid item xs={ 12 } className={ classes.axisGridItem }>
        {zAxisControl}
        <TextField
          label="z:"
          name={ Axis.Z }
          value={ displayValue(z) }
          min={ minZ }
          max={ maxZ }
          step={ step }
          disabled={ disabled[2] }
          onChange={ handleZChange }
        />
      </Grid>
    </Grid>
  );
};

export default AxisFields;
