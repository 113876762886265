import RangeSlider from '../RangeSlider';
import { calcSceneDiagonal } from '@shared/utils';

const DistanceOption = ({
  name,
  value,
  onChange,
}) => (
  <RangeSlider
    label="Distance"
    name={ name }
    min={ 0 }
    max={ 50 }
    value={ value }
    onChange={ onChange }
  />
);

export default DistanceOption;
