/* eslint-disable max-len */
import { makeStyles } from '@material-ui/core';
import SvgIcon from '@material-ui/core/SvgIcon';
import { DefaultIconSizeValue, IconSize } from '@resources/icons/constants';

const useStyles = makeStyles(() => ({
  root: {
    fill: 'none',
  },
}));

const NaturalLightRight = ({ size = IconSize.Medium, selected = false }) => {
  const classes = useStyles();
  return (
    <SvgIcon
      style={ { width: DefaultIconSizeValue[size], height: DefaultIconSizeValue[size] } }
      className={ classes.root }
      viewBox="0 0 38 35"
    >
      <path
        opacity="0.8"
        d="M2.01758 32.5L24.0176 8H32.0176L15.5176 32.5H2.01758Z"
        fill={ selected ? '#ffffff' : '#EBEFF2' }
      />
      <path
        d="M14.8195 19.07H16.2495C16.423 19.0723 16.5921 19.0159 16.7295 18.91C16.8625 18.8046 16.9564 18.6578 16.9963 18.4929C17.0362 18.328 17.0197 18.1545 16.9495 18C16.8831 17.8397 16.7579 17.711 16.5995 17.64L2.11954 10.09C2.00157 10.0309 1.87147 10.0001 1.73954 10C1.60825 10.0042 1.48029 10.0423 1.36812 10.1106C1.25596 10.179 1.16342 10.2752 1.09954 10.39C1.03733 10.5073 1.0031 10.6373 0.99954 10.77V32.5H14.7395L14.8195 19.07Z"
        fill={ selected ? '#EBEFF2' : '#ffffff' }
        stroke="#334D6E"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.16207 18.0342V32.2424C1.16207 32.3429 1.20199 32.4393 1.27304 32.5104C1.3441 32.5814 1.44047 32.6213 1.54095 32.6213H14.4231C14.5236 32.6213 14.62 32.5814 14.691 32.5104C14.7621 32.4393 14.802 32.3429 14.802 32.2424V19.3603C14.802 19.2598 14.7621 19.1634 14.691 19.0924C14.62 19.0213 14.5236 18.9814 14.4231 18.9814"
        stroke="#334D6E"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.4229 18.9812H16.2567C16.4271 18.9819 16.5927 18.9252 16.7268 18.8202C16.8609 18.7152 16.9558 18.5681 16.9961 18.4026C17.0364 18.2371 17.0197 18.0629 16.9488 17.908C16.8779 17.7531 16.7569 17.6267 16.6053 17.549L2.26822 10.0849C2.15259 10.025 2.02348 9.99594 1.89333 10.0005C1.76319 10.005 1.63641 10.043 1.52522 10.1107C1.41403 10.1785 1.32218 10.2738 1.25853 10.3874C1.19489 10.501 1.1616 10.6291 1.16187 10.7594V25.4223"
        stroke="#334D6E"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M13.5176 33.1387V18.4785H15.5176V33.1387H13.5176Z" fill="#334D6E" />
      <path d="M36.0176 9H20.0176" stroke="#334D6E" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M33.6738 14.6572L22.3601 3.34352"
        stroke="#334D6E"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22.3594 14.6572L33.6731 3.34352"
        stroke="#334D6E"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M28.0176 17V1" stroke="#334D6E" strokeLinecap="round" strokeLinejoin="round" />
      <circle
        r="5.4824"
        transform="matrix(0 -1 -1 0 28.0352 8.98244)"
        fill="#F0F0F0"
        stroke="#334D6E"
      />
      <circle r="4" transform="matrix(-1 0 0 1 28.0176 9)" fill="#334D6E" />
    </SvgIcon>
  );
};

export default NaturalLightRight;
