import {
  memo, useCallback, useEffect, useRef, useState,
} from 'react';
import { useThree } from '@react-three/fiber';
import {
  Euler, MathUtils as threeMath, Quaternion,
} from 'three';
import Cube from './Cube';
import { convertQuaternionToRadiansAngle, displayValue } from '@shared/utils';

const CubeController = ({
  disabled,
  rotation,
  onRotationEnd,
}) => {
  const cubeRef = useRef();

  const { gl } = useThree();
  const [isDragging, setDragging] = useState(false);
  const [previousMousePosition, setPreviousMousePosition] = useState({
    x: 0,
    y: 0,
  });

  const toRadians = (angle) => threeMath.degToRad(angle);

  const convertCubeQuaternionToEuler = (cube) => new Euler().setFromQuaternion(cube.quaternion);

  const shouldUseDefaultRotation = (defaultRotation) => (displayValue(defaultRotation.x) === rotation[0]
    && displayValue(defaultRotation.z) === rotation[2]);

  const getLockedCubeRotation = (cube) => {
    const defaultRotation = convertCubeQuaternionToEuler(cube);
    return shouldUseDefaultRotation(defaultRotation)
      ? defaultRotation
      : {
        x: +rotation[0],
        y: convertQuaternionToRadiansAngle(cube.quaternion, 'y'),
        z: +rotation[2],
      };
  };

  const setRotation = useCallback((offsetX, offsetY) => {
    const cube = cubeRef.current;

    const deltaMove = {
      x: offsetX - previousMousePosition.x,
      y: offsetY - previousMousePosition.y,
    };

    const x = disabled[0] ? 0 : toRadians(deltaMove.y);
    const y = disabled[1] ? 0 : toRadians(deltaMove.x);

    const deltaRotationQuaternion = new Quaternion()
      .setFromEuler(new Euler(x, y, 0, 'XYZ'));

    cube.quaternion.multiplyQuaternions(deltaRotationQuaternion, cube.quaternion);
  }, [previousMousePosition, disabled]);

  useEffect(() => {
    const cube = cubeRef.current;
    if (!cube) return () => undefined;

    const mouseDownListener = () => {
      setDragging(true);
    };

    const touchStartListener = (event) => {
      const offsetX = event.touches[0].pageX - event.touches[0].target.offsetLeft;
      const offsetY = event.touches[0].pageY - event.touches[0].target.offsetTop;

      setPreviousMousePosition({
        x: offsetX,
        y: offsetY,
      });

      setDragging(true);
    };

    const mouseMoveListener = (event) => {
      const { offsetX, offsetY } = event;

      if (isDragging) {
        setRotation(offsetX, offsetY);
      }

      setPreviousMousePosition({
        x: offsetX,
        y: offsetY,
      });
    };

    const touchMoveListener = (event) => {
      const offsetX = event.touches[0].pageX - event.touches[0].target.offsetLeft;
      const offsetY = event.touches[0].pageY - event.touches[0].target.offsetTop;

      if (isDragging) {
        setRotation(offsetX, offsetY);
      }

      setPreviousMousePosition({
        x: offsetX,
        y: offsetY,
      });
    };

    const upListener = () => {
      if (isDragging) {
        const newRotation = disabled[0] ? getLockedCubeRotation(cube) : cube.rotation;
        onRotationEnd(newRotation);
        setDragging(false);
      }
    };

    gl.domElement.addEventListener('mousedown', mouseDownListener);
    gl.domElement.addEventListener('mousemove', mouseMoveListener);
    document.addEventListener('mouseup', upListener);

    gl.domElement.addEventListener('touchstart', touchStartListener);
    gl.domElement.addEventListener('touchmove', touchMoveListener);
    document.addEventListener('touchend', upListener);

    return () => {
      gl.domElement.removeEventListener('mousedown', mouseDownListener);
      gl.domElement.removeEventListener('mousemove', mouseMoveListener);
      document.removeEventListener('mouseup', upListener);

      gl.domElement.removeEventListener('touchstart', touchStartListener);
      gl.domElement.removeEventListener('touchmove', touchMoveListener);
      document.removeEventListener('touchend', upListener);
    };
  }, [gl.domElement, isDragging, disabled, onRotationEnd, setRotation, rotation]);

  return (
    <Cube
      ref={ cubeRef }
      rotation={ rotation }
    />
  );
};

export default memo(CubeController);
