export function isNone(...objects: any[]) {
  return objects.some((o) => (o == undefined || o == null));
}

export function doWith<T>(object: T, block: (object: T) => void): T | null {
  if (isNone(object)) {
    return null;
  }

  block(object);
  return object;
}

export function transformNotNull<T, R>(transform: (...elements: T[]) => R, elements: T[]): R | null {
  if (elements.some(isNone)) {
    return null;
  }

  return transform(...elements);
}
