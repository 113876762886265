import Grid from '@material-ui/core/Grid';

const GroupField = ({ children, sizes, ...rest }) => (
  <>
    {
      children.map((child, index) => {
        const size = sizes ? sizes[index] : 12 / children.length;
        return (<Grid key={ index } item xs={ size } { ...rest }>{child}</Grid>);
      })
    }
  </>
);

export default GroupField;
