import { forwardRef } from 'react';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import MuiSlider from '@material-ui/core/Slider';

const useStyles = makeStyles(() => ({
  thumb: {
    cursor: 'ew-resize',
  },
  vertical: {
    '& $thumb': {
      cursor: 'ns-resize',
    },
  },
}));

const Slider = ({
  classes = {},
  id,
  name,
  value,
  max,
  min,
  step,
  orientation,
  isDisabled = false,
  onChange,
}, ref) => {
  const selfClasses = useStyles();

  const handleChange = (event, newValue) => {
    onChange({
      name,
      value: newValue,
    });
  };

  return (
    <MuiSlider
      ref={ ref }
      classes={ {
        ...classes,
        thumb: classNames(selfClasses.thumb, classes.thumb),
        vertical: classNames(selfClasses.vertical, classes.vertical),
        track: classNames(selfClasses.track, classes.track),
        rail: classNames(selfClasses.rail, classes.rail),
      } }
      id={ id }
      name={ name }
      value={ value }
      color="secondary"
      aria-labelledby={ `${name}-slider` }
      step={ step }
      min={ min }
      max={ max }
      orientation={ orientation }
      onChange={ handleChange }
      disabled={ isDisabled }
    />
  );
};

export default forwardRef(Slider);
