import { Suspense, useMemo, forwardRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Canvas } from '@react-three/fiber';
import * as THREE from 'three';

import Backdrop from '@shared/components/backdrop';
import CircularProgress from '@shared/components/circular-progress';
import Scene from './Scene';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    height: 'calc(100% - 68px)',
    marginTop: '68px',
  },
  canvas: {
    cursor: 'pointer',
    minHeight: 'inherit',

    '& canvas': {
      width: '100% !important',
      height: 'auto !important',
      '@media(min-width: 1280px)': {
        padding: 0.7,
      },
    },
  },
}));

const StudioViewer = ({
  value, background, camera, presets, environment,
  envIntensity, intensity, shadows, materials, onLoad, onMaterialsLoad,
  toneMapping, toneMappingExposure, ambient, ambientIntensity,
}, forwardRef) => {
  const classes = useStyles();

  const glConfig = useMemo(
    () => ({
      antialias: true,
      preserveDrawingBuffer: true,
      toneMapping: THREE[toneMapping],
      toneMappingExposure,
    }),
    [toneMapping, toneMappingExposure],
  );

  return (
    <Suspense
      fallback={ (
        <Backdrop className={ classes.backdrop }>
          <CircularProgress size="large" />
        </Backdrop>
      ) }
    >
      <div className={ classes.root }>
        <Canvas
          className={ classes.canvas }
          gl={ glConfig }
          dpr={ window.devicePixelRatio || 1 }
          camera={ camera }
          shadows
        >
          <color attach="background" args={ [background] } />
          <Scene
            ref={ forwardRef }
            value={ value }
            background={ background }
            presets={ presets }
            environment={ environment }
            envIntensity={ envIntensity }
            ambient={ ambient }
            ambientIntensity={ ambientIntensity }
            intensity={ intensity }
            shadows={ shadows }
            materials={ materials }
            camera={ camera }
            onLoad={ onLoad }
            onMaterialsLoad={ onMaterialsLoad }
          />
        </Canvas>
      </div>
    </Suspense>
  );
};

export default forwardRef(StudioViewer);
