import React from 'react';
import Grid from '@material-ui/core/Grid';

const Head = ({ children }) => (
  <Grid container spacing={ 2 }>
    {React.Children.map(children, (child) => (
      <Grid item xs={ 12 }>
        {child}
      </Grid>
    ))}
  </Grid>
);

export default Head;
