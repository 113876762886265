import { useCallback, useMemo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { Fieldset } from '@pages/products/modal/fieldset';
import LightPresetsController from '@pages/products/modal/LightPresetsController';
import ImagePreviewField from '@pages/products/modal/ImagePreviewField';
import Button from '@shared/components/button';

const useStyles = makeStyles(() => ({
  actionsWrapper: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '27px',
  },
  generateThumbnailButton: {
    width: '140px',
    height: '42px',
  },
}));

const namesMapping = {
  environmentField: Fieldset.Environment,
  modelField: Fieldset.Model,
  thumbnailField: Fieldset.Thumbnail,
  thumbnailReplicationField: Fieldset.ModelData,
};

// Replica's only purpose is to maintain current approach to show/edit/save form data for products
export default function useProductPreview({ object, gl, names = namesMapping, name } = {}) {
  const classes = useStyles();

  const {
    control,
    getValues,
    setValue,
  } = useFormContext();

  const currentModel = useWatch({ control, name: names.modelField });

  const changeReplica = useCallback((thumbnail) => {
    if (names.thumbnailReplicationField) {
      const currentReplicaValue = getValues(names.thumbnailReplicationField);

      if (typeof currentReplicaValue === 'object') {
        setValue(names.thumbnailReplicationField, typeof currentReplicaValue === 'object'
          ? { ...currentReplicaValue, thumbnail } : thumbnail);
      }
    }
  }, [getValues, names, setValue]);

  const handleGenerateThumbnailClick = useCallback(() => {
    const thumbnail = gl.domElement.toDataURL();

    setValue(names.thumbnailField, thumbnail);

    changeReplica(thumbnail);
  }, [setValue, gl, names, changeReplica]);

  const handleThumbnailChange = useCallback((callback, ...args) => {
    changeReplica(args[0]);

    if (typeof callback === 'function') {
      callback(...args);
    }
  }, [changeReplica]);

  const renderLightPresetsController = useCallback((alterObject) => (alterObject || object ? (
    <LightPresetsController name={ names.environmentField } object={ alterObject || object } />
  ) : null), [object, names]);

  const renderThumbnailController = useCallback(() => (
    <Controller
      name={ names.thumbnailField }
      control={ control }
      render={ ({ field }) => (
        <ImagePreviewField { ...field } fileName={ name } onChange={ (...args) => handleThumbnailChange(field.onChange, ...args) } />
      ) }
    />
  ), [name, names, control, handleThumbnailChange]);

  const renderActions = useCallback(() => (
    <div className={ classes.actionsWrapper }>
      <Button disabled={ !object || !currentModel.id } className={ classes.generateThumbnailButton } onClick={ handleGenerateThumbnailClick }>
        Generate Preview
      </Button>
    </div>
  ), [object, currentModel, classes, handleGenerateThumbnailClick]);

  return useMemo(() => ({
    renderLightPresetsController,
    renderThumbnailController,
    renderActions,
  }), [
    renderLightPresetsController,
    renderThumbnailController,
    renderActions,
  ]);
}
