import { forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useState } from 'react';
import { Controller, useFieldArray, useFormContext, useWatch } from 'react-hook-form';
import { get } from 'lodash';
import { makeStyles } from '@material-ui/core/styles';
import SettingsIcon from '@material-ui/icons/Settings';

import MaterialTextField from './MaterialTextField';
import GroupTextField from './GroupTextField';
import { InputLabelProps } from './fieldset';

import {
  Fields,
  TextField,
  GroupField,
  Field,
} from '@shared/views/form-dialog';
import MainGroupField from '@pages/products/modal/MainGroupField';
import { getWireframeMaterial } from '@pages/products/utils';
import { IconButton, Popover } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import useFormValues from '@shared/hooks/useFormValues';
import { deg2rad, rad2deg } from '@shared/components/camera-solver/utils';
import { useSelector } from 'react-redux';
import useActions from '@shared/hooks/useActions';
import { actions } from '@store/material-groups';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '0 8px',
    width: '100%',
  },
  label: {
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '21px',
    padding: '20px 0',
    color: theme.palette.common.darkGrey,
    textDecoration: 'underline',
    cursor: 'pointer',
    '&:hover': {
      color: theme.palette.common.green,
    },
  },
  popup: {
    padding: '1.4rem',
    maxWidth: '253px',
  },
}));

const MeshTable = ({
  name,
  control,
  object,
}, forwardRef) => {
  const classes = useStyles();
  const [popovers, setPopovers] = useState([]);

  const form = useFormContext();
  const {
    setValue,
    formState,
  } = form;

  const fieldArray = useFieldArray({
    control,
    name,
  });

  const fieldsErrors = (path) => get(formState.errors, path);

  const { fields, replace } = fieldArray;

  const values = useWatch({ name, defaultValue: fields });

  const { loadGroups } = useActions(actions);
  const groupValues = useSelector((state) => state.groups.groups);

  useImperativeHandle(forwardRef, () => fieldArray, [fieldArray]);

  const { materials, model } = useFormValues(form);

  const meshes = useMemo(() => {
    const result = [];
    if (object) {
      object.traverse((element) => {
        if (element.isMesh) {
          result.push(element);
        }
      });
    }
    return result;
  }, [object]);

  useEffect(() => {
    loadGroups();
  }, [loadGroups]);

  useEffect(() => {
    const groups = [];
    if (control._formValues.materials?.length) {
      return;
    }
    const result = meshes.map((mesh, index) => {
      if (groups.indexOf(mesh.material?.name) === -1) {
        groups.push(mesh.material?.name);
      }
      return {
        group: {
          id: undefined,
          name: 'Non-configurable Part',
        },
        isMain: index === 0,
        material: undefined,
        uvScaleX: 1,
        uvScaleY: 1,
        uvOffsetX: 0,
        uvOffsetY: 0,
        rotation: 0,
      };
    });
    replace(result);
  }, [control, meshes, replace]);

  const handleGroupChange = (index) => {
    const objectMeshPart = materials[index];
    if (!objectMeshPart.group.id) {
      return;
    }
    const anotherGroup = materials.find((m) => m !== objectMeshPart && objectMeshPart.group.id === m.group.id);
    if (anotherGroup) {
      objectMeshPart.material = anotherGroup;
      setValue(`${name}[${index}].material`, anotherGroup.material);
    }
  };

  const onClickHandler = useCallback((index) => {
    const wireframeMaterial = getWireframeMaterial();
    wireframeMaterial.wireframe = true;

    meshes[index].userData.wireframeToApply = wireframeMaterial;
  }, [meshes]);

  const mouseLeaveHandler = useCallback((index) => {
    if (meshes[index].userData.wireframeToApply) {
      meshes[index].userData.wireframeToApply.dispose();
      delete meshes[index].userData.wireframeToApply;
    }
  }, [meshes]);

  const handleMaterialSelect = useCallback((index, material) => {
    materials.forEach((item, index) => {
      mouseLeaveHandler(index);
    });

    const objectMeshPart = materials[index];

    if (!objectMeshPart.group.id) {
      return;
    }

    materials.forEach((item, itemIndex) => {
      if (item.group.id === objectMeshPart.group.id) {
        if (itemIndex !== index) {
          setValue(`${name}[${itemIndex}].material`, material);
        }
      }
    });
  }, [materials, setValue, name, mouseLeaveHandler]);

  const handleIsMainAfterChange = useCallback((index, checked) => {
    if (!checked) {
      setValue(`${name}.0.isMain`, true);
    } else {
      materials.forEach((item, itemIndex) => {
        if (itemIndex !== index && item.isMain) {
          setValue(`${name}.${itemIndex}.isMain`, false);
        }
      });
    }
  }, [materials, setValue, name]);

  return (
    <div className={ classes.root }>
      {
        fields.map((objectMeshPart, index) => (
          <div
            key={ objectMeshPart.id }
          >
            <div
              className={ classes.label }
              onClick={ onClickHandler.bind(this, index) }
              onMouseLeave={ mouseLeaveHandler.bind(this, index) }
            >Part {index + 1}
            </div>
            <Fields>
              <Field style={ { position: 'relative' } }>
                <Controller
                  name={ `${name}.${index}.material` }
                  control={ control }
                  defaultValue={ { name: '' } }
                  render={ ({ field }) => (
                    <MaterialTextField
                      { ...field }
                      helperText={ fieldsErrors(`${name}.${index}.material`)?.message }
                      label="Material"
                      error={ !!fieldsErrors(`${name}[${index}].material`) }
                      onSelect={ handleMaterialSelect.bind(this, index) }
                    />
                  ) }
                />
              </Field>
              <GroupField
                sizes={ [10, 2] }
                style={ { position: 'relative' } }
              >
                <Controller
                  name={ `${name}.${index}.group` }
                  control={ control }
                  defaultValue={ fields[index].group }
                  rules={ {
                    required: 'Required',
                  } }
                  render={ ({ field }) => (
                    <GroupTextField
                      { ...field }
                      helperText={ fieldsErrors(`${name}.${index}.group`)?.message }
                      label="Part Type"
                      required
                      error={ !!fieldsErrors(`${name}[${index}].group`) }
                      options={ groupValues }
                      onSelect={ handleGroupChange.bind(this, index) }
                    />
                  ) }
                />
                <IconButton onClick={ (event) => {
                  setPopovers((items) => {
                    items[index] = event.currentTarget;
                    return [...items];
                  });
                } }
                >
                  <SettingsIcon
                    size="large"
                    color="primary"
                  />
                </IconButton>
                <Popover
                  open={ !!popovers[index] }
                  anchorEl={ popovers[index] }
                  onClose={ () => {
                    setPopovers((items) => {
                      items[index] = null;
                      return [...items];
                    });
                  } }
                  anchorOrigin={ {
                    vertical: 'bottom',
                    horizontal: 'center',
                  } }
                  transformOrigin={ {
                    vertical: 'top',
                    horizontal: 'center',
                  } }
                >
                  <Box className={ classes.popup }>
                    <Fields>
                      <GroupField
                        sizes={ [6, 6] }
                        style={ { position: 'relative' } }
                      >
                        <Controller
                          name={ `${name}.${index}.uvScaleX` }
                          control={ control }
                          defaultValue={ 1 }
                          render={ ({ field }) => (
                            <TextField
                              { ...field }
                              label="UV Scale X"
                              type="number"
                              step="0.05"
                              InputLabelProps={ InputLabelProps }
                            />
                          ) }
                        />
                        <Controller
                          name={ `${name}.${index}.uvScaleY` }
                          control={ control }
                          defaultValue={ 1 }
                          render={ ({ field }) => (
                            <TextField
                              { ...field }
                              label="UV Scale Y"
                              type="number"
                              step="0.05"
                              InputLabelProps={ InputLabelProps }
                            />
                          ) }
                        />
                      </GroupField>
                      <GroupField
                        sizes={ [6, 6] }
                        style={ { position: 'relative' } }
                      >
                        <Controller
                          name={ `${name}.${index}.uvOffsetX` }
                          control={ control }
                          defaultValue={ 0 }
                          render={ ({ field }) => (
                            <TextField
                              { ...field }
                              label="UV Offset X"
                              type="number"
                              min={ 0 }
                              max={ 1 }
                              step="0.05"
                              InputLabelProps={ InputLabelProps }
                            />
                          ) }
                        />
                        <Controller
                          name={ `${name}.${index}.uvOffsetY` }
                          control={ control }
                          defaultValue={ 0 }
                          render={ ({ field }) => (
                            <TextField
                              { ...field }
                              label="UV Offset Y"
                              type="number"
                              min={ 0 }
                              max={ 1 }
                              step="0.05"
                              InputLabelProps={ InputLabelProps }
                            />
                          ) }
                        />
                      </GroupField>
                      <Field style={ { position: 'relative' } }>
                        <Controller
                          name={ `${name}.${index}.rotation` }
                          control={ control }
                          defaultValue={ 0 }
                          render={ ({ field }) => (
                            <TextField
                              { ...field }
                              label="UV Rotation"
                              value={ Math.round(rad2deg(field.value)) }
                              onChange={ (event) => {
                                setValue(`${name}.${index}.rotation`, deg2rad(event.target.value));
                              } }
                              type="number"
                              min={ 0 }
                              max={ 360 }
                              step="1"
                              InputLabelProps={ InputLabelProps }
                            />
                          ) }
                        />
                      </Field>
                    </Fields>
                  </Box>
                </Popover>
              </GroupField>

              <Controller
                name={ `${name}.${index}.isMain` }
                control={ control }
                /* eslint-disable-next-line no-prototype-builtins */
                defaultValue={ objectMeshPart.hasOwnProperty('isMain') ? objectMeshPart.isMain : (index === 0) }
                render={ ({ field }) => (
                  <MainGroupField
                    { ...field }
                    disabled={ !values[index]?.group.id }
                    onAfterChange={ handleIsMainAfterChange.bind(this, index) }
                  />
                ) }
              />
            </Fields>
          </div>
        ))
      }
    </div>
  );
};

export default forwardRef(MeshTable);
