import { Select, MenuItem } from '@material-ui/core';
import { useState, useMemo, ReactNode } from 'react';
import { SolverHolder, AbstractStep } from '../types';
import { avaliableSolvers } from '../solvers';
import Stack from '@shared/components/stack';
import Button from '@shared/components/button';

interface ComponentProps {
    onComplete: (holder: SolverHolder<any>) => void
    onCancel: () => void
}

function Component({ onComplete, onCancel }: ComponentProps) {
  const [index, setIndex] = useState(0);
  const holder = useMemo<SolverHolder<any>>(() => avaliableSolvers[index], [index]);

  const handleChange = ({ target }: any) => {
    setIndex(target.value);
  };

  return (
    <Stack direction="vertical" gap={ 4 }>
      <div>
        <Select value={ index } onChange={ handleChange }>
          {avaliableSolvers.map((holder, index) => (<MenuItem key={ index } value={ index }>{holder.label}</MenuItem>))}
        </Select>
      </div>
      <Stack
        direction="row"
        gap={ 2 }
      >
        <Button onClick={ () => onCancel() }>Cancel</Button>
        <Button onClick={ () => onComplete(holder) } variant="contained" disabled={ !holder }>Continue</Button>
      </Stack>
    </Stack>
  );
}

class ChooseSolverStep extends AbstractStep {
    private solverHolder: SolverHolder<any> | undefined;

    private onComplete: (holder: SolverHolder<any>) => void

    private onCancel: () => void

    get label() {
      return 'Choose Solver';
    }

    get isCompleted(): boolean {
      return this.solverHolder != null;
    }

    constructor(onComplete: (holder: SolverHolder<any>) => void, onCancel: () => void) {
      super();
      this.onComplete = onComplete;
      this.onCancel = onCancel;
    }

    component(): ReactNode {
      return (
        <Component
          onComplete={ (solver) => this.complete(solver) }
          onCancel={ this.onCancel }
        />
      );
    }

    private complete(holder: SolverHolder<any>) {
      this.solverHolder = holder;
      this.onComplete(holder);
    }
}

export default ChooseSolverStep;
