import { useState, useMemo, useEffect } from 'react';
import { Group, Line } from 'react-konva';
import {Point2D, Line2D, Axis} from '../types';
import { Point2 } from '../utils';

import LineSegment from './LineSegment';

const DEFAULT_COLOR = '#359DF9';
const DEFAULT_MIDPOINT = { x: 100, y: 100 };
const DEFAULT_DIRECTION = 'horizontal';
const DEFAULT_MARGIN = { x: 50, y: 50 };
const VP_LINE_OPACITY = 0.7;

function line(midpoint: Point2D, direction: 'horizontal' | 'vertical', lineType: 'line1' | 'line2', m: Point2D): Line2D {
  switch (direction) {
    case 'horizontal':
      switch (lineType) {
        case 'line1':
          return [Point2.sum(midpoint, { x: -m.x, y: -m.y }), Point2.sum(midpoint, { x: m.x, y: -m.y })];
        case 'line2':
          return [Point2.sum(midpoint, { x: -m.x, y: m.y }), Point2.sum(midpoint, { x: m.x, y: m.y })];
      }
    case 'vertical':
      switch (lineType) {
        case 'line1':
          return [Point2.sum(midpoint, { x: -m.x, y: -m.y }), Point2.sum(midpoint, { x: -m.x, y: m.y })];
        case 'line2':
          return [Point2.sum(midpoint, { x: m.x, y: -m.y }), Point2.sum(midpoint, { x: m.x, y: m.y })];
      }
  }
}

interface VanishingPointProps {
    vanishingPoint?: Point2D
    lines: Line2D[]
    color: string,
    scale: number,
}

function VanishingPoint({ vanishingPoint, lines, color, scale }: VanishingPointProps) {
  if (!vanishingPoint) {
    return null;
  }

  return (
    <Group>
      {lines.map((line, index) => (
        <Line
          key={index}
          points={ Point2.flatten(vanishingPoint!, Point2.nearest(vanishingPoint!, ...line)) }
          listening={ false }
          stroke={ color }
          strokeWidth={ scale }
          dash={ [1, 1] }
          opacity={ VP_LINE_OPACITY }
        />
      ))}
    </Group>
  );
}

interface VanishingPointControlProps {
    index: number
    midpoint?: Point2D | null
    direction?: 'horizontal' | 'vertical' | null
    margin?: Point2D | null
    color?: string | null
    onReady?: (result: {
      line1: Line2D,
      line2: Line2D,
      vanishingPoint: Point2D,
    }) => void
    onEmpty?: () => void
    line1?: Line2D
    line2?: Line2D,
    label?: string,
    layer: any,
    scale: number,
}

function VanishingPointControl({
  index,
  color = DEFAULT_COLOR,
  midpoint = DEFAULT_MIDPOINT,
  direction = DEFAULT_DIRECTION,
  margin = DEFAULT_MARGIN,
  onReady,
  onEmpty,
  line1,
  line2,
  label = '',
  layer,
  scale,
}: VanishingPointControlProps) {
  const [l1, setL1] = useState<Line2D>(line1 ?? line(midpoint!, direction!, 'line1', margin!));
  const [l2, setL2] = useState<Line2D>(line2 ?? line(midpoint!, direction!, 'line2', margin!));
  const [vanishingPoint, setVanishingPoint] = useState<Point2D | null>(null);
  const [isDragging, setDragging] = useState(false);

  useEffect(() => {
    const vanishingPoint = Point2.intersection(l1, l2);
    if (vanishingPoint) {
      onReady?.({
        line1: l1,
        line2: l2,
        vanishingPoint
      })
    }
    setVanishingPoint(vanishingPoint);
  }, [isDragging, l1, l2]);

  return (
    <Group>
      <LineSegment
        color={ color }
        label={ label || index.toString() }
        points={ l1 }
        onChanged={ (line, isDragging, event) => {
          setDragging(isDragging);
          setL1(line);
        } }
        scale={ scale }
      />
      <LineSegment
        color={ color }
        label={ label || index.toString() }
        points={ l2 }
        onChanged={ (line, isDragging, event) => {
          setDragging(isDragging);
          setL2(line);
        } }
        scale={ scale }
      />
      <VanishingPoint
        color={ color! }
        lines={ [l1, l2] }
        vanishingPoint={ vanishingPoint! }
        scale={ scale }
      />
    </Group>
  );
}

export default VanishingPointControl;
