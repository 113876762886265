import { forwardRef, memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import classNames from 'classnames';

import Autocomplete from '@shared/components/autocomplete/Autocomplete';
import { TextField } from '@shared/views/form-dialog';
import UploadIcon from '@resources/icons/UploadIcon';
import { Fieldset } from './fieldset';
import DeleteIcon from '@resources/icons/DeleteIcon';
import {
  deleteMaterialError,
  deleteTexture,
  loadTexturesList,
} from '@store/materials/actions';
import DialogsContainer from '@shared/components/card-controls/DialogsContainer';
import WarningDialog from '@shared/components/card-controls/WarningDialog';
import useWarningControl from '@shared/components/card-controls/useWarningControl';
import DownloadIcon from '@shared/icons/DownloadIcon';
import axios from 'axios';
import { materialsApi } from '@api';
import CircularProgress from '@shared/components/circular-progress';
import WarningUploadDialog from '@pages/materials/modal/WarningUploadDialog';

const useStyles = makeStyles({
  textFieldRoot: {
    "& > div.MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root']": {
      paddingRight: '9px',
      '& button': {
        order: 3,
      },
      '& .loader': {
        order: 3,
        margin: '0 3px',
      },
      '& > div.MuiAutocomplete-endAdornment': {
        position: 'relative',
        order: 2,
      },
    },
  },
  option: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    '& > img': {
      width: '40px',
      height: '40px',
    },
  },
  downloadButton: {
    marginRight: '5px',
    '&:disabled': {
      opacity: 0.5,
    },
  },
});

const TextureTextField = forwardRef(({
  name,
  label,
  onChange,
  value,
  options,
}, ref) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const apiError = useSelector((state) => state.materials.error);
  const defaultValue = useMemo(() => {
    const typeKey = Object.keys(Fieldset.Textures).find((key) => Fieldset.Textures[key].key === name);
    return { type: Fieldset.Textures[typeKey].type, name: '', url: '' };
  }, [name]);
  const [isUploading, setIsUploading] = useState(false);
  const [image, setImage] = useState(null);

  const [warningDeleteOpen, {
    handleWarningControlClick: handleWarningDeleteControlClick,
    handleWarningClose: handleWarningDeleteClose,
  }] = useWarningControl();

  const [warningUploadOpen, {
    handleWarningControlClick: handleWarningUploadControlClick,
    handleWarningClose: handleWarningUploadClose,
  }] = useWarningControl();

  const [warningDeleteTitle, setWarningDeleteTitle] = useState('');
  const [warningUploadTitle, setWarningUploadTitle] = useState('');

  const handleChange = useCallback((_, data) => {
    if ((data && data.id) || !data) {
      onChange(data || defaultValue);
    } else if (!data.id) {
      onChange(value);
    }
  }, [onChange, options, value, defaultValue]);

  const getOptionObject = useCallback((option) => ((typeof option === 'string' || option instanceof String)
    ? options.find((op) => op.id === option)
    : option
  ) || defaultValue, [options, defaultValue]);

  const getOptionLabel = useCallback((option) => (
    option ? getOptionObject(option).name : option
  ), [getOptionObject]);

  const getOptionSelected = useCallback((option, value) => (
    value ? option.id === getOptionObject(value).id : value
  ), [getOptionObject]);

  const handleDelete = useCallback((value, event) => {
    event.stopPropagation();
    dispatch(deleteTexture(value.id, name));
  }, [dispatch, name]);

  const handleUpload = useCallback((event) => {
    event.stopPropagation();
    const input = document.createElement('input');
    input.type = 'file';
    input.onchange = async () => {
      const files = Array.from(input.files);
      const file = files[0];
      setIsUploading(true);
      const usage = await materialsApi.checkTexture(file.name);
      // Not used
      if (usage && usage[0]?.result === false) {
        const typeKey = Object.keys(Fieldset.Textures).find((key) => Fieldset.Textures[key].key === name);
        // Texture with same name already exists
        if (usage[0].id) {
          const result = await materialsApi.updateTexture(usage[0].id, file);
          onChange(result);
        // New texture
        } else {
          const result = await materialsApi.createTexture({
            type: Fieldset.Textures[typeKey].type,
            name: file.name,
          }, file);
          onChange(result);
        }
        dispatch(loadTexturesList());
        setIsUploading(false);
        return;
      }
      setImage({
        file,
        id: usage[0].id,
      });
      setIsUploading(false);
      // Used
      handleWarningUploadControlClick();
      const titles = usage.map((message) => {
        if (message.reason === 'used') {
          const materials = message.usedIn.map((p) => p.name).join(', ');
          return `Upload cannot be performed because given texture is used in the following materials: [${materials}]. \n User action required`;
        }
      });
      setWarningUploadTitle(titles.join('\n'));
    };
    input.click();
  }, [dispatch, name, handleWarningUploadControlClick]);

  useEffect(() => {
    if (apiError && apiError.type === 'DELETE' && apiError.id === name) {
      handleWarningDeleteControlClick();
      const titles = apiError.data.message.map((message) => {
        if (message.reason === 'used') {
          const materials = message.usedIn.map((p) => p.name).join(', ');
          return `Action cannot be performed because given texture is used in the following materials: [${materials}]`;
        }
      });
      setWarningDeleteTitle(titles.join('\n'));
    }
    if (warningDeleteOpen && !apiError) {
      handleWarningDeleteClose();
    }
  }, [
    warningDeleteOpen,
    handleWarningDeleteControlClick,
    apiError,
  ]);

  const handleDownload = () => {
    axios.get(value.url, {
      headers: {
        'Content-Type': 'application/octet-stream',
      },
      responseType: 'blob',
    })
      .then((response) => {
        const a = document.createElement('a');
        const url = window.URL.createObjectURL(response.data);
        a.href = url;
        a.download = `${value.name}`;
        a.click();
      })
      .catch((err) => {
        console.log('error', err);
      });
  };

  const handleUpdateTexture = async () => {
    handleWarningUploadClose();
    setIsUploading(true);
    const result = await materialsApi.updateTexture(image.id, image.file);
    onChange(result);
    dispatch(loadTexturesList());
    setIsUploading(false);
    setImage(null);
  };

  const handleAddTexture = async (newName) => {
    handleWarningUploadClose();
    setIsUploading(true);
    const typeKey = Object.keys(Fieldset.Textures).find((key) => Fieldset.Textures[key].key === name);
    const result = await materialsApi.createTexture({
      type: Fieldset.Textures[typeKey].type,
      name: newName,
    }, image.file);
    onChange(result);
    dispatch(loadTexturesList());
    setIsUploading(false);
    setImage(null);
  };

  return (
    <>
      <Autocomplete
        freeSolo
        options={ options }
        getOptionLabel={ getOptionLabel }
        getOptionSelected={ getOptionSelected }
        onChange={ handleChange }
        onBlur={ (e) => handleChange(e, e.target.value) }
        ref={ ref }
        value={ value || defaultValue }
        filterOptions={ (options, state) => (state.inputValue.length > 0
          ? options.filter(
            (item) => String(item.name)
              .toLowerCase()
              .includes(state.inputValue.toLowerCase()),
          ).slice(0, 10)
          : options.slice(0, 10)) }
        disabled={ isUploading }
        renderInput={ (params) => (
          <TextField
            { ...params }
            classes={ {
              root: classes.textFieldRoot,
            } }
            label={ label }
            variant="outlined"
            InputProps={ {
              ...params.InputProps,
              endAdornment: (
                <>
                  {
                      value.url && (
                      <IconButton size="small" disabled={ isUploading } onClick={ handleDownload } className={ classes.downloadButton }>
                        <DownloadIcon />
                      </IconButton>
                      )
                  }
                  {
                    !isUploading && (
                    <IconButton size="small" onClick={ handleUpload }>
                      <UploadIcon />
                    </IconButton>
                    )
                  }
                  {
                    isUploading && (<CircularProgress className="loader" size="medium" />)
                  }
                  {params.InputProps.endAdornment}
                </>
              ),
            } }
          />
        ) }
        renderOption={ (props, { selected }) => (
          <div className={ classNames({ selected }, classes.option) }>
            <img alt={ props.name } src={ `${props.url}?size=64` } />
            { props.name }
            <IconButton size="small" onClick={ handleDelete.bind(null, props) }>
              <DeleteIcon />
            </IconButton>
          </div>
        ) }
      />
      <DialogsContainer>
        <WarningDialog
          open={ warningDeleteOpen }
          title={ warningDeleteTitle }
          onClose={ () => {
            dispatch(deleteMaterialError());
          } }
        />
        <WarningUploadDialog
          open={ warningUploadOpen }
          title={ warningUploadTitle }
          onUpdate={ handleUpdateTexture }
          onAdd={ handleAddTexture }
          value={ image }
          options={ options }
          onClose={ () => {
            handleWarningUploadClose();
            setImage(null);
          } }
        />
      </DialogsContainer>
    </>
  );
});

export default memo(TextureTextField);
