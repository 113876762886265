import { forwardRef, useCallback, useMemo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import PreviewPlaceholderIcon from '@resources/icons/PreviewPlaceholderIcon';
import { actions as appActions } from '@store/app';
import { useDispatch } from 'react-redux';
import SquareWrapper from '@shared/components/page-parts/SquareWrapper';
import RenewImageIcon from '@resources/icons/RenewImageIcon';
import Typography from '@shared/components/typography';
import DownloadIcon from '@shared/icons/DownloadIcon';
import axios from 'axios';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    border: `.1rem dashed ${theme.palette.common.divider}`,
  },
  label: {
    fontSize: '14px',
    fontWeight: '500',
    lineHeight: '21px',
    letterSpacing: '0.01em',
    marginBottom: '16px',
  },
  boxWrapper: {
    width: '100%',
    height: '100%',
    position: 'relative',
    backgroundColor: theme.palette.common.white,
    borderRadius: '.2rem',
  },
  visibleContentWrapper: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  withImage: {
    backgroundColor: '#FCFDFF',
  },
  withNoImage: {
    cursor: 'pointer',
  },
  image: {
    width: '100%',
    height: '100%',
    objectFit: 'contain',
  },
  placeholderIcon: {
    color: '#BBC4D0',
    margin: '32.75px auto 8px',
    width: '146.25px',
    height: '163.26px',
  },
  textHighlighted: {
    fontWeight: '500',
    color: '#00C98F',
  },
  hintBig: {
    fontSize: '14px',
    fontWeight: '600',
    lineHeight: '21px',
    letterSpacing: '0.01em',
    color: '#192A3E',
  },
  hintMedium: {
    fontSize: '13px',
    fontWeight: '500',
    lineHeight: '19.5px',
    letterSpacing: '0.01em',
    color: '#334D6E',
  },
  textPale: {
    color: '#879AB5',
  },
  renewWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(2, 0),
    borderTop: `1px solid ${theme.palette.common.divider}`,
    backgroundColor: '#E2EAF3',
    cursor: 'pointer',
  },
  renewIcon: {
    marginRight: theme.spacing(2),
  },
  renewTitle: {
    fontWeight: 600,
  },
  downloadButton: {
    position: 'absolute',
    top: theme.spacing(1),
    left: theme.spacing(1),
    padding: theme.spacing(0.5),
    display: 'flex',
    backgroundColor: '#E2EAF3',
    border: '1px solid #767676',
    borderRadius: '2px',
    width: '36px',
    height: '36px',
    alignItems: 'center',
    justifyContent: 'center',
    lineHeight: '36px',
    cursor: 'pointer',
  },
}));

const ImagePreviewField = forwardRef(({ name, value, fileName, onChange, fileSizeLimit = 8388608 }, ref) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const inputId = useMemo(() => `${name}_id`, [name]);

  const defaultErrorHandler = useCallback((error) => {
    console.error(error);
    dispatch(appActions.addMessage({ message: 'Unexpected error during upload file.', type: 'error' }));
  }, [dispatch]);

  const fileSizeErrorHandler = useCallback(() => {
    dispatch(appActions.addMessage({ message: 'The file is too big.', type: 'error' }));
  }, [dispatch]);

  const nonImageErrorHandler = useCallback(() => {
    dispatch(appActions.addMessage({ message: 'The file is not an image.', type: 'error' }));
  }, [dispatch]);

  const getImage = useCallback((file, successHandler = () => {}, errorHandler = defaultErrorHandler) => {
    try {
      const reader = new FileReader();

      reader.onloadstart = (event) => {
        if (event.total > fileSizeLimit) {
          reader.abort();
          fileSizeErrorHandler();
        }
      };
      reader.onload = async () => {
        if (reader.result.startsWith('data:image')) {
          successHandler(reader.result);
        } else {
          nonImageErrorHandler();
        }
      };
      reader.onerror = (error) => {
        errorHandler(error);
      };

      reader.readAsDataURL(file);
    } catch (error) {
      errorHandler(error);
    }
  }, [defaultErrorHandler, fileSizeErrorHandler, nonImageErrorHandler, fileSizeLimit]);

  const handleDragOver = useCallback((event) => {
    event.preventDefault();
  }, []);

  const handleDrop = useCallback((event) => {
    event.preventDefault();

    const {
      dataTransfer: { files },
    } = event;

    if (files.length) {
      getImage(files[0], onChange);
    }
  }, [getImage, onChange]);

  const handleChange = useCallback((event) => {
    getImage(event.target.files[0], onChange);
  }, [getImage, onChange]);

  const handleDownload = () => {
    axios.get(value, {
      headers: {
        'Content-Type': 'application/octet-stream',
      },
      responseType: 'blob',
    })
      .then((response) => {
        const a = document.createElement('a');
        const url = window.URL.createObjectURL(response.data);
        a.href = url;
        a.download = `${fileName?.toLowerCase() || 'product'}.png`;
        a.click();
      })
      .catch((err) => {
        console.log('error', err);
      });
  };

  return (
    <>
      <div className={ classes.label }>
        Image Preview
      </div>

      <div className={ classes.root }>
        <SquareWrapper>
          <div
            className={ classes.boxWrapper }
            onDrop={ handleDrop }
            onDragOver={ handleDragOver }
          >
            <label
              htmlFor={ value ? undefined : inputId }
              className={ classNames(
                classes.visibleContentWrapper,
                value ? classes.withImage : classes.withNoImage,
              ) }
            >
              {!value ? (
                <>
                  <PreviewPlaceholderIcon className={ classes.placeholderIcon } size="epic" />

                  <div className={ classes.hintBig }>
                    Drop your image here, or <span className={ classes.textHighlighted }>browse</span>
                  </div>
                  <div className={ classes.hintMedium }>
                    512px X 512px recommended
                  </div>
                  <div className={ classes.hintMedium }>
                    Image should be JPG/PNG format
                  </div>
                  <div className={ classNames(classes.hintMedium, classes.textPale) }>
                    Large images will be automatically scaled.
                  </div>
                </>
              ) : (
                <>
                  <img alt="Thumbnail" src={ value } className={ classes.image } />
                  <div
                    className={ classes.downloadButton }
                    type="button"
                    onClick={ handleDownload }
                  >
                    <DownloadIcon />
                  </div>
                </>
              )}
            </label>

            <input
              ref={ ref }
              type="file"
              id={ inputId }
              name={ name }
              hidden
              onChange={ handleChange }
            />
          </div>
        </SquareWrapper>

        {value ? (
          <label
            htmlFor={ inputId }
            className={ classes.renewWrapper }
          >
            <RenewImageIcon size="large" className={ classes.renewIcon } />
            <div>
              <Typography variant="body1" color="textPrimary" className={ classes.renewTitle }>
                Renew your image
              </Typography>
            </div>
          </label>
        ) : null}
      </div>
    </>
  );
});

export default ImagePreviewField;
