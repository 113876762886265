import {
  CLEAN_UP_SCENE,
  LOAD_SCENES_LIST,
  RENDER_SCENES_LIST,
  RENDER_SCENE,
  SORTED_ID,
  CONSTRAIN_PROPORTIONS,
  ROTATION,
  SCALE,
  SET_SCENES_COUNTS,
  RENDER_ENVIRONMENTS_LIST,
  DELETE_ENVIRONMENT_ERROR,
  RESET_SCENES_API_STATE,
  PUT_SCENE_ERROR,
} from './actions';
import { SortId } from '@app/sort/sortValues';
import { defaultScene } from '@shared/constants';

export const Status = {
  Idle: 'idle',
  Loading: 'loading',
};

const initialState = {
  scenes: {
    items: [],
    total: 0,
  },
  counts: [],
  scene: {
    ...defaultScene,
  },
  environments: {
    items: [],
    total: 0,
  },
  sortedId: SortId.AtoZ,
  constrProportions: false,
  rotation: {},
  scale: {},
  status: Status.Idle,
  error: null,
};

export default function scenesReducer(state = initialState, action) {
  switch (action.type) {
    case CLEAN_UP_SCENE:
      return {
        ...state,
        scene: {
          ...initialState.scene,
        },
      };
    case LOAD_SCENES_LIST:
      return {
        ...state,
        status: Status.Loading,
      };
    case RENDER_SCENES_LIST:
      return {
        ...state,
        status: Status.Idle,
        scenes: action.scenes,
      };
    case RENDER_ENVIRONMENTS_LIST:
      return {
        ...state,
        environments: action.environments,
      };
    case RENDER_SCENE:
      return {
        ...state,
        scene: action.scene,
      };
    case SORTED_ID:
      return {
        ...state,
        sortedId: action.value,
      };
    case CONSTRAIN_PROPORTIONS:
      return {
        ...state,
        constrProportions: !state.constrProportions,
      };
    case ROTATION:
      return {
        ...state,
        rotation: action.value,
      };
    case SCALE:
      return {
        ...state,
        scale: action.value,
      };
    case SET_SCENES_COUNTS:
      return {
        ...state,
        counts: action.payload,
      };
    case DELETE_ENVIRONMENT_ERROR:
      if (action.id && action.error) {
        return {
          ...state,
          error: {
            id: action.id,
            data: action.error,
            type: 'DELETE',
          },
        };
      }
      return {
        ...state,
        error: null,
      };
    case PUT_SCENE_ERROR:
      if (action.error) {
        return {
          ...state,
          error: {
            data: action.error,
            type: 'PUT',
            scene: action.scene,
          },
        };
      }
      return {
        ...state,
        error: null,
      };
    case RESET_SCENES_API_STATE:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
}
