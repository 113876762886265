import { SortId } from './sortValues';

const sortByName = (items, value, sortField = 'name') => [...items].sort((a, b) => {
  const nameA = a[sortField]?.toUpperCase();
  const nameB = b[sortField]?.toUpperCase();

  const numberA = parseInt(nameA, 10);
  const numberB = parseInt(nameB, 10);

  if (value === SortId.AtoZ) {
    if (!Number.isNaN(numberA) && !Number.isNaN(numberB)) {
      return numberA - numberB;
    }
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
  }
  if (value === SortId.ZtoA) {
    if (!Number.isNaN(numberA) && !Number.isNaN(numberB)) {
      return numberB - numberA;
    }
    if (nameA < nameB) {
      return 1;
    }
    if (nameA > nameB) {
      return -1;
    }
  }

  return 0;
});

export default sortByName;
