import { OrientationType } from '../LightDetails/constants';
import { BaseFieldset, SpotLightFieldset } from '../LightDetails/fieldset';

const getElectricLightInitialData = (sceneBox = {}, data = {}) => {
  const top = sceneBox.ceiling[0].y;
  const bottom = sceneBox.floor[0].y;
  const left = 0;
  const right = 0;
  const back = 0;
  const front = 0;
  return {
    [SpotLightFieldset.Distance]: top - bottom,
    [BaseFieldset.Orientation]: OrientationType.Top,
    [SpotLightFieldset.Position]: [(left + right) / 2, top, (back + front) / 2],
    ...(data[SpotLightFieldset.Target]
      ? {}
      : { [SpotLightFieldset.Target]: [(left + right) / 2, bottom, (back + front) / 2] }
    ),
    [SpotLightFieldset.SelectSpotCenter]: false,
  };
};

export default getElectricLightInitialData;
