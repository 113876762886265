// eslint-disable-next-line import/prefer-default-export
export const Fieldset = {
  Name: 'name',
  Width: 'width',
  Height: 'height',
  Depth: 'depth',
  SoftShadows: 'softShadows',
  Samples: 'samples',
  Size: 'size',
  Focus: 'focus',
  ShadowOpacity: 'shadowOpacity',
  Environment: 'env',
  EnvironmentIntensity: 'envIntensity',
  EnvironmentRotation: 'envRotation',
  Attributes: 'attributes',
  TranslatedFields: 'translatedFields',
  GlobalLightColor: 'globalLightColor',
  GlobalLightIntensity: 'globalLightIntensity',
};
