import {
  Dialog,
  DialogActions,
  DialogTitle,
} from '@shared/components/dialog';
import Button from '@shared/components/button';

const AlertDialog = ({
  open,
  title,
  ariaAction,
  onAgree,
  onDisagree,
}) => (
  <Dialog
    open={ open }
    maxWidth="xs"
    onClose={ onDisagree }
    aria-labelledby={ `${ariaAction}-alert-dialog-title` }
  >
    <DialogTitle id={ `${ariaAction}-alert-dialog-title` }>
      {title}
    </DialogTitle>
    <DialogActions>
      <Button onClick={ onDisagree }>
        No
      </Button>
      <Button onClick={ onAgree }>
        Yes
      </Button>
    </DialogActions>
  </Dialog>
);

export default AlertDialog;
