import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'block',
    width: '100%',
  },
  image: {
    width: 'inherit',
    aspectRatio: '1920 / 1080',
    objectFit: 'cover',
    border: `1px solid ${theme.palette.common.divider}`,
    borderRadius: theme.shape.borderRadius,
    cursor: 'pointer',
  },
}));

const Preview = ({
  src,
  alt,
  onClick,
}) => {
  const classes = useStyles();

  return (
    <picture
      className={ classes.root }
      onClick={ onClick }
    >
      <source srcSet={ src } />
      <img
        className={ classes.image }
        src="../../assets/images/no_image.png"
        alt={ alt }
      />
    </picture>
  );
};

export default Preview;
