import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    display: 'flex',
    justifyContent: 'center',
  },
}));

const CardContent = ({ children }) => {
  const classes = useStyles();

  return <div className={ classes.root }>{children}</div>;
};

export default CardContent;
