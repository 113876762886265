/* eslint-disable import/prefer-default-export */
export const ModelFormats = 'STL, OBJ, FBX, COLLADA, 3DS, Glb, glTF';

export const DefaultLightPresetsData = [{
  uid: '303b84cc-d6e3-47f7-a97b-112e77fb3c96',
  name: 'Warm',
  intensity: 1,
}, {
  uid: 'd5c5fe47-21d9-499c-a836-757c85a055d1',
  name: 'Neutral',
  intensity: 1,
}, {
  uid: 'c711a73c-f946-4f87-b57c-522ac99ea5de',
  name: 'Cold',
  intensity: 1,
}];
