import { useSelector } from 'react-redux';
import { Navigate, useLocation } from 'react-router-dom';

const withAuth = (Component) => (props) => {
  const user = useSelector((state) => state.app.user);
  const location = useLocation();

  if (!user) {
    return <Navigate to="/login" state={ { from: location } } replace />;
  }

  return <Component { ...props } />;
};

export default withAuth;
