import Grid from '@material-ui/core/Grid';
import Interior from '@pages/interiors/list/grid/Interior';
import CardsList from '@shared/components/cards-list/CardsList';
import CardItem from '@shared/components/cards-list/CardItem';
import withSelectable from '@shared/components/cards-list/withSelectable';
import NoElementsNote from '@shared/components/cards-list/NoElementsNote';

const SelectableInterior = withSelectable(Interior);

const InteriorsList = ({
  interiors,
  selectedInterior,
  onItemClick,
}) => (
  <Grid container spacing={ 2 }>
    <Grid item xs={ 12 }>
      {interiors.length > 0 ? (
        <CardsList>
          {interiors.map((interior) => (
            <CardItem key={ interior.id } xl={ 3 } lg={ 4 } md={ 6 }>
              <SelectableInterior
                item={ interior }
                selected={ selectedInterior === interior }
                onClick={ onItemClick }
                defaultClickable
              />
            </CardItem>
          ))}
        </CardsList>
      ) : (
        <NoElementsNote>Nothing found!</NoElementsNote>
      )}
    </Grid>
  </Grid>
);

export default InteriorsList;
