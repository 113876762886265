import { translatedFieldsToArray } from '@shared/utils/translations';

const ENDPOINT = '/material-groups';

class MaterialGroupsApi {
  constructor(http) {
    this.http = http;
  }

  getGroups = () => this.http.get(ENDPOINT)

  createGroup = (group) => {
    const translations = translatedFieldsToArray(group.translatedFields);
    delete group.translatedFields;
    return this.http.post(ENDPOINT, {
      ...group,
      translations,
    });
  }

  updateGroup = (id, group) => {
    const translations = translatedFieldsToArray(group.translatedFields);
    delete group.translatedFields;
    return this.http.put(`${ENDPOINT}/${id}`, {
      id,
      ...group,
      translations,
    });
  }

  deleteGroup = (id) => this.http.delete(`${ENDPOINT}/${id}`)

  getGroup = (id) => this.http.get(`${ENDPOINT}/${id}`)
}

export default MaterialGroupsApi;
