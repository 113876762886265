export const CLEAN_UP_SCENE = 'CLEAN_UP_SCENE';
export const LOAD_SCENES_LIST = 'LOAD_SCENES_LIST';
export const RENDER_SCENES_LIST = 'RENDER_SCENES_LIST';

export const LOAD_ENVIRONMENTS_LIST = 'LOAD_ENVIRONMENTS_LIST';
export const RENDER_ENVIRONMENTS_LIST = 'RENDER_ENVIRONMENTS_LIST';
export const CREATE_SCENE = 'CREATE_SCENE';
export const UPDATE_SCENE = 'UPDATE_SCENE';
export const PUT_SCENE_ERROR = 'PUT_SCENE_ERROR';
export const DELETE_SCENE = 'DELETE_SCENE';
export const DELETE_ENVIRONMENT = 'DELETE_ENVIRONMENT';
export const DELETE_ENVIRONMENT_ERROR = 'DELETE_ENVIRONMENT_ERROR';
export const LOAD_SCENE = 'LOAD_SCENE';
export const RENDER_SCENE = 'RENDER_SCENE';
export const SORTED_ID = 'SORTED_ID';
export const CONSTRAIN_PROPORTIONS = 'CONSTRAIN_PROPORTIONS';
export const ROTATION = 'ROTATION';
export const SCALE = 'SCALE';
export const HIDE_SCENE = 'HIDE_SCENE';
export const SET_SCENES_COUNTS = 'SET_SCENES_COUNTS';
export const RESET_SCENES_API_STATE = 'RESET_SCENES_API_STATE';

export function cleanUpScene() {
  return {
    type: CLEAN_UP_SCENE,
  };
}

export function rotationModel(value) {
  return {
    type: ROTATION,
    value,
  };
}

export function scaleModel(value) {
  return {
    type: SCALE,
    value,
  };
}

export function constrainProportions() {
  return {
    type: CONSTRAIN_PROPORTIONS,
  };
}

export function loadScenesList() {
  return {
    type: LOAD_SCENES_LIST,
  };
}

export function renderScenesList(scenes) {
  return {
    type: RENDER_SCENES_LIST,
    scenes,
  };
}

export function loadEnvironmentsList() {
  return {
    type: LOAD_ENVIRONMENTS_LIST,
  };
}

export function renderEnvironmentsList(environments) {
  return {
    type: RENDER_ENVIRONMENTS_LIST,
    environments,
  };
}

export function createScene(scene) {
  return {
    type: CREATE_SCENE,
    scene,
  };
}

export function renderScene(scene) {
  return {
    type: RENDER_SCENE,
    scene,
  };
}

export function loadScene(id) {
  return {
    type: LOAD_SCENE,
    id,
  };
}

export function updateScene(scene) {
  return {
    type: UPDATE_SCENE,
    scene,
  };
}

export function putSceneError(scene, error) {
  return {
    type: PUT_SCENE_ERROR,
    scene,
    error,
  };
}

export function deleteScene(id) {
  return {
    type: DELETE_SCENE,
    id,
  };
}

export function deleteEnvironment(id) {
  return {
    type: DELETE_ENVIRONMENT,
    id,
  };
}

export function deleteEnvironmentError(id, error) {
  return {
    type: DELETE_ENVIRONMENT_ERROR,
    id,
    error,
  };
}

export function sortedId(value) {
  return {
    type: SORTED_ID,
    value,
  };
}

export const hideScene = (id) => ({
  type: HIDE_SCENE,
  payload: id,
});

export const setScenesCounts = (counts) => ({
  type: SET_SCENES_COUNTS,
  payload: counts,
});

export const resetScenesApiState = () => ({
  type: RESET_SCENES_API_STATE,
});
