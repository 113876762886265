import calcPointLightDistance from '@pages/scenes/edit/utils/calcPointLightDistance';
import KelvinColor from '@shared/utils/KelvinColor';
import getElectricLightInitialData from '../utils/getElectricLightInitialData';
import getNaturalLightInitialPosition from '../utils/getNaturalLightInitialPosition';
import { DirectionalLightPreset, PointLightPreset, SpotLightPreset,
  DEFAULT_SHADOW_BIAS } from './constants';
import {
  BaseFieldset, DirectionalLightFieldset, PointLightFieldset, SpotLightFieldset,
} from './fieldset';

export const getBasic1SpotLightPreset = (sceneBox) => ({
  [BaseFieldset.Preset]: SpotLightPreset.Basic1,
  [SpotLightFieldset.Color]: new KelvinColor('ffffff'),
  [SpotLightFieldset.Intensity]: 22,
  [SpotLightFieldset.Angle]: 0.166,
  [SpotLightFieldset.Focus]: 1,
  [SpotLightFieldset.Penumbra]: 0.1,
  [SpotLightFieldset.Decay]: 2,
  [SpotLightFieldset.ShadowBias]: 0,
  ...getElectricLightInitialData(sceneBox),
});

export const getSunDirectionalLightPreset = (sceneBox) => ({
  [BaseFieldset.Preset]: DirectionalLightPreset.Sun,
  [DirectionalLightFieldset.Color]: new KelvinColor('ffffff'),
  [DirectionalLightFieldset.Intensity]: 8,
  [DirectionalLightFieldset.ShadowBias]: 0,
  ...getNaturalLightInitialPosition(sceneBox),
});

export const getBasic1PointLightPreset = (sceneBox) => ({
  [BaseFieldset.Preset]: PointLightPreset.Basic1,
  [PointLightFieldset.Color]: new KelvinColor('ffffff'),
  [PointLightFieldset.Intensity]: 30,
  [PointLightFieldset.Decay]: 1,
  [PointLightFieldset.ShadowBias]: 0,
  ...getElectricLightInitialData(sceneBox),
  [PointLightFieldset.Distance]: calcPointLightDistance(sceneBox),
});

export const getBasic2SpotLightPreset = () => ({
  [BaseFieldset.Preset]: SpotLightPreset.Basic2,
  [SpotLightFieldset.Color]: new KelvinColor('ffffff'),
  [SpotLightFieldset.Intensity]: 50,
  [SpotLightFieldset.Distance]: 6,
  [SpotLightFieldset.Angle]: 0.35,
  [SpotLightFieldset.Focus]: 1,
  [SpotLightFieldset.Penumbra]: 0.4,
  [SpotLightFieldset.Decay]: 1,
  [SpotLightFieldset.ShadowBias]: 0,
});

export const getMoonDirectionalLightPreset = () => ({
  [BaseFieldset.Preset]: DirectionalLightPreset.Moon,
  [DirectionalLightFieldset.Color]: new KelvinColor('ffffff'),
  [DirectionalLightFieldset.Intensity]: 10,
  [DirectionalLightFieldset.ShadowBias]: 0,
});

export const getBasic2PointLightPreset = () => ({
  [BaseFieldset.Preset]: PointLightPreset.Basic2,
  [PointLightFieldset.Color]: new KelvinColor('ffffff'),
  [PointLightFieldset.Intensity]: 20,
  [PointLightFieldset.Distance]: 6,
  [PointLightFieldset.Decay]: 1,
  [PointLightFieldset.ShadowBias]: 0,
});
