import { useEffect, useRef } from 'react';
import { gsap } from 'gsap';
import { Draggable } from 'gsap/Draggable';
import RotateButton from '@resources/icons/RotateButton';

gsap.registerPlugin(Draggable);

const Rotation = ({
  name,
  value,
  disabled,
  icon,
  onChange,
}) => {
  const dragTarget = useRef(null);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (!disabled) {
      const [draggable] = Draggable.create(dragTarget.current, {
        type: 'rotation',
        onDragEnd() {
          onChange({
            name,
            // eslint-disable-next-line react/no-this-in-sfc
            value: this.rotation * 0.0174533,
          });
        },
      });

      return () => {
        draggable.kill();
      };
    }
  }, [name, onChange, disabled]);

  return (
    <>
      <div className="scenes-details__icon">
        {icon}
      </div>
      <div
        className="scenes-details__button"
        disabled={ disabled }
        ref={ dragTarget }
        style={ { transform: `translate3d(0px, 0px, 0px) rotate(${(value / 0.0174533).toFixed(2)}deg)` } }
      >
        <RotateButton />
      </div>
    </>
  );
};

export default Rotation;
