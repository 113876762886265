import { makeStyles } from '@material-ui/core';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(5, 4.5, 2),
  },
  title: {
    fontSize: '1.8rem',
    lineHeight: 1.6,
    fontWeight: 500,
  },
}));

const DialogTitle = ({ children, id, align = 'center' }) => {
  const classes = useStyles();
  return (
    <MuiDialogTitle id={ id } className={ classes.root } disableTypography>
      <Typography variant="h4" component="h2" align={ align } className={ classes.title }>
        {children}
      </Typography>
    </MuiDialogTitle>
  );
};

export default DialogTitle;
