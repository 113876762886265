import { translatedFieldsToArray } from '@shared/utils/translations';

const ENDPOINT = '/products';

class ProductsApi {
  constructor(http) {
    this.http = http;
  }

  getProducts = async () => {
    const data = await this.http.get(`${ENDPOINT}/parent?populate=model`);

    return data;
  }

  getProductsCounts = () => this.http.get(`${ENDPOINT}/counts`)

  createProduct = async (product) => {
    const { thumbnail = '' } = product.model.data;

    const translations = translatedFieldsToArray(product.translatedFields);

    const createProduct = {
      externalId: product.externalId?.trim(),
      name: product.name,
      lang: product.lang,
      price: product.price,
      currency: product.currency,
      description: product.description,
      model: product.model.id,
      materials: product.materials.map((m) => {
        return { ...m,
          material: m.material ? m.material.id : undefined,
          mesh: undefined,
          group: m.group ? m.group.id : undefined,
        };
      }),
      attributes: product.attributes,
      translations,
      thumbnail,
      movementType: product.movementType,
    };

    const data = await this.http.post(ENDPOINT, createProduct, { onError: (error) => {
      throw error;
    } });

    return data;
  };

  editProduct = async (product) => {
    const { thumbnail = '' } = product.model.data;

    const translations = translatedFieldsToArray(product.translatedFields);

    const updateProduct = {
      id: product.id,
      externalId: product.externalId?.trim(),
      name: product.name,
      lang: product.lang,
      price: product.price,
      currency: product.currency,
      description: product.description,
      model: product.model.id,
      materials: product.materials.map((m) => {
        return { ...m,
          material: m.material ? m.material.id : undefined,
          mesh: undefined,
          group: m.group ? m.group.id : undefined,
        };
      }),
      attributes: product.attributes,
      translations,
      thumbnail,
      movementType: product.movementType,
    };

    const data = await this.http.put(`${ENDPOINT}/${product.id}`, updateProduct, { onError: (error) => {
      throw error;
    } });

    return data;
  }

  deleteProduct = (id) => this.http.delete(`${ENDPOINT}/${id}`, { onError: (error) => {
    throw error;
  } });

  createVariant = async (variant) => {
    const { thumbnail = '' } = variant.model.data;
    const parentProductId = typeof variant.parentProduct === 'object' ? variant.parentProduct.id : variant.parentProduct;

    const translations = translatedFieldsToArray(variant.translatedFields);

    const data = await this.http.post(`${ENDPOINT}/${parentProductId}/variants`, {
      externalId: variant.externalId?.trim(),
      name: variant.name,
      lang: variant.lang,
      price: variant.price,
      currency: variant.currency,
      description: variant.description,
      model: variant.model.id,
      materials: variant.materials.map((m) => {
        return { ...m,
          material: m.material ? m.material.id : undefined,
          group: m.group ? m.group.id : undefined,
        };
      }),
      thumbnail,
      translations,
      attributes: variant.attributes,
      movementType: variant.movementType,
    }, { onError: (error) => {
      throw error;
    } });

    return data;
  };

  editVariant = async (variant) => {
    const { thumbnail = '' } = variant.model.data;
    const parentProductId = typeof variant.parentProduct === 'object' ? variant.parentProduct.id : variant.parentProduct;

    const translations = translatedFieldsToArray(variant.translatedFields);

    const data = await this.http.put(`${ENDPOINT}/${parentProductId}/variants/${variant.id}`, {
      id: variant.id,
      externalId: variant.externalId?.trim(),
      name: variant.name,
      lang: variant.lang,
      price: variant.price,
      currency: variant.currency,
      description: variant.description,
      model: variant.model.id,
      materials: variant.materials.map((m) => {
        return { ...m,
          material: m.material ? m.material.id : undefined,
          group: m.group ? m.group.id : undefined,
        };
      }),
      thumbnail,
      translations,
      attributes: variant.attributes,
      movementType: variant.movementType,
    }, { onError: (error) => {
      throw error;
    } });

    return data;
  };

  deleteVariant = (id) => this.http.delete(`${ENDPOINT}/${id}`, { onError: (error) => {
    throw error;
  } });

  markProductAsDefaultContent = (id) => this.http.put(`${ENDPOINT}/${id}/markAsDefaultContent`)

  unmarkProductAsDefaultContent = (id) => this.http.put(`${ENDPOINT}/${id}/unmarkAsDefaultContent`)

  hideProduct = (id) => this.http.put(`${ENDPOINT}/${id}/hide`)
}

export default ProductsApi;
