import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import { loadStudiosList, loadStudio } from '@store/studios/actions';
import { loadProductsList } from '@store/products/actions';
import { loadModelsList } from '@store/models/actions';
import { loadMaterialsList } from '@store/materials/actions';

import withAuth from '@shared/components/route/withAuth';

import Studio from '@pages/studio/studio';
import StudioList from '@pages/studio/list';

import { actions } from '@store/app';
import useActions from '@shared/hooks/useActions';

export const StudiosList = withAuth(() => {
  const dispatch = useDispatch();
  const { showMenu } = useActions(actions);

  useEffect(() => {
    showMenu();
    dispatch(loadStudiosList());
  }, [dispatch, showMenu]);

  return <StudioList />;
});

const StudioPage = withAuth(() => {
  const { id } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    if (id) {
      dispatch(loadStudio(id));
    }
    dispatch(loadProductsList());
    dispatch(loadModelsList());
    dispatch(loadMaterialsList());
  }, [dispatch, id]);

  return <Studio />;
});

export default StudioPage;
