import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
    gap: theme.spacing(3),
    '& button': {
      padding: theme.spacing(3, 0),
      fontSize: '1.4rem',
    },
  },
}));

const Actions = ({ children }) => {
  const classes = useStyles();

  return <div className={ classes.root }>{children}</div>;
};

export default Actions;
