import { useState, useEffect, useMemo, forwardRef } from 'react';
import { makeStyles } from '@material-ui/core';
import SliderContainer from '@shared/components/slider/SliderContainer';
import Slider from '@shared/components/slider/Slider';
import Range from '@shared/components/slider/Range';
import RangeTypography from '@shared/components/slider/RangeTypography';
import useDebouncedCallback from '@shared/hooks/useDebouncedCallback';
import SubTitle from '../SubTitle';
import toFixedNumber from '../utils/toFixedNumber';

const SLIDER_VALUE_CSS_VAR = '--range-slider-value';

const calcStep = (min, max) => toFixedNumber((max - min) / 100, 2);

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(1),
  },
  thumb: {
    position: 'relative',
    '&::before': {
      content: `var(${SLIDER_VALUE_CSS_VAR})`,
      position: 'absolute',
      left: '50%',
      top: theme.spacing(-2.5),
      transform: 'translateX(-50%)',
      fontSize: '1.3rem',
    },
  },
}));

const RangeSlider = ({
  label,
  name,
  value,
  max,
  min,
  step = calcStep(min, max),
  onChange,
  displayValues = true,
  displayThumbValue = false,
  isDisabled = false,
  debounce = 400,
}, ref) => {
  const [sliderValue, setSliderValue] = useState(value);

  const sliderClasses = useStyles();
  const variables = useMemo(() => ({ [SLIDER_VALUE_CSS_VAR]: `'${sliderValue}'` }), [sliderValue]);

  useEffect(() => {
    setSliderValue(value);
  }, [value]);

  const onDebouncedChange = useDebouncedCallback(({ name, value }) => {
    onChange({ value }, name);
  }, debounce);

  const handleChange = ({ name, value }) => {
    setSliderValue(value);
    onDebouncedChange({ name, value });
  };

  return (
    <SliderContainer style={ variables }>
      <SubTitle id={ `${name}-slider` }>
        {label}
      </SubTitle>
      {displayValues && (
        <Range
          min={ (
            <RangeTypography>
              {sliderValue}
            </RangeTypography>
          ) }
          max={ (
            <RangeTypography>
              {max}
            </RangeTypography>
          ) }
        />
      )}
      <Slider
        ref={ ref }
        name={ name }
        value={ +sliderValue }
        aria-labelledby={ `${name}-slider` }
        step={ step }
        min={ min }
        max={ max }
        onChange={ handleChange }
        classes={ {
          root: sliderClasses.root,
          thumb: displayThumbValue ? sliderClasses.thumb : '',
        } }
        isDisabled={ isDisabled }
      />
    </SliderContainer>
  );
};

export default forwardRef(RangeSlider);
