export const Fieldset = {
  Name: 'name',
  Interior: 'interior',
  Models: 'models',
  DetailsData: 'detailsData',
  Attributes: 'attributes',
};

export const DEFAULT_VALUES = {
  [Fieldset.Name]: '',
  [Fieldset.Attributes]: [],
};
