import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@shared/components/dialog/Dialog';
import DialogTitle from '@shared/components/dialog/DialogTitle';
import DialogContent from '@shared/components/dialog/DialogContent';
import DialogActions, { Justify } from '@shared/components/dialog/DialogActions';
import Button from '@shared/components/button';

const useStyles = makeStyles((theme) => ({
  dialogContent: {
    overflow: (props) => (props.isDrawer ? 'auto' : 'initial'),
  },
  button: {
    color: theme.palette.common.darkGrey,
    backgroundColor: theme.palette.common.white,
    border: `1px solid ${theme.palette.common.darkGrey}`,
    borderRadius: '0.4rem',
  },
}));

const FormDialog = ({
  open,
  Form,
  FormProps,
  title,
  content,
  isDrawer,
  drawerPaperProps = {},
  onCancel,
  onClose,
  maxWidth = 'md',
  titleAlign = 'left',
  saveButtonText = 'Save',
  isSubmitDisabled = false,
}) => {
  const classes = useStyles({ isDrawer });

  return (
    <Dialog
      open={ open }
      onClose={ onClose }
      maxWidth={ maxWidth }
      PaperComponent={ Form }
      PaperProps={ FormProps }
      isDrawer={ isDrawer }
      drawerPaperProps={ drawerPaperProps }
    >
      <DialogTitle align={ titleAlign }>{title}</DialogTitle>
      <DialogContent className={ classes.dialogContent }>{content}</DialogContent>
      <DialogActions justify={ isDrawer ? Justify.Right : Justify.Center }>
        <Button className={ classes.button } variant="outlined" onClick={ onCancel }>
          Cancel
        </Button>
        <Button type="submit" disabled={ isSubmitDisabled }>{ saveButtonText }</Button>
      </DialogActions>
    </Dialog>
  );
};

export default FormDialog;
