export const Fieldset = {
  Id: 'id',
  Name: 'name',
  Url: 'url',
  Width: 'width',
  Height: 'height',
  Image: 'image',
  Viewport: 'layout.viewport',
  FloorPoints: 'layout.floorPoints',
  RoomHeight: 'layout.height',
  ReferenceLength: 'layout.refLength',
  Type: 'layout.type',
  VP1Line: 'layout.vp1Line',
  VP2Line: 'layout.vp2Line',
  PrincipalPoint: 'layout.principalPoint',
  VanishingPoints: 'layout.vanishingPoints',
  ReferenceAxis: 'layout.referenceAxis',
  ReferencePoints: 'layout.referencePoints',
  RL: 'layout.rl',
  Points: 'layout.points',
  Attributes: 'attributes',
};

export const DEFAULT_VALUES = {
  [Fieldset.Name]: '',
  [Fieldset.Url]: '',
  [Fieldset.Width]: '',
  [Fieldset.Height]: '',
  [Fieldset.Image]: {
    blob: '',
  },
  layout: {
    viewport: null,
    floorPoints: null,
    height: 250,
    refLength: 100,
  },
  [Fieldset.Attributes]: [],
};


export const fillWithInteriorValues = (interior) => ({
  ...interior,
  [Fieldset.Image]: {
    blob: interior[Fieldset.Url] || DEFAULT_VALUES[Fieldset.Image].blob,
  },
});
