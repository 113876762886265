const RotateButton = () => (
  <svg
    width="38"
    height="38"
    viewBox="0 0 38 38"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      cx="19"
      cy="19"
      r="18"
      fill="white"
      stroke="#727A89"
      strokeWidth="2"
    />
    <path
      d="M18.5 19L33 29.5"
      stroke="#727A89"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default RotateButton;
