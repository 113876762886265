import { useCallback, memo } from 'react';
import { useSelector } from 'react-redux';
import Card from '@shared/components/cards-list/Card';
import CardImage from '@shared/components/cards-list/CardImage';
import CardContent from '@shared/components/cards-list/CardContent';
import CardTitle from '@shared/components/cards-list/CardTitle';
import Sizes from '@shared/components/cards-list/Sizes';
import CardLabel from '@shared/components/cards-list/CardLabel';
import { selectCurrentCompany } from '@store/app/selectors';

const Interior = ({
  className,
  item,
  controls,
  onClick,
  defaultClickable,
}) => {
  const company = useSelector(selectCurrentCompany);
  const handleClick = useCallback((evt) => {
    if (!(item.defaultContent && !company.default) || defaultClickable) {
      onClick(item, evt);
    }
  }, [onClick, item]);

  return (
    <Card className={ className } onClick={ handleClick } transparent={ item.hidden }>
      {controls}
      <CardImage src={ item.url } alt="interior" />
      <CardContent>
        <CardTitle>
          {/* TODO: Should be done with CSS */}
          {item.name.length < 20 ? item.name : `${item.name.substr(0, 20)}...`}
        </CardTitle>{' '}
        {item.height && item.width ? <Sizes>({parseInt(item.width)}x{parseInt(item.height)})</Sizes> : null}
        {item.defaultContent && <CardLabel>{company.default ? 'Published' : 'Default'}</CardLabel>}
      </CardContent>
    </Card>
  );
};

export default memo(Interior);
