import {
  RENDER_MATERIALS_LIST,
  RENDER_TEXTURES_LIST,
  RENDER_MATERIALS_COUNTS,
  RESET_MATERIALS,
  RENDER_MATERIAL,
  SORTED_ID,
  DELETE_MATERIAL_ERROR,
  DELETE_TEXTURE_ERROR,
  CREATE_MATERIAL,
  UPDATE_MATERIAL,
  CREATE_MATERIAL_ERROR,
  UPDATE_MATERIAL_ERROR,
  UPDATE_MATERIAL_SUCCESS,
  CREATE_MATERIAL_SUCCESS,
  RESET_MATERIAL_API_STATE,
} from './actions';
import { SortId } from '@app/sort/sortValues';

import { translationsToFields } from '@shared/utils/translations';

const defaultMaterials = {
  items: [],
  total: 0,
};

const initialState = {
  materials: defaultMaterials,
  textures: defaultMaterials,
  counts: [],
  sorting: {
    id: SortId.AtoZ,
    label: 'A-Z',
  },
  material: {
    name: '',
    url: '',
  },
  sortedId: SortId.AtoZ,
  state: 'initial',
  error: null,
  translatedFields: [],
};

export default function materialsReducer(state = initialState, action) {
  switch (action.type) {
    case RENDER_MATERIALS_LIST: {
      const materialItems = action.materials.items
        .map((material) => {
          material.translatedFields = translationsToFields(material.translations);
          delete material.translations;
          return material;
        });
      return {
        ...state,
        materials: { ...action.materials, items: materialItems },
      };
    }
    case RENDER_TEXTURES_LIST:
      return {
        ...state,
        textures: action.textures,
      };
    case RENDER_MATERIALS_COUNTS:
      return {
        ...state,
        counts: action.payload,
      };
    case RESET_MATERIALS:
      return {
        ...state,
        materials: defaultMaterials,
      };
    case RENDER_MATERIAL:
      return {
        ...state,
        material: action.material,
      };
    case SORTED_ID:
      return {
        ...state,
        sortedId: action.value,
      };
    case CREATE_MATERIAL:
    case UPDATE_MATERIAL:
      return {
        ...state,
        state: 'processing',
      };
    case DELETE_MATERIAL_ERROR:
      if (action.id && action.error) {
        return {
          ...state,
          error: {
            id: action.id,
            data: action.error,
            type: 'DELETE',
          },
          state: 'error',
        };
      }
      return {
        ...state,
        error: null,
        state: 'success',
      };
    case CREATE_MATERIAL_ERROR:
      if (action.error) {
        return {
          ...state,
          error: {
            data: action.error,
            type: 'CREATE',
          },
          state: 'error',
        };
      }
      return {
        ...state,
        error: null,
        state: 'success',
      };
    case UPDATE_MATERIAL_ERROR:
      if (action.error) {
        return {
          ...state,
          error: {
            data: action.error,
            type: 'UPDATE',
          },
          state: 'error',
        };
      }
      return {
        ...state,
        error: null,
        state: 'success',
      };
    case UPDATE_MATERIAL_SUCCESS:
    case CREATE_MATERIAL_SUCCESS:
      return {
        ...state,
        state: 'success',
      };
    case DELETE_TEXTURE_ERROR:
      if (action.id && action.error) {
        return {
          ...state,
          error: {
            id: action.id,
            data: action.error,
            type: 'DELETE',
          },
        };
      }
      return {
        ...state,
        error: null,
      };
    case RESET_MATERIAL_API_STATE:
      return {
        ...state,
        state: 'initial',
        error: null,
      };
    default:
      return state;
  }
}
