import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@shared/components/dialog/Dialog';
import DialogContent from '@shared/components/dialog/DialogContent';
import DialogActions, { Justify } from '@shared/components/dialog/DialogActions';
import Button from '@shared/components/button';
import Typography from '@material-ui/core/Typography';
import MuiDialogTitle from '@material-ui/core/DialogTitle';

const useStyles = makeStyles((theme) => ({
  dialogContent: {
    overflow: 'auto',
    padding: 0,
  },
  button: {
    color: theme.palette.common.darkGrey,
    backgroundColor: theme.palette.common.white,
    border: `1px solid ${theme.palette.common.darkGrey}`,
    borderRadius: '0.4rem',
  },
  productDrawerPaper: {
    minWidth: '1250px',
    maxWidth: '66%',
  },
  title: {
    padding: theme.spacing(1.25, 2),
  },
  titleTypography: {
    fontSize: '1.8rem',
    lineHeight: '27px',
    fontWeight: 500,
  },
}));

const ProductFormDialog = ({
  open,
  Form,
  FormProps,
  title,
  content,
  onCancel,
  onClose,
  saveButtonText = 'Save',
  cancelButtonText = 'Cancel',
  isSaveDisabled,
}) => {
  const classes = useStyles();

  return (
    <Dialog
      open={ open }
      onClose={ onClose }
      PaperComponent={ Form }
      PaperProps={ FormProps }
      drawerPaperProps={ { className: classes.productDrawerPaper } }
      isDrawer
    >
      <MuiDialogTitle className={ classes.title } disableTypography>
        <Typography variant="h4" component="h2" align="center" className={ classes.titleTypography }>
          {title}
        </Typography>
      </MuiDialogTitle>

      <DialogContent className={ classes.dialogContent }>{content}</DialogContent>
      <DialogActions justify={ Justify.Right }>
        <Button className={ classes.button } variant="outlined" onClick={ onCancel }>
          { cancelButtonText }
        </Button>
        <Button type="submit" disabled={ isSaveDisabled }>{ saveButtonText }</Button>
      </DialogActions>
    </Dialog>
  );
};

export default ProductFormDialog;
