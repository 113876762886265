/* eslint-disable max-len */
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core';
import SvgIcon from '@material-ui/core/SvgIcon';
import { IconSize, DefaultIconSizeValue } from './constants';

const useStyles = makeStyles(() => ({
  root: {
    fill: 'none',
    stroke: 'currentColor',
  },
}));

const OpenedEye = ({ size = IconSize.Medium, className }) => {
  const classes = useStyles();
  return (
    <SvgIcon
      style={ { width: DefaultIconSizeValue[size], height: DefaultIconSizeValue[size] } }
      className={ classNames(classes.root, className) }
      viewBox="0 0 16 16"
    >
      <path d="M12.9562 6.37291L12.9562 6.37293C13.7545 7.05719 14.2043 7.73716 14.3622 8.00038C14.2045 8.26331 13.7546 8.94321 12.9562 9.62748C11.6081 10.7828 9.9 11.4 8 11.4C6.1 11.4 4.39191 10.7828 3.04383 9.62748C2.24542 8.94321 1.79553 8.26331 1.63783 8.00038C1.79565 7.73718 2.24553 7.0572 3.0438 6.37293L3.04382 6.37291C4.39191 5.21728 6.10003 4.6 8 4.6C9.89997 4.6 11.6081 5.21728 12.9562 6.37291Z" strokeWidth="1.2" />
      <path d="M5.5,8a2.5,2.5 0 1,0 5,0a2.5,2.5 0 1,0 -5,0" />
    </SvgIcon>
  );
};

export default OpenedEye;
