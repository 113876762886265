import Canvas from './Canvas';
import Scene from './Scene';

const CubeControllerScene = ({
  disabled,
  rotation,
  onRotationEnd,
}) => (
  <Canvas>
    <Scene
      disabled={ disabled }
      rotation={ rotation }
      onRotationEnd={ onRotationEnd }
    />
  </Canvas>
);

export default CubeControllerScene;
