import { translatedFieldsToArray } from '@shared/utils/translations';

const ENDPOINT = '/attribute-definitions';

class AttributeDefinitionsApi {
  constructor(http) {
    this.http = http;
  }

  getAttributeDefinitions = async (entityTypes) => {
    const params = {};
    if (entityTypes instanceof Array) {
      params.entityTypes = entityTypes;
    }
    params.populate = 'attributes';

    const data = await this.http.get(ENDPOINT, { params });

    return data;
  }

  createAttributeDefinition = async (attributeDefinition) => {
    const { name, attributes, entityTypes, translatedFields } = attributeDefinition;

    const translations = translatedFieldsToArray(translatedFields);
    const translatedAtributes = attributes.map((attribute) => {
      attribute.translations = translatedFieldsToArray(attribute.translatedFields);
      delete attribute.translatedFields;
      return attribute;
    });

    const payload = {
      name,
      attributes: translatedAtributes,
      entityTypes,
      translations,
    };

    const data = await this.http.post(ENDPOINT, payload);

    return data;
  }

  editAttributeDefinition = async (attributeDefinition) => {
    const { name, attributes, entityTypes, translatedFields } = attributeDefinition;

    const translations = translatedFieldsToArray(translatedFields);
    const translatedAtributes = attributes.map((attribute) => {
      attribute.translations = translatedFieldsToArray(attribute.translatedFields);
      delete attribute.translatedFields;
      return attribute;
    });

    const data = await this.http.put(`${ENDPOINT}/${attributeDefinition.id}`, {
      id: attributeDefinition.id,
      name,
      attributes: translatedAtributes,
      entityTypes,
      translations,
    });

    return data;
  }

  deleteAttributeDefinition = (id) => this.http.delete(`${ENDPOINT}/${id}`)
}

export default AttributeDefinitionsApi;
