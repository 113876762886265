import { makeStyles } from '@material-ui/core/styles';
import NoImageIcon from '@resources/icons/NoImageIcon';
import classNames from 'classnames';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    height: '100%',
    backgroundColor: '#EEEFF3',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  icon: {
    width: '68px',
    height: '58px',
    color: '#5F6B79',
  },
}));

const ImagePlaceholder = ({ className }) => {
  const classes = useStyles();

  return (
    <div className={ classNames(classes.root, className) }>
      <NoImageIcon className={ classes.icon } size="epic" />
    </div>
  );
};

export default ImagePlaceholder;
