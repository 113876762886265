/* eslint-disable max-len */
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core';
import SvgIcon from '@material-ui/core/SvgIcon';
import { IconSize, DefaultIconSizeValue } from './constants';

const useStyles = makeStyles(() => ({
  root: {
    fill: 'none',
    stroke: 'currentColor',
  },
}));

const MoreIcon = ({ size = IconSize.Medium, className }) => {
  const classes = useStyles();
  return (
    <SvgIcon
      style={ { width: DefaultIconSizeValue[size], height: DefaultIconSizeValue[size] } }
      className={ classNames(classes.root, className) }
      viewBox="0 0 16 4"
    >
      <path
        d="M9.5 2C9.5 2.82843 8.82843 3.5 8 3.5C7.17157 3.5 6.5 2.82843 6.5 2C6.5 1.17157 7.17157 0.5 8 0.5C8.82843 0.5 9.5 1.17157 9.5 2Z"
        fill="none"
        stroke="currentColor"
      />
      <path
        d="M3.5 2C3.5 2.82843 2.82843 3.5 2 3.5C1.17157 3.5 0.5 2.82843 0.5 2C0.5 1.17157 1.17157 0.5 2 0.5C2.82843 0.5 3.5 1.17157 3.5 2Z"
        fill="none"
        stroke="currentColor"
      />
      <path
        d="M15.5 2C15.5 2.82843 14.8284 3.5 14 3.5C13.1716 3.5 12.5 2.82843 12.5 2C12.5 1.17157 13.1716 0.5 14 0.5C14.8284 0.5 15.5 1.17157 15.5 2Z"
        fill="none"
        stroke="currentColor"
      />
    </SvgIcon>
  );
};

export default MoreIcon;
