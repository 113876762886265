/* eslint-disable max-len */
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core';
import SvgIcon from '@material-ui/core/SvgIcon';
import { IconSize, DefaultIconSizeValue } from './constants';

const useStyles = makeStyles(() => ({
  root: {
    fill: 'currentColor',
  },
}));

const ShareIcon = ({ size = IconSize.Medium, className }) => {
  const classes = useStyles();
  return (
    <SvgIcon
      style={ { width: DefaultIconSizeValue[size], height: DefaultIconSizeValue[size] } }
      className={ classNames(classes.root, className) }
      viewBox="0 0 16 16"
    >
      <path d="M8 14C7.7875 14 7.6095 13.928 7.466 13.784C7.322 13.6405 7.25 13.4625 7.25 13.25V7.8875L5.84375 9.29375C5.70625 9.43125 5.528 9.497 5.309 9.491C5.0905 9.4845 4.9125 9.4125 4.775 9.275C4.6375 9.1375 4.56875 8.9625 4.56875 8.75C4.56875 8.5375 4.6375 8.3625 4.775 8.225L7.475 5.525C7.55 5.45 7.63125 5.39675 7.71875 5.36525C7.80625 5.33425 7.9 5.31875 8 5.31875C8.1 5.31875 8.19375 5.33425 8.28125 5.36525C8.36875 5.39675 8.45 5.45 8.525 5.525L11.225 8.225C11.3625 8.3625 11.4313 8.5375 11.4313 8.75C11.4313 8.9625 11.3625 9.1375 11.225 9.275C11.0875 9.4125 10.9095 9.4845 10.691 9.491C10.472 9.497 10.2938 9.43125 10.1562 9.29375L8.75 7.8875V13.25C8.75 13.4625 8.67825 13.6405 8.53475 13.784C8.39075 13.928 8.2125 14 8 14ZM2.75 5.75C2.5375 5.75 2.35925 5.678 2.21525 5.534C2.07175 5.3905 2 5.2125 2 5V3.5C2 3.0875 2.147 2.7345 2.441 2.441C2.7345 2.147 3.0875 2 3.5 2H12.5C12.9125 2 13.2658 2.147 13.5598 2.441C13.8533 2.7345 14 3.0875 14 3.5V5C14 5.2125 13.928 5.3905 13.784 5.534C13.6405 5.678 13.4625 5.75 13.25 5.75C13.0375 5.75 12.8595 5.678 12.716 5.534C12.572 5.3905 12.5 5.2125 12.5 5V3.5H3.5V5C3.5 5.2125 3.42825 5.3905 3.28475 5.534C3.14075 5.678 2.9625 5.75 2.75 5.75Z" fill="#9DA3B2" />
    </SvgIcon>
  );
};

export default ShareIcon;
