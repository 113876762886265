import { convertFileToBase64 } from '@shared/utils';
import KelvinColor from '@shared/utils/KelvinColor';

const ENDPOINT = '/studios';

class StudiosApi {
  constructor(http) {
    this.http = http;
  }

  getStudios = async () => {
    const data = await this.http.get(`${ENDPOINT}?populate=model`);

    return {
      ...data,
      items: data.items.map((item) => ({
        ...item,
        ambient: new KelvinColor(item.ambient),
      })),
    };
  }

  getStudio = async (id) => {
    const data = await this.http.get(`${ENDPOINT}/${id}?populate=model`);
    data.ambient = new KelvinColor(data.ambient);
    return data;
  }

  getStudiosCounts = () => this.http.get(`${ENDPOINT}/counts`)

  createStudio = async (studio) => {
    const { thumbnail = '' } = studio;

    await this.populateEnvironment(studio);

    const data = await this.http.post(ENDPOINT, {
      ...studio,
      environment: studio.environment ? studio.environment.id : null,
      model: studio.model?.id,
      ambient: studio.ambient.color,
      thumbnail: '',
      id: undefined,
    });

    if (thumbnail) {
      const imageData = await this.http.post(`${ENDPOINT}/${data.id}/thumbnail`, {
        thumbnail,
      });

      data.thumbnail = imageData.thumbnail;
    }

    return data;
  }

  updateStudio = async (studio) => {
    const { thumbnail = '' } = studio;

    await this.populateEnvironment(studio);

    const data = await this.http.put(`${ENDPOINT}/${studio.id}`, {
      ...studio,
      environment: studio.environment ? studio.environment.id : null,
      model: studio.model?.id,
      ambient: studio.ambient.color,
      thumbnail: '',
    });

    if (thumbnail) {
      const imageData = await this.http.post(`${ENDPOINT}/${data.id}/thumbnail`, {
        thumbnail,
      });

      data.thumbnail = imageData.thumbnail;
    }

    return data;
  }

  async populateEnvironment(studio) {
    if (studio.environment && studio.environment.image) {
      const data = await this.http.post('/hdris', studio.environment);
      // eslint-disable-next-line no-await-in-loop
      const picture64 = await convertFileToBase64(studio.environment.image);
      // eslint-disable-next-line no-await-in-loop
      const dataUpload = await this.http.post(`/hdris/${data.id}/upload`, {
        src: picture64,
        title: `${studio.environment.image.name}`,
        type: studio.environment.type,
      });
      data.url = dataUpload.url;
      studio.environment = data;
    }
  }

  deleteStudio = (id) => this.http.delete(`${ENDPOINT}/${id}`)
}

export default StudiosApi;
