/* eslint-disable max-len */

const DownloadIcon = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_11183_40062)">
      <path
        d="M14 9.07921C14 8.86139 13.9094 8.65347 13.7585 8.50495C13.4365 8.18812 12.9031 8.18812 12.5811 8.50495L10.8302 10.2277V1.81188C10.8302 1.36634 10.4579 1 9.99497 1C9.53207 1 9.16981 1.36634 9.16981 1.81188V10.2178L7.40881 8.50495C7.2478 8.34653 7.03648 8.25743 6.82516 8.25743C6.61384 8.25743 6.40252 8.33663 6.24151 8.49505C6.0805 8.65347 6 8.86139 6 9.07921C6 9.29703 6.09057 9.50495 6.24151 9.65347C6.59371 10 9.41132 12.7624 9.41132 12.7624C9.73333 13.0792 10.2667 13.0792 10.5887 12.7624L13.7585 9.65347C13.9094 9.49505 14 9.29703 14 9.07921Z"
        fill="#40516C"
      />
      <path
        d="M6.2448 3.88965H3.0948C2.3148 3.88965 1.5748 4.18965 1.0248 4.73965C0.484805 5.28965 0.174805 6.03965 0.174805 6.80965V16.2396C0.174805 17.0196 0.474805 17.7496 1.0348 18.2996C1.5948 18.8496 2.3248 19.1496 3.1048 19.1496H16.9048C17.6848 19.1496 18.4248 18.8496 18.9748 18.2996C19.5248 17.7496 19.8348 17.0196 19.8348 16.2396V6.80965C19.8348 6.03965 19.5248 5.28965 18.9748 4.74965C18.4248 4.19965 17.6848 3.89965 16.9048 3.89965H13.7548C13.2948 3.89965 12.9348 4.26965 12.9348 4.71965C12.9348 5.16965 13.3048 5.53965 13.7548 5.53965H16.9048C17.2448 5.53965 17.5648 5.66965 17.8048 5.90965C18.0348 6.13965 18.1748 6.46965 18.1748 6.80965V16.2396C18.1748 16.5696 18.0348 16.8996 17.8048 17.1396C17.5648 17.3796 17.2448 17.5096 16.9048 17.5096H3.0948C2.7548 17.5096 2.4348 17.3796 2.1948 17.1396C1.9548 16.8996 1.8248 16.5796 1.8248 16.2396V6.80965C1.8248 6.47965 1.9648 6.14965 2.1948 5.90965C2.4348 5.66965 2.7548 5.53965 3.0948 5.53965H6.2448C6.7048 5.53965 7.0648 5.16965 7.0648 4.71965C7.0748 4.25965 6.7048 3.88965 6.2448 3.88965Z"
        fill="#40516C"
      />
    </g>
    <defs>
      <clipPath id="clip0_11183_40062">
        <rect width="19.65" height="18.32" fill="white" transform="translate(0.174805 0.839844)" />
      </clipPath>
    </defs>
  </svg>
);

export default DownloadIcon;
