import { makeStyles } from '@material-ui/core';
import Subtitle from '../card-controls/Subtitle';

const useStyles = makeStyles(() => ({
  root: {
    display: 'block',
  },
}));

const Sizes = ({ children }) => {
  const classes = useStyles();
  return (
    <Subtitle className={ classes.root } color="textSecondary">
      {children}
    </Subtitle>
  );
};

export default Sizes;
