export const IconSize = {
  Large: 'large',
  Medium: 'medium',
  Small: 'small',
  Inherit: 'inherit',
};

export const DefaultIconSizeValue = {
  [IconSize.Large]: '32px',
  [IconSize.Medium]: '24px',
  [IconSize.Small]: '16px',
  [IconSize.Inherit]: 'inherit',
};
