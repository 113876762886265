import { useCallback } from 'react';
import Divider from '@shared/components/divider';
import CubeController from '../CubeController';
import DetailsContainer from '../DetailsContainer';
import Rotation from '../Rotation';
import Position from '../Position';
import { Fieldset } from './fieldset';
import { displayValue } from '@shared/utils';

const ModelDetails = ({
  details,
  onChange,
  isEditable,
}) => {
  const disabledForRotation = [details.locked, false, details.locked];
  const disabledForPosition = [false, details.locked, false];

  const handleRotationEnd = useCallback((rotation) => {
    const newRotation = [displayValue(rotation.x), displayValue(rotation.y), displayValue(rotation.z)];
    onChange({ value: newRotation }, Fieldset.Rotate);
  }, [onChange]);

  return (
    <DetailsContainer isEditable={ isEditable }>
      <CubeController
        disabled={ disabledForRotation }
        rotation={ details[Fieldset.Rotate] }
        onRotationEnd={ handleRotationEnd }
      />
      <Rotation
        label="Rotate"
        name={ Fieldset.Rotate }
        value={ details[Fieldset.Rotate] }
        disabled={ disabledForRotation }
        step={ 0.1 }
        onChange={ onChange }
      />
      <Divider />
      <Position
        label="Position"
        name={ Fieldset.Position }
        value={ details[Fieldset.Position] }
        disabled={ disabledForPosition }
        step={ 0.1 }
        onChange={ onChange }
      />
    </DetailsContainer>
  );
};

export default ModelDetails;
