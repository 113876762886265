import { useNavigate as useNavigateOriginal } from 'react-router-dom';

const useNavigate = () => {
  const navigate = useNavigateOriginal();

  return {
    goTo: (url, options) => navigate(url, options),
    goBack: () => navigate(-1),
    goForward: () => navigate(1),
  };
};

export default useNavigate;
