import { SortId } from '@app/sort/sortValues';
import { createAction, createSlice } from '@reduxjs/toolkit';

const initialState = {
  loading: true,
  user: null,
  company: null,
  menuOpen: false,
  searchString: '',
  editModal: true,
  lightIntensity: 1,
  sortedId: SortId.AtoZ,
  error: '',
  messages: [],
};

export const initApp = createAction('app/init');
export const login = createAction('app/login');
export const logout = createAction('app/logout');

export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setUser: (state, action) => {
      state.user = action.payload;
    },
    setCompany: (state, action) => {
      state.company = action.payload;
    },
    toggleMenu: (state) => {
      state.menuOpen = !state.menuOpen;
    },
    showMenu: (state) => {
      state.menuOpen = true;
    },
    hideMenu: (state) => {
      state.menuOpen = false;
    },
    search: (state, action) => {
      state.searchString = action.payload;
    },
    editModal: (state) => {
      state.editModal = !state.editModal;
    },
    lightIntencity: (state, action) => {
      state.lightIntensity = action.payload;
    },
    sortedId: (state, action) => {
      state.sortedId = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
    clearError: (state) => {
      state.error = '';
    },
    addMessage: (state, action) => {
      state.messages = [...state.messages, action.payload];
    },
    removeMessage: (state, action) => {
      state.messages = state.messages.filter((item, index) => index !== (action.index ?? 0));
    },
  },
});

export const actions = {
  ...appSlice.actions,
  initApp,
  login,
  logout,
};

export default appSlice.reducer;
