import { useCallback } from 'react';
import { useSelector } from 'react-redux';

import Model from './Model';
import { selectCurrentCompany } from '@store/app/selectors';


const ModelViewer = ({ value, materials, scale, size, generateThumbnail = true, environment,
  onChange, onLoad, onLoadSize, onFrame }) => {
  const company = useSelector(selectCurrentCompany);
  const handleLoad = useCallback(({ object, thumbnail, size }, camera, gl, scene) => {
    const onChangeData = {
      ...value,
      isLoaded: true,
    };

    if (generateThumbnail) {
      onChangeData.thumbnail = value.thumbnail && !value.isLoaded ? null : thumbnail;
    }
    onChange && onChange(onChangeData);
    onLoad && onLoad(object, camera, gl, scene);
    onLoadSize && onLoadSize(size);
  }, [value, generateThumbnail, onChange, onLoad, onLoadSize]);

  return (
    <Model
      value={ value }
      onLoad={ handleLoad }
      materials={ materials }
      onFrame={ onFrame }
      environment={ environment }
      scale={ scale }
      size={ size }
      toneMapping={ company?.toneMapping }
      toneMappingExposure={ company?.toneMappingExposure }
    />
  );
};

export default ModelViewer;
