import { FormDialog } from '@shared/views/form-dialog';
import CreateModelForm from './Form';
import FormFieldset from '../FormFieldset';
import { useDispatch } from 'react-redux';
import { resetModelApiState } from '@store/models/actions';

const CreateFormDialog = ({
  open,
  onClose,
}) => {
  const dispatch = useDispatch();
  const handleSubmit = () => {
    dispatch(resetModelApiState());
    onClose();
  };

  const handleCancel = () => {
    dispatch(resetModelApiState());
    onClose();
  };

  return (
    <FormDialog
      open={ open }
      Form={ CreateModelForm }
      FormProps={ {
        open,
        onSubmit: handleSubmit,
      } }
      title="Add New Model"
      content={ <FormFieldset /> }
      onCancel={ handleCancel }
      onClose={ handleCancel }
      isDrawer
    />
  );
};

export default CreateFormDialog;
