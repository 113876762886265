const filterByAttributes = (items, filterValue) => {
  if (filterValue.length > 0) {
    return items.filter((item) => (
      item.attributes?.some((itemAttr) => filterValue.some((filterId) => filterId == itemAttr.id))
    ));
  }
  return items;
};

export default filterByAttributes;
