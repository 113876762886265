export const LOAD_MATERIALS_LIST = 'LOAD_MATERIALS_LIST';
export const RENDER_MATERIALS_LIST = 'RENDER_MATERIALS_LIST';
export const LOAD_TEXTURES_LIST = 'LOAD_TEXTURES_LIST';
export const DELETE_TEXTURE = 'DELETE_TEXTURE';
export const RENDER_TEXTURES_LIST = 'RENDER_TEXTURES_LIST';
export const LOAD_MATERIALS_COUNTS = 'LOAD_MATERIALS_COUNTS';
export const RENDER_MATERIALS_COUNTS = 'RENDER_MATERIALS_COUNTS';
export const RESET_MATERIALS = 'RESET_MATERIALS';
export const CREATE_MATERIAL = 'CREATE_MATERIAL';
export const CREATE_MATERIAL_ERROR = 'CREATE_MATERIAL_ERROR';
export const CREATE_MATERIAL_SUCCESS = 'CREATE_MATERIAL_SUCCESS';
export const UPDATE_MATERIAL = 'UPDATE_MATERIAL';
export const UPDATE_MATERIAL_ERROR = 'UPDATE_MATERIAL_ERROR';
export const UPDATE_MATERIAL_SUCCESS = 'UPDATE_MATERIAL_ERROR';
export const DELETE_MATERIAL = 'DELETE_MATERIAL';
export const DELETE_MATERIAL_ERROR = 'DELETE_MATERIAL_ERROR';
export const DELETE_TEXTURE_ERROR = 'DELETE_TEXTURE_ERROR';
export const LOAD_MATERIAL = 'LOAD_MATERIAL';
export const RENDER_MATERIAL = 'RENDER_MATERIAL';
export const SORTED_ID = 'SORTED_ID';
export const MARK_MATERIAL_AS_DEFAULT_CONTENT = 'MARK_MATERIAL_AS_DEFAULT_CONTENT';
export const UNMARK_MATERIAL_AS_DEFAULT_CONTENT = 'UNMARK_MATERIAL_AS_DEFAULT_CONTENT';
export const HIDE_MATERIAL = 'HIDE_MATERIAL';
export const RESET_MATERIAL_API_STATE = 'RESET_MATERIAL_API_STATE';

export function loadMaterialsList() {
  return {
    type: LOAD_MATERIALS_LIST,
  };
}

export function renderMaterialsList(materials) {
  return {
    type: RENDER_MATERIALS_LIST,
    materials,
  };
}

export function loadTexturesList() {
  return {
    type: LOAD_TEXTURES_LIST,
  };
}

export function deleteTexture(id, name) {
  return {
    type: DELETE_TEXTURE,
    id,
    name,
  };
}

export function renderTexturesList(textures) {
  return {
    type: RENDER_TEXTURES_LIST,
    textures,
  };
}

export function loadMaterialsCounts() {
  return {
    type: LOAD_MATERIALS_COUNTS,
  };
}

export function renderMaterialsCounts(counts) {
  return {
    type: RENDER_MATERIALS_COUNTS,
    payload: counts,
  };
}

export function resetMaterials() {
  return {
    type: RESET_MATERIALS,
  };
}

export function createMaterial(material) {
  return {
    type: CREATE_MATERIAL,
    material,
  };
}

export function createMaterialError(error) {
  return {
    type: CREATE_MATERIAL_ERROR,
    error,
  };
}

export function createMaterialSuccess() {
  return {
    type: CREATE_MATERIAL_SUCCESS,
  };
}

export function renderMaterial(material) {
  return {
    type: RENDER_MATERIAL,
    material,
  };
}

export function loadMaterial(id) {
  return {
    type: LOAD_MATERIAL,
    id,
  };
}

export function updateMaterial(material) {
  return {
    type: UPDATE_MATERIAL,
    material,
  };
}

export function updateMaterialError(error) {
  return {
    type: UPDATE_MATERIAL_ERROR,
    error,
  };
}

export function updateMaterialSuccess() {
  return {
    type: UPDATE_MATERIAL_SUCCESS,
  };
}

export function deleteMaterial(id) {
  return {
    type: DELETE_MATERIAL,
    id,
  };
}

export function deleteMaterialError(id, error) {
  return {
    type: DELETE_MATERIAL_ERROR,
    id,
    error,
  };
}

export function deleteTextureError(id, error) {
  return {
    type: DELETE_TEXTURE_ERROR,
    id,
    error,
  };
}

export function sortedId(value) {
  return {
    type: SORTED_ID,
    value,
  };
}

export const markMaterialAsDefaultContent = (material) => ({
  type: MARK_MATERIAL_AS_DEFAULT_CONTENT,
  payload: material,
});

export const unmarkMaterialAsDefaultContent = (material) => ({
  type: UNMARK_MATERIAL_AS_DEFAULT_CONTENT,
  payload: material,
});

export const hideMaterial = (material) => ({
  type: HIDE_MATERIAL,
  payload: material,
});

export function resetMaterialApiState() {
  return {
    type: RESET_MATERIAL_API_STATE,
  };
}
