import { forwardRef } from 'react';
import { useSelector } from 'react-redux';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

const CompanySelector = forwardRef(({
  onChange,
  value,
  error,
  ...props
}, ref) => {
  // get only non-deleted companies
  const companies = useSelector((state) => state.companies.companies.filter((company) => !company.isDeleted));

  return (
    <>
      <Autocomplete
        { ...props }
        ref={ ref }
        id="company-selector"
        value={ value }
        onChange={ (_, newValue) => onChange(newValue?.id ? newValue : companies[0]) }
        getOptionSelected={ (o, v) => o.id === v.id }
        getOptionLabel={ (o) => o?.name || '' }
        options={ companies }
        renderInput={ (params) => (
          <TextField
            { ...params }
            label="Company"
            variant="outlined"
            error={ !!error }
            helperText={ error?.message }
          />
        ) }
      />
    </>
  );
});

export default CompanySelector;
