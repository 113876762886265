/* eslint-disable max-len */
import { makeStyles } from '@material-ui/core';
import SvgIcon from '@material-ui/core/SvgIcon';
import { DefaultIconSizeValue, IconSize } from '@resources/icons/constants';

const useStyles = makeStyles(() => ({
  root: {
    fill: 'none',
  },
}));

const ElectricLightLeft = ({ size = IconSize.Medium, selected = false }) => {
  const classes = useStyles();
  return (
    <SvgIcon
      style={ { width: DefaultIconSizeValue[size], height: DefaultIconSizeValue[size] } }
      className={ classes.root }
      viewBox="0 0 32 32"
    >
      <g clipPath="url(#clip0_1086_9195)">
        <path
          d="M0 0C2.10115 0 4.18173 0.413853 6.12293 1.21793C8.06414 2.022 9.82797 3.20055 11.3137 4.68629C12.7994 6.17203 13.978 7.93586 14.7821 9.87707C15.5861 11.8183 16 13.8989 16 16C16 18.1012 15.5861 20.1817 14.7821 22.1229C13.978 24.0641 12.7994 25.828 11.3137 27.3137C9.82797 28.7994 8.06414 29.978 6.12293 30.7821C4.18172 31.5861 2.10115 32 -9.53674e-07 32L0 16L0 0Z"
          fill={ selected ? '#ffffff' : '#EBEFF2' }
        />
        <path
          d="M1 31.5C0.723858 31.5 0.5 31.2761 0.5 31L0.5 1C0.5 0.723858 0.723858 0.5 1 0.5L31 0.5C31.2761 0.5 31.5 0.723858 31.5 1L31.5 31C31.5 31.2761 31.2761 31.5 31 31.5L1 31.5Z"
          stroke="#334D6E"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <mask id="path-4-inside-1_1086_9195" fill="white">
          <path d="M0 32L0 0H32L32 32L0 32Z" />
        </mask>
        <path d="M2 32L2 0H-2L-2 32H2Z" fill="#334D6E" mask="url(#path-4-inside-1_1086_9195)" />
        <mask id="path-6-inside-2_1086_9195" fill="white">
          <path d="M0.982405 10.0352C1.76803 10.0352 2.54595 10.1899 3.27177 10.4906C3.99759 10.7912 4.65709 11.2319 5.2126 11.7874C5.76812 12.3429 6.20878 13.0024 6.50943 13.7282C6.81007 14.454 6.96481 15.232 6.96481 16.0176C6.96481 16.8032 6.81007 17.5811 6.50943 18.307C6.20878 19.0328 5.76812 19.6923 5.2126 20.2478C4.65709 20.8033 3.99759 21.244 3.27177 21.5446C2.54595 21.8453 1.76802 22 0.982404 22L0.982405 16.0176L0.982405 10.0352Z" />
        </mask>
        <path
          d="M0.982405 10.0352C1.76803 10.0352 2.54595 10.1899 3.27177 10.4906C3.99759 10.7912 4.65709 11.2319 5.2126 11.7874C5.76812 12.3429 6.20878 13.0024 6.50943 13.7282C6.81007 14.454 6.96481 15.232 6.96481 16.0176C6.96481 16.8032 6.81007 17.5811 6.50943 18.307C6.20878 19.0328 5.76812 19.6923 5.2126 20.2478C4.65709 20.8033 3.99759 21.244 3.27177 21.5446C2.54595 21.8453 1.76802 22 0.982404 22L0.982405 16.0176L0.982405 10.0352Z"
          fill="#F0F0F0"
          stroke="#334D6E"
          strokeWidth="2"
          mask="url(#path-6-inside-2_1086_9195)"
        />
        <path
          d="M0.98241 12.0283C1.50616 12.0283 2.02478 12.1315 2.50866 12.3319C2.99253 12.5324 3.4322 12.8261 3.80254 13.1965C4.17289 13.5668 4.46666 14.0065 4.66709 14.4904C4.86752 14.9742 4.97068 15.4929 4.97068 16.0166C4.97068 16.5404 4.86752 17.059 4.66709 17.5429C4.46666 18.0267 4.17289 18.4664 3.80254 18.8367C3.4322 19.2071 2.99253 19.5009 2.50865 19.7013C2.02478 19.9017 1.50616 20.0049 0.98241 20.0049L0.98241 16.0166L0.98241 12.0283Z"
          fill="#334D6E"
        />
        <path d="M8.46094 16.0176H6.4668" stroke="#334D6E" strokeLinecap="round" />
        <path d="M6.19824 21.4805L4.78818 20.0704" stroke="#334D6E" strokeLinecap="round" />
        <path d="M6.19824 10.5537L4.78818 11.9638" stroke="#334D6E" strokeLinecap="round" />
      </g>
      <defs>
        <clipPath id="clip0_1086_9195">
          <rect width="32" height="32" rx="1" fill="white" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
};

export default ElectricLightLeft;
