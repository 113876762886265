import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    height: '100%',
    width: '100%',
    backgroundColor: theme.palette.common.white,
    padding: theme.spacing(5),
    overflow: 'hidden',
  },
  inner: {
    position: 'relative',
    border: `.1rem dashed ${theme.palette.common.divider}`,
    width: '100%',
    height: `calc(100vh - ${theme.spacing(32)}px)`,
  },
  core: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',

    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '& > :not(:first-child)': {
      marginTop: theme.spacing(2),
    },
  },
}));

const Layout = ({ children }) => {
  const classes = useStyles();

  return (
    <div className={ classes.root }>
      <div className={ classes.inner }>
        <div className={ classes.core }>{children}</div>
      </div>
    </div>
  );
};

export default Layout;
