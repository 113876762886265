/* eslint-disable max-len */

const Zrotate = () => (
  <svg
    width="14"
    height="13"
    viewBox="0 0 14 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.3785 10.3781C10.2566 10.5 10.1268 10.6192 9.99164 10.7279C9.91479 10.7915 9.90419 10.9028 9.96514 10.9796C9.99959 11.022 10.0526 11.0459 10.1029 11.0459C10.1427 11.0459 10.1824 11.0326 10.2169 11.0061C10.36 10.8895 10.4978 10.765 10.6303 10.6325C10.6992 10.5609 10.6992 10.4496 10.6303 10.3781C10.564 10.3092 10.4501 10.3092 10.3785 10.3781Z"
      fill="#727A89"
      stroke="#727A89"
      strokeWidth="0.5"
    />
    <path
      d="M10.1076 11.1784C10.0122 11.1784 9.92478 11.136 9.86648 11.0645C9.75783 10.9293 9.77903 10.7332 9.91153 10.6246C10.0387 10.5212 10.1633 10.4073 10.2878 10.2827C10.3461 10.2244 10.4256 10.1926 10.5078 10.1926C10.5899 10.1926 10.6694 10.2244 10.7277 10.2854C10.8469 10.4073 10.8469 10.6034 10.7277 10.7253C10.5926 10.8604 10.4521 10.9876 10.3037 11.1068C10.2507 11.1545 10.1792 11.1784 10.1076 11.1784ZM10.5051 10.4576C10.4972 10.4576 10.4839 10.4603 10.4733 10.4709C10.3435 10.6007 10.211 10.72 10.0785 10.8286C10.0573 10.8445 10.0546 10.8737 10.0732 10.8949C10.0917 10.9187 10.1129 10.9214 10.1368 10.9002C10.2746 10.7889 10.4124 10.667 10.5396 10.5398C10.5581 10.5212 10.5581 10.4921 10.5396 10.4735C10.5263 10.4603 10.5131 10.4576 10.5051 10.4576Z"
      fill="#727A89"
      stroke="#727A89"
      strokeWidth="0.5"
    />
    <path
      d="M2.68013 10.0072C2.61653 9.93031 2.50258 9.91706 2.42573 9.98331C2.34889 10.0469 2.33829 10.1582 2.40188 10.235C2.63243 10.5133 2.89212 10.773 3.17301 11.0035C3.20746 11.03 3.24721 11.0459 3.28696 11.0459C3.33996 11.0459 3.3903 11.0221 3.42475 10.9797C3.48835 10.9028 3.47775 10.7915 3.4009 10.7279C3.13856 10.5106 2.89477 10.2695 2.68013 10.0072Z"
      fill="#727A89"
      stroke="#727A89"
      strokeWidth="0.5"
    />
    <path
      d="M3.28663 11.1758C3.21508 11.1758 3.14618 11.1493 3.08788 11.1043C2.80169 10.8711 2.5367 10.6061 2.29821 10.3172C2.18956 10.1847 2.20811 9.986 2.34061 9.87735C2.40421 9.82435 2.4837 9.79786 2.5685 9.80846C2.65065 9.81641 2.7275 9.85615 2.78049 9.9224C2.98984 10.1768 3.22568 10.4126 3.48272 10.6246C3.54632 10.6776 3.58872 10.7518 3.59667 10.8366C3.60462 10.9188 3.58077 11.0009 3.52777 11.0645C3.46682 11.1361 3.37938 11.1758 3.28663 11.1758ZM2.53935 10.0734C2.5314 10.0734 2.5208 10.0761 2.5102 10.084C2.489 10.1026 2.48635 10.1317 2.50225 10.1503C2.73015 10.4259 2.98189 10.6776 3.25483 10.9002C3.27603 10.9188 3.30518 10.9135 3.32108 10.8949C3.33168 10.8817 3.33168 10.8684 3.33168 10.8605C3.33168 10.8525 3.32903 10.8393 3.31578 10.8287C3.04549 10.6061 2.79639 10.357 2.57645 10.0893C2.56585 10.0761 2.5526 10.0734 2.54465 10.0708C2.542 10.0734 2.542 10.0734 2.53935 10.0734Z"
      fill="#727A89"
      stroke="#727A89"
      strokeWidth="0.5"
    />
    <path
      d="M5.18653 11.6819C4.86324 11.5838 4.54525 11.454 4.24581 11.2923C4.15836 11.2473 4.04971 11.2791 4.00202 11.3665C3.95432 11.454 3.98877 11.5626 4.07621 11.6103C4.39685 11.7826 4.73604 11.923 5.08318 12.0264C5.09908 12.0317 5.11763 12.037 5.13618 12.037C5.21303 12.037 5.28458 11.9866 5.30843 11.9098C5.33492 11.8091 5.28193 11.711 5.18653 11.6819Z"
      fill="#727A89"
      stroke="#727A89"
      strokeWidth="0.5"
    />
    <path
      d="M5.137 12.1667C5.10785 12.1667 5.07605 12.1614 5.04426 12.1508C4.69447 12.0448 4.34733 11.9017 4.01609 11.7268C3.86504 11.6446 3.80674 11.4565 3.88889 11.3054C3.97104 11.1544 4.15918 11.0987 4.31023 11.1782C4.59907 11.3346 4.90911 11.4618 5.2271 11.5598C5.39139 11.6102 5.48414 11.7824 5.43644 11.9494C5.39404 12.0739 5.27215 12.1667 5.137 12.1667ZM4.16448 11.4035C4.14858 11.4035 4.13003 11.4114 4.12208 11.4273C4.10883 11.4485 4.11943 11.4777 4.14328 11.4909C4.45862 11.6579 4.78986 11.7957 5.1264 11.8964C5.16085 11.899 5.1794 11.8858 5.1847 11.8646C5.19265 11.8407 5.1794 11.8142 5.1529 11.8063C4.81636 11.7029 4.49307 11.5704 4.18833 11.4061C4.17773 11.4035 4.16978 11.4035 4.16448 11.4035Z"
      fill="#727A89"
      stroke="#727A89"
      strokeWidth="0.5"
    />
    <path
      d="M1.71772 8.22104C1.68857 8.12829 1.58787 8.07529 1.49248 8.10179C1.39708 8.13094 1.34408 8.23164 1.37323 8.32704C1.47923 8.67417 1.61967 9.01071 1.79192 9.33135C1.82372 9.3923 1.88731 9.42675 1.95091 9.42675C1.98006 9.42675 2.00921 9.4188 2.03571 9.40555C2.12316 9.35785 2.15496 9.24921 2.10991 9.16176C1.94826 8.86232 1.81577 8.54698 1.71772 8.22104Z"
      fill="#727A89"
      stroke="#727A89"
      strokeWidth="0.5"
    />
    <path
      d="M1.95072 9.55904C1.83677 9.55904 1.73077 9.49544 1.67512 9.39474C1.50023 9.0688 1.35448 8.72167 1.24584 8.36658C1.22199 8.28708 1.22994 8.20228 1.26969 8.12808C1.30944 8.05389 1.37568 8.00089 1.45518 7.97704C1.53468 7.95319 1.61948 7.96114 1.69102 8.00089C1.76522 8.04064 1.81822 8.10423 1.84207 8.18373C1.93747 8.49642 2.06731 8.80646 2.22366 9.1006C2.26341 9.17215 2.27136 9.25695 2.24751 9.33645C2.22366 9.41594 2.17066 9.48219 2.09646 9.52194C2.05141 9.54579 2.00106 9.55904 1.95072 9.55904ZM1.54528 8.22613C1.53998 8.22613 1.53733 8.22613 1.53203 8.22878C1.51613 8.23408 1.50818 8.24468 1.50288 8.24998C1.50023 8.25793 1.49493 8.27118 1.50023 8.28708C1.60358 8.62627 1.74137 8.95486 1.90832 9.26755C1.91892 9.2861 1.93747 9.29405 1.95072 9.29405C1.98781 9.2808 1.99311 9.26755 1.99576 9.2596C1.99841 9.25165 2.00106 9.2384 1.99311 9.22515C1.82617 8.91511 1.69102 8.58917 1.59033 8.25793C1.58503 8.24203 1.57443 8.23408 1.56913 8.23143C1.56383 8.23143 1.55588 8.22613 1.54528 8.22613Z"
      fill="#727A89"
      stroke="#727A89"
      strokeWidth="0.5"
    />
    <path
      d="M9.14609 11.2923C8.84665 11.4513 8.52866 11.5838 8.20537 11.6818C8.10997 11.7083 8.05697 11.8117 8.08612 11.9071C8.10997 11.9839 8.18152 12.0343 8.25836 12.0343C8.27426 12.0343 8.29281 12.0316 8.30871 12.0263C8.65585 11.923 8.99504 11.7825 9.31568 11.6103C9.40313 11.5626 9.43493 11.4539 9.38988 11.3665C9.34218 11.279 9.23354 11.2446 9.14609 11.2923Z"
      fill="#727A89"
      stroke="#727A89"
      strokeWidth="0.5"
    />
    <path
      d="M8.25817 12.1641C8.11772 12.1641 7.99848 12.074 7.95873 11.9415C7.93488 11.862 7.94283 11.7772 7.98258 11.703C8.02233 11.6288 8.08857 11.5758 8.16807 11.5519C8.48341 11.4565 8.7908 11.3294 9.08229 11.173C9.15384 11.1333 9.23864 11.1253 9.31814 11.1492C9.39763 11.173 9.46388 11.2287 9.50363 11.3029C9.54338 11.3744 9.55133 11.4592 9.52748 11.5387C9.50363 11.6182 9.45063 11.6844 9.37643 11.7242C9.04519 11.8991 8.7007 12.0422 8.34562 12.1508C8.31647 12.1614 8.28732 12.1641 8.25817 12.1641ZM9.23069 11.4035C9.22539 11.4035 9.21744 11.4035 9.20949 11.4088C8.9021 11.5731 8.57616 11.7083 8.24492 11.8063C8.22902 11.8116 8.22107 11.8196 8.21842 11.8275C8.21577 11.8328 8.21047 11.8487 8.21577 11.8646C8.22372 11.8885 8.24492 11.8991 8.26082 11.8991C8.61061 11.7957 8.94185 11.6579 9.25719 11.491C9.27574 11.4804 9.28634 11.4486 9.27574 11.4274C9.26779 11.4115 9.25454 11.4062 9.24659 11.4035C9.24129 11.4062 9.23599 11.4035 9.23069 11.4035Z"
      fill="#727A89"
      stroke="#727A89"
      strokeWidth="0.5"
    />
    <path
      d="M7.2056 11.878C6.87436 11.9098 6.52722 11.9098 6.18538 11.878C6.08469 11.8727 5.99989 11.9416 5.98929 12.0396C5.97869 12.1377 6.05289 12.2251 6.15094 12.2357C6.33113 12.2543 6.51397 12.2622 6.69682 12.2622C6.87966 12.2622 7.06251 12.2543 7.24005 12.2357C7.3381 12.2251 7.40965 12.1377 7.4017 12.0396C7.3911 11.9416 7.3063 11.8727 7.2056 11.878Z"
      fill="#727A89"
      stroke="#727A89"
      strokeWidth="0.5"
    />
    <path
      d="M6.69727 12.3946C6.51177 12.3946 6.32363 12.384 6.13814 12.3681C6.05599 12.3601 5.98179 12.3204 5.92879 12.2568C5.87579 12.1932 5.85194 12.111 5.85989 12.0289C5.87579 11.8619 6.02684 11.7427 6.19378 11.748C6.53297 11.7798 6.86686 11.7798 7.1928 11.748H7.1981C7.373 11.7427 7.51609 11.8619 7.53199 12.0289C7.54789 12.2011 7.42335 12.3522 7.2511 12.3707C7.07091 12.3866 6.88276 12.3946 6.69727 12.3946ZM6.17258 12.0103C6.15139 12.0103 6.12489 12.0236 6.12224 12.0527C6.11959 12.0686 6.12754 12.0819 6.13284 12.0872C6.13814 12.0951 6.14874 12.1031 6.16463 12.1031C6.52237 12.1375 6.88011 12.1375 7.22725 12.1031C7.25375 12.1004 7.2723 12.0766 7.26965 12.0501C7.267 12.0209 7.23785 12.0024 7.214 12.0077C6.87481 12.0448 6.52502 12.0448 6.17258 12.0103Z"
      fill="#727A89"
      stroke="#727A89"
      strokeWidth="0.5"
    />
    <path
      d="M13.4178 5.60828C13.3463 5.53938 13.235 5.53938 13.1634 5.60828L12.2545 6.5172C12.1565 3.53339 9.7053 1.13257 6.69764 1.13257C3.62904 1.13257 1.13281 3.62879 1.13281 6.6974C1.13281 6.88289 1.14076 7.07104 1.15931 7.25388C1.16726 7.34663 1.24676 7.41553 1.33686 7.41553C1.34216 7.41553 1.35011 7.41553 1.35541 7.41553C1.45345 7.40493 1.525 7.31748 1.51705 7.21943C1.50115 7.04719 1.49055 6.87229 1.49055 6.6974C1.49055 3.82754 3.82513 1.49031 6.69499 1.49031C9.54895 1.49031 11.8756 3.80369 11.8994 6.65235L10.8554 5.60563C10.7838 5.53673 10.6725 5.53673 10.601 5.60563C10.5321 5.67453 10.5294 5.78848 10.601 5.86002L12.0081 7.26713L13.4152 5.86002C13.4867 5.79113 13.4867 5.67718 13.4178 5.60828Z"
      fill="#727A89"
      stroke="#727A89"
      strokeWidth="0.5"
    />
    <path
      d="M1.33919 7.54795C1.17754 7.54795 1.04505 7.42605 1.02915 7.26706C1.0106 7.07891 1 6.88812 1 6.69733C1 3.55452 3.55717 1 6.69733 1C9.68643 1 12.1244 3.26833 12.3682 6.21504L13.0677 5.51546C13.1896 5.39357 13.3884 5.39357 13.5076 5.51546C13.6295 5.63736 13.6295 5.83345 13.5076 5.958L12.0051 7.4605L10.5053 5.958C10.3834 5.8361 10.3834 5.63736 10.5053 5.51546C10.6272 5.39357 10.8232 5.39357 10.9451 5.51546L11.7507 6.32104C11.5599 3.70027 9.35784 1.62273 6.69733 1.62273C3.89901 1.62273 1.62538 3.89901 1.62538 6.69733C1.62538 6.86692 1.63333 7.03652 1.64923 7.20611C1.66513 7.3757 1.54058 7.5294 1.37099 7.54795H1.33919ZM6.69733 1.26499C3.70292 1.26499 1.26499 3.70292 1.26499 6.69733C1.26499 6.88017 1.27294 7.06301 1.29149 7.24056C1.29414 7.26441 1.31269 7.28296 1.33919 7.28296C1.37099 7.28031 1.38954 7.25646 1.38689 7.22996C1.36834 7.05241 1.36039 6.87222 1.36039 6.69733C1.36039 3.75327 3.75592 1.35774 6.69733 1.35774C9.61489 1.35774 12.0078 3.73207 12.0343 6.65228L12.0369 6.97557L10.7623 5.70096C10.7438 5.68241 10.7146 5.68241 10.6961 5.70096C10.6775 5.71951 10.6775 5.74865 10.6961 5.7672L12.0078 7.08156L13.3221 5.76985C13.3407 5.7513 13.3407 5.72216 13.3221 5.70361C13.3036 5.68506 13.2744 5.68506 13.2532 5.70361L12.1297 6.82717L12.1191 6.52243C12.0263 3.57307 9.64404 1.26499 6.69733 1.26499Z"
      fill="#727A89"
      stroke="#727A89"
      strokeWidth="0.5"
    />
    <path
      d="M6.69653 8.36939C7.57903 8.36939 8.29444 7.65398 8.29444 6.77149C8.29444 5.88899 7.57903 5.17358 6.69653 5.17358C5.81404 5.17358 5.09863 5.88899 5.09863 6.77149C5.09863 7.65398 5.81404 8.36939 6.69653 8.36939Z"
      fill="#727A89"
      stroke="#727A89"
      strokeWidth="0.5"
    />
  </svg>
);

export default Zrotate;
