import { Point2D, Line2D, Size2D } from '../types';

export function p(x: number, y: number): Point2D {
  return { x, y };
}

export function zero(): Point2D {
  return p(0, 0);
}

export function sum(...points: Point2D[]): Point2D {
  return points.reduce((r, v) => ({ x: r.x + v.x, y: r.y + v.y }), zero());
}

export function add(a: Point2D, b: Point2D): Point2D {
  return p(a.x + b.x, a.y + b.y);
}

export function substract(a: Point2D, b: Point2D | number): Point2D {
  if (typeof b === 'number') {
    return p(a.x - b, a.y - b);
  }
  return p(a.x - b.x, a.y - b.y);
}

export function multiply(a: Point2D, b: Point2D | number): Point2D {
  if (typeof b === 'number') {
    return p(a.x * b, a.y * b);
  }
  return p(a.x * b.x, a.y * b.y);
}

export function divide(point: Point2D, divider: Point2D): Point2D {
  return p(point.x / divider.x, point.y / divider.y);
}

export function midpoint(...points: Point2D[]): Point2D {
  return divide(sum(...points), { x: points.length, y: points.length });
}

export function dot(a: Point2D, b: Point2D): number {
  return a.x * b.x + a.y * b.y;
}

export function distance(a: Point2D, b: Point2D): number {
  return Math.sqrt(Math.pow(b.x - a.x, 2) + Math.pow(b.y - a.y, 2));
}

export function length(vector: Point2D): number {
  return Math.sqrt(Math.pow(vector.x, 2) + Math.pow(vector.y, 2));
}

export function nearest(target: Point2D, ...points: Point2D[]): Point2D {
  return points.reduce((result, point) => (
    distance(target, point) < distance(target, result) ? point : result
  ), points[0]);
}

export function flatten(...points: Point2D[]): number[] {
  return points.reduce((result, point) => {
    result.push(point.x, point.y);
    return result;
  }, [] as number[]);
}

export function normalized(vector: Point2D): Point2D {
  const l = length(vector);

  if (l == 0) {
    return p(0, 0);
  }
  return p(vector.x / l, vector.y / l);
}

export function normal(vector: Point2D, clockwise: boolean = true): Point2D {
  const v1 = normalized(vector);
  return clockwise ? p(v1.y, -v1.x) : p(-v1.y, v1.x);
}

export function thirdVertex(a: Point2D, b: Point2D, o: Point2D): Point2D {
  const ab = normalized({ x: b.x - a.x, y: b.y - a.y });
  const proj = dot(ab, substract(o, a));
  const p = { x: a.x + proj * ab.x, y: a.y + proj * ab.y };
  const n = { x: ab.y, y: -ab.x };
  const h = dot(substract(a, p), substract(o, b)) / (dot(n, substract(o, b)));

  return { x: p.x + h * n.x, y: p.y + h * n.y };
}

export function intersection(line1: Line2D, line2: Line2D): Point2D | null {
  const xdiff = [line1[0].x - line1[1].x, line2[0].x - line2[1].x];
  const ydiff = [line1[0].y - line1[1].y, line2[0].y - line2[1].y];
  const det = (a: number[], b: number[]) => (a[0] * b[1] - a[1] * b[0]);
  const div = det(xdiff, ydiff);

  if (!div) {
    return null;
  }

  const d = [
    det(flatten(line1[0]), flatten(line1[1])),
    det(flatten(line2[0]), flatten(line2[1])),
  ];
  const x = det(d, xdiff) / div;
  const y = det(d, ydiff) / div;

  return { x, y };
}

export function project(point: Point2D, toLine: Line2D): Point2D {
  const l2 = Math.pow(distance(toLine[0], toLine[1]), 2);
  const t = divide(multiply(substract(point, toLine[0]), substract(toLine[1], toLine[0])), { x: l2, y: l2 });

  return sum(toLine[0], multiply(substract(toLine[1], toLine[0]), { x: t.x + t.y, y: t.x + t.y }));
}

export function rel(point: Point2D, { width, height }: Size2D): Point2D {
  return divide(point, p(width, height));
}

export function abs(point: Point2D, { width, height }: Size2D): Point2D {
  return multiply(point, p(width, height));
}

export function ndc(point: Point2D, { width, height }: Size2D): Point2D {
  const aspectRatio = width / height;

  if (aspectRatio <= 1) {
    return p((2 * point.x - 1) * aspectRatio, 1 - 2 * point.y);
  }
  return p(2 * point.x - 1, (1 - 2 * point.y) / aspectRatio);
}
