/* eslint-disable max-len */

const Yrotate = () => (
  <svg
    width="17"
    height="14"
    viewBox="0 0 17 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.17875 3.03823L7.75059 4.46639L9.17875 5.89455C9.27205 5.98785 9.42276 5.98785 9.51605 5.89455C9.60935 5.80125 9.60935 5.65054 9.51605 5.55725L8.66562 4.70681C10.5172 4.71399 12.2504 5.02976 13.5529 5.59672C14.7945 6.13856 15.5086 6.85981 15.5086 7.58107C15.5086 9.13841 12.3365 10.4553 8.58308 10.4553C4.82968 10.4553 1.6576 9.13841 1.6576 7.58107C1.6576 7.4483 1.54994 7.34424 1.41718 7.34424C1.28441 7.34424 1.17676 7.45189 1.17676 7.58107C1.17676 9.46136 4.42779 10.9362 8.5795 10.9362C12.7312 10.9362 15.9822 9.46495 15.9822 7.58107C15.9822 6.6481 15.1856 5.79049 13.7395 5.15894C12.376 4.56687 10.5746 4.23674 8.65844 4.22956L9.51247 3.37554C9.60576 3.28224 9.60576 3.13153 9.51247 3.03823C9.42276 2.94494 9.27205 2.94494 9.17875 3.03823Z"
      fill="#727A89"
      stroke="#727A89"
      strokeWidth="0.5"
    />
    <path
      d="M8.58215 11.112C4.32997 11.112 1 9.56179 1 7.58103C1 7.35138 1.18659 7.16119 1.41983 7.16119C1.65308 7.16119 1.83967 7.34779 1.83967 7.58103C1.83967 9.01637 4.99382 10.2759 8.58574 10.2759C12.1777 10.2759 15.3318 9.01637 15.3318 7.58103C15.3318 6.93513 14.6572 6.27129 13.4838 5.76174C12.3104 5.24861 10.7674 4.94719 9.10964 4.89337L9.6443 5.42803C9.80578 5.5895 9.80578 5.85504 9.6443 6.0201C9.47924 6.18517 9.2137 6.18158 9.05223 6.0201L7.49489 4.46635L9.04864 2.9126C9.20652 2.75472 9.48283 2.75472 9.64072 2.9126C9.80219 3.07408 9.80219 3.33962 9.64072 3.50468L9.08452 4.06087C10.8679 4.1147 12.5329 4.44124 13.8104 4.99743C15.3246 5.65768 16.1607 6.57271 16.1607 7.58462C16.1643 9.56179 12.8343 11.112 8.58215 11.112ZM1.41983 7.52003C1.38754 7.52003 1.35883 7.54515 1.35883 7.58103C1.35883 9.33214 4.5991 10.7531 8.58215 10.7531C12.5652 10.7531 15.8055 9.32855 15.8055 7.58103C15.8055 6.72342 15.0483 5.92322 13.6704 5.32397C12.332 4.74266 10.5522 4.41612 8.65751 4.40894L8.22691 4.40535L9.38594 3.24632C9.40747 3.22479 9.40747 3.18532 9.38594 3.16379C9.35723 3.13508 9.33212 3.13508 9.30341 3.16379L8.00084 4.46635L9.30341 5.76892C9.32853 5.79045 9.36441 5.79045 9.38953 5.76892C9.41106 5.74739 9.41106 5.70792 9.38953 5.68639L8.2305 4.52736H8.66827C10.545 4.53453 12.3033 4.85748 13.6274 5.43162C14.9371 6.00216 15.6907 6.78442 15.6907 7.57744C15.6907 9.26037 12.5042 10.6311 8.58574 10.6311C4.66728 10.6311 1.48084 9.26037 1.48084 7.57744C1.47725 7.54515 1.45213 7.52003 1.41983 7.52003Z"
      fill="#727A89"
      stroke="#727A89"
      strokeWidth="0.5"
    />
    <path
      d="M7.08594 13.4403L7.08594 1.17896H6.55845L6.55845 13.4403H7.08594Z"
      fill="#727A89"
      stroke="#727A89"
      strokeWidth="0.5"
    />
    <path
      d="M7.26523 13.6202H6.37891V1H7.26523V13.6202ZM6.73774 13.2613H6.90639V1.35883H6.73774V13.2613Z"
      fill="#727A89"
      stroke="#727A89"
      strokeWidth="0.5"
    />
  </svg>
);

export default Yrotate;
