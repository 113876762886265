import { makeStyles } from '@material-ui/core';
import FormHelperText from '@material-ui/core/FormHelperText';

const useStyles = makeStyles((theme) => ({
  error: {
    fontSize: '1rem',
    color: theme.palette.error.main,
  },
  warning: {
    fontSize: '1rem',
    color: theme.palette.warning.main,
  },
}));

const HelperText = ({ className, ...props }) => {
  const classes = useStyles();

  return (
    <FormHelperText className={ className ? classes[className] : '' } { ...props } />
  );
};

export default HelperText;
