import { calcSceneDiagonal } from '@shared/utils';
import RangeSlider from '../RangeSlider';

const SpotDiameter = ({
  name,
  value,
  onChange,
  sceneBox,
}) => (
  <RangeSlider
    label="Spot diameter"
    name={ name }
    min={ 0 }
    max={ Math.ceil(calcSceneDiagonal(sceneBox)) }
    value={ value }
    onChange={ onChange }
    displayValues={ false }
    displayThumbValue
  />
);

export default SpotDiameter;
