import { ImageData } from '../types';

function loadImage(url: string): Promise<ImageData> {
  return new Promise((resolve, reject) => {
    const image = new Image();

    image.src = url;
    image.onload = () => {
      resolve({ url, data: image as any, width: image.width, height: image.height });
    };
    image.onerror = reject;
  });
}

export default loadImage;
