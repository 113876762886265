export const LOAD_STYLING = {
  START: 'LOAD_STYLING_START',
  SUCCESS: 'LOAD_STYLING_SUCCESS',
  FAIL: 'LOAD_STYLING_FAIL',
};

export const CREATE_STYLING = 'CREATE_STYLING';
export const UPDATE_STYLING = 'UPDATE_STYLING';

export const loadStyling = () => ({
  type: LOAD_STYLING.START,
});

export const loadStylingSuccess = (styling) => ({
  type: LOAD_STYLING.SUCCESS,
  payload: {
    styling,
  },
});

export const loadStylingFail = (error) => ({
  type: LOAD_STYLING.FAIL,
  payload: {
    error,
  },
});

export function createStyling(styling) {
  return {
    type: CREATE_STYLING,
    styling,
  };
}

export function updateStyling(styling) {
  return {
    type: UPDATE_STYLING,
    styling,
  };
}
