import { unstable_createMuiStrictModeTheme as createTheme } from '@material-ui/core/styles';

const theme = createTheme({
  palette: {
    common: {
      icon: '#9DA3B2',
      divider: '#C2CFE0',
      grey: '#9CACC2',
      darkGrey: '#334D6E',
      white: '#ffffff',
      black: '#192A3E',
      green: '#00C98F',
      lightBlue: '#EBEFF2',
      darkBlue: '#272D4E',
      semiLightGrey: '#6A707E',
      paleBlue: '#90A0B7',
      superPaleBlue: '#d9dce2',
      hawkesBlue: '#D9E3F2',
      red: '#f44336',
    },
    primary: {
      main: '#00C98F',
    },
    secondary: {
      main: '#334d6e',
    },
    text: {
      primary: '#192A3E',
      secondary: '#9CACC2',
    },
  },
  typography: {
    body1: {
      fontSize: '1.3rem',
    },
  },
});

export default theme;
