import { makeStyles } from '@material-ui/core/styles';

import imageSrc from '@images/icons/image.svg';

const useStyles = makeStyles((theme) => ({
  root: {
    fontWeight: 600,
    fontSize: '1.4rem',
    lineHeight: '2.1rem',
    textAlign: 'center',
    letterSpacing: '0.01em',
    color: theme.palette.common.black,

    '&::before': {
      content: "''",
      display: 'block',
      background: `url(${imageSrc}) no-repeat`,
      width: '4.3rem',
      height: '4.3rem',
      backgroundSize: 'contain',
      margin: '0 auto',
      marginBottom: '1.4rem',
    },
  },
}));

const Title = ({ children }) => {
  const classes = useStyles();

  return <h3 className={ classes.root }>{children}</h3>;
};

export default Title;
