import {
  all, call, put, takeEvery,
} from 'redux-saga/effects';
import { stylingApi } from '@api';
import {
  LOAD_STYLING,
  CREATE_STYLING,
  UPDATE_STYLING,
  loadStyling,
  loadStylingSuccess,
  loadStylingFail,
} from './actions';

function* fetchStylingList() {
  try {
    const data = yield call(stylingApi.getStyling);

    if (data.error) {
      yield put(loadStylingFail(data));
    } else {
      yield put(loadStylingSuccess(data));
    }
  } catch (error) {
    yield put(loadStylingFail(error));
  }
}

export function* fetchCreateStyling({ styling }) {
  try {
    yield call(stylingApi.createStyling, styling);

    yield put(loadStyling());
  } catch (error) {
    console.error(error);
  }
}

export function* fetchUpdateStyling({ styling }) {
  try {
    yield call(stylingApi.updateStyling, styling);

    yield put(loadStyling());
  } catch (error) {
    console.error(error);
  }
}

export default function* stylingSaga() {
  yield all([
    takeEvery(LOAD_STYLING.START, fetchStylingList),
    takeEvery(CREATE_STYLING, fetchCreateStyling),
    takeEvery(UPDATE_STYLING, fetchUpdateStyling),
  ]);
}
