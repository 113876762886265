import { forwardRef, useMemo } from 'react';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import MuiTextField from '@material-ui/core/TextField';

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiSvgIcon-root': {
      width: '1.5em',
      height: '1.5em',
      fill: '#C2CFE0',
    },
  },
  input: {
    fontSize: '1.4rem',
    caretColor: theme.palette.primary.main,
    height: theme.spacing(6),
    boxSizing: 'border-box',
  },
  notchedOutline: {
    borderColor: '#E2EAF3',
  },
  error: {
    fontSize: '1.2rem',
  },
  formControl: {
    fontSize: '1.4rem',
    color: '#C2CFE0',
  },
}));

const TextField = forwardRef(
  (
    {
      className,
      InputLabelProps: inputLabelProps,
      InputProps: inputProps,
      inputProps: RootProps,
      min,
      max,
      step,
      onValueChange = () => {},
      ...rest
    },
    ref,
  ) => {
    const { formControl, ...inputClasses } = useStyles();

    const InputProps = useMemo(() => {
      const classes = {
        ...(inputProps?.classes ? inputProps.classes : {}),
        ...inputClasses,
      };

      return {
        ...inputProps,
        classes: Object.keys(classes).reduce((acc, key) => {
          acc[key] = classNames(inputProps?.classes?.[key], inputClasses[key]);
          return acc;
        }, {}),
      };
    }, [inputClasses, inputProps]);

    const InputLabelProps = useMemo(
      () => ({
        ...inputLabelProps,
        classes: {
          ...(inputLabelProps?.classes ? inputLabelProps.classes : {}),
          formControl,
        },
        shrink: true,
      }),
      [formControl, inputLabelProps],
    );

    const rootProps = useMemo(
      () => ({
        ...RootProps,
        min,
        max,
        step,
      }),
      [RootProps, max, min, step],
    );

    return (
      <MuiTextField
        ref={ ref }
        className={ className }
        color="primary"
        variant="outlined"
        InputLabelProps={ InputLabelProps }
        InputProps={ InputProps }
        // eslint-disable-next-line react/jsx-no-duplicate-props
        inputProps={ rootProps }
        { ...rest }
        onChange={ (...data) => {
          if (rest.onChange) {
            rest.onChange(...data);
          }
          onValueChange(...data);
        } }
      />
    );
  },
);

export default TextField;
