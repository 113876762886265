import { Route, Routes } from 'react-router-dom';

import Home from '@pages/home';
import Login from '@pages/login';
import Register from '@pages/register';

import { ModelsList } from './Models';
import { BackgroundsList } from './Backgrounds';
import { SceneEdit, SceneNew, ScenesList } from './Scenes';
import Companies from './Companies';
import Users from './Users';
import ProductsList from './Products';
import StudioPage, { StudiosList } from './Studio';

import { ROUTES } from './routesUrls';
import { MaterialsList } from './Materials';
import AttributeDefinitions from './AttributeDefinitions';
import StylingRoute from '@app/routes/StylingRoute';
import ProfilePage from '@app/routes/Profile';

const AppRoutes = () => (
  <Routes>
    <Route path={ ROUTES.HOME } exact element={ <Home /> } />
    <Route path={ ROUTES.PROFILE } exact element={ <ProfilePage /> } />
    <Route path={ ROUTES.LOGIN } exact element={ <Login /> } />
    <Route path={ ROUTES.REGISTER } exact element={ <Register /> } />
    <Route path={ ROUTES.INTERIORS } exact element={ <BackgroundsList /> } />
    <Route path={ ROUTES.MODELS } exact element={ <ModelsList /> } />
    <Route path={ ROUTES.SCENES.ROOT } exact element={ <ScenesList /> } />
    <Route path={ `${ROUTES.SCENES.ROOT}${ROUTES.SCENES.NEW}` } exact element={ <SceneNew /> } />
    <Route path={ `${ROUTES.SCENES.ROOT}${ROUTES.SCENES.EDIT()}` } exact element={ <SceneEdit /> } />
    <Route path={ ROUTES.COMPANIES } exact element={ <Companies /> } />
    <Route path={ ROUTES.USERS } exact element={ <Users /> } />
    <Route path={ ROUTES.PRODUCTS.ROOT } exact element={ <ProductsList /> } />
    <Route path={ ROUTES.MATERIALS } exact element={ <MaterialsList /> } />
    <Route path={ `${ROUTES.STUDIO.ROOT}` } exact element={ <StudiosList /> } />
    <Route path={ `${ROUTES.STUDIO.ROOT}${ROUTES.STUDIO.NEW}` } exact element={ <StudioPage /> } />
    <Route path={ `${ROUTES.STUDIO.ROOT}${ROUTES.STUDIO.EDIT()}` } exact element={ <StudioPage /> } />
    <Route path={ ROUTES.ATTRIBUTE_DEFINITIONS } exact element={ <AttributeDefinitions /> } />
    <Route path={ ROUTES.STYLING } exact element={ <StylingRoute /> } />
  </Routes>
);

export default AppRoutes;
