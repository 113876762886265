/* eslint-disable max-len */

const Xrotate = () => (
  <svg
    width="14"
    height="17"
    viewBox="0 0 14 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.03828 7.98659L4.46644 9.41475L5.8946 7.98659C5.98789 7.89329 5.98789 7.74259 5.8946 7.64929C5.8013 7.55599 5.65059 7.55599 5.55729 7.64929L4.71044 8.49972C4.71762 6.64814 5.0334 4.91498 5.60035 3.61241C6.14219 2.37085 6.86345 1.65677 7.5847 1.65677C9.14204 1.65677 10.459 4.82886 10.459 8.58226C10.459 12.3357 9.14204 15.5077 7.5847 15.5077C7.45193 15.5077 7.34787 15.6154 7.34787 15.7482C7.34787 15.8809 7.45552 15.9886 7.5847 15.9886C9.46499 15.9886 10.9398 12.7375 10.9398 8.58584C10.9398 4.43414 9.46858 1.18311 7.5847 1.18311C6.65173 1.18311 5.79412 1.97972 5.16257 3.42582C4.5705 4.78938 4.24037 6.59073 4.2332 8.5069L3.37917 7.65288C3.28587 7.55958 3.13517 7.55958 3.04187 7.65288C2.94498 7.74259 2.94498 7.89329 3.03828 7.98659Z"
      fill="#727A89"
      stroke="#727A89"
      strokeWidth="0.5"
    />
    <path
      d="M7.58081 16.1643C7.35115 16.1643 7.16097 15.9777 7.16097 15.7445C7.16097 15.5112 7.34757 15.3246 7.58081 15.3246C9.01614 15.3246 10.2756 12.1705 10.2756 8.57857C10.2756 4.98664 9.01614 1.83249 7.58081 1.83249C6.93491 1.83249 6.27106 2.5071 5.76152 3.68049C5.25198 4.85387 4.94697 6.39327 4.89314 8.05467L5.42781 7.52001C5.50675 7.44106 5.61081 7.398 5.72205 7.398C5.83329 7.398 5.93735 7.44106 6.01629 7.52001C6.17777 7.68148 6.17777 7.94702 6.01629 8.11208L4.46254 9.66942L2.90521 8.11208C2.82626 8.03314 2.7832 7.92908 2.7832 7.81784C2.7832 7.7066 2.82626 7.60254 2.90521 7.5236C2.98415 7.44465 3.08821 7.40159 3.19945 7.40159C3.31069 7.40159 3.41475 7.44465 3.49369 7.5236L4.04989 8.07979C4.10371 6.29638 4.43025 4.6314 4.98644 3.35036C5.65387 1.83608 6.57248 1 7.58081 1C9.56157 1 11.1153 4.32998 11.1153 8.58216C11.1153 12.8343 9.56157 16.1643 7.58081 16.1643ZM7.58081 1.47725C9.26374 1.47725 10.6345 4.66369 10.6345 8.58216C10.6345 12.5006 9.26374 15.6871 7.58081 15.6871C7.54851 15.6871 7.51981 15.7122 7.51981 15.7481C7.51981 15.7804 7.54492 15.8091 7.58081 15.8091C9.33192 15.8091 10.7565 12.5688 10.7565 8.58574C10.7565 4.60269 9.33192 1.35883 7.58081 1.35883C6.7232 1.35883 5.923 2.11597 5.32375 3.49389C4.74244 4.83234 4.41948 6.61216 4.40872 8.5068L4.40513 8.9374L3.2461 7.77837C3.23175 7.76401 3.2138 7.76043 3.20304 7.76043C3.19227 7.76043 3.17433 7.76401 3.15998 7.77837C3.14562 7.79272 3.14204 7.81066 3.14204 7.82143C3.14204 7.83219 3.14562 7.84655 3.15998 7.86449L4.46254 9.16705L5.76511 7.86449C5.78664 7.84296 5.78664 7.80349 5.76511 7.78196C5.75076 7.7676 5.73281 7.76401 5.72205 7.76401C5.72564 7.75684 5.69693 7.7676 5.67899 7.78196L4.51996 8.94099L4.52355 8.5068C4.53072 6.6301 4.85367 4.87182 5.42781 3.54772C6.00553 2.2308 6.78779 1.47725 7.58081 1.47725Z"
      fill="#727A89"
      stroke="#727A89"
      strokeWidth="0.5"
    />
    <path
      d="M13.441 10.0786H1.17969V10.6061H13.441V10.0786Z"
      fill="#727A89"
      stroke="#727A89"
      strokeWidth="0.5"
    />
    <path
      d="M13.6202 10.7855H1V9.89917H13.6202V10.7855ZM1.35883 10.4267H13.2613V10.258H1.35883V10.4267Z"
      fill="#727A89"
      stroke="#727A89"
      strokeWidth="0.5"
    />
  </svg>
);

export default Xrotate;
