const ENDPOINT = '/auth';

class AuthApi {
  constructor(http) {
    this.http = http;
  }

  login = (credentials) => this.http.post(`${ENDPOINT}/login`, credentials)

  refreshToken = () => this.http.post(`${ENDPOINT}/refresh`)
}

export default AuthApi;
