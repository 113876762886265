import classNames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';

const Size = {
  Large: 'large',
  Medium: 'medium',
  Small: 'small',
};

const useStyles = makeStyles(() => ({
  '@keyframes spinner-border': {
    to: {
      transform: 'rotate(1turn)',
    },
  },
  root: {
    display: 'inline-block',
    verticalAlign: '-0.125em',
    border: '0.25em solid',
    borderRight: '0.25em solid transparent',
    borderRadius: '50%',
    animation: '$spinner-border .75s linear infinite',
  },
  [Size.Large]: {
    width: '3rem',
    height: '3rem',
  },
  [Size.Medium]: {
    width: '2rem',
    height: '2rem',
  },
  [Size.Small]: {
    width: '1rem',
    height: '1rem',
  },
}));

const CircularProgress = ({ className, size = Size.Medium }) => {
  const classes = useStyles();

  return <div className={ classNames(className, classes.root, classes[size]) } />;
};

export default CircularProgress;
