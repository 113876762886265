import { ReactNode } from 'react';
import { isNone } from './utils';

export interface ImageData {
    url: string | null,
    data: Buffer | null
    width: number | null,
    height: number | null,
}

export interface Step {
    id: string
    label: string
    isCompleted: boolean
    component(roomHeight: number, refLength: number): ReactNode
    prepare(): any
}

export class AbstractStep implements Step {
    id = `${Math.floor(Math.random() * 100000)}`

    get label(): string {
      return '';
    }

    get isCompleted(): boolean {
      return false;
    }

    component(roomHeight: number, refLength: number): ReactNode {
      return null;
    }

    prepare() {}

    cancel() {}
}

export interface SolverStepInput {}

export interface SolverStepResult {}

export class SolverStep<I extends SolverStepInput, R extends SolverStepResult> extends AbstractStep {
    public solver: Solver<any>

    private onPrepare: () => I

    private onComplete: (result: R) => void

    private onCancel: () => void

    input?: I

    result?: R

    get isComplete(): boolean {
      return this.result != null;
    }

    constructor(from: Solver<any>, onPrepare: () => I, onComplete: (result: R) => void, onCancel: () => void) {
      super();
      this.solver = from;
      this.onPrepare = onPrepare;
      this.onComplete = onComplete;
      this.onCancel = onCancel;
    }

    prepare(): void {
      this.input = this.onPrepare.call(this.solver);
    }

    complete(result: R) {
      this.result = result;
      this.onComplete.call(this.solver, result);
    }

    cancel() {
      this.onCancel.call(this.solver);
    }
}

export interface SolverResult {
    viewport: ZKViewport
    floorPoints: ZKPosition[]
    type: number;
    vp1Line: [Line2D, Line2D] | undefined;
    vp2Line: [Line2D, Line2D] | undefined;
    principalPoint: Point2D | undefined;
    vanishingPoints: [Point2D, Point2D, Point2D] | undefined;
    referenceAxis: Axis | undefined;
    referencePoints: [Point2D, Point2D] | undefined;
    rl: Line2D | undefined;
    points: Point2D[] | undefined;
}

export interface SolverOptions<R extends SolverResult> {
    image: ImageData | undefined
    layout?: any;
    onForward?: () => void
    onBackward?: () => void
    onComplete?: (result: R | null, error: Error | null) => void

    onCancel?: () => void
}

export class Solver<R extends SolverResult> {
    readonly image: ImageData

    private index: number = 0

    private onForward?: () => void

    private onBackward?: () => void

    private onComplete?: (result: R | null, error: Error | null) => void

    private onCancel?: () => void;

    steps: Array<SolverStep<any, any>> = []

    get activeStep(): SolverStep<any, any> | null {
      const { index, steps } = this;
      return (steps?.length && index < steps.length) ? steps[index] : null;
    }

    constructor({ image, onBackward, onForward, onComplete, onCancel }: SolverOptions<R>) {
      this.image = image!;
      this.onBackward = onBackward;
      this.onForward = onForward;
      this.onComplete = onComplete;
      this.onCancel = onCancel;
    }

    prepare() {
      const step = this.activeStep;

      if (step) {
        step.prepare();
      }
    }

    forward() {
      const index = this.index + 1;
      const step = index < this.steps.length ? this.steps[index] : null;

      if (!step) {
        this.complete();
        return;
      }

      step.prepare();

      this.index = index;
      this.onForward?.();
    }

    backward() {
      const index = this.index - 1;
      const step = index > -1 ? this.steps[index] : null;

      if (!step) {
        this.onCancel?.();
        return;
      }

      this.index = index;
      this.onBackward?.();
    }

    solve(): R | null {
      return null;
    }

    private complete() {
      try {
        const result = this.solve();

        this.onComplete?.(result, isNone(result) ? new Error('Empty result') : null);
      } catch (error: any) {
        this.onComplete?.(null, error);
      }
    }
}

export interface SolverHolder<R extends SolverResult> {
    id: number
    label: string
    instance: (options: SolverOptions<R>) => Solver<R>
}

export interface Point2D {
    x: number
    y: number
}

export interface Point3D {
    x: number
    y: number
    z: number
}

export type Vector3D = Point3D

export interface Size2D {
    width: number
    height: number
}

export type Line2D = [Point2D, Point2D]

export type Line3D = [Point3D, Point3D]

export type Plane3D = [Point3D, Point3D, Point3D]

export enum Axis {
    X, Y, Z
}

export type Matrix = number[][]

export interface ZKPosition {
    x: number
    y: number
    z: number
}

export interface ZKEulerAngles {
    roll: number
    yaw: number
    pitch: number
}

export interface ZKFieldOfView {
    x: number
    y: number
}

export interface ZKLightEstimate {
    intensity: number
    colorTemperature: number
}

export interface ZKViewport {
    position: ZKPosition
    eulerAngles: ZKEulerAngles
    fieldOfView: ZKFieldOfView
    lightEstimate?: ZKLightEstimate
}
