import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  root: {
    fontWeight: 500,
    fontSize: '1.5rem',
    lineHeight: '2.2rem',
    letterSpacing: '0.01em',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
}));

const CardTitle = ({ children }) => {
  const classes = useStyles();

  return <h3 className={ classes.root } title={ children }>{children}</h3>;
};

export default CardTitle;
