import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import EditIcon from '@resources/icons/EditIcon';
import CopyIcon from '@resources/icons/CopyIcon';
import DeleteIcon from '@resources/icons/DeleteIcon';

import CardControls from '@shared/components/card-controls';
import Control from '@shared/components/card-controls/Control';
import AlertDialog from '@shared/components/card-controls/AlertDialog';
import WarningDialog from '@shared/components/card-controls/WarningDialog';
import useControls from '@shared/components/card-controls/useControls';
import useAlertControl, { Types } from '@shared/components/card-controls/useAlertControl';
import useWarningControl from '@shared/components/card-controls/useWarningControl';
import DialogsContainer from '@shared/components/card-controls/DialogsContainer';
import OpenedEye from '@resources/icons/OpenedEye';
import ClosedEye from '@resources/icons/ClosedEye';
import IconToggler from '@shared/components/icon-toggler';
import ShareIcon from '@resources/icons/ShareIcon';
import { selectCurrentCompany } from '@store/app/selectors';

const Controls = ({
  apiError,
  onEdit,
  onCopy,
  resetScenes,
  onDelete,
  onHide,
  onShare,
  onApiErrorClear,
  item,
}) => {
  const company = useSelector(selectCurrentCompany);
  const {
    open,
    handleMoreIconClick,
    handleClickOutside,
    handleControlClick,
  } = useControls();

  const [alertOpen, {
    handleAlertControlClick,
    handleAgree,
    handleDisagree,
    ariaAction,
    title,
  }] = useAlertControl();

  const [warningOpen, {
    handleWarningControlClick,
    handleWarningClose,
  }] = useWarningControl();

  const [deleteProcessing, setDeleteProcessing] = useState(false);
  const [warningTitle, setWarningTitle] = useState('');

  useEffect(() => {
    if (deleteProcessing) {
      handleAlertControlClick(Types.Delete, onDelete)();
      resetScenes();
      setDeleteProcessing(false);
    }
  }, [
    deleteProcessing,
    warningTitle,
    handleWarningControlClick,
    resetScenes,
    handleAlertControlClick,
    onDelete,
  ]);

  useEffect(() => {
    if (apiError && apiError.type === 'DELETE' && apiError.id === item.id) {
      handleWarningControlClick();
      const titles = apiError.data.message.map((message) => {
        if (message.reason === 'used') {
          const interiors = message.usedIn.map((p) => p.name).join(', ');
          return `Action cannot be performed because given background is used in the following interiors: [${interiors}]`;
        }
      });
      setWarningTitle(titles.join('\n'));
    }
    if (warningOpen && !apiError) {
      handleWarningClose();
    }
  }, [
    warningOpen,
    handleWarningControlClick,
    apiError,
  ]);

  const handleDeleteClick = (event) => {
    event.stopPropagation();
    setDeleteProcessing(true);
  };

  return (
    <>
      <CardControls
        open={ open }
        onMoreIconClick={ handleMoreIconClick }
        onClickOutside={ handleClickOutside }
      >
        {company?.default && !item.hidden && (
          <Control onClick={ handleControlClick(onShare) }>
            <ShareIcon size="small" />
          </Control>
        )}
        {item.defaultContent
          ? (
            <>
              {
                  !company.default && (
                  <Control onClick={ handleControlClick(onHide) }>
                    <IconToggler
                      activeIcon={ ClosedEye }
                      inactiveIcon={ OpenedEye }
                      isActive={ item.hidden }
                    />
                  </Control>
                  )
              }
              {company.default && (
                <>
                  <Control onClick={ handleControlClick(onEdit) }>
                    <EditIcon />
                  </Control>
                  <Control onClick={ handleControlClick(onCopy) }>
                    <CopyIcon />
                  </Control>
                  <Control onClick={ handleDeleteClick }>
                    <DeleteIcon />
                  </Control>
                </>
              )}
            </>
          ) : (
            <>
              <Control onClick={ handleControlClick(onHide) }>
                <IconToggler
                  activeIcon={ ClosedEye }
                  inactiveIcon={ OpenedEye }
                  isActive={ item.hidden }
                />
              </Control>
              <Control onClick={ handleControlClick(onEdit) }>
                <EditIcon />
              </Control>
              <Control onClick={ handleControlClick(onCopy) }>
                <CopyIcon />
              </Control>
              <Control onClick={ handleDeleteClick }>
                <DeleteIcon />
              </Control>
            </>
          )}
      </CardControls>
      <DialogsContainer>
        <AlertDialog
          open={ alertOpen }
          ariaAction={ ariaAction }
          title={ title }
          onAgree={ handleControlClick(handleAgree) }
          onDisagree={ handleDisagree }
        />
        <WarningDialog
          open={ warningOpen }
          title={ warningTitle }
          onClose={ onApiErrorClear }
        />
      </DialogsContainer>
    </>
  );
};

export default Controls;
