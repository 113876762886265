import { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import ModelsList from './ModelsList';
import Dialog from '../ListDialog/Dialog';
import DialogSearchInput from '../ListDialog/DialogSearchInput';
import DialogContent from '../ListDialog/DialogContent';
import DialogActions from '../ListDialog/DialogActions';
import CloseButton from '../ListDialog/CloseButton';
import SubmitButton from '../ListDialog/SubmitButton';
import useSelectItem from '../ListDialog/useSelectItem';
import { selectProducts } from '@store/products/selectors';
import TopNav from '@shared/components/top-nav/Nav';
import filterData from '@app/filter/filterData';
import Button from '@shared/components/button';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  backButton: {
    maxWidth: '140px',
  },
}));

const ModelsListDialog = ({
  open,
  onSubmit,
  onClose,
}) => {
  const classes = useStyles();

  const models = useSelector(selectProducts);

  const [model, setModel] = useState(null);
  const [showVariantsFor, setShowVariantsFor] = useState(null);
  const [selectedVariant, setSelectedVariant] = useState(null);
  const [shouldScrollToTop, setShouldScrollToTop] = useState(false);

  const [search, setSearch] = useState('');

  const handleCloseClick = () => {
    onClose();
    setModel(null);
    setSelectedVariant(null);
    setShowVariantsFor(null);
  };

  const handleChooseClick = () => {
    onSubmit(showVariantsFor ? selectedVariant : model);
    setModel(null);
    setSelectedVariant(null);
    setShowVariantsFor(null);
  };

  const handleSearchChange = ({ target }) => {
    setSearch(target.value);
  };

  const [contentRef, handleSelectModel] = useSelectItem(setModel);

  const handleItemClick = useCallback((...args) => {
    if (showVariantsFor) {
      setSelectedVariant(args[0]);
    } else {
      handleSelectModel(...args);
    }
  }, [showVariantsFor, handleSelectModel]);


  const scrollToTop = () => {
    setShouldScrollToTop(true);
  };

  useEffect(() => {
    if (shouldScrollToTop && contentRef.current) {
      contentRef.current.scrollTop = 0;
      setShouldScrollToTop(false);
    }
  }, [shouldScrollToTop, contentRef]);

  return (
    <Dialog
      onClose={ handleCloseClick }
      open={ open }
    >
      <TopNav>
        <DialogSearchInput
          value={ search }
          onChange={ handleSearchChange }
        />
      </TopNav>
      <DialogContent ref={ contentRef }>
        <ModelsList
          models={ showVariantsFor ? filterData(showVariantsFor.childProducts, search) : filterData(models.items, search) }
          selectedModel={ showVariantsFor ? selectedVariant : model }
          filter={ showVariantsFor && (
            <Button
              className={ classes.backButton }
              onClick={ setShowVariantsFor.bind(this, null) }
            >
              Back
            </Button>
          ) }
          onItemClick={ handleItemClick }
          onShowVariantsClick={ setShowVariantsFor }
          scrollToTop={ scrollToTop }
          showVariantsFor={ showVariantsFor }
        />
      </DialogContent>
      <DialogActions>
        <CloseButton onClick={ handleCloseClick }>
          Cancel
        </CloseButton>
        <SubmitButton disabled={ !model } onClick={ handleChooseClick }>
          Choose
        </SubmitButton>
      </DialogActions>
    </Dialog>
  );
};

export default ModelsListDialog;
