import { useSelector } from 'react-redux';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import Typography from '@material-ui/core/Typography';
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';
import { makeStyles } from '@material-ui/core/styles';
import Checkbox from '@shared/components/checkbox';
import { selectAttributeDefinitions } from '@store/attribute-definitions/selectors';
import { useCallback } from 'react';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.common.white,
    padding: 0,
    overflowY: 'auto',
    height: '91vh',
  },
  attributeLabel: {
    fontWeight: 700,
    fontSize: '14px',
    lineHeight: '21px',
    padding: '8px 0',
  },
  label: {
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '21px',
    padding: '8px 0',
  },
}));

const AttributesFilter = ({ value, onChange }) => {
  const classes = useStyles();

  const attributeDefinitions = useSelector(selectAttributeDefinitions);

  const handleChange = useCallback((attributeId, checked) => {
    let updatedValue;
    if (checked) {
      updatedValue = [...value, attributeId];
    } else {
      updatedValue = value.filter((attrId) => attrId != attributeId);
    }
    onChange(updatedValue);
  }, [onChange]);

  return (
    <div className={ classes.root }>
      {
        attributeDefinitions.map((attributeDefinition) => (
          <div
            key={ attributeDefinition.id }
          >
            <Accordion>
              <AccordionSummary
                expandIcon={ <ArrowDropDown /> }
              >
                <Typography className={ classes.attributeLabel }>{ attributeDefinition.name }</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <FormGroup>
                  <>
                    { attributeDefinition.attributes.sort((a, b) => a.value.localeCompare(b.value)).map((attribute) => (
                      <FormControlLabel
                        key={ attribute.id }
                        label={ <Typography className={ classes.label }>{ attribute.value }</Typography> }
                        control={ (
                          <Checkbox
                            value={ attribute }
                            onChange={ (_, checked) => handleChange(attribute.id, checked) }
                          />
                        ) }
                      />
                    )) }
                  </>
                </FormGroup>
              </AccordionDetails>
            </Accordion>
          </div>
        ))
      }
    </div>
  );
};

export default AttributesFilter;
