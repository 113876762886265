import { forwardRef, memo, useCallback, useEffect, useMemo } from 'react';

import Autocomplete from '@shared/components/autocomplete/Autocomplete';
import { TextField } from '@shared/views/form-dialog';

const Input = (props) => (
  <TextField
    { ...props }
    id="type"
    label="Currency"
    fullWidth
    required
    variant="outlined"
  />
);

const optionLabels = {
  EUR: 'EUR',
  USD: 'USD',
  GBP: 'GBP',
  CHF: 'CHF',
  DKK: 'DKK',
  PLN: 'PLN',
  SEK: 'SEK',
};

const DEFAULT_CURRENCY = 'USD';

const CurrencyTextField = forwardRef(({
  onChange,
  value,
  ...props
}, ref) => {
  const options = useMemo(() => (['EUR', 'USD', 'GBP', 'CHF', 'DKK', 'PLN', 'SEK']), []);

  const handleChange = useCallback((_, data) => {
    const option = options.find((op) => op === data);
    if (option) {
      onChange(option);
      return;
    }
    if (value === data) {
      return;
    }
    onChange(DEFAULT_CURRENCY);
  }, [onChange, options, value]);

  useEffect(() => {
    if (!value) {
      onChange(DEFAULT_CURRENCY);
    }
  }, [onChange, value]);

  return (
    <Autocomplete
      { ...props }
      disableClearable
      options={ options }
      onChange={ handleChange }
      ref={ ref }
      value={ value || DEFAULT_CURRENCY }
      disablePortal={ false }
      renderInput={ Input }
      getOptionLabel={ (o) => optionLabels[o] || '' }
    />
  );
});

export default memo(CurrencyTextField);
