import { Controller, useFormContext } from 'react-hook-form';
import {
  TextField,
  Fields,
  Field,
} from '@shared/views/form-dialog';
import { Fieldset } from './fieldset';

const FormFieldset = () => {
  const {
    control,
    formState: { errors: fieldsErrors },
  } = useFormContext();

  return (
    <Fields>
      <Field>
        <Controller
          name={ Fieldset.Name }
          control={ control }
          rules={ {
            required: 'Required',
          } }
          render={ ({ field }) => (
            <TextField
              { ...field }
              helperText={ fieldsErrors[Fieldset.Name]?.message }
              label="Name"
              required
              error={ !!fieldsErrors[Fieldset.Name] }
            />
          ) }
        />
      </Field>
    </Fields>
  );
};

export default FormFieldset;
