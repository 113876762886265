import { useCallback, useRef } from 'react';
import CreateVariantForm from './VariantForm';
import VariantFormFieldset from '../VariantFormFieldset';
import ProductFormDialog from '../ProductFormDialog';
import { useDispatch } from 'react-redux';
import { resetProductApiState } from '@store/products/actions';

const VariantFormDialog = ({
  open,
  data,
  onClose,
}) => {
  const dispatch = useDispatch();
  const beforeSubmitCallback = useRef();

  const handleSubmit = useCallback(async (formData, close = true) => {
    if (beforeSubmitCallback.current && formData) {
      await beforeSubmitCallback.current(formData);
    }
    if (close) {
      dispatch(resetProductApiState());
      onClose();
    }
  }, [beforeSubmitCallback, onClose]);

  const handleCancel = useCallback(() => {
    dispatch(resetProductApiState());
    onClose();
  }, [onClose]);

  const registerBeforeSubmit = useCallback((callback) => {
    beforeSubmitCallback.current = callback;
  }, [beforeSubmitCallback]);

  return (
    <ProductFormDialog
      open={ open }
      Form={ CreateVariantForm }
      FormProps={ {
        open,
        data,
        onSubmit: handleSubmit,
      } }
      title="Add New Variation"
      content={ (
        <VariantFormFieldset
          registerBeforeSubmit={ registerBeforeSubmit }
        />
      ) }
      onCancel={ handleCancel }
      onClose={ onClose }
    />
  );
};

export default VariantFormDialog;
