import { memo } from 'react';
import { SvgIcon } from '@zolak/zolak-viewer';

const ChevronDownIcon = (props) => (
  <SvgIcon { ...props } viewBox="0 0 10 6">
    {/* eslint-disable-next-line max-len */}
    <path d="M5.00081 5.99977L0.757812 1.75677L2.17281 0.342773L5.00081 3.17177L7.82881 0.342773L9.24381 1.75677L5.00081 5.99977Z" fill="currentColor" />
  </SvgIcon>
);

export default memo(ChevronDownIcon);
