import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import TranslationsDropdown from '@shared/components/translations/TranslationsDropdown';


const useStyles = makeStyles((theme) => ({
  root: {
    margin: 0,
    padding: 15,
    borderWidth: 1,
    borderRadius: 4,
    borderColor: '#E2EAF3',
  },
}));

const TranslatedFieldsGroup = ({ children, onLangChange, selectedLanguage }) => {
  const classes = useStyles();

  return (
    <Grid item xs={ 12 }>
      <Box
        className={ classes.root }
        component="fieldset"
      >
        <legend>
          <TranslationsDropdown
            onLangChange={ onLangChange }
            selectedLanguage={ selectedLanguage }
          />
        </legend>
        <Grid container spacing={ 2 }>
          {
            Array.isArray(children)
              ? children.map((child) => child)
              : children
          }
        </Grid>
      </Box>
    </Grid>
  );
};

export default TranslatedFieldsGroup;
