import classNames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    '&:disabled': {
      '&:hover': {
        opacity: 'initial',
      },
    },
  },
  span: {
    '&&': {
      fontSize: 'inherit',
      fontWeight: 'inherit',
      lineHeight: 'inherit',
      letterSpacing: 'inherit',
      color: 'inherit',
      marginLeft: 0,
    },
  },
  startAdornment: {
    marginRight: theme.spacing(0.5),
    display: 'flex',
  },
  endAdornment: {
    marginLeft: theme.spacing(0.5),
    display: 'flex',
  },
}));

const Color = {
  Primary: 'primary',
  Secondary: 'secondary',
};

const Variant = {
  Contained: 'contained',
  Outlined: 'outlined',
};

const Button = ({
  children,
  className = '',
  color = Color.Primary,
  variant = Variant.Contained,
  disabled = false,
  type = 'button',
  leftPlace = false,
  rightPlace = false,
  onClick,
  onMouseDown = () => {},
}) => {
  const classes = useStyles();

  return (
    <button
      className={ classNames('button', classes.root, className, {
        button__green: color === Color.Primary,
        button__grey_ghost:
          color === Color.Secondary && variant === Variant.Outlined,
      }) }
      disabled={ disabled }
      type={ type }
      onClick={ onClick }
      onMouseDown={ onMouseDown }
    >
      {leftPlace && (
        <span className={ classNames(classes.span, classes.startAdornment) }>
          {leftPlace}
        </span>
      )}
      <span className={ classes.span }>{children}</span>
      {rightPlace && (
        <span className={ classNames(classes.span, classes.endAdornment) }>
          {rightPlace}
        </span>
      )}
    </button>
  );
};

export default Button;
