import { useForm, FormProvider } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { joiResolver } from '@hookform/resolvers/joi';
import joi from 'joi';
import { Form } from '@shared/views/form-dialog';
import { createAttributeDefinition, editAttributeDefinition } from '@store/attribute-definitions/actions';
import ActionType from './ActionType';

const schema = joi.object({
  name: joi.string().required(),
  attributes: joi
    .array()
    .items(
      joi
        .object({
          value: joi.string().required(),
        })
        .unknown(true),
    )
    .unique((a, b) => a.value === b.value),
  entityTypes: joi
    .array()
    .items(
      joi.string().required(),
    )
    .unique((a, b) => a === b),
  translatedFields: joi
    .object(),
});

const correctMessage = (message) => {
  const regexp = /"\w+\[(\d)\][.\w]*"([a-zA-Z\s]+)/;
  return message.replace(regexp, (_, p1, p2) => {
    const index = parseInt(p1, 10) + 1;
    return `"Value ${index}"${p2}`;
  });
};

const AttributeDefinitionForm = ({
  children,
  className,
  open,
  type,
  data,
  onSubmit,
}) => {
  const dispatch = useDispatch();

  const form = useForm({
    mode: 'all',
    defaultValues: {
      name: data?.name,
      entityTypes: data?.entityTypes.map((entityType) => entityType),
      attributes: data?.attributes,
      translatedFields: data?.translatedFields ?? {},
    },
    resolver: async (data, context, options) => {
      const result = await joiResolver(schema)(data, context, options);
      if (result.errors.attributes) {
        result.errors.attributes = result.errors.attributes.map((attribute) => {
          const value = attribute.value ? attribute.value : attribute;
          return { value: { ...value, message: correctMessage(value.message) } };
        });
      }
      return result;
    },
  });

  const handleSubmit = async (newData) => {
    onSubmit();
    if (type === ActionType.CREATE) dispatch(createAttributeDefinition(newData));
    if (type === ActionType.UPDATE) dispatch(editAttributeDefinition({ ...newData, id: data.id }));
  };

  return (
    <>
      <Form
        className={ className }
        open={ open }
        onSubmit={ form.handleSubmit(handleSubmit) }
      >
        <FormProvider { ...form }>
          {children}
        </FormProvider>
      </Form>
    </>
  );
};

export default AttributeDefinitionForm;
