/* eslint-disable max-len */

import SvgIcon from '@material-ui/core/SvgIcon';

const DeleteIcon = ({ fontSize = 'inherit' }) => (
  <SvgIcon fontSize={ fontSize } viewBox="0 0 14 14">
    <path
      d="M7 14C3.1339 14 0 10.8661 0 7C0 3.1339 3.1339 0 7 0C10.8661 0 14 3.1339 14 7C14 10.8661 10.8661 14 7 14ZM6.3 6.3V10.5H7.7V6.3H6.3ZM6.3 3.5V4.9H7.7V3.5H6.3Z"
      fill="#8B95A2"
    />
  </SvgIcon>
);

export default DeleteIcon;
