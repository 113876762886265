import Grid from '@material-ui/core/Grid';

const CardItem = ({
  children, xl = 2, lg = 3, md = 4, xs = 6,
}) => (
  <Grid item xl={ xl } lg={ lg } md={ md } xs={ xs }>
    {children}
  </Grid>
);

export default CardItem;
