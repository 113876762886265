import { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import ChevronRight from '@material-ui/icons/ChevronRight';
import classNames from 'classnames';
import Stack from '../stack';
import Typography from '../typography';

const useStyles = makeStyles((theme) => ({
  item: {
    fontSize: '1.6rem',
    color: theme.palette.common.grey,
  },
  chevron: {
    fill: theme.palette.common.grey,
  },
  selected: {
    fontWeight: 700,
    textDecoration: 'underline',
    color: theme.palette.common.darkGrey,
  },
}));

const Breadcrumbs = ({ items }) => {
  const classes = useStyles();
  return (
    <Stack direction="horizontal" gap={ 1 }>
      {items.map(({ link, name }, i) => {
        const isSelected = i === items.length - 1;
        const textClassName = classNames(classes.item, { [classes.selected]: isSelected });
        return (
          <Fragment key={ `${link}_${name}` }>
            {link
              ? (
                <Link to={ link }>
                  <Typography className={ textClassName } variant="h5">
                    {name}
                  </Typography>
                </Link>
              ) : (
                <Typography className={ textClassName } variant="h5">
                  {name}
                </Typography>
              )}
            {!isSelected && (
              <ChevronRight className={ classes.chevron } />
            )}
          </Fragment>
        );
      })}
    </Stack>
  );
};

export default Breadcrumbs;
