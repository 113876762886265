import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import EditIcon from '@resources/icons/EditIcon';
import CopyIcon from '@resources/icons/CopyIcon';
import DeleteIcon from '@resources/icons/DeleteIcon';

import CardControls from '@shared/components/card-controls';
import Control from '@shared/components/card-controls/Control';
import AlertDialog from '@shared/components/card-controls/AlertDialog';
import WarningDialog from '@shared/components/card-controls/WarningDialog';
import useControls from '@shared/components/card-controls/useControls';
import useAlertControl, { Types } from '@shared/components/card-controls/useAlertControl';
import useWarningControl from '@shared/components/card-controls/useWarningControl';
import DialogsContainer from '@shared/components/card-controls/DialogsContainer';
import { selectCurrentCompany } from '@store/app/selectors';
import ShareIcon from '@resources/icons/ShareIcon';

const Controls = ({
  apiError,
  onShare,
  onEdit,
  onCopy,
  onDelete,
  onApiErrorClear,
  item,
}) => {
  const company = useSelector(selectCurrentCompany);
  const {
    open,
    handleMoreIconClick,
    handleClickOutside,
    handleControlClick,
  } = useControls();

  const [alertOpen, {
    handleAlertControlClick,
    handleAgree,
    handleDisagree,
    ariaAction,
    title,
  }] = useAlertControl();

  const [warningOpen, {
    handleWarningControlClick,
    handleWarningClose,
  }] = useWarningControl();

  const [deleteProcessing, setDeleteProcessing] = useState(false);
  const [warningTitle, setWarningTitle] = useState('');

  useEffect(() => {
    if (deleteProcessing) {
      handleAlertControlClick(Types.Delete, onDelete)();
      setDeleteProcessing(false);
    }
  }, [
    deleteProcessing,
    warningTitle,
    handleWarningControlClick,
    handleAlertControlClick,
    onDelete,
  ]);

  useEffect(() => {
    if (apiError && apiError.type === 'DELETE' && apiError.id === item.id) {
      handleWarningControlClick();
      const titles = apiError.data.message.map((message) => {
        if (message.reason === 'used') {
          const products = message.usedIn.map((p) => p.name).join(', ');
          if (message.location === 'Product') {
            return `Action cannot be performed because given material is used in the following products: [${products}]`;
          }
          if (message.location === 'Studio') {
            return `Action cannot be performed because given material is used in the following studios: [${products}]`;
          }
          return 'Action cannot be performed because given material is used';
        }
      });
      setWarningTitle(titles.join('\n'));
    }
    if (warningOpen && !apiError) {
      handleWarningClose();
    }
  }, [
    warningOpen,
    handleWarningControlClick,
    apiError,
  ]);

  const handleDeleteClick = (event) => {
    event.stopPropagation();
    setDeleteProcessing(true);
  };

  return (
    <>
      { !company?.default && item.defaultContent ? (
        <CardControls
          open={ open }
          onMoreIconClick={ handleMoreIconClick }
          onClickOutside={ handleClickOutside }
        >
          <Control onClick={ handleControlClick(onCopy) }>
            <CopyIcon />
          </Control>
        </CardControls>
      ) : (
        <>
          <CardControls
            open={ open }
            onMoreIconClick={ handleMoreIconClick }
            onClickOutside={ handleClickOutside }
          >
            { company?.default && (
              <Control onClick={ handleControlClick(onShare) }>
                <ShareIcon size="small" />
              </Control>
            )}
            <>
              <Control onClick={ handleControlClick(onEdit) }>
                <EditIcon />
              </Control>
              <Control onClick={ handleControlClick(onCopy) }>
                <CopyIcon />
              </Control>
              <Control onClick={ handleDeleteClick }>
                <DeleteIcon />
              </Control>
            </>
          </CardControls>
          <DialogsContainer>
            <AlertDialog
              open={ alertOpen }
              ariaAction={ ariaAction }
              title={ title }
              onAgree={ handleControlClick(handleAgree) }
              onDisagree={ handleDisagree }
            />
            <WarningDialog
              open={ warningOpen }
              title={ warningTitle }
              onClose={ onApiErrorClear }
            />
          </DialogsContainer>
        </>
      )}
    </>
  );
};

export default Controls;
