import { forwardRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import SharedTextField from '@shared/components/text-field';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.common.white,
  },
}));

const TextField = forwardRef(({
  InputProps,
  ...rest
}, ref) => {
  const classes = useStyles();

  return (
    <SharedTextField
      ref={ ref }
      fullWidth
      size="small"
      InputProps={ {
        classes: {
          ...classes,
          ...InputProps?.classes,
        },
        ...InputProps,
      } }
      { ...rest }
    />
  );
});

export default TextField;
