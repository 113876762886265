import * as math from 'mathjs';
import { Matrix } from 'mathjs';
import { Point3D } from '../types';

function clamp(value: number, min: number, max: number): number {
  return Math.max(min, Math.min(max, value));
}

export type Order = 'XYZ' | 'YXZ' | 'ZXY' | 'ZYX' | 'YZX' | 'XZY' | string

export function fromMatrix(m: Matrix, order: Order): Point3D | null {
  const m11 = m.get([0, 0]); const m12 = m.get([0, 1]); const
    m13 = m.get([0, 2]);
  const m21 = m.get([1, 0]); const m22 = m.get([1, 1]); const
    m23 = m.get([1, 2]);
  const m31 = m.get([2, 0]); const m32 = m.get([2, 1]); const
    m33 = m.get([2, 2]);

  switch (order) {
    case 'XYZ':
      if (Math.abs(m13) < 0.9999999) {
        return {
          x: Math.atan2(-m23, m33),
          y: Math.asin(clamp(m13, -1, 1)),
          z: Math.atan2(-m12, m11),
        };
      }
      return {
        x: Math.atan2(m32, m22),
        y: Math.asin(clamp(m13, -1, 1)),
        z: 0,
      };

    case 'YXZ':
      if (Math.abs(m23) < 0.9999999) {
        return {
          x: Math.asin(-clamp(m23, -1, 1)),
          y: Math.atan2(m13, m33),
          z: Math.atan2(m21, m22),
        };
      }
      return {
        x: Math.asin(-clamp(m23, -1, 1)),
        y: Math.atan2(-m31, m11),
        z: 0,
      };

    case 'ZXY':
      if (Math.abs(m32) < 0.9999999) {
        return {
          x: Math.asin(clamp(m32, -1, 1)),
          y: Math.atan2(-m31, m33),
          z: Math.atan2(-m12, m22),
        };
      }
      return {
        x: Math.asin(clamp(m32, -1, 1)),
        y: 0,
        z: Math.atan2(m21, m11),
      };

    case 'ZYX':
      if (Math.abs(m31) < 0.9999999) {
        return {
          x: Math.atan2(m32, m33),
          y: Math.asin(-clamp(m31, -1, 1)),
          z: Math.atan2(m21, m11),
        };
      }
      return {
        x: 0,
        y: Math.asin(-clamp(m31, -1, 1)),
        z: Math.atan2(-m12, m22),
      };

    case 'YZX':
      if (Math.abs(m21) < 0.9999999) {
        return {
          x: Math.atan2(-m23, m22),
          y: Math.atan2(-m31, m11),
          z: Math.asin(clamp(m21, -1, 1)),
        };
      }
      return {
        x: 0,
        y: Math.atan2(m13, m33),
        z: Math.asin(clamp(m21, -1, 1)),
      };

    case 'XZY':
      if (Math.abs(m12) < 0.9999999) {
        return {
          x: Math.atan2(m32, m22),
          y: Math.atan2(m13, m11),
          z: Math.asin(-clamp(m12, -1, 1)),
        };
      }
      return {
        x: Math.atan2(-m23, m33),
        y: 0,
        z: Math.asin(-clamp(m12, -1, 1)),
      };
  }

  return null;
}

export function toMatrix(euler: Point3D, order: Order): Matrix | undefined {
  const { x, y, z } = euler;

  const a = Math.cos(x); const
    b = Math.sin(x);
  const c = Math.cos(y); const
    d = Math.sin(y);
  const e = Math.cos(z); const
    f = Math.sin(z);

  const ac = a * c; const ad = a * d; const ae = a * e; const
    af = a * f;
  const bc = b * c; const bd = b * d; const be = b * e; const
    bf = b * f;
  const ce = c * e; const
    cf = c * f;
  const de = d * e; const
    df = d * f;

  switch (order) {
    case 'XYZ':
      return math.matrix([
        [c * e, -c * f, d],
        [af + be * d, ae - bf * d, -b * c],
        [bf - ae * d, be + af * d, a * c],
      ]);
    case 'YXZ':
      return math.matrix([
        [ce + df * b, de * b - cf, a * d],
        [a * f, a * e, -b],
        [cf * b - de, df + ce * b, a * c],
      ]);
    case 'ZXY':
      return math.matrix([
        [ce - df * b, -a * f, de + cf * b],
        [cf + de * b, a * e, df - ce * b],
        [-a * d, b, a * c],
      ]);
    case 'ZYX':
      return math.matrix([
        [c * e, be * d - af, ae * d + bf],
        [c * f, bf * d + ae, af * d - be],
        [-d, b * c, a * c],
      ]);
    case 'YZX':
      return math.matrix([
        [c * e, bd - ac * f, bc * f + ad],
        [f, a * e, -b * e],
        [-d * e, ad * f + bc, ac - bd * f],
      ]);
    case 'XZY':
      return math.matrix([
        [c * e, -f, d * e],
        [ac * f + bd, a * e, ad * f - bc],
        [bc * f - ad, b * e, bd * f + ac],
      ]);
  }
}
