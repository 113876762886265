import { SplitPane as RMSPSplitPane } from 'react-multi-split-pane';

const SplitPane = ({
  children, split, defaultSizes, minSize,
}) => (
  <RMSPSplitPane split={ split } defaultSizes={ defaultSizes } minSize={ minSize }>
    {children}
  </RMSPSplitPane>
);

export default SplitPane;
