import { useCallback, memo } from 'react';
import { useSelector } from 'react-redux';
import Card from '@shared/components/cards-list/Card';
import { makeStyles } from '@material-ui/core/styles';
import CardImage from '@shared/components/cards-list/CardImage';
import CardContent from '@shared/components/cards-list/CardContent';
import CardTitle from '@shared/components/cards-list/CardTitle';
import CardLabel from '@shared/components/cards-list/CardLabel';
import { selectCurrentCompany } from '@store/app/selectors';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(0.8, 1.5, 0.8, 3.5),
  },
}));

const Model = ({
  className,
  item,
  controls,
  onClick,
  defaultClickable,
}) => {
  const classes = useStyles();
  const company = useSelector(selectCurrentCompany);
  const handleClick = useCallback((evt) => {
    if (!(item.defaultContent && !company.default) || defaultClickable) {
      onClick(item, evt);
    }
  }, [onClick, item]);
  const isHasVariation = item?.childProducts.length > 0;

  return (
    <Card
      className={ className }
      onClick={ handleClick }
      transparent={ item.hidden }
    >
      {controls}
      <CardImage
        src={ item.thumbnail }
        alt="model"
        variant="stretch"
        withPlaceholder
      />
      <CardContent>
        <CardTitle>
          {/* TODO: Should be done with CSS */}
          {item.name.length < 20 ? item.name : `${item.name.substr(0, 20)}...`}
        </CardTitle>
      </CardContent>
      {item.defaultContent && <CardLabel className={ isHasVariation ? classes.root : '' }>{company.default ? 'Published' : 'Default'}</CardLabel>}
    </Card>
  );
};

export default memo(Model);
