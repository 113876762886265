/* eslint-disable max-len */

const MaterialsIcon = () => (
  <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_7619_44303)">
      <mask id="path-1-inside-1_7619_44303" fill="white">
        <rect className="ignore" x="2.75" y="2.75" width="17.6" height="17.6" rx="0.6" />
      </mask>
      <rect className="ignore" x="2.75" y="2.75" width="17.6" height="17.6" rx="0.6" stroke="#BCC8D9" strokeWidth="2" mask="url(#path-1-inside-1_7619_44303)" />
      <rect x="15.4609" y="3.72656" width="0.977778" height="15.6444" fill="#BCC8D9" />
      <rect x="6.66016" y="3.72656" width="0.977778" height="15.6444" fill="#BCC8D9" />
      <rect x="11.0625" y="3.72656" width="0.977778" height="15.6444" fill="#BCC8D9" />
      <rect x="19.3711" y="15.4609" width="0.977778" height="15.6444" transform="rotate(90 19.3711 15.4609)" fill="#BCC8D9" />
      <rect x="19.3711" y="6.66211" width="0.977778" height="15.6444" transform="rotate(90 19.3711 6.66211)" fill="#BCC8D9" />
      <rect x="19.3711" y="11.0625" width="0.977778" height="15.6444" transform="rotate(90 19.3711 11.0625)" fill="#BCC8D9" />
    </g>
    <defs>
      <clipPath id="clip0_7619_44303">
        <rect className="ignore" width="22" height="22" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default MaterialsIcon;
