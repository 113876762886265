import classNames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  backdropRoot: {
    position: 'absolute',
    zIndex: 2,
    width: '100%',
    height: '100%',
    backgroundColor: '#00000040',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: '#ffffff',
    flexDirection: 'column',
  },
}));

const Backdrop = ({ children, className }) => {
  const classes = useStyles();

  return (
    <div className={ classNames(className, classes.backdropRoot) }>
      {children}
    </div>
  );
};

export default Backdrop;
