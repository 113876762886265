import sortByName from './sortByName';
import { SortId } from './sortValues';

const getDate = (item) => new Date(item.updatedAt || item.created || item.createdAt).getTime() || 0;

const sortByDate = (items, sortType) => [...items].sort((a, b) => (sortType === SortId.Latest
  ? getDate(a) - getDate(b)
  : getDate(b) - getDate(a)));

const sortData = (items, sortType, sortField) => {
  const [itemsToSort, hiddenItems] = items.reduce((acc, item) => (item.hidden
    ? [acc[0], [...acc[1], item]]
    : [[...acc[0], item], acc[1]]),
  [[], []]);
  const sortedItems = [SortId.AtoZ, SortId.ZtoA].includes(sortType)
    ? sortByName(itemsToSort, sortType, sortField)
    : sortByDate(itemsToSort, sortType);
  return [...sortedItems, ...hiddenItems];
};

export default sortData;
