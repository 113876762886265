import { useCallback, useRef, useState } from 'react';
import EditModelForm from './ModelForm';
import FormFieldset from '../FormFieldset';
import ProductFormDialog from '../ProductFormDialog';
import CreateVariantFormDialog from '@pages/products/modal/create-variant/VariantFormDialog';
import EditVariantFormDialog from '@pages/products/modal/edit-variant/VariantFormDialog';
import { actions as appActions } from '@store/app';
import { useDispatch } from 'react-redux';
import { resetProductApiState } from '@store/products/actions';

const VariantDialogTypes = {
  none: 'none',
  create: 'create',
  edit: 'edit',
};

const ModelFormDialog = ({
  open,
  data,
  onClose,
}) => {
  const dispatch = useDispatch();
  const beforeSubmitCallback = useRef();

  const [variantData, setVariantData] = useState(null);
  const [showVariantDialog, setShowVariantDialog] = useState(VariantDialogTypes.none);
  const [initialTab, setInitialTab] = useState(0);
  const [isSaveDisabled, setIsSaveDisabled] = useState(true);

  const handleSubmit = async (formData) => {
    if (beforeSubmitCallback.current) {
      await beforeSubmitCallback.current(formData);
    }
    dispatch(resetProductApiState());
  };

  const handleCancel = () => {
    dispatch(resetProductApiState());
    onClose();
    setInitialTab(0);
  };

  const registerBeforeSubmit = useCallback((callback) => {
    beforeSubmitCallback.current = callback;
  }, []);

  const handleAddVariantClick = useCallback(() => {
    const allMaterialsSet = !data.materials.some((m) => !m.material);
    if (allMaterialsSet) {
      setVariantData(data ? ({
        ...data,
        parentProduct: { ...data },
        childProducts: [],
        id: null,
        attributes: [],
      }) : null);
      setShowVariantDialog(VariantDialogTypes.create);
    } else {
      dispatch(appActions.addMessage({ message: 'Please select all materials for the product to be able to create variations.', type: 'error' }));
    }
  }, [data]);

  const handleEditVariantClick = useCallback((variantId) => {
    const variant = data?.childProducts?.find((item) => item.id === variantId);

    setVariantData(variant ? ({ ...variant, parentProduct: { ...data } }) : null);
    setShowVariantDialog(VariantDialogTypes.edit);
  }, [data]);

  const handleVariantModelClose = useCallback(() => {
    setShowVariantDialog(VariantDialogTypes.none);
  }, []);

  // TODO: check FormFieldset props. ATM it is only "renewable" and "registerBeforeSubmit" are available
  return (
    <>
      <ProductFormDialog
        open={ open }
        Form={ EditModelForm }
        FormProps={ {
          open,
          data,
          onSubmit: handleSubmit,
        } }
        title={ data?.id ? 'Edit Product' : 'Add New Product' }
        isSaveDisabled={ isSaveDisabled }
        content={ (
          <FormFieldset
            data={ data }
            onAddVariantClick={ handleAddVariantClick }
            onEditVariantClick={ handleEditVariantClick }
            registerBeforeSubmit={ registerBeforeSubmit }
            initialtab={ initialTab }
            onTabChange={ setInitialTab }
            onObject={ (object) => {
              setIsSaveDisabled(!object);
            } }
          />
        ) }
        onCancel={ handleCancel }
        onClose={ handleCancel }
        cancelButtonText="Quit"
      />

      <CreateVariantFormDialog
        open={ showVariantDialog === VariantDialogTypes.create }
        data={ variantData }
        onClose={ handleVariantModelClose }
      />

      <EditVariantFormDialog
        open={ showVariantDialog === VariantDialogTypes.edit }
        data={ variantData }
        onClose={ handleVariantModelClose }
      />
    </>
  );
};

export default ModelFormDialog;
