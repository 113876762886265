import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(6),
  },
}));

const Content = ({ children }) => {
  const classes = useStyles();

  return <div className={ classes.root }>{children}</div>;
};

export default Content;
