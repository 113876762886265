import classNames from 'classnames';
import { makeStyles } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Radio from '@material-ui/core/Radio';
import Typography from '@shared/components/typography';
import SubTitle from '../SubTitle';

const useStyles = makeStyles((theme) => ({
  item: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    padding: theme.spacing(1.5, 0),
    color: theme.palette.common.darkGrey,
    cursor: 'pointer',
  },
  checked: {
    backgroundColor: theme.palette.common.lightBlue,
  },
  grid: {
    width: theme.spacing(22),
    margin: '0 auto',
  },
  radio: {
    display: 'none',
  },
  paper: {
    width: theme.spacing(10),
    height: theme.spacing(10),
    borderColor: theme.palette.common.lightBlue,
  },
}));

const OrientationGroup = ({
  options,
  selectedValue,
  onChange,
  name,
  label,
}) => {
  const classes = useStyles();

  const handleChange = ({ target: { value, name } }) => {
    onChange({ value }, name);
  };

  return (
    <>
      <SubTitle align="center">{label}</SubTitle>
      <Grid className={ classes.grid } container spacing={ 1 }>
        {options.map(({
          value, title, icon: Icon,
        }) => {
          const isChecked = selectedValue === value;
          return (
            <Grid item xs={ 6 } key={ value }>
              <Paper className={ classes.paper } variant="outlined">
                <label className={ classNames(classes.item, { [classes.checked]: isChecked }) }>
                  <Radio
                    className={ classes.radio }
                    name={ name }
                    value={ value }
                    onChange={ handleChange }
                    checked={ isChecked }
                  />
                  <Icon size="large" selected={ isChecked } />
                  <Typography variant="body1">{title}</Typography>
                </label>
              </Paper>
            </Grid>
          );
        })}
      </Grid>
    </>
  );
};

export default OrientationGroup;
