import { makeStyles } from '@material-ui/core/styles';
import { Canvas as RTFCanvas } from '@react-three/fiber';

import axesSrc from '@images/icons/axes.svg';

const useStyles = makeStyles(() => ({
  root: {
    minHeight: 260,
    maxHeight: 260,
  },
  canvas: {
    background: `url('${axesSrc}') no-repeat`,
    backgroundPosition: 'center center',
    cursor: 'pointer',
    minHeight: 'inherit',
    maxHeight: 'inherit',

    '& div': {
      minHeight: 'inherit',
      maxHeight: 'inherit',
    },

    '& canvas': {
      width: '100% !important',
      height: 'auto !important',
      '@media(min-width: 1280px)': {
        padding: 0.7,
      },
    },
  },
}));

const Canvas = ({
  children,
}) => {
  const classes = useStyles();

  return (
    <div className={ classes.root }>
      <RTFCanvas
        className={ classes.canvas }
        gl={ { antialias: false } }
      >
        {children}
      </RTFCanvas>
    </div>
  );
};

export default Canvas;
