/* eslint-disable max-len */
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core';
import SvgIcon from '@material-ui/core/SvgIcon';
import { IconSize, DefaultIconSizeValue } from './constants';

export const IconSizeValue = {
  ...DefaultIconSizeValue,
  [IconSize.Large]: '43px',
};

const useStyles = makeStyles(() => ({
  root: {
    fill: 'none',
  },
}));

const ImageIcon = ({ size = IconSize.Medium, className }) => {
  const classes = useStyles();
  return (
    <SvgIcon
      style={ { width: IconSizeValue[size], height: IconSizeValue[size] } }
      className={ classNames(classes.root, className) }
      viewBox="0 0 43 43"
    >
      <rect width="43" height="43" rx="6" fill="#1FD09D" />
      <path d="M29.8166 14.0228H13.2706C12.8553 14.0228 12.5186 14.3595 12.5186 14.7749V28.3125C12.5186 28.7278 12.8553 29.0646 13.2706 29.0646H29.8166C30.2319 29.0646 30.5686 28.7278 30.5686 28.3125V14.7749C30.5686 14.3595 30.2319 14.0228 29.8166 14.0228Z" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M12.5186 25.304L17.2513 20.5712C17.3212 20.5014 17.4041 20.446 17.4953 20.4082C17.5866 20.3704 17.6844 20.351 17.7831 20.351C17.8819 20.351 17.9797 20.3704 18.071 20.4082C18.1622 20.446 18.2451 20.5014 18.315 20.5712L22.516 24.7722C22.5858 24.8421 22.6687 24.8975 22.7599 24.9353C22.8512 24.9731 22.949 24.9925 23.0478 24.9925C23.1465 24.9925 23.2443 24.9731 23.3356 24.9353C23.4268 24.8975 23.5097 24.8421 23.5796 24.7722L25.5243 22.8275C25.5941 22.7577 25.677 22.7023 25.7683 22.6645C25.8595 22.6267 25.9573 22.6072 26.0561 22.6072C26.1549 22.6072 26.2527 22.6267 26.3439 22.6645C26.4352 22.7023 26.5181 22.7577 26.5879 22.8275L30.5686 26.8082" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M24.176 20.0395C24.799 20.0395 25.3041 19.5344 25.3041 18.9113C25.3041 18.2883 24.799 17.7832 24.176 17.7832C23.5529 17.7832 23.0479 18.2883 23.0479 18.9113C23.0479 19.5344 23.5529 20.0395 24.176 20.0395Z" fill="white" />
    </SvgIcon>
  );
};

export default ImageIcon;
