import { forwardRef } from 'react';
import InputAdornment from '@material-ui/core/InputAdornment';
import SharedTextField from '../TextField';

const TextField = forwardRef(({
  label,
  ...rest
}, ref) => (
  <SharedTextField
    type="number"
    InputProps={ {
      startAdornment: (
        <InputAdornment position="start">
          {label}
        </InputAdornment>
      ),
    } }
    ref={ ref }
    { ...rest }
  />
));

export default TextField;
